import React from "react";
import PropTypes from "prop-types";
import {doLogout} from "../../store/actions/authActions";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import SearchBar from "customs/components/SearchBar";
import Results from "customs/components/Results";
import Paper from "@material-ui/core/Paper";
import axios from "axios/axios";
import validations from "../../customs/helpers/validations";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import helpers from "customs/helpers/helpers";

class Tools extends React.Component {

    initSearch = {
        "title": "",
        "name": "",
        "active": "",
        "abn": "",
        "location": ""
    };

    constructor (props) {

        super(props);
        this.state = {
            "search": "",
            "searchText": "Enter a ABN and press enter to conduct a search.",
            "searchColor": "textSecondary",
            "searchResult": this.initSearch
        };

        this.handleSearch = this.handleSearch.bind(this);

    }

    handleSearch (value) {
        if (validations.validateABN(this.state.search)) {
            this.setState({"searchResult": this.initSearch, searchText: "Searching, please wait...", searchColor: "textSecondary"});

            helpers.showLoading();
            axios(this.props)
                .get(`/abn/${value}`)
                .then((response) => {
                    helpers.hideLoading();
                    if (response.data.exception && response.data.exception.exceptionDescription) {
                        this.setState({searchText: response.data.exception.exceptionDescription, searchColor: "secondary"});
                    } else {
                        this.setState({
                            "searchResult": {
                                "title": `${response.data.businessEntity.entityType.entityDescription} | ASIC Number: ${response.data.businessEntity.ASICNumber}`,
                                "name": response.data.businessEntity.mainName.organisationName,
                                "active": response.data.businessEntity.entityStatus.effectiveFrom,
                                "abn": response.data.businessEntity.ABN.identifierValue,
                                "location": `${response.data.businessEntity.mainBusinessPhysicalAddress.stateCode} ${response.data.businessEntity.mainBusinessPhysicalAddress.postcode}`
                            }
                        });
                    }
                }).catch((error) => {
                    this.setState({searchText: "An error has occurred, please try again."});
                });
        }
    }

    render () {
        return (
            <Paper style={{"paddingTop": "0.5rem"}}>
                <SearchBar
                    error={
                        !validations.validateABN(this.state.search) && this.state.search.length > 0
                    }
                    label="ABN Search"
                    onChange={(newValue) => this.setState({"search": newValue})}
                    onRequestSearch={() => this.handleSearch(this.state.search)}
                    success={validations.validateABN(this.state.search) ? 1 : 0}
                    value={this.state.search}
                />
                {this.state.searchResult.title !== ""
                    ? <Results
                        abn={this.state.searchResult.abn}
                        active={this.state.searchResult.active}
                        location={this.state.searchResult.location}
                        name={this.state.searchResult.name}
                        title={this.state.searchResult.title}
                      />
                    : <Card>
                        <CardContent>
                            <Typography
                                color={this.state.searchColor}
                                gutterBottom
                            >
                                {this.state.searchText}
                            </Typography>
                        </CardContent>
                      </Card>}
            </Paper>
        );
    }
}

Tools.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Tools));
