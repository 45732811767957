import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SignatureCanvas from 'react-signature-canvas';

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class SignaturePad extends React.Component {
    
    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="update-profile-confirm-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => this.props.onClose("signaturePadModal")}
                open={this.props.signaturePadModal}
                fullWidth
                maxWidth="sm"
                style={{"minHeight": "80vh"}}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {"Create new signature"}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="update-profile-confirm-modal-slide-description"
                    style={{"textAlign": "center"}}
                >
                    <SignatureCanvas
                        penColor='black'
                        canvasProps={{width: 500, height: 242, className: 'sigCanvas'}}
                        ref={(ref) => { this.sigCanvas = ref }}
                    />
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        onClick={() => {
                            this.props.onAccept(this.sigCanvas.toDataURL());
                            this.props.onClose("signaturePadModal");
                        }}
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color="success"
                        simple
                    >
                        Accept
                    </Button>
                    <Button
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color="primary"
                        simple
                        onClick={() => this.sigCanvas.clear()}
                    >
                        Reset
                    </Button>
                    <Button
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color="transparent"
                        onClick={() => this.props.onClose("signaturePadModal")}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(SignaturePad);
