import decode from "jwt-decode";

export default class AuthService {

    constructor (state, props) {
        this.state = state;
        this.props = props;
    }

    getToken () {
        let token = "";
        try {
            if (
                this.state &&
                this.state.auth &&
                this.state.auth.data &&
                this.state.auth.data.token
            ) {
                if (this.state.auth.data.user) {
                    token = this.state.auth.data.token;
                }
            } else if (this.props && this.props.auth && this.props.auth.token) {
                if (this.props.auth.user) {
                    token = this.props.auth.token;
                }
            }
        } catch (e) {}
        return token;
    }

    loggedIn () {
        const token = this.getToken();
        return Boolean(token) && !this.isTokenExpired(token);
    }

    isTokenExpired (token) {
        try {
            const decoded = decode(token);
            return decoded.exp < Date.now() / 1000;
        } catch (err) {}
        return false;
    }

    isPasswordExpired () {
        const password_expires = this.state.auth &&
            this.state.auth.data &&
            this.state.auth.data.user &&
            this.state.auth.data.user.expires
                ? this.state.auth.data.user.expires * 1000
                : (this.props.auth &&
                this.props.auth.user &&
                this.props.auth.user.expires
                    ? this.props.auth.user.expires * 1000
                    : "");
        return password_expires ? password_expires < Date.now() : true;
    }

    getUserId () {
        return this.props.auth && this.props.auth.user && this.props.auth.user.id
            ? this.props.auth.user.id
            : "";
    }

}
