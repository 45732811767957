import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ReactTable from "react-table";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import {doLogout} from "../../store/actions/authActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../../customs/components/Pagination";
import Refresh from "@material-ui/icons/Refresh";
import axios from "../../axios/axios";
import axiosHelper from "axios/axiosHelper";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import AccessControl from "customs/components/AccessControl";
import helpers from "customs/helpers/helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

class Mcc extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            "documents_pages": 1,
            "loading": false,
            "doneModalMsg": "",
            "tr_color": "success",
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "mccs": [],
            "selected_file": null
        };
        this.refreshMccsTable = this.refreshMccsTable.bind(this); 
        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.updateMccStatus = this.updateMccStatus.bind(this);
        this.setCheckbox = this.setCheckbox.bind(this);
    }

    handleConfirmClick () {
        
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

    setCheckbox(mcc, index, classes) {
        return <FormControlLabel
            control={
                <Checkbox
                    key={"key_" + index}
                    className={classes.positionAbsolute}
                    tabIndex={-1}
                    onClick={() => {
                        this.updateMccStatus(mcc.code, mcc.active);
                    }}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                    }}
                    checked={mcc.active === 1 ? true : false}
                    disabled={!AccessControl.checkRoutePermissions("/mcc", this.props.auth, "update")}
                />
            }
            label={mcc.active === 1 ? 'Active' : 'Inactive'}
        />;
    }

    updateMccStatus(code, active) {
        const url = "/mcc/status",
              postObject = {"code": code, "status": active === 1 ? 0 : 1,};
        helpers.showLoading();
        axios(this.props)
            .post(url, postObject)
            .then((response) => {
                const { classes } = this.props;
                let mccs = this.state.mccs.map((mcc, index) => {
                    if (mcc.code === code) {
                        mcc.active = active === 1 ? 0 : 1;
                        mcc.status = this.setCheckbox(mcc, index, classes);
                    }
                    return mcc;
                });
                this.setState({mccs});
                helpers.hideLoading();
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processMccData (res) {
        const { classes } = this.props;
        const mccs = res.data.data.map((mcc, index) => {
            mcc.status = this.setCheckbox(mcc, index, classes);
            return mcc;
        });
        this.setState({
            mccs,
            documents_pages: res.data.last_page,
            loading: false,
            "items_data": res.data.from && res.data.to && res.data.total
                ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
                : "",
        });
    }

    hideNotification;
    showUsersNotification () {
        if (!this.state.tr) {
            this.setState({"tr": true});
        } else {
            clearTimeout(this.hideNotification);
        }
        this.setHideNotificationTimeout();
    }
    
    setUsersNotification (msg, color) {
        this.setState({"doneModalMsg": msg, "tr_color": color});
        this.showUsersNotification();
    }

    setHideNotificationTimeout () {
        this.hideNotification = setTimeout(
            () => {
                this.handleCloseNotification();
            },
            this.state.tr_color === "success" ? 5000 : 10000
        );
    }

    handleCloseNotification () {
        clearTimeout(this.hideNotification);
        this.setState({"tr": false,
            "doneModalMsg": ""});
    }

    getMccsData (page, pageSize, sorted, filtered, handleRetrievedData) {
        const url = "/mcc/paginate",
            postObject = {
                "page": page + 1,
                "per_page": pageSize,
                "sort": sorted,
                "filter": filtered,
            };

        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then((response) => {
                handleRetrievedData(response);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    CustomTableFilter = ({filter, onChange}) => (<form>
        <TextField
          className="w-100"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          onChange={event => onChange(event.target.value)}
          type="search"
          value={filter ? filter.value : ""}
          />
      </form>)
    ;

    MccsTableColumns = [
        {
            "Header": "Status",
            "accessor": "status",
            "width": 150,
            "Filter": ({filter, onChange}) => (
                <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{"width": "100%", "height": "3.2rem", fontSize: ".875rem"}}
                    value={filter ? filter.value : "all"}
                >
                    <option value="" />
                    <option key="active_1" value="1">
                        {"Active"}
                    </option>
                    <option key="active_0" value="0">
                        {"Inactive"}
                    </option>
                </select>
            )
        },
        {
            "Header": "Code",
            "accessor": "code",
            "width": 150,
            "Filter": this.CustomTableFilter
        },
        {
            "Header": "Title",
            "accessor": "title",
            "Filter": this.CustomTableFilter
        },
    ];

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showUsersNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );
        }

    refreshMccsTable() {
        this.refReactMccsTable.fireFetchData();
    }

    render () {

        const authService = new AuthService(
            this.state,
            this.props
        );
        if (!authService.getToken()) {
            return <Redirect to="/auth/login" />;
        }


        if (authService.isPasswordExpired()) {
            return <Redirect to="/admin/changepassword" />;
        }
        const {classes} = this.props,
            {mccs} = this.state;
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            color="success"
                            icon
                        >
                            <CardIcon color="success">
                                <SettingsApplications />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                MCC
                            </h4>
                            <CardIcon
                                title={'Refresh MCC table'}
                                className="float-button"
                                color="warning"
                                onClick={() => this.refreshMccsTable()}
                            >
                                <Refresh />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                className="-striped -highlight"
                                columns={this.MccsTableColumns}
                                data={mccs}
                                defaultPageSize={20}
                                filterable
                                manual
                                loading={this.state.loading}
                                onFetchData={(state, instance) => {
                                    this.setState({"loading": true});
                                    this.getMccsData(
                                        state.page,
                                        state.pageSize,
                                        state.sorted,
                                        state.filtered,
                                        (res) => this.processMccData(res)
                                    );
                                }}
                                pages={this.state.documents_pages}
                                PaginationComponent={Pagination}
                                ref={(refReactMccsTable) => {
                                    this.refReactMccsTable = refReactMccsTable;
                                }}
                                itemsCountText={this.state.items_data}
                                showPageSizeOptions
                                showPaginationBottom
                                showPaginationTop={false}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12}>
                    <style>
                        {"@media (min-width: 768px) {.MuiTabs-flexContainer {float: right;}}" +
                    ".CustomTabs-cardTitle-1018 {font-weight: bold;} .MuiTabs-scroller {overflow: auto !important;}" +
                    ".MuiTableCell-head {font-size: 0.9rem !important;}" +
                    ".MuiTableCell-body {padding: 0px 0px 0px 8px! important;}" +
                    ".ReactTable .rt-thead .rt-th:last-child {text-align: left !important;}" +
                    ".MuiFormControlLabel-label {font-size: .875rem; color: rgba(0, 0, 0, 0.65);}"}
                    </style>
                </GridItem>
                {this.state.doneModalMsg
                    ? <Snackbar
                        close
                        closeNotification={() => {
                            this.handleCloseNotification();
                        }}
                        color={this.state.tr_color}
                        icon={AddAlert}
                        message={this.state.doneModalMsg}
                        open={this.state.tr}
                        place="tr"
                    />
                    : ""}
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />
            </GridContainer>
        );

    }
}

Mcc.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Mcc));
