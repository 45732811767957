import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

class ChangeApplicationStatusDialog extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
            "previous_status": props.application_status,
            "current_status": props.application_status,
            "btnDisabled": true,
            "message": "",
            "additional_fields_values": [],
            "message_text": "Message (Optional)",
            "changeStatus": props.changeStatus
        };
    }

    handleChange = (value) => {
        if (value !== this.state.current_status) {
            this.setState({"current_status" : value});
        }
        let btnDisabled = this.state.previous_status === value;
        if ((value === 'not_suitable' || value === 'declined') && this.state.message.toString().length === 0) btnDisabled = true;
        this.setState({
            "btnDisabled" : btnDisabled,
            "message_text": value === 'not_suitable' || value === 'declined' ? 'Message (Required)' : 'Message (Optional)',
        });
        this.updateUI();
    };

    componentWillReceiveProps(props) {
        if (props.changeStatus !== this.state.changeStatus) {
            if (props.application_status !== this.state.current_status) {
                this.setState({"previous_status" : props.application_status, "current_status" : props.application_status, "btnDisabled" : true});
            }
            if (props.available_fields) {
                let additional_fields_values = [];
                props.available_fields.forEach(element => {
                    additional_fields_values[element.registry_key] = this.state.additional_fields_values.hasOwnProperty(element.registry_key)
                        ? (this.state.additional_fields_values[element.registry_key] === 0 ? 0 : 1) : 0 ;
                });
                this.setState({"additional_fields_values": props.changeStatus ? additional_fields_values : []});
            }
            if (this.props.changeStatus !== props.changeStatus && props.changeStatus === true)
                this.setState({"message": ""});
        }
        this.setState({"changeStatus": props.changeStatus});
    }

    updateUI() {
        // TODO
    }

    handleStatus(event) {
        this.props.handleStatusChange(this.props.selected_application_id, this.state.current_status, this.state.message, this.state.additional_fields_values);
    }

    allPagesAreVerified() {
        let all_verified = true;
        this.props.verify_pages.forEach(element => {
            if (!this.props.checkIfVerified(element)) all_verified = false;
        });
        return all_verified;
    }

  render () {
      const {classes} = this.props;
      return (
          <Dialog
              aria-describedby="update-profile-confirm-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{
                  "root": `${classes.center} ${classes.modalRoot}`,
                  "paper": `${classes.modal} ${classes.modalSmall}`
              }}
              fullWidth
              keepMounted
              onClose={() => {this.setState({"additional_fields_values": [], "message": ""}); this.props.onClose("changeStatus");}}
              open={this.props.changeStatus}
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  Application Status
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="update-profile-confirm-modal-slide-description"
              >
                <InputLabel
                    className={classes.selectLabel}
                    htmlFor="application_status"
                >
                    Status
                </InputLabel>
                <Select
                    value={this.state.current_status ? this.state.current_status : ""}
                    onChange={(event) => this.handleChange(event.target.value)}
                    MenuProps={{"className": classes.selectMenu}}
                    classes={{"select": classes.select}}
                    inputProps={{
                        name: `application_status`,
                        id: `application_status`
                    }}
                    style={{width: "100%"}}
                >
                    <MenuItem value="" />
                    <MenuItem disabled={!this.props.canApprove} classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={1} value={'approved'}>{'Approved'}</MenuItem>
                    <MenuItem disabled={!this.allPagesAreVerified()} classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={2} value={'conditional_approved'}>{'Conditional Approved' + (!this.allPagesAreVerified() ? ' (Verify all pages)' : '')}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={3} value={'declined'}>{'Declined'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={4} value={'application_submitted'}>{'Application Submitted'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={5} value={'application_resubmitted'}>{'Application Resubmitted'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={6} value={'application_in_review'}>{'Application In Review'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={7} value={'awaiting_customer_response'}>{'Awaiting Customer Response'}</MenuItem>
                    <MenuItem classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple}} key={8} value={'not_suitable'}>{'Not Suitable'}</MenuItem>
                </Select>

                <InputLabel
                    style={{ paddingTop : "1.5rem" }}
                    className={classes.selectLabel}
                    htmlFor="message"
                >
                    {this.state.message_text}
                </InputLabel>
                <TextField
                    inputProps={{
                        name: `message`,
                        id: `message`
                    }}
                    type="text"
                    className="w-100"
                    value={this.state.message ? this.state.message : ""}
                    multiline={true}
                    rows={3}
                    onChange={(event) => {
                        let value = event.target.value;
                        let btnDisabled = this.state.previous_status === this.state.current_status;
                        if ((this.state.current_status === 'not_suitable' || this.state.current_status === 'declined') && value.toString().length === 0) btnDisabled = true;
                        this.setState({"message": value, "btnDisabled" : btnDisabled});
                    }}
                />

                {
                    this.state.current_status === 'awaiting_customer_response'
                    ? <div>
                        <InputLabel key="l1"
                            style={{ paddingTop : "1.5rem", paddingBottom: "0.5rem" }}
                            className={classes.selectLabel}
                        >
                            Request More Information
                        </InputLabel>
                        <div key="d1" style={{padding: "0 13px", border: "1px solid #8080804a", maxHeight: this.props.mcc && this.props.high_risk_mccs.includes(this.props.mcc.value.toString()) ? "25vh" : "50vh", overflowY: "auto"}}>
                        {
                            this.props.available_fields.map((field, index) => {
                                if (field.hasOwnProperty('high_risk') && (field.high_risk === true || field.high_risk === 'true')) {
                                    return '';
                                }
                                if (field.hasOwnProperty('show_in_change_status') && !(field.show_in_change_status === true || field.show_in_change_status === 'true')) {
                                    return '';
                                }
                                return <div key={"d1" + index}>
                                    <Checkbox
                                        // style={{"margin": "2px 0px 2px 13px"}}
                                        checked={
                                            this.state.additional_fields_values.hasOwnProperty(field.registry_key)
                                                ? (this.state.additional_fields_values[field.registry_key] === 0 ? false : true) : false
                                        }
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            "checked": classes.checked,
                                            "root": classes.checkRoot
                                        }}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        onClick={(event) => {
                                            let additional_fields_values = this.state.additional_fields_values;
                                            additional_fields_values[field.registry_key] = event.target.checked ? 1 : 0;
                                            this.setState({"additional_fields_values" : additional_fields_values});
                                        }}
                                        tabIndex={-1}
                                    />
                                    {field.name}</div>;
                            })
                        }
                        </div>
                        {
                            this.props.mcc && this.props.high_risk_mccs.includes(this.props.mcc.value.toString())
                            ? <div>
                                <InputLabel key="l2"
                                    style={{ paddingTop : "1.5rem", paddingBottom: "0.5rem" }}
                                    className={classes.selectLabel}
                                >
                                    High Risk Gaming Merchants
                                </InputLabel>
                                <div key="d2" style={{padding: "0 13px", border: "1px solid #8080804a", maxHeight: "25vh", overflowY: "auto"}}>
                                {
                                    this.props.available_fields.map((field, index) => {
                                        if (!field.hasOwnProperty('high_risk') || !(field.high_risk === true || field.high_risk === 'true')) {
                                            return '';
                                        }
                                        return <div key={"d2" + index}>
                                            <Checkbox
                                                // style={{"margin": "2px 0px 2px 13px"}}
                                                checked={
                                                    this.state.additional_fields_values.hasOwnProperty(field.registry_key)
                                                        ? (this.state.additional_fields_values[field.registry_key] === 0 ? false : true) : false
                                                }
                                                checkedIcon={<Check className={classes.checkedIcon} />}
                                                classes={{
                                                    "checked": classes.checked,
                                                    "root": classes.checkRoot
                                                }}
                                                icon={<Check className={classes.uncheckedIcon} />}
                                                onClick={(event) => {
                                                    let additional_fields_values = this.state.additional_fields_values;
                                                    additional_fields_values[field.registry_key] = event.target.checked ? 1 : 0;
                                                    this.setState({"additional_fields_values" : additional_fields_values});
                                                }}
                                                tabIndex={-1}
                                            />
                                            {field.name}</div>;
                                    })
                                }
                                </div>
                            </div>
                            : ''
                        }
                    </div>
                    : ''
                }

              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                <Button
                    className={classes.modalSmallFooterFirstButton}
                    color="transparent"
                    onClick={() => {this.setState({"additional_fields_values": [], "message": ""}); this.props.onClose("changeStatus");}}
                >
                    Close
                </Button>
                <Button
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color="success"
                    disabled={this.state.btnDisabled}
                    onClick={(event) => this.handleStatus(event)}
                    simple
                    >
                    Update
                </Button>
              </DialogActions>
          </Dialog>
      );
  }
}

export default withStyles(extendedFormsStyle)(ChangeApplicationStatusDialog);
