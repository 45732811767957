import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class ManagerTeamDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            "managers_open": false
        }
    }

  handleChange = (event, maxLength) => {
      event.target.value = event.target.value.slice(0, maxLength);
      this.props.handleChange(event);
  };

  handleMultiple = (event) => {
    let selected = event.target.value;
    if (Object.keys(selected).length > 0) {
        selected = selected.filter((item) => item !== 'filter');
    }
    event.target.value = selected;
      this.props.handleMultiple(event);
  };

  render () {

      const {classes} = this.props;
      return (
          <Dialog
              aria-describedby="avatar-update-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{"root": `${classes.center} ${classes.modalRoot}`}}
              fullWidth
              keepMounted
              maxWidth="sm"
              onClose={() => this.props.onClose("teamDetailsModal")}
              open={this.props.teamDetailsModal}
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  {this.props.dialogTitle}
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="avatar-update-modal-slide-description"
                  style={{"width": "100%",
                      "overflowX": "hidden"}}
              >
                  <GridContainer
                      key="details_grid"
                      style={{"borderTop": "#9c27b0 solid 1px"}}
                  >
                      <GridItem md={12}>
                          <CustomInput
                              error={this.props.name_valid === "error"}
                              formControlProps={{"fullWidth": true}}
                              id="name"
                              inputProps={{
                                  "onChange": (event) => this.handleChange(event, 254),
                                  "value": this.props.name,
                                  "name": "Name",
                                  "disabled": this.props.is_view
                              }}
                              labelText="Name"
                              success={this.props.name_valid === "success"}
                          />
                      </GridItem>
                      <GridItem
                          md={12}
                          sm={12}
                          xs={12}
                      >
                          <CustomInput
                              error={this.props.description_valid === "error"}
                              formControlProps={{
                                  "fullWidth": true
                              }}
                              id="description"
                              inputProps={{
                                  "value": this.props.description,
                                  "onChange": (event) => this.handleChange(event, 512),
                                  "multiline": true,
                                  "rows": 5,
                                  "disabled": this.props.is_view
                              }}
                              labelText={<span>
                                  Description
                                         </span>}
                              success={this.props.description_valid === "success"}
                          />
                      </GridItem>
                  </GridContainer>
                  <GridContainer
                    key="main_manager_grid"
                    >
                        <GridItem md={12}>
                        <FormControl
                            className={classes.selectFormControl}
                            fullWidth
                        >
                            <InputLabel
                                className={classes.selectLabel}
                                error={this.props.managers_valid === "error"}
                                htmlFor="team_managers"
                            >
                                Choose Managers
                            </InputLabel>
                            <Select
                                classes={{"select": classes.select}}
                                inputProps={{
                                    "name": "team_managers",
                                    "id": "team_managers"
                                }}
                                MenuProps={{"className": classes.selectMenu}}
                                multiple
                                onChange={this.handleMultiple}
                                value={this.props.team_managers ? this.props.team_managers : []}
                                open={this.state.managers_open}
                                onOpen={() => this.setState({managers_open: true})}
                                onClose={() => this.setState({managers_open: false})}
                            >
                                <MenuItem
                                    classes={{"root": classes.selectMenuItem}}
                                    disabled
                                >
                                    Choose Managers
                                </MenuItem>
                                <div
                                    classes={{"root": classes.selectMenuItem}}
                                    value="filter"
                                    style={{"padding": "0px 10px", "position": "absolute"}}
                                >
                                    <Button
                                        className="edit"
                                        color="info"
                                        onClick={() => {
                                            this.setState({managers_open: false});
                                        }}
                                        round
                                        style={{"position": "fixed", "zIndex": "10"}}
                                    >
                                        Close
                                    </Button>
                                </div>
                                <MenuItem
                                    classes={{"root": classes.selectMenuItem}}
                                    disabled
                                    style={{"height": "3rem"}}
                                >
                                    {""}
                                </MenuItem>
                                {this.props.managers
                                    ? this.props.managers.map((prop, key) => <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelectedMultiple
                                            }}
                                            key={key}
                                            value={prop.id}
                                        >
                                            {`${prop.first_name  } ${prop.last_name}`}
                                        </MenuItem>)
                                    : ""}
                            </Select>
                        </FormControl>
                        </GridItem>
                    </GridContainer>
              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                  <Button
                      className={classes.modalSmallFooterFirstButton}
                      color="transparent"
                      onClick={() => this.props.onClose("teamDetailsModal")}
                  >
                      Close
                  </Button>
                  <Button
                      className={
                          `${classes.modalSmallFooterFirstButton
                          } ${
                              classes.modalSmallFooterSecondButton}`
                      }
                      color="success"
                      disabled={this.props.btnDisabled}
                      onClick={this.props.onYesClick}
                      simple
                  >
                      Save
                  </Button>
              </DialogActions>
          </Dialog>
      );

  }

}

export default withStyles(extendedFormsStyle)(ManagerTeamDetails);
