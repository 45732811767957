import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class ConfirmDialog extends React.Component {

    render () {

        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="small-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => this.props.onClose("confirmModal")}
                open={this.props.confirmModal}
                style={{"zIndex": 10000}}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.confirmModalTitle
                        ? this.props.confirmModalTitle
                        : "Confirm"}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="small-modal-slide-description"
                >
                    <h5 style={{"minWidth": "200px",
                        "marginBottom": "0px"}}
                    >
                        {this.props.confirmModalMsg
                            ? this.props.confirmModalMsg
                            : "Are you sure?"}
                    </h5>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("confirmModal")}
                    >
                        No
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        onClick={this.props.onYesClick}
                        simple
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(userProfileStyles)(ConfirmDialog);
