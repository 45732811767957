import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ReactTable from "react-table";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import {doLogout} from "../../store/actions/authActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../../customs/components/Pagination";
import Refresh from "@material-ui/icons/Refresh";
import axios from "../../axios/axios";
import axiosHelper from "axios/axiosHelper";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import TagsInput from "react-tagsinput";
import AccessControl from "customs/components/AccessControl";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import helpers from "customs/helpers/helpers";
import LibraryFile from "customs/components/LibraryFile";

class DocumentLibrary extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            "documents_pages": 1,
            "loading": false,
            "doneModalMsg": "",
            "tr_color": "success",
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "document": [],
            "document_details": false,
            "selected_file": null
        };
        this.refreshDocumentsTable = this.refreshDocumentsTable.bind(this); 
        this.setUsersNotification = this.setUsersNotification.bind(this);
    }

    handleConfirmClick () {
        if (this.state.action === "delete_file") {
            this.doHandleDeleteDocumentClick();
        }
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

    processDocumentData (res) {
        const documents = res.data.data.map((document, index) => {
            document.orig_tags = document.tags;
            document.tags = <TagsInput
                disabled
                onlyUnique
                tagProps={{"className": "react-tagsinput-tag primary"}}
                value={document.tags}
            />;
            document.actions =
                <div key={index}>
                    <Button
                        title={'Download file'}
                        className="view"
                        color="facebook"
                        justIcon
                        key={`btnF${index}`}
                        onClick={(event) => this.handleDownloadFile(event, document)}
                        round
                        simple
                    >
                        <i
                            className="fa fa-download fa-sm"
                            key={`i_document_${index}`}
                        />
                    </Button>
                    {" "}
                    {AccessControl.checkRoutePermissions(
                        "/document_library",
                        this.props.auth,
                        "update"
                    )
                        ? <Button
                            title={'Update document'}
                            className="edit"
                            color="info"
                            justIcon
                            onClick={(event) => {
                                const obj = this.state.documents.find((o) => o.id === document.id);
                                this.handleEditDocument(event, obj);
                            }}
                            round
                            simple
                        >
                            <Edit />
                        </Button>
                        : ""
                    }
                    {" "}
                    {AccessControl.checkRoutePermissions(
                        "/document_library",
                        this.props.auth,
                        "delete"
                    )
                        ? <Button
                            title={'Delete document'}
                            className="remove"
                            color="danger"
                            justIcon
                            onClick={(event) => this.handleDeleteDocumentClick(event, document)}
                            round
                            simple
                        >
                            <Close />
                        </Button>
                        : ""
                    }
                    {" "}
                </div>
            ;
            return document;
        });
        this.setState({
            documents,
            documents_pages: res.data.last_page,
            loading: false,
            "items_data": res.data.from && res.data.to && res.data.total
                ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
                : "",
        });
    }

    hideNotification;
    showUsersNotification () {
        if (!this.state.tr) {
            this.setState({"tr": true});
        } else {
            clearTimeout(this.hideNotification);
        }
        this.setHideNotificationTimeout();
    }
    
    setUsersNotification (msg, color) {
        this.setState({"doneModalMsg": msg, "tr_color": color});
        this.showUsersNotification();
    }

    setHideNotificationTimeout () {
        this.hideNotification = setTimeout(
            () => {
                this.handleCloseNotification();
            },
            this.state.tr_color === "success" ? 5000 : 10000
        );
    }

    handleCloseNotification () {
        clearTimeout(this.hideNotification);
        this.setState({"tr": false,
            "doneModalMsg": ""});
    }

    getDocumentsData (page, pageSize, sorted, filtered, handleRetrievedData) {
        const url = "/document_library/paginate",
            postObject = {
                "page": page + 1,
                "per_page": pageSize,
                "sort": sorted,
                "filter": filtered,
            };

        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then((response) => {
                handleRetrievedData(response);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    CustomTableFilter = ({filter, onChange}) => (<form>
        <TextField
          className="w-100"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          onChange={event => onChange(event.target.value)}
          type="search"
          value={filter ? filter.value : ""}
          />
      </form>)
    ;

    DocumentsTableColumns = [
        {"Header": "ID",
            "accessor": "id",
            "width": 70,
            "Filter": this.CustomTableFilter},
        {
            "Header": "Filename",
            "accessor": "filename",
            "Filter": this.CustomTableFilter
        },
        {
            "Header": "Tags",
            "accessor": "tags",
            "sortable": false,
            "filterable": false
        },
        {
            "Header": "Actions",
            "accessor": "actions",
            "sortable": false,
            "filterable": false,
            "width": 150
        }
    ];

    handleDownloadFile = (event, document) => {
        event.preventDefault();
        const FileDownload = require("js-file-download");
        helpers.showLoading();
        axios(this.props)
          .get(
                `document_library/${document.id}/download`,
                {
                    "responseType": "blob",
                    "timeout": 30000
                }
            )
            .then((response) => {
              helpers.hideLoading();
              FileDownload(
                  response.data,
                  document.filename
              );
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));

    };

    handleDeleteDocumentClick = (event, document) => {
        event.preventDefault();

        this.setState({
            "confirmModal": true,
            "confirmModalMsg": "Are you sure you want to delete this file?",
            "confirmModalTitle": "Delete File",
            "action": "delete_file",
            "document": document
        });
    }

    doHandleDeleteDocumentClick () {
        this.setState({"confirmModal": false, "action": "delete_file"});
        helpers.showLoading();
        const fd = new FormData();
        fd.append("_method", "DELETE");
        this.setState({"btnDisabled": true});

        axios(this.props)
            .post(
                `document_library/${this.state.document.id}`,
                fd,
                {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
            )
            .then((response) => {
                helpers.hideLoading();
                if (this.isUnmounted) {return;}
                this.setState({
                    "btnDisabled": false,
                    "document": [],
                    "doneModalMsg": "Document deleted",
                    "tr_color": "success"
                });
                this.showUsersNotification();
                this.refreshDocumentsTable();
            })
            .catch((error) => this.processErrorAxios(error));
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showUsersNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );
        }

    refreshDocumentsTable() {
        this.refReactDocumentsTable.fireFetchData();
    }

    handleAddDocument = (event) => {
        event.preventDefault();
        this.setState({
            "document_details": true,
            "selected_file": null,
            "is_view": false,
            "file_dialog_title": "Create Document"
        });
    };

    handleEditDocument = (event, document) => {
        event.preventDefault();
        this.setState({
            "document_details": true,
            "selected_file": document,
            "is_view": false,
            "file_dialog_title": "Edit Document"
        });
    };

    render () {

        const authService = new AuthService(
            this.state,
            this.props
        );
        if (!authService.getToken()) {
            return <Redirect to="/auth/login" />;
        }


        if (authService.isPasswordExpired()) {
            return <Redirect to="/admin/changepassword" />;
        }
        const {classes} = this.props,
            {documents} = this.state;
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            color="success"
                            icon
                        >
                            <CardIcon color="success">
                                <LibraryBooks />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Document Library
                            </h4>
                            {AccessControl.checkRoutePermissions(
                                "/document_library",
                                this.props.auth,
                                "add"
                            )
                                ? <CardIcon
                                    title={'Create document'}
                                    className="float-button"
                                    color="info"
                                    onClick={(event) => this.handleAddDocument(event)}
                                >
                                    <Add />
                                </CardIcon>
                                : ""
                            }
                            <CardIcon
                                title={'Refresh documents table'}
                                className="float-button"
                                color="warning"
                                onClick={() => this.refreshDocumentsTable()}
                            >
                                <Refresh />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                className="-striped -highlight"
                                columns={this.DocumentsTableColumns}
                                data={documents}
                                defaultPageSize={10}
                                filterable
                                manual
                                loading={this.state.loading}
                                onFetchData={(state, instance) => {
                                    this.setState({"loading": true});
                                    this.getDocumentsData(
                                        state.page,
                                        state.pageSize,
                                        state.sorted,
                                        state.filtered,
                                        (res) => this.processDocumentData(res)
                                    );
                                }}
                                pages={this.state.documents_pages}
                                PaginationComponent={Pagination}
                                ref={(refReactDocumentsTable) => {
                                    this.refReactDocumentsTable = refReactDocumentsTable;
                                }}
                                itemsCountText={this.state.items_data}
                                showPageSizeOptions
                                showPaginationBottom
                                showPaginationTop={false}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12}>
                    <style>
                        {"@media (min-width: 768px) {.MuiTabs-flexContainer {float: right;}}" +
                    ".CustomTabs-cardTitle-1018 {font-weight: bold;} .MuiTabs-scroller {overflow: auto !important;}" +
                    ".MuiTableCell-head {font-size: 0.9rem !important;}" +
                    ".MuiTableCell-body {padding: 0px 0px 0px 8px! important;}"}
                    </style>
                </GridItem>
                {this.state.doneModalMsg
                    ? <Snackbar
                        close
                        closeNotification={() => {
                            this.handleCloseNotification();
                        }}
                        color={this.state.tr_color}
                        icon={AddAlert}
                        message={this.state.doneModalMsg}
                        open={this.state.tr}
                        place="tr"
                    />
                    : ""}
                {this.state.document_details  
                    ? <LibraryFile
                        auth={this.props.auth}
                        document_id={this.state.document ? this.state.document.id : ""}
                        onClose={() => this.handleClose("document_details")}
                        refreshDocumentsTable={this.refreshDocumentsTable}
                        setUsersNotification={this.setUsersNotification}
                        {...this.state}
                    />
                    : ""}
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />
            </GridContainer>
        );

    }
}

DocumentLibrary.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(DocumentLibrary));
