import React from "react";
// Nodejs library that concatenates classes
import classNames from "classnames";
// Nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// Core components
import cardIconStyle from "assets/jss/material-dashboard-pro-react/components/cardIconStyle.jsx";

function CardIcon ({...props}) {

    const {classes, className, children, color, ...rest} = props,
        cardIconClasses = classNames({
            [classes.cardIcon]: true,
            [classes[`${color}CardHeader`]]: color,
            [className]: className !== undefined
        });
    return (
        <div
            className={cardIconClasses}
            {...rest}
        >
            {children}
        </div>
    );

}

CardIcon.propTypes = {
    "classes": PropTypes.object.isRequired,
    "className": PropTypes.string,
    "color": PropTypes.oneOf([
        "warning",
        "success",
        "danger",
        "info",
        "primary",
        "rose"
    ]),
    "children": PropTypes.node
};

export default withStyles(cardIconStyle)(CardIcon);
