import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

import helpers from "customs/helpers/helpers";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

class SubmittedClientApplicationDownload extends React.Component {

    render () {

        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="small-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => this.props.onClose("downloadApplicationBundleModal")}
                open={this.props.downloadApplicationBundleModal}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.downloadApplicationBundleTitle ? this.props.downloadApplicationBundleTitle : "Done"}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="small-modal-slide-description"
                >
                    <h5 style={{"minWidth": "200px",
                        "marginBottom": "0px"}}
                    >
                        {helpers.addLineBreaks(this.props.downloadApplicationBundleModalMsg)}
                    </h5>
                    <GridContainer>
                        <style>
                            {".download-button {padding: 25px 0px 10px 0px! important;}"},
                        </style>
                        <GridItem md={12} style={{"textAlign": "center"}} className="download-button">
                            <Button
                                className={
                                    `${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`
                                }
                                color="success"
                                onClick={(event) => this.props.downloadFile(event, this.props.client_id, {"id": this.props.id, "filename": this.props.fileName})}
                            >
                                Download
                            </Button>
                        </GridItem>
                    </GridContainer>
                    <h5 style={{"minWidth": "200px", "marginBottom": "0px"}}>
                        {helpers.addLineBreaks(this.props.downloadApplicationBundleModalFooter)}
                    </h5>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("downloadApplicationBundleModal")}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(userProfileStyles)(SubmittedClientApplicationDownload);
