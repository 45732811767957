import React from "react";
import helpers from "customs/helpers/helpers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import PreviewDocument from "customs/components/ScriptStep/PreviewDocument.jsx";

class Todo extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            "previewDocumentModal": false,
            "previewDocumentValue": null,
            "previewDocumentName": null,
        };
        this.previewDocument = this.previewDocument.bind(this);
    }

    previewDocument(name, id) {
        this.setState({
            "previewDocumentModal": true,
            "previewDocumentValue": id,
            "previewDocumentName": name
        });
    }

    render () {
        const {checklist, step, type, index, stepAnswered, displayAnswer, displayQuestion, classes} = this.props;
        const preview_btn = this.props.step.action_config.hasOwnProperty('preview_btn') && this.props.step.action_config.preview_btn === true;
        const registry_key = this.props.step.action_config.hasOwnProperty('registry_key') && this.props.step.action_config.registry_key;
        let registry = null;
        this.props.registry_entries.forEach(field => {
            if (field.registry_key === registry_key) {
                registry = field;
            }
        });
        let file_name = "";
        if (registry !== null) {
            this.props.files.forEach((file) => {
                if (parseInt(registry.value) === file.id) {
                    file_name = file.filename;
                }
            });
        }
        return <div>
            <GridContainer
                key="{{ this.props.step.id }}_grid"
            >
                <GridItem md={preview_btn ? 8 : 12}>
                    {helpers.addLineBreaks(displayQuestion(step, type))}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={stepAnswered(step, type)}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                classes={{
                                    "checked": classes.checked,
                                    "root": classes.checkRoot
                                }}
                                onClick={(event) => {
                                    this.props.handleUpdateStep(step['id'], step['client_script_id'], {'answer': event.target.checked ? 1 : 0}, null);
                                }}
                                tabIndex={-1}
                                disabled={this.props.readOnly || !(index > 0 ? checklist.steps[index - 1].status === 1 : true)}
                                color={'primary'}
                            />
                        }
                        style={{'marginRight': '0px'}}
                    />
                    {helpers.addLineBreaks(displayAnswer(checklist.steps[index], type))}
                </GridItem>
                {
                    preview_btn
                    ?  <GridItem md={4}>
                        {
                            <Button color={registry !== null ? "success" : "warning"} className={this.props.classes.marginRight} type="button" id={this.props.step.id + '_btn'} onClick={() => {
                                    if (registry !== null) this.previewDocument(file_name, registry.value);
                                }} disabled={this.props.readonly}
                            >
                                {registry !== null ? "Preview" : "No value"}
                            </Button>
                        }
                    </GridItem>
                    : ""
                }
            </GridContainer>
            {
                this.state.previewDocumentModal
                ? <PreviewDocument
                    value={this.state.previewDocumentValue}
                    name={this.state.previewDocumentName}
                    client_id={this.props.checklist.client_id}
                    onClose={() => {
                        this.setState({
                            "previewDocumentModal": false,
                            "previewDocumentValue": null,
                            "previewDocumentName": null
                        });
                    }}
                    auth={this.props.auth}
                    previewDocumentModal={this.state.previewDocumentModal}
                />
                : ""
            }
        </div>;
    }
}

export default withStyles(extendedFormsStyle)(Todo);
