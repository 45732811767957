import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
// React component for creating dynamic tables
import ReactTable from "react-table";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import UsersIcon from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

/*
 * Import Assignment from "@material-ui/icons/Assignment";
 * core components
 */
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Pagination from "customs/components/Pagination";

import ConfirmDialog from "customs/components/ConfirmDialog";
import UserDetailsDialog from "customs/components/UserDetailsDialog";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import helpers from "customs/helpers/helpers";
import validations from "customs/helpers/validations";
import {doLogout, doUpdateProfileData} from "../../store/actions/authActions";
// Import UserFullDetails from 'customs/components/UserFullDetails';

import axios from "axios/axios";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import axiosHelper from "axios/axiosHelper";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

class Users extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "tableData": [],
            "loading": false,
            "pages": 0,
            "roles": [],
            "manager_teams": [],
            "acquirers": [],
            "userDetailsModal": false,
            "confirmModal": false,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": "",
            "doneModal": false,
            "doneModalMsg": "",
            "doneModalBtn": "",
            "id": "",
            "username": "",
            "password": "",
            "first_name": "",
            "last_name": "",
            "email": "",
            "company": "",
            "agent_code": "",
            "user_roles": [],
            "user_manager_team": [],
            "user_acquirers": [],
            "username_valid": "",
            "password_valid": "",
            "first_name_valid": "",
            "last_name_valid": "",
            "email_valid": "",
            "company_valid": "",
            "agent_code_valid": "",
            "roles_valid": "",
            "action": "",
            "delete_id": "",
            "btnDisabled": false,
            "tr": false,
            "tr_color": "success",
            "user_selected": null,
            "current_user_tab": 0
        };

        this.fetchData = this.fetchData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeUserTabCallBack = this.changeUserTabCallBack.bind(this);
        this.refreshUserContactsTable = this.refreshUserContactsTable.bind(this);
        this.setUsersNotification = this.setUsersNotification.bind(this);

    }

    refreshUserContactsTable (event, user_id, callback) {
        if (event !== null) {
            event.preventDefault();
        }
        helpers.showLoading();

        axios(this.props)
            .get(`/user/${user_id}`)
            .then((response) => this.processRefreshUserContactsTable(
                response,
                callback ? callback : null
            ))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));

    }

    processRefreshUserContactsTable (results, callback) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "btnDisabled": false,
            "tableData": this.state.tableData.map((prop, key) => {
                if (results.data.id === prop.id) {
                    prop.data = results.data;
                }
                if (
                    this.state.user_selected &&
                    this.state.user_selected.id &&
                    this.state.user_selected.id === prop.id
                ) {
                    this.setState({"user_selected": prop.data});
                }
                return this.setUserDetails(
                    prop.data,
                    key
                );
            }),
            "contact": null
        });
        helpers.hideLoading();
        if (callback !== null) {
            callback();
        }
    }

    fetchData (state, instance) {
        this.setState({"loading": true});
    }

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value,
            [`${event.target.id}_valid`]: ""
        });
    }

  handleMultiple = (event) => {
      this.setState({"user_roles": event.target.value});
  };

  handleSelectManagerTeam = (event) => {
      this.setState({"user_manager_team": event.target.value});
  };

  handleSelectAcquirers = (event) => {
      this.setState({"user_acquirers": event.target.value});
  };

  getUsersData (page, pageSize, sorted, filtered, handleRetrievedData) {
      const url = "/users",
          postObject = {
              "page": page + 1,
              "per_page": pageSize,
              "sort": sorted,
              "filter": filtered
          };

      return axios(this.props)
        .post(
              url,
              postObject
          )
          .then((response) => handleRetrievedData(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  getRoles (roles) {
      return roles
        .map((prop, key) => prop.description)
          .join("<br>");
  }

  componentWillUnmount () {
      this.isUnmounted = true;
      clearTimeout(this.hideNotification);
  }

  processUsersData (res) {
      if (this.isUnmounted) {
          return;
      }
      const {user_selected} = this.state;
      this.setState({"user_selected": null});
      this.setState({
          "roles": res.data.roles,
          "manager_teams": res.data.manager_teams,
          "acquirers": res.data.acquirers,
          "tableData": res.data.data.map((prop, key) => {
              if (
                user_selected &&
                user_selected.id &&
                user_selected.id === prop.id
              ) {
                  this.setState({"user_selected": prop});
              }
              return this.setUserDetails(
                  prop,
                  key
              );
          }),
          "pages": res.data.last_page,
          "items_data": res.data.from && res.data.to && res.data.total
            ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
            : "",
          "loading": false
      });
  }

  setUserDetails (prop, key) {
      return {
          "id": prop.id,
          key,
          "username": prop.username,
          "first_name": prop.first_name,
          "last_name": prop.last_name,
          "email": prop.email,
          "company": prop.company,
          "agent_code": prop.agent_code,
          "roles": helpers.addLineBreaks(this.getRoles(prop.roles)),
          "data": prop,
          "actions":
        <div>
            <Button
                title={'Update user details'}
                className="edit"
                color="info"
                justIcon
                onClick={() => {

                    const obj = this.state.tableData.find((o) => o.key === key);
                    this.handleUserDetailsClick(
                        "userDetailsModal",
                        obj.data
                    );

                }}
                round
                simple
            >
                <Edit />
            </Button>
            {" "}
            {this.props.auth.user.id !== prop.id
                ? <Button
                    title={'Delete user'}
                    className="remove"
                    color="danger"
                    justIcon
                    onClick={() => {

                        const obj = this.state.tableData.find((o) => o.key === key);
                        this.handleUserDetailsDeleteClick(
                            obj.data.id,
                            obj.data.username
                        );

                    }}
                    round
                    simple
                >
                    <Close />
                </Button>
                : ""

                /*
                * <Button
                * JustIcon
                * Round
                * Simple
                * OnClick={() => {
                *     Let obj = this.state.tableData.find(o => o.key === key);
                *     This.handleUserLedgerClick(obj.data);
                * }}
                * Color="warning"
                * ClassName="edit"
                * >
                * <Assignment />
                * </Button>
                */
            }
            {" "}
        </div>
      };

  }

  handleUserDetailsClick (modal, data) {
      const userDetails = data !== null
          ? {
              "id": data.id ? data.id : "",
              "username": data.username ? data.username : "",
              "password": "",
              "first_name": data.first_name ? data.first_name : "",
              "last_name": data.last_name ? data.last_name : "",
              "email": data.email ? data.email : "",
              "company": data.company ? data.company : "",
              "agent_code": data.agent_code ? data.agent_code : "",
              "user_roles": data.roles
                  ? data.roles.map((prop, key) => prop.id)
                  : [],
              "user_manager_team": data.manager_team
                  ? data.manager_team.map((prop, key) => prop.id)
                  : [],
              "user_acquirers": data.acquirers
                  ? data.acquirers.map((prop, key) => prop.id)
                  : [],
              "username_valid": "",
              "password_valid": "",
              "first_name_valid": "",
              "last_name_valid": "",
              "email_valid": "",
              "company_valid": "",
              "agent_code_valid": "",
              "roles_valid": "",
              "action": "update",
              "confirmModalTitle": "Update User Details"
          }
          : {
              "id": "",
              "username": "",
              "password": "",
              "first_name": "",
              "last_name": "",
              "email": "",
              "company": "",
              "agent_code": "",
              "user_roles": [],
              "user_manager_team": [],
              "user_acquirers": [],
              "username_valid": "",
              "password_valid": "",
              "first_name_valid": "",
              "last_name_valid": "",
              "email_valid": "",
              "company_valid": "",
              "agent_code_valid": "",
              "roles_valid": "",
              "action": "new",
              "confirmModalTitle": "Create User"
          },

      x = [];
      x[modal] = true;
      this.setState({...x,
          ...userDetails});
  }

  handleClose (modal) {
      const x = [];
      x[modal] = false;
      this.setState(x);
  }

  handleAcceptUserDetails (modal) {
      const username_valid = validations.validateUsername(this.state.username),
          password_valid = this.state.action === "update"
            ? true
            : validations.validatePassword(this.state.password),
          first_name_valid = this.state.first_name.length > 0 && this.state.first_name.length <= 255,
          last_name_valid = this.state.last_name.length > 0 && this.state.last_name.length <= 255,
          email_valid = validations.validateEmail(this.state.email),
          company_valid = this.state.company.length > 0 && this.state.company.length <= 255,
          agent_code_valid = this.state.agent_code.length <= 50,
          roles_valid = this.state.user_roles.length > 0;
      if (
        username_valid &&
        password_valid &&
        email_valid &&
        first_name_valid &&
        last_name_valid &&
        company_valid &&
        agent_code_valid &&
        roles_valid
      ) {
          const x = [];
          x[modal] = true;
          this.setState({
              ...x,
              "confirmModalMsg": "Are you sure?",
              "username_valid": "",
              "password_valid": "",
              "email_valid": "",
              "first_name_valid": "",
              "last_name_valid": "",
              "company_valid": "",
              "agent_code_valid": "",
              "roles_valid": ""
          });
      } else {
          this.setState({
              "username_valid": username_valid ? "success" : "error",
              "password_valid": password_valid ? "success" : "error",
              "email_valid": email_valid ? "success" : "error",
              "first_name_valid": first_name_valid > 0 ? "success" : "error",
              "last_name_valid": last_name_valid > 0 ? "success" : "error",
              "company_valid": company_valid > 0 ? "success" : "error",
              "agent_code_valid": agent_code_valid > 0 ? "success" : "error",
              "roles_valid": roles_valid > 0 ? "success" : "error"
          });
      }
  }

  doSaveUserDetailsClick () {
      this.handleClose("confirmModal");
      if (this.state.action === "update" && this.state.id.length === 0) {
      } else {
          helpers.showLoading();
          this.setState({"btnDisabled": true});
          if (this.state.action === "update") {
              const fd = new FormData();
              fd.append(
                  "id",
                  this.state.id
              );
              fd.append(
                  "username",
                  this.state.username
              );
              fd.append(
                  "password",
                  this.state.password
              );
              fd.append(
                  "first_name",
                  this.state.first_name
              );
              fd.append(
                  "last_name",
                  this.state.last_name
              );
              fd.append(
                  "email",
                  this.state.email
              );
              fd.append(
                  "company",
                  this.state.company
              );
              fd.append(
                  "agent_code",
                  this.state.agent_code
              );
              for (var i = 0; i < this.state.user_roles.length; i++) {
                  fd.append(
                      "roles[]",
                      this.state.user_roles[i]
                  );
              }
              for (i = 0; i < this.state.user_manager_team.length; i++) {
                  fd.append(
                      "manager_team[]",
                      this.state.user_manager_team[i]
                  );
              }
              for (i = 0; i < this.state.user_acquirers.length; i++) {
                  fd.append(
                      "acquirers[]",
                      this.state.user_acquirers[i]
                  );
              }
              fd.append(
                  "_method",
                  "PATCH"
              );

              axios(this.props)
                .post(
                      "/user",
                      fd,
                      {
                          "headers": {"Content-Type": "application/x-www-form-urlencoded"}
                      }
                  )
                  .then((response) => this.processSaveUserDetails(response))
                  .catch((error) => this.processErrorAxios(error));
          } else {
              axios(this.props)
                .post(
                      "/user",
                      {
                          "id": this.state.id,
                          "username": this.state.username,
                          "password": this.state.password,
                          "first_name": this.state.first_name,
                          "last_name": this.state.last_name,
                          "email": this.state.email,
                          "company": this.state.company,
                          "agent_code": this.state.agent_code,
                          "roles": this.state.user_roles,
                          "manager_team": this.state.user_manager_team,
                          "acquirers": this.state.user_acquirers
                      }
                  )
                  .then((response) => this.processSaveUserDetails(response))
                  .catch((error) => this.processErrorAxios(error));
          }
      }
  }

  processSaveUserDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      if (
        results.data &&
        results.data.user &&
        results.data.user.id &&
        results.data.user.id === this.props.auth.user.id
      ) {
          this.props.doUpdateProfileData(
              results.data,
              {...this.props}
          );
      }
      this.setState({
          "btnDisabled": false,
          "doneModal": false,
          "userDetailsModal": false,
          "doneModalMsg": "User details saved",
          "doneModalBtn": "OK",
          "tr_color": "success"
      });
      this.showUsersNotification();
      this.refreshUsersTable();
      helpers.hideLoading();
  }

  processErrorAxios (error) {
      axiosHelper.processError(
          this.isUnmounted,
          null,
          error,
          (state) => {
              this.setState(state);
          },
          () => {
              this.showUsersNotification();
          },
          () => {
              this.props.doLogout({...this.props});
          }
      );
  }

  handleUserDetailsDeleteClick (id, username) {
      if (id.length === 0) {
          return;
      }
      this.setState({
          "confirmModal": true,
          "confirmModalMsg":
        `Are you sure you want to delete ${username}'s account?`,
          "confirmModalTitle": "Delete User",
          "action": "delete",
          "delete_id": id
      });
  }

  doDeleteUserDetailsClick () {
      this.handleClose("confirmModal");
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "id",
          this.state.delete_id
      );
      fd.append(
          "_method",
          "DELETE"
      );
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              "/user",
              fd,
              {
                  "headers": {"Content-Type": "application/x-www-form-urlencoded"}
              }
          )
          .then((response) => this.processDeleteUserDetails(response))
          .catch((error) => this.processErrorAxios(error));
  }

  processDeleteUserDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "doneModal": false,
          "userDetailsModal": false,
          "doneModalMsg": "User details deleted",
          "doneModalBtn": "OK",
          "tr_color": "success"
      });
      this.showUsersNotification();
      this.refreshUsersTable();
      helpers.hideLoading();
  }

  refreshUsersTable () {
      this.refReactUsersTable.fireFetchData();
  }

  CustomTableFilter = ({filter, onChange}) => (<form>
          <TextField
              type="search"
              className="w-100"
              value={filter ? filter.value : ""}
              onChange={(event) => onChange(event.target.value)}
              InputProps={{
                  startAdornment: 
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            
              }}
          />
       </form>)
    ;

  UserTableColumns = [
      {"Header": "ID",
          "accessor": "id",
          "width": 70,
          "Filter": this.CustomTableFilter},
      {
          "Header": "Username",
          "accessor": "username",
          "Filter": this.CustomTableFilter
      },
      {
          "Header": "First Name",
          "accessor": "first_name",
          "Filter": this.CustomTableFilter
      },
      {
          "Header": "Last Name",
          "accessor": "last_name",
          "Filter": this.CustomTableFilter
      },
      {
          "Header": "Email",
          "accessor": "email",
          "style": {"whiteSpace": "nowrap"},
          "Filter": this.CustomTableFilter
      },
      {"Header": "Company",
          "accessor": "company",
          "Filter": this.CustomTableFilter},
      {
          "Header": "Roles",
          "accessor": "roles",
          "sortable": false,
          "Filter": ({filter, onChange}) => (<select
                  onChange={(event) => onChange(event.target.value)}
                  style={{"width": "100%", "height": "3.2rem",
                      fontSize: ".875rem"}}
                  value={filter ? filter.value : "all"}
              >
                  <option value="" />
                  {this.state.roles
                      ? this.state.roles.map((prop, key) => 
                  <option key={key} value={prop["id"]}>
                    {prop["description"]}
                  </option>
                )
                      : ""}
              </select>)
      },
      {
          "Header": "Actions",
          "accessor": "actions",
          "sortable": false,
          "filterable": false,
          "width": 100
      }
  ];

  hideNotification;
  showUsersNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false,
          "doneModalMsg": ""});
  }

  handleUserLedgerClick (data) {
      this.setState({"user_selected": data});
  }

  changeUserTabCallBack (tab) {
      this.setState({"current_client_tab": tab});
  }

  setUsersNotification (msg, color) {
      this.setState({"doneModalMsg": msg,
          "tr_color": color});
      this.showUsersNotification();
  }

  render () {
      const authService = new AuthService(
          this.state,
          this.props
      );
      if (!authService.getToken()) {
          return <Redirect to="/auth/login" />;
      }

      if (authService.isPasswordExpired()) {
          return <Redirect to="/admin/changepassword" />;
      }
      const {classes} = this.props,
          {tableData, pages, loading} = this.state;
      return (
          <div>
              <GridContainer>
                  <GridItem xs={12}>
                      <Card>
                          <CardHeader
                              color="success"
                              icon
                          >
                                <CardIcon color="success">
                                    <UsersIcon />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    Users
                                </h4>
                                <CardIcon
                                    title={'Create user'}
                                    className="float-button"
                                    color="info"
                                    onClick={() => this.handleUserDetailsClick(
                                        "userDetailsModal",
                                        null
                                    )}
                                >
                                    <Add />
                                </CardIcon>
                                <CardIcon
                                    title={'Refresh users table'}
                                    className="float-button"
                                    color="warning"
                                    onClick={() => this.refreshUsersTable()}
                                >
                                  <Refresh />
                              </CardIcon>
                          </CardHeader>
                          <CardBody>
                              <ReactTable
                                  className="-striped -highlight"
                                  columns={this.UserTableColumns}
                                  data={tableData}
                                  defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                  defaultPageSize={10}
                                  filterable
                                  getTrProps={(state, rowInfo, column, instance) => {
                                      if (typeof rowInfo !== "undefined") {
                                          return {
                                              "onClick": (e, handleOriginal) => {
                                                  this.setState({
                                                      "selected": rowInfo.index
                                                  });
                                                  if (handleOriginal) {
                                                      handleOriginal();
                                                  }
                                                  if (
                                                      this.state.user_selected &&
                                                        rowInfo.original &&
                                                        rowInfo.original.id &&
                                                        rowInfo.original.id !== this.state.user_selected.id
                                                  ) {
                                                      this.setState({"user_selected": null});
                                                  }
                                              },
                                              "style": {
                                                  "background":
                                                    this.state.user_selected &&
                                                    rowInfo.original &&
                                                    rowInfo.original.id &&
                                                    rowInfo.original.id === this.state.user_selected.id
                                                        ? "lightcyan"
                                                        : null
                                              }
                                          };
                                      }
                                      return {
                                          "onClick": (e, handleOriginal) => {
                                              if (handleOriginal) {
                                                  handleOriginal();
                                              }
                                          }
                                      };
                                  }}
                                  itemsCountText={this.state.items_data}
                                  loading={loading}
                                  manual
                                  onFetchData={(state, instance) => {
                                      this.setState({"loading": true});
                                      this.getUsersData(
                                          state.page,
                                          state.pageSize,
                                          state.sorted,
                                          state.filtered,
                                          (res) => this.processUsersData(res)
                                      );
                                  }}
                                  pages={pages}
                                  PaginationComponent={Pagination}
                                  ref={(refReactUsersTable) => {
                                      this.refReactUsersTable = refReactUsersTable;
                                  }}
                                  showPageSizeOptions
                                  showPaginationBottom
                                  showPaginationTop={false}
                              />
                          </CardBody>
                      </Card>
                      <UserDetailsDialog
                          dialogTitle={
                              this.state.action === "update"
                                  ? "Update User Details"
                                  : "Create User"
                          }
                          handleChange={this.handleChange}
                          handleMultiple={this.handleMultiple}
                          handleSelectManagerTeam={this.handleSelectManagerTeam}
                          handleSelectAcquirers={this.handleSelectAcquirers}
                          onClose={() => this.handleClose("userDetailsModal")}
                          onYesClick={() => this.handleAcceptUserDetails("confirmModal")}
                          {...this.state}
                      />
                      <ConfirmDialog
                          confirmModal={this.state.confirmModal}
                          confirmModalMsg={this.state.confirmModalMsg}
                          confirmModalTitle={this.state.confirmModalTitle}
                          onClose={() => this.handleClose("confirmModal")}
                          onYesClick={() => (this.state.action === "delete"
                                      ? this.doDeleteUserDetailsClick()
                                      : this.doSaveUserDetailsClick())}
                      />
                      {this.state.doneModalMsg
                          ? <Snackbar
                              close
                              closeNotification={() => {
                                  this.handleCloseNotification();
                              }}
                              color={this.state.tr_color}
                              icon={AddAlert}
                              message={this.state.doneModalMsg}
                              open={this.state.tr}
                              place="tr"
                          />
                          : ""}
                  </GridItem>
                  {/* <UserFullDetails
                            accessControlManager={this.accessControlManager}
                            handleDeleteFile={this.handleDeleteFile}
                            changeUserTabCallBack={this.changeUserTabCallBack}
                            setUsersNotification={this.setUsersNotification}
                            refreshUserContactsTable={this.refreshUserContactsTable}
                            auth={this.props.auth}
                            {...this.state} /> */}
              </GridContainer>
          </div>
      );
  }
}

Users.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doUpdateProfileData": (params, props) => dispatch(doUpdateProfileData(
            params,
            props
        )),
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Users));
