import React from "react";
// Nodejs library that concatenates classes
import classNames from "classnames";
// Nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// Core components
import cardFooterStyle from "assets/jss/material-dashboard-pro-react/components/cardFooterStyle.jsx";

function CardFooter ({...props}) {

    const {
            classes,
            className,
            children,
            plain,
            profile,
            pricing,
            testimonial,
            stats,
            chart,
            product,
            ...rest
        } = props,
        cardFooterClasses = classNames({
            [classes.cardFooter]: true,
            [classes.cardFooterPlain]: plain,
            [classes.cardFooterProfile]: profile || testimonial,
            [classes.cardFooterPricing]: pricing,
            [classes.cardFooterTestimonial]: testimonial,
            [classes.cardFooterStats]: stats,
            [classes.cardFooterChart]: chart || product,
            [className]: className !== undefined
        });
    return (
        <div
            className={cardFooterClasses}
            {...rest}
        >
            {children}
        </div>
    );

}

CardFooter.propTypes = {
    "classes": PropTypes.object.isRequired,
    "className": PropTypes.string,
    "plain": PropTypes.bool,
    "profile": PropTypes.bool,
    "pricing": PropTypes.bool,
    "testimonial": PropTypes.bool,
    "stats": PropTypes.bool,
    "chart": PropTypes.bool,
    "product": PropTypes.bool,
    "children": PropTypes.node
};

export default withStyles(cardFooterStyle)(CardFooter);
