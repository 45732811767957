import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class SelectScriptTemplates extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            templates: this.props.selected_templates
        };
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.selected_templates !== null || nextProps.selected_templates !== undefined) {
            this.setState({
                "templates": nextProps.selected_templates
            });
        }
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="update-profile-confirm-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => this.props.onClose("selectScriptTemplateModal")}
                open={this.props.selectScriptTemplateModal}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    Select Script Templates
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="update-profile-confirm-modal-slide-description"
                >
                    <FormControl
                        disabled={this.state.is_view}
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            className={classes.selectLabel}
                        >
                            Select Script Templates
                        </InputLabel>
                        <Select
                            value={this.state.templates}
                            onChange={(event) => {
                                var ids = event.target.value;
                                if (event.target.value.includes('all')) {
                                    ids = this.props.script_templates.map(function(prop) { return prop.id; });
                                }
                                this.props.onChange(ids);
                                this.setState({'templates': ids});
                            }}
                            multiple
                            MenuProps={{"className": classes.selectMenu}}
                            classes={{"select": classes.select}}
                            inputProps={{
                                name: 'templates',
                                id: 'templates'
                            }}
                        >
                            <MenuItem
                                key={"template_all"}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelectedMultiple
                                }}
                                value={'all'}
                            >
                                {"Select All"}
                            </MenuItem>
                            {this.props.script_templates !== null
                                ? this.props.script_templates.map((prop, key) => 
                                    <MenuItem
                                        key={"template_" + key}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelectedMultiple
                                        }}
                                        value={prop.id}
                                    >
                                        {prop.name}
                                    </MenuItem>
                                    )
                                : ""}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    {this.props.showDuplicateButton === true
                    ? <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="primary"
                        disabled={this.props.btnDisabled}
                        onClick={(event) => {
                            this.props.showDuplicateScriptDialog();
                            this.props.onClose("selectScriptTemplateModal");
                        }}
                        simple
                        >
                        Duplicate Client Script
                    </Button>
                    : ""}
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("selectScriptTemplateModal")}
                    >
                        Close
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        disabled={this.props.btnDisabled}
                        onClick={(event) => this.props.handleGenerateScript(this.state.templates)}
                        simple
                        >
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(SelectScriptTemplates);
