import React, {Component} from "react";
import PropTypes from "prop-types";
import Navs from "./Navs";
// import HiddenField from "./HiddenField";
import NavField from "./NavField";

const formWithNav = (FormComponent, NavComponent = Navs) => {
    class FormWithTabs extends Component {
        render () {
            const {fields = {}, uiSchema} = this.props,
                formConf = {...this.props,
                    uiSchema,
                    "fields": {...fields,
                        "nav": NavField(NavComponent)}};
            return (<FormComponent {...formConf}>
                    {this.props.children}
                </FormComponent>);
        }
    }

    FormWithTabs.propTypes = {
        "navs": PropTypes.shape({
            "links": PropTypes.array
        })
    };

    return FormWithTabs;
};

export default formWithNav;
