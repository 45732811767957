import React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";

import { WidgetProps } from "@rjsf/core";

const CustomCheckboxWidget = ({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  label,
  onChange,
  onBlur,
  onFocus,
  uiSchema
}: WidgetProps) => {
  const { enumOptions, enumDisabled } = options;

  const _onChange = (a: {}, value: any) =>
    onChange(schema.type === "boolean" ? value !== "false" : value);
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const row = options ? options.inline : false;

  return (
    <>
      <FormLabel required={required} htmlFor={id}>
        {label || schema.title}
      </FormLabel>
      <RadioGroup
        value={`${value}`}
        row={row as boolean}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}>
          <Grid container spacing={1}>
            {(enumOptions as any).map((option: any, i: number) => {
              const itemDisabled =
                enumDisabled && (enumDisabled as any).indexOf(option.value) !== -1;

              const radio = (
                <Grid item md={uiSchema.hasOwnProperty('grid') ? (parseInt(uiSchema.grid) > 0 && parseInt(uiSchema.grid) <= 12 ? uiSchema.grid : false) : 12} key={'grid_'+i}>
                <FormControlLabel
                  control={<Radio color="primary" key={i} />}
                  label={`${option.label}`}
                  value={`${option.value}`}
                  key={i}
                  disabled={disabled || itemDisabled || readonly}
                />
                </Grid>
              );

              return radio;
            })}
        </Grid>
      </RadioGroup>
    </>
  );
};

export default CustomCheckboxWidget;