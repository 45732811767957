import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ReactTable from "react-table";
import AccountTree from "@material-ui/icons/AccountTree";
// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import {doLogout} from "../../store/actions/authActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "../../customs/components/Pagination";
import Refresh from "@material-ui/icons/Refresh";
import axios from "../../axios/axios";
import axiosHelper from "axios/axiosHelper";
import Assignment from "@material-ui/icons/Assignment";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import helpers from "customs/helpers/helpers";
import AcquirerDetails from "customs/components/AcquirerDetails";
import AccessControl from "customs/components/AccessControl";

class Acquirers extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "acquirers": [],
            "config_auths": [],
            "config_doc_types": [],
            "config_methods": [],
            "methods": [],
            "templates": [],
            "acquirer": [],
            "acquirer_details": null,
            "pages": 0,
            "loading": false,
            "doneModalMsg": "",
            "tr_color": "success",
            "tr": false,
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "acquirer_dialog_title": "",
            "acquirerDetailsModal": false
        };
        this.handleAcquirerDetailsClick = this.handleAcquirerDetailsClick.bind(this);
        this.handleEditAcquirerDetailsClick = this.handleEditAcquirerDetailsClick.bind(this);
        this.handleAcquirerDetailsDeleteClick = this.handleAcquirerDetailsDeleteClick.bind(this);
        this.doDeleteAcquirerDetails = this.doDeleteAcquirerDetails.bind(this);
        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.refreshAcquirersTable = this.refreshAcquirersTable.bind(this);

    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }
    
    handleConfirmClick () {
        if (this.state.action === "delete_acquirer") {
            this.doDeleteAcquirerDetails();
        }
    }

    hideNotification;
    showUsersNotification () {
        if (!this.state.tr) {
            this.setState({"tr": true});
        } else {
            clearTimeout(this.hideNotification);
        }
        this.setHideNotificationTimeout();
    }

    setHideNotificationTimeout () {
        this.hideNotification = setTimeout(
            () => {
                this.handleCloseNotification();
            },
            this.state.tr_color === "success" ? 5000 : 10000
        );
    }

    handleCloseNotification () {
        clearTimeout(this.hideNotification);
        this.setState({"tr": false,
            "doneModalMsg": ""});
    }

    getAcquirersData (page, pageSize, sorted, filtered, handleRetrievedData) {

        const url = "/acquirers",
            postObject = {
                "page": page + 1,
                "per_page": pageSize,
                "sort": sorted,
                "filter": filtered,
            };

        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then((response) => {
                handleRetrievedData(response);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    processAcquirersData (res) {

        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "acquirers": res.data.data.map((prop, key) => {
                return this.setAcquirerDetails(
                    prop,
                    key
                );
  
            }),
            "config_auths": res.data.config_auths,
            "config_doc_types": res.data.config_doc_types,
            "config_methods": res.data.config_methods,
            "methods": res.data.methods,
            "templates": res.data.templates,
            "pages": res.data.last_page,
            "items_data": res.data.from && res.data.to && res.data.total
              ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
              : "",
            "loading": false
        });

    }

    handleAcquirerDetailsClick(acquirer) {
        this.setState({"acquirer_details" : acquirer, "acquirerDetailsModal": true, "is_view": true, "acquirer_dialog_title": "Acquirer Details"});
    }

    handleEditAcquirerDetailsClick(acquirer) {
        this.setState({"acquirer_details" : acquirer, "acquirerDetailsModal": true, "is_view": false, "acquirer_dialog_title": "Update Acquirer"});
    }

    handleAcquirerDetailsDeleteClick(acquirer) {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to delete ${acquirer.name}?`,
            "confirmModalTitle": "Delete Acquirer",
            "action": "delete_acquirer",
            "acquirer": acquirer
        });
    }

    doDeleteAcquirerDetails() {
        this.setState({"confirmModal": false, "action": ""});
        helpers.showLoading();
        const fd = new FormData();
        fd.append(
            "_method",
            "DELETE"
        );
        this.setState({"btnDisabled": true});

        axios(this.props)
            .post(
                `/acquirer/${this.state.acquirer.id}`, fd,
                {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
            )
            .then((response) => this.processDeleteContactDetails(response))
            .catch((error) => this.processErrorAxios(error, null));
    }

    processDeleteContactDetails(result) {
        if (this.isUnmounted) {
            return;
        }
        this.refreshAcquirersTable();
        this.setState({
            "btnDisabled": false,
            "acquirer": [],
            "doneModalMsg": "Acquirer " + this.state.acquirer.name + " deleted",
            "tr_color": "success"
        });
        this.showUsersNotification();
        helpers.hideLoading();
    }

    handleAddAcquirerDetailsClick() {
        this.setState({"acquirer_details" : null, "acquirerDetailsModal": true, "is_view": false, "acquirer_dialog_title": "Create Acquirer"});
    }
    
    setAcquirerDetails (prop, key) {
        return {
            "id": prop.id,
            key,
            "name": prop.name,
            "template": prop.template_details.name,
            "method": prop.method === 'api' ? 'API' : startCase(toLower(prop.method)),
            "data": prop,
            "actions":
              <div>
                <Button
                    title={'Acquirer details'}
                    className="edit"
                    color="warning"
                    justIcon
                    onClick={() => {

                        const obj = this.state.acquirers.find((o) => o.key === key);
                        this.handleAcquirerDetailsClick(obj.data);

                    }}
                    round
                    simple
                >
                    <Assignment />
                </Button>
                {" "}
                {AccessControl.checkRoutePermissions("/acquirers", this.props.auth, "read_only") ? "" :
                  <Button
                      title={'Update acquirer'}
                      className="edit"
                      color="info"
                      justIcon
                      onClick={() => {
  
                          const obj = this.state.acquirers.find((o) => o.key === key);
                          this.handleEditAcquirerDetailsClick(obj.data);
  
                      }}
                      round
                      simple
                  >
                      <Edit />
                  </Button>
                }
                  {" "}
                  {AccessControl.checkRoutePermissions("/acquirers", this.props.auth, "read_only") ? "" :
                    <Button
                        title={'Delete acquirer'}
                        className="remove"
                        color="danger"
                        justIcon
                        onClick={() => {
    
                            const obj = this.state.acquirers.find((o) => o.key === key);
                            this.handleAcquirerDetailsDeleteClick(obj.data);
    
                        }}
                        round
                        simple
                    >
                        <Close />
                    </Button>
                  }
                  {" "}
              </div>
        };
    }

    CustomTableFilter = ({filter, onChange}) => (<form>
        <TextField
          className="w-100"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            )
          }}
          onChange={event => onChange(event.target.value)}
          type="search"
          value={filter ? filter.value : ""}
          />
      </form>)
    ;

    AcquirersTableColumns = [
        {
            "Header": "ID",
            "accessor": "id",
            "width": 70,
            "Filter": this.CustomTableFilter},
        {
            "Header": "Name",
            "accessor": "name",
            "Filter": this.CustomTableFilter
        },
        {
            "Header": "Method",
            "accessor": "method",
            "Filter": this.CustomTableFilter
        },
        {
            "Header": "Template",
            "accessor": "template",
            "Filter": this.CustomTableFilter
        },
        {
            "Header": "Actions",
            "accessor": "actions",
            "sortable": false,
            "filterable": false,
            "width": AccessControl.checkRoutePermissions("/acquirers", this.props.auth, "read_only") ? 70 : 150
        }
    ];

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showUsersNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );
    }

    refreshAcquirersTable() {
        this.refReactAcquirersTable.fireFetchData();
    }

    setUsersNotification (msg, color) {
        this.setState({"doneModalMsg": typeof msg === 'string' ? msg : msg.join('<br>'), "tr_color": color});
        this.showUsersNotification();
    }

    render () {

        const authService = new AuthService(
            this.state,
            this.props
        );
        if (!authService.getToken()) {
            return <Redirect to="/auth/login" />;
        }
        if (authService.isPasswordExpired()) {
            return <Redirect to="/admin/changepassword" />;
        }
        const {classes} = this.props;
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            color="success"
                            icon
                        >
                            <CardIcon color="success">
                                <AccountTree />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Acquirers
                            </h4>
                            {AccessControl.checkRoutePermissions("/acquirers", this.props.auth, "read_only") ? "" :
                                <CardIcon
                                    title={'Create acquirer'}
                                    className="float-button"
                                    color="info"
                                    onClick={() => this.handleAddAcquirerDetailsClick()}
                                >
                                    <Add />
                                </CardIcon>
                            }
                            <CardIcon
                                title={'Refresh acquirers table'}
                                className="float-button"
                                color="warning"
                                onClick={() => this.refreshAcquirersTable()}
                            >
                                <Refresh />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                className="-striped -highlight"
                                columns={this.AcquirersTableColumns}
                                data={this.state.acquirers}
                                defaultPageSize={10}
                                filterable
                                manual
                                loading={this.state.loading}
                                onFetchData={(state, instance) => {

                                this.setState({"loading": true});
                                    this.getAcquirersData(
                                        state.page,
                                        state.pageSize,
                                        state.sorted,
                                        state.filtered,
                                        (res) => this.processAcquirersData(res)
                                    );

                                }}
                                pages={this.state.pages}
                                PaginationComponent={Pagination}
                                ref={(refReactAcquirersTable) => {
                                    this.refReactAcquirersTable = refReactAcquirersTable;
                                }}
                                itemsCountText={this.state.items_data}
                                showPageSizeOptions
                                showPaginationBottom
                                showPaginationTop={false}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
                {this.state.acquirerDetailsModal
                    ? <AcquirerDetails
                        auth={this.props.auth}
                        onClose={() => this.handleClose("acquirerDetailsModal")}
                        refreshAcquirersTable={
                            this.refreshAcquirersTable
                        }
                        setUsersNotification={this.setUsersNotification}
                        {...this.state}
                    />
                    : ""}
                {this.state.doneModalMsg
                    ? <Snackbar
                        close
                        closeNotification={() => {
                            this.handleCloseNotification();
                        }}
                        color={this.state.tr_color}
                        icon={AddAlert}
                        message={typeof this.state.doneModalMsg === 'string' ? this.state.doneModalMsg : this.state.doneModalMsg.join('<br>')}
                        open={this.state.tr}
                        place="tr"
                    />
                    : ""}
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />
            </GridContainer>
        );

    }
}

Acquirers.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Acquirers));
