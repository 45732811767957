export const asNavField = (field, navConfs, uiSchema) => {
    const separatorIndex = field.indexOf(".");
    if (separatorIndex === -1) {
        uiSchema[field] = {
            navConfs,
            "ui:field": "nav",
            "origUiSchema": uiSchema[field]
        };
    } else {
        const parentField = field.substr(
                0,
                separatorIndex
            ),
            childField = field.substr(separatorIndex + 1);
        asNavField(
            childField,
            navConfs,
            uiSchema[parentField]
        );
    }
};

function asHiddenField (field, uiSchema) {
    uiSchema[field] = {
        "ui:widget": "hidden",
        "ui:field": "hidden"
    };
}

export const toHiddenUiSchema = ({properties}, uiSchema) => {
    const cleanUiSchema = Object.keys(properties).reduce(
        (agg, field) => {
            asHiddenField(
                field,
                agg
            );
            //Hide dependent fields
            if (uiSchema[field] !== undefined && typeof uiSchema[field] === 'object') {
                for (var schema in uiSchema[field]) {
                    if (typeof uiSchema[field][schema] === 'object') {
                        for (var key in uiSchema[field][schema]) {
                            if (typeof uiSchema[field][schema][key] === 'object') {
                                asHiddenField(
                                    key,
                                    agg[field]
                                );
                            }
                        }
                    }
                }
            }
            if ("properties" in properties[field]) {
                Object.assign(
                    agg[field],
                    agg[field],
                    toHiddenUiSchema(
                        properties[field],
                        agg[field]
                    )
                );
            }
            return agg;
        },
        {
            ...uiSchema
        }
    );
    return cleanUiSchema;
};
