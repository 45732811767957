import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

import helpers from "customs/helpers/helpers";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ConfirmDialog from "customs/components/ConfirmDialog";
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

class SubmitClientApplication extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            "acquirer": "",
            "acquirer_documents": [],
            "documents": [],
            "documents_store": [],
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateBeforeSubmit = this.validateBeforeSubmit.bind(this);
        this.handleAcquirerSelect = this.handleAcquirerSelect.bind(this);
        this.generateDocumentsGrid = this.generateDocumentsGrid.bind(this);
        this.doSubmitApplication = this.doSubmitApplication.bind(this);
    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "submit_application") {
            this.doSubmitApplication();
        }
    }

    validateBeforeSubmit() {
        let cont = true, msg = [];
        if (this.state.acquirer === null || this.state.acquirer.length === 0) {
            cont = false;
            msg.push("Please selected an acquirer");
        }
        if (this.state.acquirer_documents.length > 0) {
            this.state.acquirer_documents.forEach((document, key) => {
                if (document.is_required === 1) {
                    let doc = this.state.documents[key]["file"] === "" || this.state.documents[key]["file"] === null || this.state.documents[key]["file"] === undefined
                        ? this.state.documents_store[key]["id"]
                        : this.state.documents[key]["file"];
                    if (doc === null || doc === undefined || doc.length === 0) {
                        cont = false;
                        msg.push(document.title + " file is required.");
                    }
                }
            });
        }

        if (!cont) {
            this.props.setUsersNotification(
                msg.join('<br>'),
                "danger"
            );
        }

        return cont;
    }

    handleSubmit() {
        if (this.validateBeforeSubmit()) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": `Are you sure?`,
                "confirmModalTitle": "Submit Application",
                "action": "submit_application"
            });
        }
    }

    doSubmitApplication() {
        let fd = new FormData();
        let documents_store = this.state.documents_store;
        this.state.documents.forEach((document, key) => {
            if (document["file"] !== "" && document["file"] !== null && document["file"] !== undefined) {
                fd.append(`documents[${this.state.acquirer_documents[key].id}]`, document["file"]);
            } else if (documents_store[key]["id"] !== "" && documents_store[key]["id"] !== null && documents_store[key]["id"] !== undefined) {
                fd.append(`documents_store[${this.state.acquirer_documents[key].id}]`, documents_store[key]["id"]);
            }
        });

        this.props.onSubmitClick(
            fd,
            `/form/${this.props.form_id}/acquirers/${this.state.acquirer}/submit/application`
        );
    }

    handleAcquirerSelect(event) {
        const acquirer_id = event.target.value;
        let acquirer_documents = this.props.acquirers.find((acquirer) => acquirer.id === acquirer_id).documents;
        let documents = acquirer_documents.map((document) => {
            return {
                "filename_tmp": "",
                "file": ""
            };
        });
        let documents_store = acquirer_documents.map((document) => {
            return {"id": ""};
        });
        this.setState({
            "acquirer": acquirer_id,
            "acquirer_documents": acquirer_documents,
            "documents": documents,
            "documents_store": documents_store
        });
    }

    async handleChange (event, stateName, field, key) {
        let state_value = this.state[stateName];
        let documents_store = this.state.documents_store;
        let attach = true;
        try {
            if (Math.round(event.target.files[0].size / 1024) >= 4096) {
                this.props.setUsersNotification(
                    `${event.target.files[0].name} is too big, please select a file that is less than 4mb`,
                    "danger"
                );
                attach = false;
            }
        } catch (e) {}
        if (attach) {
            state_value[key][`${field}_tmp`] = event.target.files[0].name;
            state_value[key].file = event.target.files[0];
            documents_store[key].id = "";
        }
        this.setState({[stateName]: state_value, documents_store});
    }

    handleSelectDocChange (event, stateName, key) {
        let state_value = this.state[stateName];
        state_value[key].id = event.target.value;
        let documents = this.state.documents;
        documents[key].filename_tmp = "";
        documents[key].file = "";
        this.setState({[stateName]: state_value, documents});
    }

    generateDocumentsGrid() {
        const {classes} = this.props;
        if (this.state.acquirer_documents.length > 0) {
            let container = this.state.acquirer_documents
                .map((prop, key) => {
                    return <GridItem md={12} key={"doc_" + key}>
                        <div style={{ padding: "0.75rem 0", "borderTop": "solid 1px #d8d8d8" }}>
                            <label style={{ color: "#495057" }}>
                                {prop.title}{prop.is_required === 1 ? <span style={{ color: "red"}}> *</span> : ""}
                            </label>
                            <GridContainer>
                                <GridItem md={6} key={"doc_store" + key}>
                                    <label>
                                        Choose from stored files
                                    </label>
                                    <Select
                                        classes={{"select": classes.select}}
                                        fullWidth
                                        inputProps={{
                                            "name": "documents_store[" + prop.id + "]",
                                            "id": "acquirer",
                                            "stye": {"width": "100%"}
                                        }}
                                        MenuProps={{"className": classes.selectMenu}}
                                        onChange={(event) => this.handleSelectDocChange(event, "documents_store", key)}
                                        value={this.state.documents_store[key]["id"]}
                                    >
                                        <MenuItem
                                            classes={{"root": classes.selectMenuItem}}
                                            disabled
                                        >
                                            Choose a file
                                        </MenuItem>
                                        {this.props.client_files.map((value, k) => (
                                            <MenuItem
                                                classes={{"root": classes.selectMenuItem}}
                                                key={`fileKey${k}`}
                                                value={value.id}
                                            >
                                                {value.filename ? value.filename : ""}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </GridItem>
                                <GridItem md={1} key={"doc_space" + key}>
                                    <label>
                                        Or
                                    </label>
                                </GridItem>
                                <GridItem md={5} key={"doc_input" + key}>
                                    <label>
                                        Choose from device
                                    </label>
                                    <div className={"upload-btn-wrapper"} style={{ width: "100%" }}>
                                        <button className={"btn"} style={{ marginRight: "10px" }}>
                                            Choose a file
                                        </button>
                                        <input
                                            type="file"
                                            name={"documents[" + prop.id + "]"}
                                            onChange={event =>
                                                this.handleChange(
                                                    event, "documents", "filename", key
                                                )
                                            }
                                            accept={prop.accepts.join(',')}
                                            value=""
                                        />
                                    </div>
                                    <label htmlFor={"document_" + key} style={{ color: "#495057" }}>
                                        {this.state.documents[key]['filename_tmp']}
                                    </label>
                                </GridItem>
                            </GridContainer>
                            {prop.description && prop.description.length > 0
                                ? <label style={{ color: "#495057" }}>
                                        {prop.description}
                                    </label>
                                : ""}
                        </div>
                    </GridItem>; 
                });
            return <GridContainer>
                <GridItem md={12} key={"doc_x"}>
                    <h4 style={{paddingTop: "0.75rem"}}>Additional Documents</h4>
                </GridItem>
                {container}
            </GridContainer>;
        }
    }

    render () {

        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="small-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => this.props.onClose("submitClientApplicationModal")}
                open={this.props.submitClientApplicationModal}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.submitClientApplicationTitle ? this.props.submitClientApplicationTitle : ""}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="small-modal-slide-description"
                >
                    <h5 style={{"minWidth": "200px",
                        "marginBottom": "0px"}}
                    >
                        {helpers.addLineBreaks(this.props.submitClientApplicationModalMsg)}
                    </h5>
                    <GridContainer>
                        <GridItem md={12}>
                            <label>
                                Acquirers
                            </label>
                            <Select
                                classes={{"select": classes.select}}
                                fullWidth
                                inputProps={{
                                    "name": "acquirer",
                                    "id": "acquirer",
                                    "stye": {"width": "100%"}
                                }}
                                MenuProps={{"className": classes.selectMenu}}
                                onChange={(event) => this.handleAcquirerSelect(event)}
                                value={this.state.acquirer}
                            >
                                {this.props.acquirers.map((prop, key) => (
                                    <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        key={`rangeKey${key}`}
                                        value={prop.id}
                                    >
                                        {prop.name + ' (' + (prop.method === 'api' ? 'API' : startCase(toLower(prop.method))) + ')'}
                                    </MenuItem>
                                ))}
                            </Select>
                        </GridItem>
                    </GridContainer>
                    {this.generateDocumentsGrid()}
                    <ConfirmDialog
                        confirmModal={this.state.confirmModal}
                        confirmModalMsg={this.state.confirmModalMsg}
                        confirmModalTitle={this.state.confirmModalTitle}
                        onClose={() => this.setState({"confirmModal": false})}
                        onYesClick={() => this.handleConfirmClick()}
                    />
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("submitClientApplicationModal")}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        onClick={this.handleSubmit}
                        simple
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(userProfileStyles)(SubmitClientApplication);
