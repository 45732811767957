import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// @material-ui/icons
import VpnKey from "@material-ui/icons/VpnKey";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

import ConfirmDialog from "customs/components/ConfirmDialog";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import {
    doChangePassword,
    doUpdateProfileData
} from "../../store/actions/authActions";

import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";

class ChangePassword extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "old_password_valid": "",
            "password_valid": "",
            "password_confirmation_valid": "",
            "error_msg": "",
            "old_password": "",
            "password": "",
            "password_confirmation": "",
            "confirm_modal": false,
            "tr": false,
            "password_updated": null
        };

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value,
            [`${event.target.id}_valid`]: ""
        });
    }

    doChangePasswordClick () {
        helpers.showLoading();
        this.handleClose("confirm_modal");
        this.props.doChangePassword(
            {
                "id": this.props.auth.user.id,
                "old_password": this.state.old_password,
                "password": this.state.password,
                "password_confirmation": this.state.password_confirmation
            },
            {...this.props}
        );
    }

    handleChangePasswordClick (modal) {
        let error_msg = "";
        const old_password_valid = validations.validatePassword(this.state.old_password),
            password_valid = validations.validatePassword(this.state.password),
            password_confirmation_valid = validations.validatePassword(this.state.password_confirmation);
        if (
            this.state.old_password &&
            this.state.old_password === this.state.password
        ) {
            error_msg = "Old and New passwords should not be the same";
        }
        if (this.state.password !== this.state.password_confirmation) {
            error_msg =
                `${error_msg ? `${error_msg}<br>` : ""
                }The password confirmation does not match`;
        }
        if (
            old_password_valid &&
            password_valid &&
            password_confirmation_valid &&
            !error_msg
        ) {

            const x = [];
            x[modal] = true;
            this.setState({
                ...x,
                "old_password_valid": "",
                "password_valid": "",
                "password_confirmation_valid": "",
                error_msg
            });

        } else {
            this.setState({
                "old_password_valid": old_password_valid ? "success" : "error",
                "password_valid": password_valid ? "success" : "error",
                "password_confirmation_valid": password_confirmation_valid
                    ? "success"
                    : "error",
                error_msg
            });
        }
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

  hideNotification;

  showChangePasswordNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseChangePasswordNotification();
          },
          this.state.password_updated === "Password changed" ? 5000 : 10000
      );
  }

  handleCloseChangePasswordNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false});
  }

  componentWillUnmount () {
      this.setState({"password_updated": null});
      clearTimeout(this.hideNotification);
  }

  render () {
      const authService = new AuthService(
          this.state,
          this.props
      );
      if (!authService.getToken()) {
          return (
              <div>
                  <Redirect to="/auth/login" />
              </div>
          );

      }

      const {classes} = this.props,
          password_updated =
        this.props.auth && this.props.auth && this.props.auth.authMessage
            ? this.props.auth.authMessage
            : "";
      if (password_updated) {
          this.props.auth.authMessage = "";
          setTimeout(
              () => {
                  this.setState({
                      password_updated,
                      "old_password_valid": "",
                      "password_valid": "",
                      "password_confirmation_valid": "",
                      "old_password": "",
                      "password": "",
                      "password_confirmation": ""
                  });
                  this.props.doUpdateProfileData(
                      this.props.auth,
                      {...this.props}
                  );
                  this.showChangePasswordNotification();
                  helpers.hideLoading();
              },
              100
          );
      }
      return (
          <div>
              {authService.isPasswordExpired()
                  ? <SnackbarContent
                      color="danger"
                      message="Your password has expired. Please provide a new password."
                  />
                  : ""}
              <GridContainer justify="center">
                  <GridItem
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                  >
                      <Card>
                          <CardHeader
                              color="success"
                              icon
                          >
                              <CardIcon color="success">
                                  <VpnKey />
                              </CardIcon>
                              <h4 className={classes.cardIconTitle}>
                                  Change Password
                              </h4>
                          </CardHeader>
                          <CardBody>
                              {this.state.error_msg
                                  ? <h4
                                      className={classes.textCenter}
                                      style={{"color": "red"}}
                                  >
                                      {helpers.addLineBreaks(this.state.error_msg)}
                                    </h4>
                                  : ""}
                              <GridContainer>
                                  <GridItem
                                      md={12}
                                      sm={12}
                                      xs={12}
                                  >
                                      <CustomInput
                                          error={this.state.old_password_valid === "error"}
                                          formControlProps={{
                                              "fullWidth": true
                                          }}
                                          helperText={
                                              this.state.old_password_valid === "error" &&
                          this.state.old_password
                                                  ? validations.passwordErrorMsg()
                                                  : undefined
                                          }
                                          id="old_password"
                                          inputProps={{
                                              "onChange": this.handleChange,
                                              "type": "password",
                                              "autoComplete": "off",
                                              "value": this.state.old_password
                                          }}
                                          labelText="Old Password"
                                          success={this.state.old_password_valid === "success"}
                                      />
                                  </GridItem>
                              </GridContainer>
                              <GridContainer>
                                  <GridItem
                                      md={12}
                                      sm={12}
                                      xs={12}
                                  >
                                      <CustomInput
                                          error={this.state.password_valid === "error"}
                                          formControlProps={{
                                              "fullWidth": true
                                          }}
                                          helperText={
                                              this.state.password_valid === "error" && this.state.password
                                                  ? validations.passwordErrorMsg()
                                                  : undefined
                                          }
                                          id="password"
                                          inputProps={{
                                              "onChange": this.handleChange,
                                              "type": "password",
                                              "autoComplete": "off",
                                              "value": this.state.password
                                          }}
                                          labelText="New Password"
                                          success={this.state.password_valid === "success"}
                                      />
                                  </GridItem>
                              </GridContainer>
                              <GridContainer>
                                  <GridItem
                                      md={12}
                                      sm={12}
                                      xs={12}
                                  >
                                      <CustomInput
                                          error={
                                              this.state.password_confirmation_valid === "error"
                                          }
                                          formControlProps={{
                                              "fullWidth": true
                                          }}
                                          helperText={
                                              this.state.password_confirmation_valid === "error" && this.state.password_confirmation
                                                  ? validations.passwordErrorMsg()
                                                  : undefined
                                          }
                                          id="password_confirmation"
                                          inputProps={{
                                              "onChange": this.handleChange,
                                              "type": "password",
                                              "autoComplete": "off",
                                              "value": this.state.password_confirmation
                                          }}
                                          labelText="Confirm Password"
                                          success={
                                              this.state.password_confirmation_valid === "success"
                                          }
                                      />
                                  </GridItem>
                              </GridContainer>
                              <Button
                                  className={classes.updateProfileButton}
                                  color="success"
                                  onClick={() => this.handleChangePasswordClick("confirm_modal")}
                              >
                                  Change Password
                              </Button>
                              <ConfirmDialog
                                  confirmModal={this.state.confirm_modal}
                                  confirmModalTitle="Change Password"
                                  onClose={() => this.handleClose("confirm_modal")}
                                  onYesClick={() => this.doChangePasswordClick()}
                              />
                              {this.state.password_updated
                                  ? <Snackbar
                                      close
                                      closeNotification={() => {

                                          this.handleCloseChangePasswordNotification();

                                      }}
                                      color={
                                          this.state.password_updated === "Password changed"
                                              ? "success"
                                              : "danger"
                                      }
                                      icon={AddAlert}
                                      message={this.state.password_updated}
                                      open={this.state.tr}
                                      place="tr"
                                  />
                                  : ""}
                              <Clearfix />
                          </CardBody>
                      </Card>
                  </GridItem>
              </GridContainer>
          </div>
      );
  }

}

ChangePassword.propTypes = {
    "classes": PropTypes.object,
    "force_render": PropTypes.number
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data,
        "force_render": Math.random()
    }),

    mapDispatchToProps = (dispatch) => ({
        "doChangePassword": (params, props) => dispatch(doChangePassword(
            params,
            props
        )),
        "doUpdateProfileData": (params, props) => dispatch(doUpdateProfileData(
            params,
            props
        ))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(userProfileStyles)(ChangePassword));
