import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";

import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import DoneIcon from "@material-ui/icons/Done";
// import Badge from "@material-ui/core/Badge";
import Process from "./Process";
// import GridItem from "../../components/Grid/GridItem";
// import helpers from "customs/helpers/helpers";
import Icon from '@material-ui/core/Icon';

function TabPanel (props) {

    const {children, value, index, ...other} = props;
    return (
        <div
            aria-labelledby={`vertical-tab-${index}`}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            role="tabpanel"
            {...other}
        >
            {value === index &&
            <Box style={{'padding': '0px 15px'}}>
                {children}
            </Box>}
        </div>
    );

}

TabPanel.propTypes = {
    "children": PropTypes.node,
    "index": PropTypes.any.isRequired,
    "value": PropTypes.any.isRequired
};

function tabProps (index) {
    return {
        "id": `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    "root": {
        "flexGrow": 1,
        "backgroundColor": theme.palette.background.paper,
        "display": "flex"
    },
    "tabs": {
        "borderRight": `1px solid ${theme.palette.divider}`
    }
}));

function VerticalTabs (props) {
    const classes = useStyles(),
        [
            value,
            setValue
        ] = React.useState(props.value ? props.value : 0),
        {checklist, postChecklist, basic, registry_entries, watched} = props;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getPercentageComplete = (script) => {
        let progress = Object.keys(script.steps.filter((step) => step.status === 1)).length / Object.keys(script.steps).length;
        return ((isNaN(progress) ? 0 : progress) * 100).toFixed(2).replace('.00', '') + '%';
    };

    const basicView = () => {
        return checklist !== undefined && checklist !== null && checklist.length > 0
            ? <div style={{"width": "100%"}}>
                {checklist.map((elem, index) => {
                    return <div onClick={() => props.onClick(index)} key={index} style={{"cursor": "pointer"}}>
                        {elem.name + ' (' + getPercentageComplete(elem) + ' completed)'}
                    </div>;
                })}
            </div>
            : "";
    }

    const setWatched = (elem) => {
        try {
            if (Object.keys(
                watched.filter((watch) => watch.event === 'script' && parseInt(watch.element) === parseInt(elem.script_template_id) && watch.status === 1)
            ).length === 1) return true;
        } catch(e) {}
        return false;
    }

    const fullView = () => {
        return <div className={classes.root}>
            {checklist !== undefined && checklist !== null &&  checklist.length > 0
                ? <Tabs
                    className={classes.tabs}
                    onChange={handleChange}
                    orientation="vertical"
                    value={checklist.length > value ? value : 0}
                    variant="scrollable"
                >
                    {checklist.map((elem, index) => <Tab style={{'textTransform': 'none'}} key={index} label={elem.name + ' (' + getPercentageComplete(elem) + ')'}
                        {...tabProps(index)} />)}
                </Tabs>
                : ""}
            {checklist !== undefined && checklist !== null && checklist.map((elem, index) => {
                let isWatched = setWatched(elem);
                return <TabPanel key={index} 
                    value={checklist.length > value ? value : 0}
                    index={index}
                >
                    <Typography style={{"fontSize": "1.25rem"}}>
                        {elem.name + ' - ' + getPercentageComplete(elem) + ' completed'}
                        <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher"}
                            onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('script', elem.script_template_id, !isWatched);}} />
                    </Typography>
                    <Typography>{elem.description}</Typography>
                    <Process
                        checklist={elem}
                        watched={watched}
                        ignored_watch={isWatched}
                        registry_entries={registry_entries}
                        postChecklist={postChecklist}
                        handleUpdateStep={props.handleUpdateStep}
                        handleUpdateWatch={props.handleUpdateWatch}
                        saveChecklist={props.saveChecklist}
                        files={props.files}
                        readOnly={props.readOnly}
                        acquirers={props.acquirers}
                        applications={props.applications}
                        application_schema={props.application_schema}
                        client_files={props.client_files}
                        computeApplicationProgress={props.computeApplicationProgress}
                        setUsersNotification={props.setUsersNotification}
                        refreshClientContactsTable={props.refreshClientContactsTable}
                        handleDownloadFile={props.handleDownloadFile}
                        handleApplicationSignature={props.handleApplicationSignature}
                        auth={props.auth}
                        username={props.username}
                        mccs={props.mccs}
                        saved_data={props.saved_data}
                        client_company={props.client_company}
                        transaction_methods={props.transaction_methods}
                    />
                </TabPanel>;
            })}
        </div>;
    }

    return (
        basic ? basicView() : fullView()
    );
}

const TabbedChecklist = withStyles(extendedFormsStyle)(VerticalTabs);

export default TabbedChecklist;
