import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
// React component for creating dynamic tables
import ReactTable from "react-table";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import DeviceHub from "@material-ui/icons/DeviceHub";
import Add from "@material-ui/icons/Add";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

/*
 * Import Assignment from "@material-ui/icons/Assignment";
 * core components
 */
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Pagination from "customs/components/Pagination";

import ConfirmDialog from "customs/components/ConfirmDialog";
import LeadDetails from "customs/components/LeadDetails";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import helpers from "customs/helpers/helpers";
import {doLogout} from "../../store/actions/authActions";

import axios from "axios/axios";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import axiosHelper from "axios/axiosHelper";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

class Leads extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "pages": 0,
            "btnDisabled": false,
            "tr": false,
            "tr_color": "success",
            "id": "",
            "name": "",
            "lead_source_id": "",
            "new_lead_source_name": "",
            "action": "",
            "confirmModal": false,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": "",
            "delete_id": "",
            "leadDetailsModal": false,
            "leadSources": []
        };
        this.fetchData = this.fetchData.bind(this);
        this.setLeadsNotification = this.setLeadsNotification.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    fetchData (state, instance) {
        this.setState({"loading": true});
    }

    componentWillUnmount () {
        this.isUnmounted = true;
        clearTimeout(this.hideNotification);
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

    processErrorAxios (error) {
        axiosHelper.processError(
            this.isUnmounted,
            null,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showLeadsNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );
    }

  hideNotification;
  showLeadsNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false,
          "doneModalMsg": ""});
  }

  setLeadsNotification (msg, color) {
      this.setState({"doneModalMsg": msg,
          "tr_color": color});
      this.showLeadsNotification();
  }

  refreshLeadsTable () {
      this.refReactLeadsTable.fireFetchData();
  }

  CustomTableFilter = ({filter, onChange}) => (<form>
          <TextField
              type="search"
              className="w-100"
              value={filter ? filter.value : ""}
              onChange={(event) => onChange(event.target.value)}
              InputProps={{
                  startAdornment: 
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            
              }}
          />
       </form>)
    ;

    LeadsTableColumns = [
        {
            "Header": "ID",
            "accessor": "id",
            "width": 70,
            "Filter": this.CustomTableFilter},
        {
            "Header": "Name",
            "accessor": "name",
            "Filter": this.CustomTableFilter},
        {
            "Header": "Source",
            "accessor": "source",
            "Filter": this.CustomTableFilter
        },
        {
            "Header": "Actions",
            "accessor": "actions",
            "sortable": false,
            "filterable": false,
            "width": 100
        }
    ];

  processLeadsData (res) {
      if (this.isUnmounted) {
          return;
      }
      const {lead_selected} = this.state;
      this.setState({"lead_selected": null});
      this.setState({
          "tableData": res.data.data.map((prop, key) => {
              if (
                lead_selected &&
                lead_selected.id &&
                lead_selected.id === prop.id
              ) {
                  this.setState({"lead_selected": prop});
              }
              return this.setLeadDetails(
                  prop,
                  key
              );

          }),
          "pages": res.data.last_page,
          "items_data": res.data.from && res.data.to && res.data.total
            ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
            : "",
          "loading": false,
          "leadSources": res.data.sources
      });
  }

  setLeadDetails (prop, key) {
      return {
          "id": prop.id,
          key,
          "name": prop.name,
          "source": prop.lead_source.name,
          "data": prop,
          "actions":
            <div>
                <Button
                    title={'Edit lead'}
                    className="edit"
                    color="info"
                    justIcon
                    onClick={() => {

                        const obj = this.state.tableData.find((o) => o.key === key);
                        this.handleLeadDetailsClick(
                            "leadDetailsModal",
                            obj.data
                        );

                    }}
                    round
                    simple
                >
                    <Edit />
                </Button>
                {" "}
                <Button
                    title={'Delete lead'}
                    className="remove"
                    color="danger"
                    justIcon
                    onClick={() => {

                        const obj = this.state.tableData.find((o) => o.key === key);
                        this.handleLeadDetailsDeleteClick(
                            obj.data.id,
                            obj.data.name
                        );

                    }}
                    round
                    simple
                >
                    <Close />
                </Button>
                {" "}
            </div>
      };
  }

  handleLeadDetailsClick (modal, data) {
      const leadDetails = data !== null
          ? {
              "id": data.id ? data.id : "",
              "name": data.name ? data.name : "",
              "lead_source_id": data.lead_source_id ? data.lead_source_id : "",
              "action": "update",
              "confirmModalTitle": "Update Lead Details"
          }
          : {
              "id": "",
              "name": "",
              "lead_source_id": "",
              "action": "new",
              "confirmModalTitle": "Create Lead"
          },

          x = [];
      x[modal] = true;
      this.setState({...x,
          ...leadDetails});
  }

  getLeadsData (page, pageSize, sorted, filtered, handleRetrievedData) {
      const url = "/leads/paginate",
          postObject = {
              "page": page + 1,
              "per_page": pageSize,
              "sort": sorted,
              "filter": filtered
          };

      return axios(this.props)
        .post(
              url,
              postObject
          )
          .then((response) => handleRetrievedData(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  handleLeadDetailsDeleteClick (id, name) {
      if (id.length === 0) {
          return;
      }
      this.setState({
          "confirmModal": true,
          "confirmModalMsg": `Are you sure you want to delete ${name}?`,
          "confirmModalTitle": "Delete Lead",
          "action": "delete",
          "delete_id": id
      });
  }

  doDeleteLeadDetailsClick () {
      this.handleClose("confirmModal");
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "_method",
          "DELETE"
      );
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              `/leads/${this.state.delete_id}`,
              fd,
              {
                  "headers": {"Content-Type": "application/x-www-form-urlencoded"}
              }
          )
          .then((response) => this.processDeleteLeadDetails(response))
          .catch((error) => this.processErrorAxios(error));
  }

  processDeleteLeadDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "doneModal": false,
          "leadDetailsModal": false,
          "doneModalMsg": "Lead details deleted",
          "doneModalBtn": "OK",
          "tr_color": "success"
      });
      this.showLeadsNotification();
      this.refreshLeadsTable();
      helpers.hideLoading();
  }

  handleAcceptLeadDetails (modal, lead_operator_name, lead_source_id, new_lead_source) {
    const x = [];
    x[modal] = true;
    this.setState({
        ...x,
        "confirmModalMsg": "Are you sure?",
        "name": lead_operator_name,
        "lead_source_id": lead_source_id,
        "new_lead_source_name": new_lead_source,
    });
  }

  doSaveLeadDetailsClick () {
      this.handleClose("confirmModal");
      if (this.state.action === "update" && this.state.id.length === 0) {
      } else {
          helpers.showLoading();
          this.setState({"btnDisabled": true});
          if (this.state.action === "update") {
              const fd = new FormData();
              fd.append(
                  "name",
                  this.state.name
              );
              if (this.state.lead_source_id === "add_new") {
                fd.append(
                    "new_lead_source_name",
                    this.state.new_lead_source_name
                );
              } else {
                fd.append(
                    "lead_source_id",
                    this.state.lead_source_id
                );
              }
              fd.append(
                  "_method",
                  "PATCH"
              );
              axios(this.props)
                .post(
                      `/leads/${this.state.id}`,
                      fd,
                      {
                          "headers": {"Content-Type": "application/x-www-form-urlencoded"}
                      }
                  )
                  .then((response) => this.processSaveLeadDetails(response))
                  .catch((error) => this.processErrorAxios(error));
          } else {
              axios(this.props)
                .post(
                      "/leads",
                      {
                          "name": this.state.name,
                          "lead_source_id": this.state.lead_source_id === "add_new" ? null : this.state.lead_source_id,
                          "new_lead_source_name": this.state.lead_source_id === "add_new" ? this.state.new_lead_source_name : null
                      }
                  )
                  .then((response) => this.processSaveLeadDetails(response))
                  .catch((error) => this.processErrorAxios(error));
          }
      }
  }

  processSaveLeadDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "doneModal": false,
          "leadDetailsModal": false,
          "doneModalMsg": "Lead details saved",
          "doneModalBtn": "OK",
          "tr_color": "success"
      });
      this.showLeadsNotification();
      this.refreshLeadsTable();
      helpers.hideLoading();
  }

  render () {
      const authService = new AuthService(
          this.state,
          this.props
      );
      if (!authService.getToken()) {
          return <Redirect to="/auth/login" />;
      }

      if (authService.isPasswordExpired()) {
          return <Redirect to="/admin/changepassword" />;
      }
      const {classes} = this.props,
          {tableData, pages, loading} = this.state;
      return (
          <div>
              <GridContainer>
                  <GridItem xs={12}>
                      <Card>
                          <CardHeader
                              color="success"
                              icon
                          >
                              <CardIcon color="success">
                                  <DeviceHub />
                              </CardIcon>
                              <h4 className={classes.cardIconTitle}>
                                Leads
                              </h4>
                              <CardIcon
                                  title={'Create lead'}
                                  className="float-button"
                                  color="info"
                                  onClick={() => this.handleLeadDetailsClick(
                                      "leadDetailsModal",
                                      null
                                  )}
                              >
                                  <Add />
                              </CardIcon>
                              <CardIcon
                                  title={'Refresh leads table'}
                                  className="float-button"
                                  color="warning"
                                  onClick={() => this.refreshLeadsTable()}
                              >
                                  <Refresh />
                              </CardIcon>
                          </CardHeader>
                          <CardBody>
                              <ReactTable
                                  className="-striped -highlight"
                                  columns={this.LeadsTableColumns}
                                  data={tableData}
                                  defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                  defaultPageSize={10}
                                  filterable
                                  getTrProps={(state, rowInfo, column, instance) => {

                                      if (typeof rowInfo !== "undefined") {

                                          return {
                                              "onClick": (e, handleOriginal) => {

                                                  this.setState({
                                                      "selected": rowInfo.index
                                                  });
                                                  if (handleOriginal) {

                                                      handleOriginal();

                                                  }

                                                  if (
                                                    this.state.lead_selected &&
                                                    rowInfo.original &&
                                                    rowInfo.original.id &&
                                                    rowInfo.original.id !== this.state.lead_selected.id
                                                  ) {
                                                      this.setState({"lead_selected": null});
                                                  }
                                              },
                                              "style": {
                                              "background":
                                                this.state.lead_selected &&
                                                rowInfo.original &&
                                                rowInfo.original.id &&
                                                rowInfo.original.id === this.state.lead_selected.id
                                                    ? "lightcyan"
                                                    : null
                                              }
                                          };
                                      }
                                      return {
                                          "onClick": (e, handleOriginal) => {
                                              if (handleOriginal) {
                                                  handleOriginal();
                                              }
                                          }
                                      };
                                  }}
                                  itemsCountText={this.state.items_data}
                                  loading={loading}
                                  manual
                                  onFetchData={(state, instance) => {
                                      this.setState({"loading": true});
                                      this.getLeadsData(
                                          state.page,
                                          state.pageSize,
                                          state.sorted,
                                          state.filtered,
                                          (res) => this.processLeadsData(res)
                                      );
                                  }}
                                  pages={pages}
                                  PaginationComponent={Pagination}
                                  ref={(refReactLeadsTable) => {
                                      this.refReactLeadsTable = refReactLeadsTable;
                                  }}
                                  showPageSizeOptions
                                  showPaginationBottom
                                  showPaginationTop={false}
                              />
                          </CardBody>
                      </Card>
                      {this.state.leadDetailsModal
                        ? <LeadDetails
                            dialogTitle={
                                this.state.action === "update"
                                    ? "Update Lead Details"
                                    : "Create Lead"
                            }
                            handleChange={this.handleChange}
                            onClose={() => this.handleClose("leadDetailsModal")}
                            onYesClick={(lead_operator_name, lead_source_id, new_lead_source) => this.handleAcceptLeadDetails("confirmModal", lead_operator_name, lead_source_id, new_lead_source)}
                            {...this.state}
                        />
                        : ""
                      }
                      <ConfirmDialog
                          confirmModal={this.state.confirmModal}
                          confirmModalMsg={this.state.confirmModalMsg}
                          confirmModalTitle={this.state.confirmModalTitle}
                          onClose={() => this.handleClose("confirmModal")}
                          onYesClick={() => (this.state.action === "delete"
                                      ? this.doDeleteLeadDetailsClick()
                                      : this.doSaveLeadDetailsClick())}
                      />
                      {this.state.doneModalMsg
                          ? <Snackbar
                              close
                              closeNotification={() => {
                                  this.handleCloseNotification();
                              }}
                              color={this.state.tr_color}
                              icon={AddAlert}
                              message={this.state.doneModalMsg}
                              open={this.state.tr}
                              place="tr"
                          />
                          : ""}
                  </GridItem>
              </GridContainer>
          </div>
      );
  }
}

Leads.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Leads));
