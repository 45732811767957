import {toHiddenUiSchema} from "./util";

const restoreField = (field, uiSchema, origUiSchema, schema) => {

    const separatorIndex = field.indexOf(".");
    if (separatorIndex === -1) {
        uiSchema[field] = origUiSchema[field];
    } else {
        const parentField = field.substr(
                0,
                separatorIndex
            ),
            childField = field.substr(separatorIndex + 1),
            parentFieldSchema = schema.properties[parentField];
        if (uiSchema[parentField]["ui:widget"] === "hidden") {
            delete uiSchema[parentField]["ui:widget"];
            delete uiSchema[parentField]["ui:field"];
            uiSchema[parentField] = toHiddenUiSchema(
                parentFieldSchema,
                origUiSchema[parentField]
            );

            //Show dependent fields
            if (origUiSchema[parentField][childField] !== undefined) {
                for (var key in origUiSchema[parentField][childField]) {
                    if (typeof origUiSchema[parentField][childField][key] === 'object') {
                        if (uiSchema[parentField][key] !== undefined) {
                            delete uiSchema[parentField][key]["ui:widget"];
                            delete uiSchema[parentField][key]["ui:field"];
                        }
                    }
                }
            }
        }

        restoreField(
            childField,
            uiSchema[parentField],
            origUiSchema[parentField],
            parentFieldSchema
        );
    }

};

function restoreFields (fields, uiSchema, origUiSchema, schema) {
    fields.forEach((field) => restoreField(
        field,
        uiSchema,
        origUiSchema,
        schema
    ));
    return uiSchema;
}

function replaceAliases (aliases, uiSchema, origUiSchema) {
    Object.keys(aliases).forEach((field) => {
        const alias = aliases[field];
        uiSchema[field] = origUiSchema[alias];
    });
}

export default function extractSubUiSchema (
    fields,
    aliases,
    origUiSchema,
    uiSchema = {},
    schema
) {
    restoreFields(
        fields,
        uiSchema,
        origUiSchema,
        schema
    );
    replaceAliases(
        aliases,
        uiSchema,
        origUiSchema
    );

    return uiSchema;
}
