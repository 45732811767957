import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import Paper from "@material-ui/core/Paper";
// import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
// import Popper from "@material-ui/core/Popper";
// import Divider from "@material-ui/core/Divider";

// @material-ui/icons
// import Person from "@material-ui/icons/Person";
import ExitToApp from "@material-ui/icons/ExitToApp";

// Core components
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

import {connect} from "react-redux";
import {doClientLogout} from "../../store/actions/authActions";
// import AuthService from "customs/auth/AuthServiceClient";
// import helpers from "customs/helpers/helpers";

class HeaderLinks extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            // "openNotification": false,
            // "openProfile": false,
            // "notifications": [],
            // "notification_source_token": props.auth.token ? props.auth.token : ""
        };
        // this.notification_source = null;
        // this.handleEventMessage = this.handleEventMessage.bind(this);
        // this.handleEventError = this.handleEventError.bind(this);

    }

//   handleClickNotification = () => {

//       this.setState({"openNotification": !this.state.openNotification});

//   };

//   handleCloseNotification = () => {

//       this.setState({
//           "openNotification": false,
//           "notifications": this.state.notifications.map((prop, key) => {

//               prop.seen = 1;
//               return prop;

//           })
//       });

//   };

//   handleClickProfile = () => {

//       this.setState({"openProfile": !this.state.openProfile});

//   };

//   handleCloseProfile = () => {

//       this.setState({"openProfile": false});

//   };

  activeRoute = (routeName) => (window.location.href.indexOf(routeName) > -1 ? "active" : "");

//   handleGotoProfile = () => {

//       this.handleCloseProfile();
//       if (!this.activeRoute("/profile")) {

//           this.props.history.push("/admin/profile");

//       }

//   };

//   handleGotoChangePassword = () => {

//       this.handleCloseProfile();
//       if (!this.activeRoute("/changepassword")) {

//           this.props.history.push("/admin/changepassword");

//       }

//   };

  handleLogoutClick = () => {

    //   this.handleCloseProfile();
      this.props.doClientLogout({...this.props});
      try {

          console.clear();

      } catch (err) {}

  };

//   handleGotoNotifications = () => {

//       this.handleCloseNotification();
//       if (!this.activeRoute("/notifications")) {

//           this.props.history.push("/admin/notifications");

//       }

//   };

  componentDidMount () {

    //   if (new AuthService(
    //       this.state,
    //       this.props
    //   ).getToken()) {

    //       if (!this.notification_source) {

    //           this.initializeNotificationSource();

    //       }
    //       this.addEventSourceListener();

    //   } else {

    //       this.closeNotificationConnection();

    //   }

  }

  componentWillUnmount () {

      this.isUnmounted = true;
    //   this.closeNotificationConnection();

  }

  componentDidUpdate () {

    //   const token = this.props.auth.token ? this.props.auth.token : "";
    //   if (token.length > 0 && this.state.notification_source_token !== token) {

    //       this.setState({"notification_source_token": token});
    //       this.closeNotificationConnection();
    //       this.initializeNotificationSource();

    //   }

  }

  initializeNotificationSource () {

    //   const new_source = helpers.createNotificationSource(
    //       new AuthService(
    //           this.state,
    //           this.props
    //       ).getUserId(),
    //       this.props
    //   );
    //   if (new_source) {

    //       this.notification_source = new_source;

    //   }
    //   this.addEventSourceListener();

  }

//   handleEventMessage = function (message) {

//       if (this.isUnmounted) {

//           return;

//       }
//       const data = JSON.parse(message.data);
//       if (data.length !== 0) {

//           this.setState({
//               "notifications": [
//                   {...data,
//                       "seen": 0},
//                   ...this.state.notifications
//               ]
//           });

//       }

//   };

//   handleEventError = function (e) {

//       if (this.isUnmounted) {

//           return;

//       }
//       if (e.status === 401) {

//           this.setState({"notification_source_token": ""});
//           this.closeNotificationConnection();

//       }

//   };

//   addEventSourceListener () {

//       if (this.notification_source) {

//           this.notification_source.addEventListener(
//               "message",
//               this.handleEventMessage,
//               false
//           );
//           this.notification_source.addEventListener(
//               "error",
//               this.handleEventError,
//               false
//           );

//       }

//   }

//   closeNotificationConnection () {

//       this.setState({"notifications": []});
//       if (this.notification_source) {

//           this.notification_source.close();

//       }

//   }

  render () {

      const {classes, rtlActive} = this.props,
        //   {openNotification, openProfile} = this.state,
        //   dropdownItem = classNames(
        //       classes.dropdownItem,
        //       classes.primaryHover,
        //       {[classes.dropdownItemRTL]: rtlActive}
        //   ),
          wrapper = classNames({
              [classes.wrapperRTL]: rtlActive
          }),
          managerClasses = classNames({
              [classes.managerClasses]: true
          })
        //   ,
        //   {notifications} = this.state,
        //   new_notification_cnt = notifications.filter((item) => item.seen === 0)
        ;
      return (
          <div className={wrapper}>
              <div className={managerClasses}>
                    <Button
                        aria-haspopup="true"
                        aria-label="Logout"
                        className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                        color="transparent"
                        justIcon
                        title={'Logout'}
                        muiClasses={{
                            "label": rtlActive ? classes.labelRTL : ""
                        }}
                        onClick={this.handleLogoutClick}
                    >
                        <ExitToApp
                            className={
                                `${classes.headerLinksSvg
                                } ${
                                    rtlActive
                                        ? `${classes.links} ${classes.linksRTL}`
                                        : classes.links}`
                            }
                        />
                        <Hidden
                            implementation="css"
                            mdUp
                        >
                            <span
                                className={classes.linkText}
                                onClick={this.handleLogoutClick}
                            >
                                {rtlActive ? "إعلام" : "Logout"}
                            </span>
                        </Hidden>
                    </Button>
                  {/* <Button
                      aria-haspopup="true"
                      aria-label="Notifications"
                      aria-owns={openNotification ? "notification-menu-list" : null}
                      buttonRef={(node) => {

                          this.anchorNotification = node;

                      }}
                      className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                      color="transparent"
                      justIcon
                      title={'Show Notifications'}
                      muiClasses={{
                          "label": rtlActive ? classes.labelRTL : ""
                      }}
                      onClick={this.handleClickNotification}
                  >
                      <Notifications
                          className={
                              `${classes.headerLinksSvg
                              } ${
                                  rtlActive
                                      ? `${classes.links} ${classes.linksRTL}`
                                      : classes.links}`
                          }
                      />
                      {new_notification_cnt.length > 0
                          ? <span className={classes.notifications}>
                              {new_notification_cnt.length}
                          </span>
                          : ""}
                      <Hidden
                          implementation="css"
                          mdUp
                      >
                          <span
                              className={classes.linkText}
                              onClick={this.handleClickNotification}
                          >
                              {rtlActive ? "إعلام" : "Notification"}
                          </span>
                      </Hidden>
                  </Button>
                  <Popper
                      anchorEl={this.anchorNotification}
                      className={classNames({
                          [classes.popperClose]: !openNotification,
                          [classes.popperResponsive]: true,
                          [classes.popperNav]: true
                      })}
                      disablePortal
                      open={openNotification}
                      placement="bottom"
                      transition
                  >
                      {({TransitionProps}) => (<Grow
                              {...TransitionProps}
                              id="notification-menu-list"
                              style={{
                                  "transformOrigin": "0 0 0",
                                  "overflowY": "auto",
                                  "maxHeight": "500px"
                              }}
                          >
                              <Paper className={classes.dropdown}>
                                  <ClickAwayListener onClickAway={this.handleCloseNotification}>
                                      <MenuList role="menu">
                                          {notifications.length > 0 
                                                ? notifications.map((prop, key) => (
                                                    <MenuItem
                                                    key={key}
                                                    className={
                                                        dropdownItem +
                                                        (prop.seen === 0 ? " dropdownItemNew" : "")
                                                    }
                                                    onClick={this.handleGotoNotifications}
                                                    >
                                                    {prop.title}
                                                    </MenuItem>
                                                ))
                                            :                         <MenuItem
                                                  onClick={this.handleGotoNotifications}
                                                  className={dropdownItem}
                                              >
                                                  {"No new notifications"}
                                              </MenuItem>}
                                      </MenuList>
                                  </ClickAwayListener>
                              </Paper>
                          </Grow>)}
                  </Popper> */}
              </div>

              <div className={managerClasses}>
                {/* <Button
                      aria-haspopup="true"
                      aria-label="Person"
                      aria-owns={openNotification ? "profile-menu-list" : null}
                      buttonRef={(node) => {

                          this.anchorProfile = node;

                      }}
                      className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                      color="transparent"
                      justIcon
                      muiClasses={{
                          "label": rtlActive ? classes.labelRTL : ""
                      }}
                      onClick={this.handleClickProfile}
                  >
                      <Person
                          className={
                              `${classes.headerLinksSvg
                              } ${
                                  rtlActive
                                      ? `${classes.links} ${classes.linksRTL}`
                                      : classes.links}`
                          }
                      />
                      <Hidden
                          implementation="css"
                          mdUp
                      >
                          <span
                              className={classes.linkText}
                              onClick={this.handleClickProfile}
                          >
                              {rtlActive ? "الملف الشخصي" : "Profile"}
                          </span>
                      </Hidden>
                  </Button> */}
                  {/* <Button
                      aria-haspopup="true"
                      aria-label="Person"
                      aria-owns={openNotification ? "profile-menu-list" : null}
                      buttonRef={(node) => {

                          this.anchorProfile = node;

                      }}
                      className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                      color="transparent"
                      justIcon
                      muiClasses={{
                          "label": rtlActive ? classes.labelRTL : ""
                      }}
                      onClick={this.handleClickProfile}
                  >
                      <Person
                          className={
                              `${classes.headerLinksSvg
                              } ${
                                  rtlActive
                                      ? `${classes.links} ${classes.linksRTL}`
                                      : classes.links}`
                          }
                      />
                      <Hidden
                          implementation="css"
                          mdUp
                      >
                          <span
                              className={classes.linkText}
                              onClick={this.handleClickProfile}
                          >
                              {rtlActive ? "الملف الشخصي" : "Profile"}
                          </span>
                      </Hidden>
                  </Button>
                  <Popper
                      anchorEl={this.anchorProfile}
                      className={classNames({
                          [classes.popperClose]: !openProfile,
                          [classes.popperResponsive]: true,
                          [classes.popperNav]: true
                      })}
                      disablePortal
                      open={openProfile}
                      placement="bottom"
                      transition
                  >
                      {({TransitionProps}) => (<Grow
                              {...TransitionProps}
                              id="profile-menu-list"
                              style={{"transformOrigin": "0 0 0"}}
                          >
                              <Paper className={classes.dropdown}>
                                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                                      <MenuList role="menu">
                                          <MenuItem
                                              className={dropdownItem}
                                              onClick={this.handleGotoProfile.bind(this)}
                                          >
                                              {rtlActive ? "الملف الشخصي" : "Profile"}
                                          </MenuItem>
                                          <Divider light />
                                          <MenuItem
                                              className={dropdownItem}
                                              onClick={this.handleGotoChangePassword.bind(this)}
                                          >
                                              {rtlActive ? "" : "Change Password"}
                                          </MenuItem>
                                          <Divider light />
                                          <MenuItem
                                              className={dropdownItem}
                                              onClick={this.handleLogoutClick.bind(this)}
                                          >
                                              {rtlActive ? "الخروج" : "Log out"}
                                          </MenuItem>
                                      </MenuList>
                                  </ClickAwayListener>
                              </Paper>
                          </Grow>)}
                  </Popper> */}
              </div>
          </div>
      );

  }

}

HeaderLinks.propTypes = {
    "classes": PropTypes.object.isRequired,
    "rtlActive": PropTypes.bool
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doClientLogout": (props) => dispatch(doClientLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(adminNavbarLinksStyle)(HeaderLinks)));
