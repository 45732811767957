import React, {Children} from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import EventDetailsDialog from "customs/components/EventDetailsDialog";

import BigCalendar from "react-big-calendar";
import moment from "moment";
import "moment-timezone";

import {connect} from "react-redux";
import helpers from "customs/helpers/helpers";
import {doLogout} from "../../store/actions/authActions";

import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

import "moment/locale/en-au";
import "react-big-calendar/lib/css/react-big-calendar.css";

let localizer,
    m;

class Calendar extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "events": [],
            "tr": false,
            "tr_color": "success",
            "doneModalMsg": "",
            "event_details": false,
            "event_dialog_title": "",
            "action": "add",
            "selected_slot_info": null,
            "currentView": "month",
            "local_tz_offset": moment.tz(moment.tz.guess()).utcOffset(),
            "user_tz_offset": moment.tz(props.auth.user.timezone).utcOffset()
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.setEvent = this.setEvent.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.showUsersNotification = this.showUsersNotification.bind(this);
        this.setUsersNotification = this.setUsersNotification.bind(this);

        moment.tz.setDefault(props.auth.user.timezone);
        moment.updateLocale(
            "en-au",
            {"week": {"dow": 0}}
        );
        this.setLocalizer();

    }

    setLocalizer () {
        BigCalendar.tz = moment.tz.guess();
        m = (...args) => moment.tz(
            ...args,
            BigCalendar.tz
        );
        m.localeData = moment.localeData;

        localizer = BigCalendar.momentLocalizer(m);
    }

  convertDateTimeToDate = (datetime, timeZoneName) => {
      const m = moment.tz(
          datetime,
          timeZoneName
      );
      return new Date(
          m.year(),
          m.month(),
          m.date(),
          m.hour(),
          m.minute(),
          0
      );
  };

  convertDateToDateTime = (date, timeZoneName) => {
      const dateM = moment.tz(
              date,
              BigCalendar.tz
          ),
          m = moment.tz(
              {
                  "year": dateM.year(),
                  "month": dateM.month(),
                  "date": dateM.date(),
                  "hour": dateM.hour(),
                  "minute": dateM.minute()
              },
              BigCalendar.tz
          );
      return m.toDate();
  };

  componentDidMount () {
      this._ismounted = true;
      helpers.showLoading();

      axios(this.props)
        .get(`/user/${this.props.auth.user.id}/calendar/events`)
        .then((response) => this.processCalendarEvents(response))
        .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processCalendarEvents (data) {
      this.setState({
          "events": data.data.user_calendar_events
              ? data.data.user_calendar_events.map((prop, key) => this.setEvent(prop))
              : []
      });
      helpers.hideLoading();
  }

  processErrorAxios (error, prop) {
      axiosHelper.processError(
          this.isUnmounted,
          prop,
          error,
          (state) => {
              this.setState(state);
          },
          () => {
              this.showUsersNotification();
          },
          () => {
              this.props.doLogout({...this.props});
          }
      );
  }

  hideNotification;
  showUsersNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  setUsersNotification (msg, color) {
      this.setState({"doneModalMsg": msg,
          "tr_color": color});
      this.showUsersNotification();
  }

  handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false,
          "doneModalMsg": ""});
  }

  setEvent (event) {
      event.allDay = parseInt(event.allDay);
      event.utcStart = event.start;
      event.utcEnd = event.end;
      if (event.start) {
          event.start = moment.utc(event.start).toDate();
      }
      if (event.end) {
          event.end = moment.utc(event.end).toDate();
      }
      return event;
  }

  saveEvent (action, slotInfo) {
      if (action === "add") {
          this.addNewEvent(slotInfo);
      } else {
          this.updateEvent(slotInfo);
      }
  }

  addNewEventAlert (slotInfo) {
    const e = JSON.parse(JSON.stringify(slotInfo));
    e.start = moment
        .utc(moment(slotInfo.start)
        .add(
            this.state.local_tz_offset,
            "minutes"
        )
        .format("YYYY-MM-DD HH:mm:ss"))
        .utcOffset(this.state.user_tz_offset)
        .toDate();
    e.end = moment
        .utc(moment(slotInfo.end)
        .add(
                this.state.local_tz_offset,
                "minutes"
        )
        .format("YYYY-MM-DD HH:mm:ss"))
        .utcOffset(this.state.user_tz_offset)
        .toDate();

    const min_date = moment("2010-01-01");
    if (moment(e.start).isBefore(min_date) || moment(e.start).isBefore(min_date)) {
        this.setUsersNotification(
            "Date not supported.",
            "warning"
        );
    } else {
        this.setState({
            "event_details": true,
            "event_dialog_title": "New Event",
            "action": "add",
            "selected_slot_info": e
        });
    }
  }

  addNewEvent (slotInfo) {
      const new_event = {
          "title": slotInfo.title,
          "start": moment(slotInfo.start)
            .clone()
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss"),
          "end": moment(slotInfo.end)
            .clone()
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss"),
          "allDay": slotInfo.allDay === true ? 1 : 0,
          "color": slotInfo.color
      };

      helpers.showLoading();
      axios(this.props)
        .post(
              `/user/${this.props.auth.user.id}/calendar/events`,
              new_event
          )
          .then((response) => this.processAddEventDetails(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processAddEventDetails (data) {
      if (this.isUnmounted) {
          return;
      }
      if (data.data && data.data.event && data.data.event.id) {
          this.state.events.push(this.setEvent(data.data.event));
      }
      this.setState({"event_details": false});
      this.setUsersNotification(
          "Event created.",
          "success"
      );
      helpers.hideLoading();
  }

  updateEventAlert (slotInfo) {
      const e = JSON.parse(JSON.stringify(slotInfo));
      e.start = moment.utc(e.utcStart).toDate();
      e.end = moment.utc(e.utcEnd).toDate();

      this.setState({
          "event_details": true,
          "event_dialog_title": "Update Event",
          "action": "update",
          "selected_slot_info": e
      });
  }

  updateEvent (slotInfo) {
      const fd = new FormData();
      fd.append(
          "title",
          slotInfo.title
      );
      fd.append(
          "start",
          moment(slotInfo.start)
            .clone()
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss")
      );
      fd.append(
          "end",
          moment(slotInfo.end)
            .clone()
            .tz("UTC")
            .format("YYYY-MM-DD HH:mm:ss")
      );
      fd.append(
          "allDay",
          slotInfo.allDay === true ? 1 : 0
      );
      fd.append(
          "color",
          slotInfo.color
      );
      fd.append(
          "_method",
          "PATCH"
      );

      helpers.showLoading();
      axios(this.props)
        .post(
              `/user/${this.props.auth.user.id}/calendar/events/${slotInfo.id}`,
              fd,
              {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
          )
          .then((response) => this.processUpdateEventDetails(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processUpdateEventDetails (data) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "events": this.state.events.map((prop, key) => {

              if (
                  data.data &&
          data.data.event &&
          data.data.event.id &&
          data.data.event.id === prop.id
              ) {

                  prop = this.setEvent(data.data.event);

              }
              return prop;

          }),
          "event_details": false
      });
      this.setUsersNotification(
          "Event updated.",
          "success"
      );
      helpers.hideLoading();
  }

  deleteEvent (slotInfo) {
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "_method",
          "DELETE"
      );
      axios(this.props)
        .post(
              `/user/${this.props.auth.user.id}/calendar/events/${slotInfo.id}`,
              fd,
              {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
          )
          .then((response) => {

              this.setState({
                  "events": this.state.events.filter((event) => event.id !== slotInfo.id),
                  "event_details": false
              });
              this.setUsersNotification(
                  "Event deleted.",
                  "success"
              );
              helpers.hideLoading();

          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  hideAlert (stateName) {
      this.setState({
          [stateName]: null
      });
  }

  eventColors (event) {
      let backgroundColor = "event-";
      event.color
          ? backgroundColor += event.color
          : backgroundColor += "default";
      return {
          "className": backgroundColor
      };
  }

  render () {
      const today = moment
        .utc(moment()
                .subtract(
                      this.state.local_tz_offset,
                      "minutes"
                  )
                  .format("YYYY-MM-DD HH:mm:ss"))
                  .utcOffset(this.state.user_tz_offset)
                  .toDate(),
          CURRENT_DATE =
      String(String(today.getFullYear()) + today.getMonth()) + today.getDate();
      return (
          <div>
              <GridContainer justify="center">
                  <GridItem
                      md={12}
                      sm={12}
                      xs={12}
                  >
                      <Card>
                          <CardBody
                              calendar
                              style={{"height": "70vh"}}
                          >
                              <style>
                                  {".rbc-date-cell.rbc-now {font-weight: normal !important}"}
                                  {".rbc-today {background-color: transparent;}"}
                              </style>
                              <BigCalendar
                                  components={{
                                      "dateCellWrapper": ({children, value}) => {

                                          const isCurrentDay =
                        CURRENT_DATE ===
                        String(String(value.getFullYear()) +
                          value.getMonth()) +
                          value.getDate();
                                          return React.cloneElement(
                                              Children.only(children),
                                              {
                                                  "style": {
                                                      ...children.style,
                                                      "backgroundColor": isCurrentDay
                                                          ? "#eaf6ff"
                                                          : "transparent"
                                                  }
                                              }
                                          );

                                      }
                                  }}
                                  defaultDate={new Date()}
                                  defaultView="month"
                                  eventPropGetter={this.eventColors}
                                  events={this.state.events.map((event) => ({
                                      ...event,
                                      "start": this.convertDateTimeToDate(
                                          event.start,
                                          this.props.auth.user.timezone
                                      ),
                                      "end": this.convertDateTimeToDate(
                                          event.end,
                                          this.props.auth.user.timezone
                                      )
                                  }))}
                                  formats={{
                                      "agendaHeaderFormat": ({start, end}, culture, local) => `${local.format(
                                          start,
                                          "MMM DD YYYY",
                                          culture
                                      )
                                      } - ${
                                          local.format(
                                              end,
                                              "MMM DD YYYY",
                                              culture
                                          )}`

                                  }}
                                  localizer={localizer}
                                  onSelectEvent={(slotInfo) => this.updateEventAlert(slotInfo)}
                                  onSelectSlot={
                                      this.state.currentView === "month"
                                          ? (slotInfo) => {

                                              slotInfo.start = this.convertDateToDateTime(
                                                  slotInfo.start,
                                                  this.props.auth.user.timezone
                                              );
                                              slotInfo.end = this.convertDateToDateTime(
                                                  slotInfo.end,
                                                  this.props.auth.user.timezone
                                              );
                                              slotInfo.slots = slotInfo.slots.map((date) => this.convertDateToDateTime(
                                                  date,
                                                  this.props.auth.user.timezone
                                              ));
                                              return this.addNewEventAlert(slotInfo);

                                          }
                                          : () => {} // TODO, get correct datetime by timezone
                                  }
                                  onView={(view) => {

                                      this.setState({"currentView": view});

                                  }}
                                  scrollToTime={new Date(
                                      1970,
                                      1,
                                      1,
                                      6
                                  )}
                                  selectable
                                  views={[
                                      "month",
                                      "agenda"
                                  ]}
                              />
                          </CardBody>
                      </Card>
                  </GridItem>
              </GridContainer>
              {this.state.event_details
                  ? <EventDetailsDialog
                      action={this.state.action}
                      auth={this.props.auth}
                      currentView={this.state.currentView}
                      deleteEvent={this.deleteEvent}
                      event={this.state.selected_slot_info}
                      event_details={this.state.event_details}
                      onClose={() => this.setState({"event_details": false})}
                      saveEvent={this.saveEvent}
                      setUsersNotification={this.setUsersNotification}
                      {...this.state}
                    />
                  : ""}
              {this.state.doneModalMsg
                  ? <Snackbar
                      close
                      closeNotification={() => {

                          this.handleCloseNotification();

                      }}
                      color={this.state.tr_color}
                      icon={AddAlert}
                      message={this.state.doneModalMsg}
                      open={this.state.tr}
                      place="tr"
                    />
                  : ""}
          </div>
      );
  }

}

Calendar.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Calendar));
