import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import moment from "moment";
import helpers from "customs/helpers/helpers";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class ApplicationTimelineDialog extends React.Component {

    getDetails(details) {
        return <div>
            {
                details.hasOwnProperty('message') && details.message !== null
                ? <p>{helpers.addLineBreaks(details.message.replace(/[\n]/g, '<br>'))}</p> : ''
            }
            {
                details.hasOwnProperty('request_field_values') && details.request_field_values !== null
                ? <div>
                    Requested Fields:
                    <div style={{maxHeight: "100px", overflowY: "auto", paddingLeft: "5px"}}>
                        {
                            details.request_field_values.map((prop, key) => {
                                let val = prop, found = false;
                                for(let i=0; i<this.props.available_fields.length; i++) {
                                    if (this.props.available_fields[i].registry_key === prop['key']) {
                                        val = this.props.available_fields[i].name;
                                        found = true;
                                        break;
                                    }
                                }
                                return found ? <div key={key}>{val}</div> : '';
                            })
                        }
                    </div>
                </div> : ''
            }
            {
                details.hasOwnProperty('answers') && details.answers !== null
                ? <div>
                    Answers:
                    <div style={{maxHeight: "100px", overflowY: "auto", paddingLeft: "5px"}}>
                        {
                            Object.keys(details.answers).map((prop) => {
                                let val = details.answers[prop], name = '', found = false;
                                for(let i=0; i<this.props.available_fields.length; i++) {
                                    if (this.props.available_fields[i].registry_key === prop) {
                                        name = this.props.available_fields[i].name;
                                        found = true;
                                        break;
                                    }
                                }
                                return found ? <div key={prop}>{name + ':'}<br/>&nbsp;&nbsp;{val}</div> : '';
                            })
                        }
                    </div>
                </div> : ''
            }
        </div>;
    }

    renderMessageReply(message) {
        return <div>Message Body: <div style={{maxHeight: "100px", overflowY: "auto", paddingLeft: "5px"}}>{helpers.addLineBreaks(message.replace(/[\n]/g, '<br>'))}</div></div>;
    }

    renderTimeline(timeline) {
        let timelines = [];
        timeline.forEach((prop, key) => {
            timelines.push({
                'date' : moment.utc(prop.created_at).format('DD-MM-YYYY HH:mm:ss'),
                'bg_color' : this.props.colors[prop.status],
                'line_connector' : !(prop.status === 'approved' && timeline.length === (key + 1)),
                'value' : helpers.fixStatusLabel(prop.status),
                'by_line' : ' by ' + prop.updater.name + ' (' + (prop.updater.type === 'A' ? 'Admin' : prop.updater.type) + ')',
                'details' : prop.details !== null ? this.getDetails(prop.details) : ''
            });
            let logs = prop.details && prop.details.hasOwnProperty('logs') ? prop.details.logs : {};
            if (logs.length > 0) {
                logs.forEach((p, k) => {
                    timelines.push({
                        'date' : moment.utc(p.date).format('DD-MM-YYYY HH:mm:ss'),
                        'bg_color' : '',
                        'line_connector' : true,
                        'value' : p.message,
                        'by_line' : '',
                        'details' : p.hasOwnProperty('details')
                                        ? (p.hasOwnProperty('is_reply_to_msg') && p.is_reply_to_msg === true ? this.renderMessageReply(p.details) : '')
                                        : ''
                    });
                });
            }
        });

        return <Timeline position="alternate">{
            timelines.map((prop, key) => {
                return <TimelineItem key={key}>
                    <TimelineOppositeContent color="text.secondary">
                        { prop.date }
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot style={{"background": prop.bg_color}} />
                        {prop.line_connector ? <TimelineConnector /> : ''}
                    </TimelineSeparator>
                    <TimelineContent style={{'flex': 2}}>
                        <span>
                            <span style={{"color": prop.bg_color}}>
                                {prop.value}
                            </span>
                            {prop.by_line.toString().length > 0 ? <p style={{fontSize: "0.75rem"}}>{prop.by_line}</p> : ''}
                        </span>
                        {prop.details}
                    </TimelineContent>
                </TimelineItem>;
            })
        }</Timeline>;
    }

  render () {
        try {
            if (document.getElementById('view-timeline-dialog') !== null) {
                document.getElementById('view-timeline-dialog').querySelector('.MuiDialog-paperWidthSm').style.maxWidth = '700px';
            }
        } catch(e) {}

      const {classes} = this.props;
      return (
          <Dialog
              aria-describedby="update-profile-confirm-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{
                  "root": `${classes.center} ${classes.modalRoot}`,
                  "paper": `${classes.modal} ${classes.modalSmall}`
              }}
              fullWidth
              keepMounted
              onClose={() => this.props.onClose("applicationTimelineModal")}
              open={this.props.applicationTimeline}
              id="view-timeline-dialog"
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  Status Timeline
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="update-profile-confirm-modal-slide-description"
              >
                  {this.props.application_history && this.props.application_history.length > 0
                      ? this.renderTimeline(this.props.application_history)
                      : <div style={{width: "100%", paddingTop: "1rem", textAlign: "center"}}>{"No status history"}</div>}

              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                  <Button
                      className={classes.modalSmallFooterFirstButton}
                      color="transparent"
                      onClick={() => this.props.onClose("applicationTimeline")}
                  >
                      Close
                  </Button>
              </DialogActions>
          </Dialog>
      );
  }
}

export default withStyles(extendedFormsStyle)(ApplicationTimelineDialog);
