import React from "react";

import Autosuggest from "react-autosuggest";
import TagsInput from "react-tagsinput";

const theme = {
    "suggestionsList": {
        "margin": 0,
        "padding": 0,
        "listStyleType": "none"
    }
};

class AutoCompleteTags extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "tags": []
        };
        this.handleChange = this.handleChange.bind(this);
        this.autocompleteRenderInput = this.autocompleteRenderInput.bind(this);

    }

    handleChange (tags) {
        this.setState({tags});
    }

    autocompleteRenderInput ({addTag, ...props}) {
        const /*handleOnChange = (e, {newValue, method}) => {
                if (method === "enter") {
                    e.preventDefault();
                } else {
                    props.onChange(e);
                }
            },*/
            inputValue = (props.value && props.value.trim().toLowerCase()) || "",
            inputLength = inputValue.length;
        let suggestions = [];
        try {
            suggestions = inputLength === 0 ? [] : this.props.tag_list.filter((tag) => tag.toLowerCase().slice(0, inputLength) === inputValue);
        } catch(e) {suggestions = [];}

        return (
            <Autosuggest
                getSuggestionValue={(suggestion) => suggestion}
                inputProps={{...props}}
                onSuggestionsClearRequested={() => {}}
                onSuggestionSelected={(e, {suggestion}) => {
                    addTag(suggestion);
                }}
                onSuggestionsFetchRequested={() => {}}
                ref={props.ref}
                renderSuggestion={(suggestion) => <span className="react-tagsinput-tag primary">
                        {suggestion}
                     </span>}
                suggestions={suggestions}
                theme={theme}
            />
        );
    }

    render () {
        return (
            <TagsInput
                disabled={this.props.is_view}
                onChange={this.props.onChange}
                onlyUnique
                renderInput={this.autocompleteRenderInput}
                tagProps={{"className": "react-tagsinput-tag primary"}}
                value={this.props.tags}
            />
        );
    }
}

export default AutoCompleteTags;
