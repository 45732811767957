import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import Button from "components/CustomButtons/Button.jsx";
import Create from "@material-ui/icons/Create";
import Assignment from "@material-ui/icons/Assignment";
import FormDialog from './FormDialog';
import {findVal} from "customs/forms/utils";
import isEqual from 'lodash/isEqual';
import { dataURItoBlob } from "@rjsf/core/lib/utils";
import helpers from "customs/helpers/helpers";

FormDialog.propTypes = {
    "onClose": PropTypes.func.isRequired,
    "open": PropTypes.bool.isRequired,
};

function FormOpenDialog (props) {

    var form_field_count = 0;
    const [
            open,
            setOpen
        ] = React.useState(false),
        {
            options,
            schema,
            uiSchema,
            onSubmit,
            // onClose,
            client_form_id,
            submitForApproval
        } = props,

        handleClickOpen = () => {

            setOpen(true);

        },

        handleClose = () => {
            setOpen(false);
        },

        checkForFile = (formData) => {
            let erroredFiles = [];
            Object.keys(formData).map((key, i) => {
                let prop_ui_schema = findVal(uiSchema, key);
                let prop_schema = findVal(schema.properties, key);
                if (prop_schema['field_type'] !== undefined &&
                    (prop_schema['field_type'] === 'file' || prop_schema['field_type'] === 'files' || prop_schema['field_type'] === 'file_sig') &&
                    formData[key] !== undefined && Object.keys(formData[key]).length > 0
                ) {
                    if (prop_ui_schema['ui:options'] !== undefined) {
                        const maxBytes = prop_ui_schema['ui:options']['maxBytes'] !== undefined ? prop_ui_schema['ui:options']['maxBytes'] : Infinity;
                        const fileTypes = prop_ui_schema['ui:options']['accept'] !== undefined ? prop_ui_schema['ui:options']['accept'].replaceAll(' ', '').split(',') : [];
                        let details;
                        if (typeof formData[key] === 'object') {
                            formData[key].map((data, k) => {
                                try {
                                    details = dataURItoBlob(data);
                                    let sizeError = details.blob.size > maxBytes;
                                    let typeError = fileTypes.length > 0 && !fileTypes.includes('.' + details.name.split('.').pop());
                                    if (sizeError || typeError) {
                                        erroredFiles.push(decodeURIComponent(details.name) + ' - invalid file ' + (sizeError ? 'size' : (typeError ? 'extension' : '')));
                                    }
                                } catch(e) {}
                                return data;
                            });
                        } else {
                            try {
                                details = dataURItoBlob(formData[key]);
                                let sizeError = details.blob.size > maxBytes;
                                let typeError = fileTypes.length > 0 && !fileTypes.includes('.' + details.name.split('.').pop());
                                if (sizeError || typeError) {
                                    erroredFiles.push(decodeURIComponent(details.name) + ' - invalid file ' + (sizeError ? 'size' : (typeError ? 'extension' : '')));
                                }
                            } catch(e) {}
                        }
                    }
                } else if (formData[key] !== undefined && formData[key] !== null && typeof formData[key] === 'object' && Object.keys(formData[key]).length > 0) {
                    erroredFiles.push(...checkForFile(formData[key]));
                }
                return key;
            });

            return erroredFiles;
        },

        handleUpdateFormFieldsCount = (count) => {
            form_field_count = count;
        },

        handleSubmit = ({formData}, e) => {
            helpers.showLoading();
            setTimeout(
                () => {
                    let erroredFiles = checkForFile(formData);
                    helpers.hideLoading();
                    if (erroredFiles.length > 0) {
                        props.setUsersNotification('Some attached files are invalid, please correct the following files:<br>' + erroredFiles.join('<br>'), 'danger');
                    } else {
                        onSubmit(
                            formData,
                            client_form_id,
                            form_field_count
                        );
                    }
                },
                1
            );
        },

        handleSubmitForApproval = (formData) => {
            if (!isEqual(props.form_data_init, formData)) {
                props.setUsersNotification('Please save changes first.', 'danger');
            } else {
                submitForApproval(formData);
            }
        };

    return (
        <>
            <Button
                title={'Show application details'}
                className="edit"
                color="primary"
                justIcon
                onClick={handleClickOpen}
                round
                simple
            >
                {
                    props.disabled
                    ? <Assignment style={{width:"22px", height: "22px"}} />
                    : <Create style={{width:"22px", height: "22px"}} />
                }
            </Button>
            <FormDialog
                disabled={props.disabled}
                form_data_init={props.form_data_init}
                onClose={handleClose}
                onSubmit={handleSubmit}
                onCountChange={handleUpdateFormFieldsCount}
                open={open}
                options={options}
                schema={schema}
                submitForApproval={handleSubmitForApproval}
                uiSchema={uiSchema}
            />
        </>
    );

}

export default withStyles(extendedFormsStyle)(FormOpenDialog);
