import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import ReactTable from "react-table";
import Description from "@material-ui/icons/Description";
import Refresh from "@material-ui/icons/Refresh";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import DescriptionIcon from "@material-ui/icons/Description";
// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import ConfirmDialog from "customs/components/ConfirmDialog";
import FileSearch from "customs/components/FileSearch";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import Search from "@material-ui/icons/Search";
import UserFile from "customs/components/UserFile";
import TagsInput from "react-tagsinput";
// import AutoCompleteTags from "customs/components/AutoCompleteTags";
import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import helpers from "customs/helpers/helpers";
import {doLogout} from "../../store/actions/authActions";

import axios from "axios/axios";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import axiosHelper from "axios/axiosHelper";

class Files extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "files": [],
            "is_view": false,
            "user_file_details": false,
            "file_dialog_title": "",
            "selected_file": [],
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "doSearch": false
        };

        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.handleAddFile = this.handleAddFile.bind(this);
        this.handleViewFile = this.handleViewFile.bind(this);
        this.handleDownloadFile = this.handleDownloadFile.bind(this);
        this.handleDownloadVersionFile = this.handleDownloadVersionFile.bind(this);
        this.handleEditFile = this.handleEditFile.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.refreshUserFilesTable = this.refreshUserFilesTable.bind(this);

    }

  hideNotification;
  showUsersNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false,
          "doneModalMsg": ""});
  }

  setUsersNotification (msg, color) {
      this.setState({"doneModalMsg": msg,
          "tr_color": color});
      this.showUsersNotification();
  }

  componentWillUnmount () {
      this.isUnmounted = true;
      clearTimeout(this.hideNotification);
  }

  processErrorAxios (error, prop) {
      axiosHelper.processError(
          this.isUnmounted,
          prop,
          error,
          (state) => {
              this.setState(state);
          },
          () => {
              this.showUsersNotification();
          },
          () => {
              this.props.doLogout({...this.props});
          }
      );
  }

  getFilesData (page, pageSize, sorted, filtered, handleRetrievedData) {
      const url = `/user/${this.props.auth.user.id}`;
      return axios(this.props)
        .get(url)
        .then((response) => {
              handleRetrievedData(response);
          })
          .catch((error) => {
              this.processErrorAxios(
                  error,
                  null
              );
          });
  }

  processFilesData (res) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "files": res.data.files.map((prop, key) => this.setFileDetails(
              prop,
              key
          )),
          "items_data":
        res.data.from && res.data.to && res.data.total
            ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
            : "",
          "loading": false
      });
  }

  setFileDetails (prop, key) {
      return {
          "id": prop.id,
          key,
          "filename": prop.filename,
          "tags": prop.tags != null && prop.tags.length > 0
            ? <TagsInput
                disabled
                onlyUnique
                tagProps={{"className": "react-tagsinput-tag primary"}}
                value={prop.tags}
            />
            : "",
          "data": prop,
          "actions":
        <div style={{"float": "right"}}>
            <Button
                title={'File details'}
                className="view"
                color="warning"
                justIcon
                key={`btnV${key}`}
                onClick={(event) => this.handleViewFile(
                    event,
                    this.props.auth.user.id,
                    prop
                )}
                round
                simple
            >
                <DescriptionIcon />
            </Button>
            <Button
                title={'Edit file'}
                className="edit"
                color="info"
                justIcon
                key={`btnE${key}`}
                onClick={(event) => this.handleEditFile(
                    event,
                    this.props.auth.user.id,
                    prop
                )}
                round
                simple
            >
                <Edit />
            </Button>
            <Button
                title={'Download file'}
                className="view"
                color="facebook"
                justIcon
                key={`btnF${key}`}
                onClick={(event) => this.handleDownloadFile(
                    event,
                    this.props.auth.user.id,
                    prop
                )}
                round
                simple
            >
                <i
                    className="fa fa-download fa-sm"
                    key={`i_document_${key}`}
                />
            </Button>
            <Button
                title={'Delete file'}
                className="remove"
                color="danger"
                justIcon
                key={`btnC${key}`}
                onClick={(event) => this.handleDeleteFile(
                    event,
                    this.props.auth.user.id,
                    prop.id
                )}
                round
                simple
            >
                <Close />
            </Button>
            {" "}
        </div>
      };
  }

  columns = [
      {
          "Header": "ID",
          "accessor": "id",
          "width": 70,
          "sortable": false,
          "filterable": false
      },
      {
          "Header": "File Name",
          "accessor": "filename",
          "sortable": false,
          "filterable": false
      },
      {"Header": "Tags",
          "accessor": "tags",
          "sortable": false,
          "filterable": false},
      {
          "Header": "Actions",
          "accessor": "actions",
          "sortable": false,
          "filterable": false,
          "width": 200
      }
  ];

  refreshFilesTable () {
      this.refReactFilesTable.fireFetchData();
  }

  refreshUserFilesTable = (event, user_id) => {
      if (this.isUnmounted) {
          return;
      }
      this.refreshFilesTable();
      helpers.hideLoading();
  };

  handleClose (modal) {
      const x = [];
      x[modal] = false;
      this.setState(x);
  }

  handleAddFile = (event, user_id) => {
      this.setState({
          "user_file_details": true,
          "selected_file": [],
          "is_view": false,
          "file_dialog_title": "New File"
      });
  };

  handleViewFile = (event, user_id, file) => {
      this.setState({
          "user_file_details": true,
          "selected_file": file,
          "is_view": true,
          "file_dialog_title": "File Details"
      });
  };

  handleDownloadFile = (event, user_id, file) => {
      event.preventDefault();
      const FileDownload = require("js-file-download");
      helpers.showLoading();
      axios(this.props)
        .get(
              `user/${user_id}/file/${file.id}/download`,
              {
                  "responseType": "blob",
                  "timeout": 30000
              }
          )
          .then((response) => {
            helpers.hideLoading();
            FileDownload(
                response.data,
                file.filename
            );
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  };

  handleDownloadVersionFile = (event, user_id, file_id, version_file) => {
      event.preventDefault();
      const FileDownload = require("js-file-download");
      helpers.showLoading();
      axios(this.props)
        .get(
              `user/${user_id}/file/${file_id}/download/${version_file.id}`,
              {"responseType": "blob",
                  "timeout": 30000}
          )
          .then((response) => {
            helpers.hideLoading();
            FileDownload(
                response.data,
                version_file.filename
            );
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  };

  handleEditFile = (event, user_id, file) => {
      this.setState({
          "user_file_details": true,
          "selected_file": file,
          "is_view": false,
          "file_dialog_title": "Edit File"
      });
  };

  handleDeleteFile (event, user_id, file_id) {
      event.preventDefault();
      this.setState({
          "confirmModal": true,
          "confirmModalMsg": "Are you sure you want to delete this file?",
          "confirmModalTitle": "Delete File",
          "action": "delete_file",
          "file": {user_id,
              file_id}
      });
  }

  doHandleDeleteFile () {
      this.setState({"confirmModal": false,
          "action": "delete_file"});
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "_method",
          "DELETE"
      );
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              `/user/${this.props.auth.user.id}/file/${this.state.file.file_id}`,
              fd,
              {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
          )
          .then((response) => this.processDeleteFileDetails(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  processDeleteFileDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "doneModalMsg": "User file deleted",
          "tr_color": "success"
      });
      this.refreshUserFilesTable();
      this.showUsersNotification();
      helpers.hideLoading();
  }

  handleConfirmClick () {
      if (this.state.action === "delete_file") {
          this.doHandleDeleteFile();
      }
  }

  render () {
      const authService = new AuthService(
          this.state,
          this.props
      );
      if (!authService.getToken()) {
          return <Redirect to="/auth/login" />;
      }

      if (authService.isPasswordExpired()) {
          return <Redirect to="/admin/changepassword" />;
      }
      const {classes} = this.props,
          {files, loading} = this.state;
      return (
          <GridContainer>
              <GridItem xs={12}>
                  <Card>
                      <CardHeader
                          color="success"
                          icon
                      >
                          <CardIcon color="success">
                              <Description />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                              Files
                          </h4>
                          <CardIcon
                              title={'New file'}
                              className="float-button"
                              color="info"
                              onClick={() => this.handleAddFile()}
                          >
                              <Add />
                          </CardIcon>
                          <CardIcon
                              title={'Refresh files table'}
                              className="float-button"
                              color="warning"
                              onClick={() => this.refreshFilesTable()}
                          >
                              <Refresh />
                          </CardIcon>
                          <CardIcon
                              title={'Search files'}
                              className="float-button"
                              color="primary"
                              onClick={() => this.setState({"doSearch": true})}
                          >
                              <Search />
                          </CardIcon>
                      </CardHeader>
                      <CardBody>
                          <ReactTable
                              className="-striped -highlight"
                              columns={this.columns}
                              data={files}
                              defaultPageSize={10}
                              getTrProps={(state, rowInfo, column, instance) => {

                                  if (typeof rowInfo !== "undefined") {

                                      return {
                                          "onClick": (e, handleOriginal) => {

                                              this.setState({
                                                  "selected": rowInfo.index
                                              });
                                              if (handleOriginal) {

                                                  handleOriginal();

                                              }

                                          },
                                          "style": {
                                                "background":
                                                    this.state.selected_file &&
                                                    rowInfo.original &&
                                                    rowInfo.original.id &&
                                                    rowInfo.original.id === this.state.selected_file.id
                                                        ? "lightcyan"
                                                        : null
                                          }
                                      };

                                  }
                                  return {
                                      "onClick": (e, handleOriginal) => {

                                          if (handleOriginal) {

                                              handleOriginal();

                                          }

                                      }
                                  };

                              }}
                              itemsCountText={this.state.items_data}
                              loading={loading}
                              manual
                              onFetchData={(state, instance) => {

                                  this.setState({"loading": true});
                                  this.getFilesData(
                                      state.page,
                                      state.pageSize,
                                      state.sorted,
                                      state.filtered,
                                      (res) => this.processFilesData(res)
                                  );

                              }}
                              ref={(refReactFilesTable) => {

                                  this.refReactFilesTable = refReactFilesTable;

                              }}
                              showPageSizeOptions={false}
                              showPaginationBottom={false}
                              showPaginationTop={false}
                          />
                      </CardBody>
                      <ConfirmDialog
                          confirmModal={this.state.confirmModal}
                          confirmModalMsg={this.state.confirmModalMsg}
                          confirmModalTitle={this.state.confirmModalTitle}
                          onClose={() => this.handleClose("confirmModal")}
                          onYesClick={() => this.handleConfirmClick()}
                      />
                      {this.state.user_file_details
                          ? <UserFile
                              auth={this.props.auth}
                              handleDownloadFile={this.handleDownloadFile}
                              handleDownloadVersionFile={this.handleDownloadVersionFile}
                              onClose={() => {

                                  this.handleClose("user_file_details");
                                  this.setState({"selected_file": null});

                              }}
                              refreshUserContactsTable={this.refreshUserFilesTable}
                              setUsersNotification={this.setUsersNotification}
                              user_id={this.props.auth.user.id}
                              UserFile={this.state.user_file_details}
                              {...this.state}
                          />
                          : ""}
                      {this.state.doSearch
                          ? <FileSearch
                              auth={this.props.auth}
                              FileSearch={this.state.doSearch}
                              onClose={() => this.setState({"doSearch": false})}
                              setUsersNotification={this.setUsersNotification}
                              user_id={this.props.auth.user.id}
                              {...this.state}
                          />
                          : ""}
                      {this.state.doneModalMsg
                          ? <Snackbar
                              close
                              closeNotification={() => {

                                  this.handleCloseNotification();

                              }}
                              color={this.state.tr_color}
                              icon={AddAlert}
                              message={this.state.doneModalMsg}
                              open={this.state.tr}
                              place="tr"
                          />
                          : ""}
                  </Card>
              </GridItem>
          </GridContainer>
      );
  }
}

Files.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Files));
