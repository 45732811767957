import React from "react";
// Nodejs library that concatenates classes
import classNames from "classnames";
// Nodejs library to set properties for components
import PropTypes from "prop-types";

// Material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// Core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import customTabsStyle from "assets/jss/material-dashboard-pro-react/components/customTabsStyle.jsx";

class CustomTabs extends React.Component {

    constructor (props) {

        super(props);
        this.changeTabCallBack = this.changeTabCallBack.bind(this);

    }

  state = {
      "value":
      this.props.value &&
      Number.isInteger(this.props.value) &&
      this.props.value >= 0 &&
      this.props.value < this.props.tabs.length
          ? this.props.value
          : 0
  };

  handleChange = (event, value) => {

      this.setState({value});
      this.changeTabCallBack(value);

  };

  changeTabCallBack (value) {

      this.props.changeTabCallBack(value);

  }

  componentWillReceiveProps(props) {
    if (props.value !== this.state.value) {
        this.setState({"value" : props.value});
    }
  }

  render () {

      const {
              classes,
              headerColor,
              plainTabs,
              tabs,
              title,
              rtlActive,
              marginBottom,
              bodyStyle
          } = this.props,
          cardTitle = classNames({
              [classes.cardTitle]: true,
              [classes.cardTitleRTL]: rtlActive
          });
      return (
          <Card style={{marginBottom: marginBottom}} plain={plainTabs}>
              <CardHeader
                  color={headerColor}
                  plain={plainTabs}
              >
                  {title !== undefined
                      ? <div className={cardTitle}>
                          {title}
                      </div>
                      : null}
                  <Tabs
                      classes={{
                          "root": classes.tabsRoot,
                          "indicator": classes.displayNone
                      }}
                      onChange={this.handleChange}
                      value={this.state.value}
                  >
                      {tabs.map((prop, key) => {

                          let icon = {};
                          if (prop.tabIcon) {

                              icon = {
                                  "icon": <prop.tabIcon />
                              };

                          }
                          return (
                              <Tab
                                  classes={{
                                      "root": classes.tabRootButton,
                                      "selected": classes.tabSelected,
                                      "wrapper": classes.tabWrapper
                                  }}
                                  key={key}
                                  label={prop.tabName}
                                  {...icon}
                                  style={prop.tabStyle}
                              />
                          );

                      })}
                  </Tabs>
              </CardHeader>
              <CardBody style={bodyStyle}>
                  {tabs.map((prop, key) => {

                      if (key === this.state.value) {

                          return (<div key={key}>
{prop.tabContent}
</div>);

                      }
                      return null;

                  })}
              </CardBody>
          </Card>
      );

  }

}

CustomTabs.propTypes = {
    "classes": PropTypes.object.isRequired,
    "headerColor": PropTypes.oneOf([
        "warning",
        "success",
        "danger",
        "info",
        "primary",
        "rose"
    ]),
    "title": PropTypes.string,
    "tabs": PropTypes.arrayOf(PropTypes.shape({
        "tabName": PropTypes.string.isRequired,
        "tabIcon": PropTypes.object,
        "tabContent": PropTypes.node.isRequired,
        "tabStyle": PropTypes.any
    })),
    "rtlActive": PropTypes.bool,
    "plainTabs": PropTypes.bool,
    "marginBottom": PropTypes.string,
    "bodyStyle": PropTypes.object
};

export default withStyles(customTabsStyle)(CustomTabs);
