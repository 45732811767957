import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import moment from "moment-timezone";

import axiosHelper from "axios/axiosHelper";

class EventDetailsDialog extends React.Component {

    constructor (props) {

        super(props);
        const start = props.event && props.event.start
            ? this.setDate(
                props.event.start,
                props.action,
                5
            )
            : this.setDate(
                moment
                .clone()
                .tz(props.auth.user.timezone)
                .toDate(),
                "add",
                5
            ),
        end = props.event && props.event.end
            ? this.setDate(
                props.event.end,
                props.action,
                6
            )
            : this.setDate(
                moment
                .clone()
                .tz(props.auth.user.timezone)
                .toDate(),
                "add",
                6
            );
        this.state = {
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "event": props.event,
            "event_title": props.event && props.event.title ? props.event.title : "",
            "event_title_state": "",
            "event_all_day": props.event && props.event.allDay ? props.event.allDay === 1 : false,
            "event_start_date": start,
            "event_end_date": end,
            "event_color": props.event && props.event.color ? props.event.color : "default"
        };
    }

    setDate (date, action, add_hrs) {
        const m = moment(date);
        if (action === "add" && this.props.currentView === "month") {
            const today = moment
                .utc(moment()
                    .subtract(
                            moment.tz(moment.tz.guess()).utcOffset(),
                            "minutes"
                        )
                        .format("YYYY-MM-DD HH:mm:ss"))
                        .utcOffset(moment.tz(this.props.auth.user.timezone).utcOffset())
                    .toDate(),
                hr = today.getHours(),
                min = today.getMinutes();
            m.set({
                "hour": hr,
                "minute": min > 30 ? 30 : 0,
                "second": 0,
                "millisecond": 0
            }).add(
                add_hrs,
                "hours"
            );
        }
        return m.toDate();
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            (msg, color) => {
                this.props.setUsersNotification(
                    msg,
                    color
                );
            }
        );
    }

    change (event, stateName, type, stateNameEqualTo) {
        const new_value = event.target.value,
            new_value_state = (stateNameEqualTo !== null || stateNameEqualTo !== undefined) && stateNameEqualTo > 0
                ? new_value.length >= stateNameEqualTo
                    ? ""
                    : "error"
                : "";
        this.setState({
            [stateName]: new_value,
            [`${stateName}_state`]: new_value_state
        });

    }

    saveEvent () {
        if (this.state.event_title.length === 0) {
            this.props.setUsersNotification(
                "Please add a title",
                "danger"
            );
        } else if (
            !this.state.event_all_day &&
            moment(this.state.event_end_date).diff(moment(this.state.event_start_date)) <= 0
        ) {
            this.props.setUsersNotification(
                "End date should be greater than the start date.",
                "danger"
            );
        } else {
            const {event} = this.state;
            event.title = this.state.event_title;
            event.allDay = this.state.event_all_day;
            event.start = this.state.event_start_date;
            event.end = this.state.event_end_date;
            event.color = this.state.event_color;

            this.props.saveEvent(
                this.props.action,
                event
            );
        }
    }

    deleteEvent () {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": "Are you sure you want to delete this event?",
            "confirmModalTitle": "Delete Event",
            "action": "delete_event"
        });
    }

    doDeleteEvent () {
        this.props.deleteEvent(this.state.event);
    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "delete_event") {
            this.doDeleteEvent();
        }
    }

    changeDate (date, stateName) {
        this.setState({[stateName]: date});
        if (
            stateName === "event_start_date" &&
            moment(this.state.event_end_date).diff(moment(date)) <= 0
        ) {
            this.setState({"event_end_date": date});
        }
    }

    render () {
        const {classes} = this.props,
            colors = [
                "rose",
                "orange",
                "azure",
                "red",
                "green",
                "purple",
                "black",
                "yellow",
                "default"
            ];
        return (
            <Dialog
                aria-describedby="avatar-update-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{"root": `${classes.center} ${classes.modalRoot}`}}
                fullWidth
                keepMounted
                maxWidth="sm"
                onClose={() => this.props.onClose()}
                open={this.props.event_details}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.event_dialog_title}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="avatar-update-modal-slide-description"
                    style={{"width": "100%",
                        "overflow": "hidden",
                        "paddingBottom": "60px"}}
                >
                    <style>
                        {".customSelectMargin {" +
              " margin: 0px 0 10px 0 !important;" +
              "}" +
              ".rdt.rdtOpen .rdtPicker {" +
              "  position: relative !important;" +
              "}"}
                    </style>
                    <GridContainer
                        key="details_grid"
                        style={{"borderTop": "#9c27b0 solid 1px"}}
                    >
                        <GridItem
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <CustomInput
                                error={this.state.event_title_state === "error"}
                                formControlProps={{"fullWidth": true}}
                                id="event_title"
                                inputProps={{
                                    "value": this.state.event_title,
                                    "onChange": (event) => this.change(
                                        event,
                                        "event_title",
                                        "length",
                                        1
                                    ),
                                    "multiline": true
                                }}
                                labelText={<span>
                                    Title
                                           </span>}
                                success={this.state.event_title_state === "success"}
                            />
                        </GridItem>
                        <GridItem
                            md={12}
                            sm={12}
                            style={{"display": "flex",
                                "alignItems": "center"}}
                            xs={12}
                        >
                            <FormControlLabel
                                classes={{
                                    "label": `${classes.label} customSwitchLabel`
                                }}
                                control={
                                    <Switch
                                        checked={this.state.event_all_day}
                                        classes={{
                                            "switchBase": classes.switchBase,
                                            "checked": classes.switchChecked,
                                            "thumb": classes.switchIcon,
                                            "track": classes.switchBar
                                        }}
                                        onChange={(event) => this.setState({"event_all_day": event.target.checked})}
                                        value="all_day"
                                    />
                                }
                                label="All day"
                            />
                        </GridItem>
                        <GridItem
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <InputLabel className={classes.label}>
                                Start Date
                            </InputLabel>
                            <FormControl fullWidth>
                                <Datetime
                                    dateFormat="YYYY-MM-DD"
                                    inputProps={{"placeholder": "Set start date"}}
                                    isValidDate={(current) => current.isAfter(moment("2010-01-01").subtract(
                                        1,
                                        "day"
                                    ))}
                                    onChange={(date) => this.changeDate(
                                        date.toDate(),
                                        "event_start_date"
                                    )}
                                    timeFormat={!this.state.event_all_day}
                                    value={this.state.event_start_date}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <InputLabel className={classes.label}>
                                End Date
                            </InputLabel>
                            <FormControl fullWidth>
                                <Datetime
                                    dateFormat="YYYY-MM-DD"
                                    inputProps={{"placeholder": "Set end date"}}
                                    isValidDate={(current) => current.isAfter(moment("2010-01-01").subtract(
                                        1,
                                        "day"
                                    ))}
                                    onChange={(date) => this.changeDate(
                                        date.toDate(),
                                        "event_end_date"
                                    )}
                                    timeFormat={!this.state.event_all_day}
                                    value={this.state.event_end_date}
                                />
                            </FormControl>
                        </GridItem>
                        <GridItem
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <InputLabel className={classes.label}>
                                Color
                            </InputLabel>
                            <FormControl
                                className={`${classes.selectFormControl} customSelectMargin`}
                                fullWidth
                            >
                                <Select
                                    classes={{
                                        "select": classes.select
                                    }}
                                    inputProps={{
                                        "name": "simpleSelect",
                                        "id": "simple-select"
                                    }}
                                    MenuProps={{
                                        "className": classes.selectMenu
                                    }}
                                    onChange={(event) => this.setState({"event_color": event.target.value})}
                                    value={this.state.event_color}
                                >
                                    <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        disabled
                                        value=""
                                    >
                                        Choose a color
                                    </MenuItem>
                                    {colors.map((prop, key) => <MenuItem
                                            classes={{root: classes.selectMenuItem}}
                                            key={`color_key_${  key}`}
                                            value={prop}
                                        >
                                            {prop.charAt(0).toUpperCase() + prop.slice(1)}
                                        </MenuItem>)}
                                </Select>
                            </FormControl>
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    {this.props.action === "update"
                        ? <Button
                            className={classes.modalSmallFooterFirstButton}
                            color="danger"
                            onClick={(e) => {
                                e.preventDefault();
                                this.deleteEvent();
                            }}
                          >
                            Delete
                        </Button>
                        : ""}
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose()}
                    >
                        Close
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        disabled={this.props.btnDisabled}
                        onClick={(e) => {
                            e.preventDefault();
                            this.saveEvent();
                        }}
                        simple
                    >
                        Save
                    </Button>
                </DialogActions>
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.setState({"confirmModal": false})}
                    onYesClick={() => this.handleConfirmClick()}
                />
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(EventDetailsDialog);
