import React from "react";
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch
} from "react-router-dom";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import ClientAuthLayout from "layouts/ClientAuth.jsx";
import ClientLayout from "layouts/Client.jsx";
import helpers from "customs/helpers/helpers";

import {connect} from "react-redux";
import {doTokenLogin} from "../store/actions/authActions";
import login from "assets/img/login.jpeg";

class CheckAuth extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            showPages: false,
            path: window.location.pathname
        };
    }

    componentDidMount () {
        helpers.showLoading();
        if (!this.state.path.startsWith('/client')) {
            this.props.doTokenLogin({...this.props});
        } else {
            this.setState({"showPages": true});
        }
    }
    
    render () {
        return (
            this.state.showPages || this.props.showPages
                ? <Router>
                    <Switch>
                        <Route
                            component={() => <AuthLayout prevPath={this.props.logout ? '' : this.state.path} />}
                            path="/auth"
                        />
                        <Route
                            component={AdminLayout}
                            path="/admin"
                        />
                        <Route
                            component={ClientAuthLayout}
                            path="/client/auth"
                        />
                        <Route
                            component={ClientLayout}
                            path="/client"
                        />
                        <Redirect
                            from="/"
                            to="/auth/login"
                        />
                    </Switch>
                </Router>
                : <div>
                    <style>
                        {
                            "@media (max-width: 959.95px) {.fillPage {min-height: inherit !important;}"
                        }
                    </style>
                    <div>
                        <div
                            className={'fillPage'}
                            style={{
                                "backgroundImage": `url(${login})`,
                                "color": "#FFF", "border": "0", "height": "100%", "margin": "0", "display": "flex", "padding": "120px 0", "position": "relative",
                                "minHeight": "100vh", "alignItems": "center", "backgroundSize": "cover", "backgroundPosition": "center center",
                                "filter": "brightness(0.4)"
                            }}
                        >
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    helpers.hideLoading();
    return {
        "auth": state.auth.data,
        "showPages": state.auth.showPages !== undefined,
        "logout": state.auth.logout !== undefined
    };

},

mapDispatchToProps = (dispatch) => ({
    "doTokenLogin": (props) => dispatch(doTokenLogin(props))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckAuth);