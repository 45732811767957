import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const styles = (theme) => ({
    "root": {
        "minWidth": 275
    },
    "bullet": {
        "display": "inline-block",
        "margin": "0 2px",
        "transform": "scale(0.8)"
    },
    "title": {
        "fontSize": 14
    },
    "pos": {
        "marginBottom": 12
    }
});

/**
 * Primary UI component for user interaction
 */
export const Results = React.forwardRef(({classes, title, name, active, abn, location, ...props}, ref) => {

//     const bull = (<span className={classes.bullet}>
// •
// </span>),
    const routeTo = {
            "pathname": "/admin/clients",
            "state": {"from": "abn",
                abn,
                "company": name,
                "clientDetailsModal": true}
        },
        abr = `https://abr.business.gov.au/ABN/View/${abn}`;
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                >
                    {title}
                </Typography>
                <Typography
                    component="h2"
                    variant="h5"
                >
                    {name}
                </Typography>
                <Typography
                    className={classes.pos}
                    color="textSecondary"
                >
                    Active from
                    {" "}
                    {active}
                </Typography>
                <Typography
                    component="p"
                    variant="body2"
                >
                    <b>
                        ABN:
                    </b>
                    {" "}
                    {abn}
                    <br />
                    <b>
                        Main business location:
                    </b>
                    {" "}
                    {location}
                </Typography>
            </CardContent>
            <CardActions>
                <Link to={routeTo}>
                    <Button size="small">
                        Create Client
                    </Button>
                </Link>
                <Button
                    size="small"
                    onClick={()=> window.open(abr, "_blank")}
                >
                    View on ABR
                </Button>
            </CardActions>
        </Card>
    );

});

Results.propTypes = {

    /** Override or extend the styles applied to the component. */
    "classes": PropTypes.object.isRequired
};

Results.defaultProps = {
    "classes": styles
};

export default withStyles(styles)(Results);
