import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import typographyStyle from "assets/jss/material-dashboard-pro-react/components/typographyStyle.jsx";

function Danger ({...props}) {

    const {classes, children} = props;
    return (
        <div className={`${classes.defaultFontStyle} ${classes.dangerText}`}>
            {children}
        </div>
    );

}

Danger.propTypes = {
    "classes": PropTypes.object.isRequired,
    "children": PropTypes.node
};

export default withStyles(typographyStyle)(Danger);
