import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import avatar from "assets/img/default-avatar.png";
import AvatarEditor from "react-avatar-editor";
import RotateLeft from "@material-ui/icons/RotateLeft";
import RotateRight from "@material-ui/icons/RotateRight";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

class UpdateAvatarDialog extends React.Component {

    render () {

        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="avatar-update-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{"root": `${classes.center} ${classes.modalRoot}`}}
                keepMounted
                onClose={() => this.props.onClose("avatarModal")}
                open={this.props.avatarModal}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    Update Avatar
                </DialogTitle>
                <style>
                    {
                        "@media (max-width: 500px) {.editor-canvas {width: 220px !important; height: 220px !important;}"
                    }
                </style>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="avatar-update-modal-slide-description"
                >
                    <div style={{"textAlign": "center"}}>
                        <AvatarEditor
                            borderRadius={this.props.width / (100 / this.props.borderRadius)}
                            className="editor-canvas"
                            height={this.props.height}
                            image={this.props.image ? this.props.image : avatar}
                            onPositionChange={this.props.handleAvatarPositionChange}
                            position={this.props.position}
                            ref={this.props.setEditorRef}
                            rotate={parseFloat(this.props.rotate)}
                            scale={parseFloat(this.props.scale)}
                            width={this.props.width}
                        />
                    </div>
                    <div>
                        <input
                            className="cr-slider"
                            max="2"
                            min="1"
                            name="scale"
                            onChange={this.props.handleAvatarScale}
                            step="0.01"
                            style={{"width": "98%"}}
                            type="range"
                            value={this.props.scale}
                        />
                    </div>
                    <div
                        className="upload-btn-wrapper"
                        style={{"width": "100%",
                            "textAlign": "center"}}
                    >
                        <div>
                            <button
                                className="btn"
                                style={{"marginRight": "10px"}}
                            >
                                Choose a picture
                            </button>
                            <input
                                accept="image/*"
                                name="newAvatarImage"
                                onChange={this.props.handleNewAvatar}
                                type="file"
                                value=""
                            />
                            <button
                                title={'Rotate left'}
                                className="simple-button"
                                name="avatar_rotate_left"
                                onClick={this.props.handleAvatarRotateLeft}
                                style={{"transform": "translateY(25%)"}}
                            >
                                <RotateLeft />
                            </button>
                            <button
                                title={'Rotate right'}
                                className="simple-button"
                                name="avatar_rotate_right"
                                onClick={this.props.handleAvatarRotateRight}
                                style={{"transform": "translateY(25%)"}}
                            >
                                <RotateRight />
                            </button>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("avatarModal")}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        onClick={this.props.handleAcceptNewAvatar}
                        simple
                    >
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(extendedFormsStyle)(UpdateAvatarDialog);
