import React from "react";
import eventSource from "customs/connections/EventSource";
import _ from 'lodash';

let noti_source_cnt = 0;

const helpers = {
    "showLoading" () {
        document.getElementById("loading").classList.add("loading-active");
    },
    "hideLoading" () {
        document.getElementById("loading").classList.remove("loading-active");
    },
    "addLineBreaks" (string) {
        if (typeof string === "object") {
            string = JSON.stringify(string);
        }
        return (typeof string === 'string' ? string : string.join("<br>")).split("<br>").map((text, index) => {
            return <React.Fragment key={`${index}`}>
                {
                    text.includes('</b>')
                        ? text.split('</b>').map((t, i) => t.includes('<b>') ? <b key={`${i}`}>{t.replace('<b>', '')}</b>: t)
                        : text
                }
                <br />
            </React.Fragment>
        });
    },
    "createNotificationSource" (id, props) {
        noti_source_cnt++;
        if (noti_source_cnt > 1) {
            noti_source_cnt = 0;
            return null;
        }
        return eventSource(
            `/notification/${id}`,
            props
        );
    },
    "getErrorMessage" (error) {
        let default_err = "An error has occurred, please try again.";
        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                const result = error.response.data.errors,
                    arr = [];
                if (result) {
                    Object.keys(result).forEach((key, val) => {
                        if (result[key] && result[key][0]) {
                            arr.push(result[key][0]);
                        }
                    });
                } else {
                    arr.push(default_err);
                }
                default_err = arr.join("<br>");
            } else if (
                error.response.data.status === "Token is Invalid" ||
                error.response.data.status === "Token is Expired" ||
                error.response.data.status === "Authorization Token not found"
            ) {
                return false;
            } else if (error.response.data.error) {
                default_err = error.response.data.error;
            }
        }
        return default_err;
    },
    createFormData (formData, data, key) {
        if (data !== null && data !== undefined && data instanceof File) {
            formData.append(key, data);
        } else if ((typeof data === "object" && data !== null) || Array.isArray(data)) {
            for (const i in data) {
                if ((typeof data[i] === "object" && data[i] !== null) || Array.isArray(data[i])) {
                    formData = helpers.createFormData(
                        formData,
                        data[i],
                        `${key}[${i}]`
                    );
                } else if (data[i] !== null && data[i] !== undefined) {
                    formData.append(
                        `${key}[${i}]`,
                        data[i]
                    );
                }
            }
        } else if (data !== null && data !== undefined) {
            formData.append(
                key,
                data
            );
        }
        return formData;
    },
    countKeys(obj, ignoreNumericKeys) {
        if (typeof obj !== 'object' || obj === null || obj === undefined) {
          return 0;
        }
        const keys = Object.keys(obj).filter(key => {
            return _.endsWith(key, '_label') ? false : (ignoreNumericKeys ? !(/^-?[\d.]+(?:e-?\d+)?$/.test(key)) : true);
        });
        let sum = keys.length;
        keys.forEach(key => sum += obj[key] === undefined ? 0 : this.countKeys(obj[key], ignoreNumericKeys));
        return sum;
    },
    arraysEqual(array1, array2) {
        if (array1.length === array2.length) {
            return array1.every((element, index) => {
                if (element === array2[index]) {
                    return true;
                }
                return false;
            });
        }
        return false;
    },
    fixStatusLabel(status) {
        return status.toLowerCase().replace(/_/g, ' ').replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
            function(s){
                return s.toUpperCase();
            });
    },
    abnFormat(value) {
        if (!value) return value;
        value = value.replace(/[^\d]/g, '').substr(0, 11);;
        if (value.length < 3) return value;
        let first = value.substr(0, 2);
        let last = value.length > 2 ? value.substr(2).match(/.{1,3}/g).join(' ') : '';
        return first + ' ' + last;
    }
};

export default helpers;
