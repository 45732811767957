import React from "react";
import PropTypes from "prop-types";
import {Redirect, Link} from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";

import axiosHelper from "axios/axiosHelper";
import AccessControl from "customs/components/AccessControl";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AccountBox from "@material-ui/icons/AccountBox";
import ListAlt from "@material-ui/icons/ListAlt";
import EventNote from "@material-ui/icons/EventNote";
import CardIcon from "components/Card/CardIcon.jsx";
import axios from "axios/axios";
import helpers from "customs/helpers/helpers";
import ClientDetailsDialog from "customs/components/ClientDetailsDialog";
import validations from "customs/helpers/validations";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import SelectScriptTemplates from "customs/components/SelectScriptTemplates";
import DuplicateClientScripts from "customs/components/DuplicateClientScripts";
import CardFooter from "components/Card/CardFooter.jsx";
import { withRouter } from "react-router-dom";

import moment from "moment";
import "moment-timezone";

class Dashboard extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "value": 0,
            "events": [],
            "range": "Today",
            "tr": false,
            "tr_color": "success",
            "doneModalMsg": "",
            "clients": null,
            "change_events": null,
            "clientDetailsModal": false,
            "managers": [],
            "manager_teams": [],
            "merchant_types": [],
            "mccs": [],
            "states": null,
            "countries": null,
            "id": "",
            "username": "",
            "password": "",
            "company": "",
            "user_roles": [],
            "username_valid": "",
            "password_valid": "",
            "primary_contact_id": "",
            "contacts": [],
            "company_valid": "",
            "managers_valid": "",
            "client_transaction_methods": [],
            "transaction_methods_valid": "",
            "client_managers": [],
            "client_manager_team": "",
            "client_manager_team_valid": "",
            "primary_contact_first_name_valid": "",
            "primary_contact_last_name_valid": "",
            "lead_operator_id": "",
            "merchant_type_id": "",
            "mcc_code": "",
            "switch_to_client_script": null,
            "selected_templates": [],
            "from_client_id": "",
            "duplicateClientScriptsModal": false,
            "clientsList": [],
            "clientScripts": [],
            "available_managers": [],
            "client_primary_contact": [],
            "contact_documents": [],
            "document_library": [],
            "selected_documents": [],
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "script_templates": [],
            "selectScriptTemplateModal": false,
            "client_count": null,
            "user_tz_offset": moment.tz(props.auth.user.timezone).utcOffset()
        };
        this.processErrorAxios = this.processErrorAxios.bind(this);
        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleManualChange = this.handleManualChange.bind(this);
        this.handleChangeByName = this.handleChangeByName.bind(this);
        this.generateScriptDialog = this.generateScriptDialog.bind(this);
        this.handleGenerateScript = this.handleGenerateScript.bind(this);
        this.doSaveUserDetailsClick = this.doSaveUserDetailsClick.bind(this);
        this.processSaveUserDetails = this.processSaveUserDetails.bind(this);
        this.handleContactDetailsSaved = this.handleContactDetailsSaved.bind(this);
        this.doUploadDocuments = this.doUploadDocuments.bind(this);
        this.processGenerateScript = this.processGenerateScript.bind(this);
        this.showDuplicateScriptDialog = this.showDuplicateScriptDialog.bind(this);
        this.handleDuplicateScriptClientSelect = this.handleDuplicateScriptClientSelect.bind(this);
        this.handleDuplicateScripts = this.handleDuplicateScripts.bind(this);
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showUsersNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );
    }

  hideNotification;
  showUsersNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  setUsersNotification (msg, color) {
      this.setState({"doneModalMsg": msg,
          "tr_color": color});
      this.showUsersNotification();
  }

  handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false,
          "doneModalMsg": ""});
  }

  handleRedirect () {
      const authService = new AuthService(
          this.state,
          this.props
      );
      return !authService.getToken()
          ? <Redirect to="/auth/login" />
          : authService.isPasswordExpired()
              ? <Redirect to="/admin/changepassword" />
              : "";
  }

    componentDidMount () {
        this._ismounted = true;
        if (AccessControl.checkRoutePermissions("/clients", this.props.auth, "view")) {
            this.fetchDashboard();
        }
        if (AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "incomplete_scripts")) {
            this.fetchIncompleteScripts();
        }
        if (AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "change_events")) {
            this.fetchChangeEvents();
        }
    }

    fetchDashboard() {
        // helpers.showLoading();
        axios(this.props)
            .post(
                "/dashboard",
                {}
            )
            .then((res) => {
                helpers.hideLoading();
                this.setState({
                    "roles": res.data.roles,
                    "managers": res.data.managers,
                    "manager_teams": res.data.manager_teams,
                    "leads": res.data.leads,
                    "transaction_methods": res.data.transaction_methods,
                    "script_templates": res.data.script_templates,
                    "merchant_types": res.data.merchant_types,
                    "mccs": res.data.mccs,
                    "document_library": res.data.document_library,
                    "countries": res.data.countries,
                    "client_count": res.data.clients
                })
            })
            .catch((error) => {});
    }

    fetchIncompleteScripts() {
        // helpers.showLoading();
        axios(this.props)
            .post(
                "/incomplete_scripts",
                {}
            )
            .then((res) => {
                helpers.hideLoading();
                this.setState({
                    "clients": res.data.scripts ? res.data.scripts : [],
                });

                if (this.state.switch_to_client_script !== null) {
                    let client = this.state.clients.find((row) => row.id === this.state.switch_to_client_script);
                    if (client !== undefined && client !== null) {
                        setTimeout(
                            () => {
                                let script = client.scripts[0];
                                this.props.history.push({ 
                                    "pathname": "/admin/clients",
                                    "state": {"from": "dashboard", script}
                                });
                            },
                            300
                        );
                    }
                }
            })
            .catch((error) => {});
    }

    fetchChangeEvents() {
        // helpers.showLoading();
        axios(this.props)
            .post(
                "/change_events",
                {}
            )
            .then((res) => {
                helpers.hideLoading();
                this.setState({
                    "change_events": res.data.change_events ? res.data.change_events : []
                })
            })
            .catch((error) => {});
    }

    accessControlManager = AccessControl.checkRoutePermissions(
        "/clients",
        this.props.auth,
        "add_manager"
    );

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value,
            [`${event.target.id}_valid`]: ""
        });
    }
  
    handleManualChange (name, value) {
        this.setState({[name]: value, [`${name}_valid`]: ""});
    }
  
    handleChangeByName (event) {
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}_valid`]: ""
        });
    }

    handleMultiple = (event) => {
        this.setState({"client_managers": event.target.value});
    };
  
    handleTransactionMethods = (values) => {
        this.setState({"client_transaction_methods": values});
    };

    handleSelectManagerTeam = (event) => {
        this.setState({"client_manager_team": event.target.value});
    };

    handleSelectLead = (event) => {
        this.setState({"lead_operator_id": event.target.value});
    };

    handleSelectPrimaryContact = (event) => {
        this.setState({"primary_contact_id": event.target.value});
    };

    handleSelectMerchantType = (event) => {
        this.setState({"merchant_type_id": event.target.value});
    };

    handleSelectMCCCode = (code) => {
        this.setState({"mcc_code": code});
    };

    handleAcceptClientDetails (modal, client_primary_contact, contact_documents) {
        const username_valid = true, //this.state.username.length > 0 ? validations.validateUsername(this.state.username) : true,
            password_valid = true, //validations.validatePassword(this.state.password),
            company_valid = this.state.company.length > 0 && this.state.company.length <= 255 && validations.validateBusinessName(this.state.company),
          //   abn_valid = validations.validateABN(this.state.abn) || validations.validateACN(this.state.abn),
            transaction_methods_valid = this.state.client_transaction_methods && this.state.client_transaction_methods.length > 0,
            client_manager_team_valid = (this.state.client_manager_team && this.state.client_manager_team.toString().length > 0)
                                      || (this.state.client_managers && this.state.client_managers.toString().length > 0),
            primary_contact_first_name_valid = client_primary_contact.first_name && client_primary_contact.first_name.length > 0,
            primary_contact_last_name_valid = client_primary_contact.last_name && client_primary_contact.last_name.length > 0,
            managers_valid = client_manager_team_valid;
        if (
          username_valid &&
          password_valid &&
          company_valid &&
          managers_valid &&
          // abn_valid &&
          transaction_methods_valid &&
          client_manager_team_valid &&
          primary_contact_first_name_valid &&
          primary_contact_last_name_valid
        ) {
            const x = [];
            x[modal] = true;
            this.setState({
                ...x,
                "confirmModalMsg": "Are you sure?",
                "username_valid": "",
                "password_valid": "",
                "company_valid": "",
                "managers_valid": "",
                "transaction_methods_valid": "",
                "client_manager_team_valid": "",
                "primary_contact_first_name_valid": "",
                "primary_contact_last_name_valid": "",
              //   "abn_valid": "",
                "client_primary_contact": client_primary_contact,
                "contact_documents": contact_documents
            });
        } else {
            this.setState({
                "username_valid": username_valid ? "success" : "error",
                "password_valid": password_valid ? "success" : "error",
                "company_valid": company_valid ? "success" : "error",
                "managers_valid": managers_valid ? "success" : "error",
              //   "abn_valid": abn_valid ? "success" : "error",
                "client_primary_contact": client_primary_contact,
                "transaction_methods_valid": transaction_methods_valid ? "success" : "error",
                "client_manager_team_valid": client_manager_team_valid ? "success" : "error",
                "primary_contact_first_name_valid": primary_contact_first_name_valid ? "success" : "error",
                "primary_contact_last_name_valid": primary_contact_last_name_valid ? "success" : "error",
                "contact_documents": contact_documents
            });
        }
    }

    getCountriesData () {
        if (this.state.countries === null) {
            axios(this.props)
              .get("/countries")
              .then((response) => this.processCountriesData(response))
              .catch((error) => this.processErrorAxios(
                    error,
                    null
                ));
        }
    }
  
    processCountriesData (res) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({"countries": res.data.countries});
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

    handleConfirmClick () {
        if (this.state.action === "create_client") {
            this.doSaveUserDetailsClick();
        } else if (this.state.action === "generate_script") {
            this.doHandleGenerateScript();
        } else if (this.state.action === "duplicate_script") {
            this.doHandleDuplicateScripts();
        }
    }

    doSaveUserDetailsClick () {
        this.handleClose("confirmModal");
        helpers.showLoading();
        this.setState({"btnDisabled": true});

        let formData = {
            "id": this.state.id,
            "username": this.state.username,
            "password": this.state.password,
            "company": this.state.company,
            "cm_user_ids": this.state.client_managers,
            "manager_team_id": this.state.client_manager_team,
            "lead_operator_id": this.state.lead_operator_id,
            "merchant_type_id": this.state.merchant_type_id,
            "mcc_code": this.state.mcc_code,
            "primary_contact": this.state.client_primary_contact,
            "transaction_methods": this.state.client_transaction_methods
        };
        let fd = new FormData();
        Object.keys(formData).forEach((key) => {
            fd = helpers.createFormData(
                fd,
                formData[key],
                key
            );
        });
        axios(this.props)
            .post(
                "/client",
                fd,
                {
                    "headers": {"Content-Type": "application/x-www-form-urlencoded"}
                }
            )
            .then((response) => this.processSaveUserDetails(response, -1))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
  
    processSaveUserDetails (results, idx) {
        if (this.isUnmounted) {
            return;
        }
  
        let client = results.hasOwnProperty('data') && results.data.hasOwnProperty('client')
            ? results.data.client : (results.hasOwnProperty('data') ? results.data : results);
        this.setState({client_selected: client, client_count: this.state.client_count + 1});
      if (this.state.contact_documents === undefined || this.state.contact_documents.length === 0 || idx >= this.state.contact_documents.length - 1) {
          this.handleContactDetailsSaved();
      } else {
          this.doUploadDocuments(
              client.id,
              client.primary_contact_id,
              ++idx,
              client
          );
      }
    }

    doUploadDocuments (client_id, contact_id, idx, prev_result) {
      let fd = new FormData(),
          url = `/client/${client_id}/contact/${contact_id}/document`;
      if (
          this.state.contact_documents[idx].id &&
          this.state.contact_documents[idx].id !== undefined &&
          this.state.contact_documents[idx].id !== null
      ) {
          fd.append(
              "_method",
              "PUT"
          );
          fd.append(
              "id",
              this.state.contact_documents[idx].id
          );
          url = `${url}/${this.state.contact_documents[idx].id}`;
      }
      if (this.state.contact_documents[idx].file !== "") {
          fd.append(
              "file",
              this.state.contact_documents[idx].file
          );
      }
      if (this.state.contact_documents[idx].tags !== "") {
          fd.append(
              "tags",
              JSON.stringify(this.state.contact_documents[idx].tags)
          );
      }
      axios(this.props)
        .post(
              url,
              fd,
              {
                  "headers": {"Content-Type": "application/x-www-form-urlencoded"}
              }
          )
          .then((response) => this.processSaveUserDetails(prev_result, idx))
          .catch((error) => this.processUploadErrorAxios(
              error,
              null,
              client_id,
              contact_id,
              idx,
              prev_result
          ));
    }
  
    processUploadErrorAxios (
      error,
      prop,
      client_id,
      contact_id,
      idx,
      prev_result
    ) {
      const default_err = helpers.getErrorMessage(error),
          saveDetailsErrors = default_err === false
            ? this.state.saveDetailsErrors
            : [
                ...this.state.saveDetailsErrors,
                default_err
            ];
      this.setState({saveDetailsErrors});
      if (this.state.contact_documents.length === 0 || idx >= this.state.contact_documents.length - 1) {
          this.handleContactDetailsSaved();
      } else {
          this.doUploadDocuments(
              client_id,
              contact_id,
              ++idx,
              prev_result
          );
      }
    }

    handleContactDetailsSaved() {
        this.setState({
            "btnDisabled": false,
            "confirmModal": false,
            "clientDetailsModal": false,
            "doneModalMsg": "Client details saved",
            "tr_color": "success",
            "client_primary_contact": [],
            "contact_documents": []
        });
        this.showUsersNotification();
        helpers.hideLoading();

        this.generateScriptDialog(this.state.client_selected.id, this.state.client_selected.username);
    }

    generateScriptDialog(id, username) {
        if (this.isUnmounted) {
            return;
        }

        helpers.showLoading();
        axios(this.props)
            .post(`/client/${id}/scripts`, {}, {})
            .then((response) => {
                this.setState({
                    "selectScriptTemplateModal": true,
                    "username": username,
                    "client_id_script": id,
                    "script_templates": response.data
                });
                helpers.hideLoading();
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    handleGenerateScript(templates) {
        if (Object.keys(templates).length === 0 || templates.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select at least one script template.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }

        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to generate scripts for this client?`,
            "confirmModalTitle": "Generate Script",
            "action": "generate_script",
            "selected_templates": templates
        });
    }

    doHandleGenerateScript() {
        this.setState({"confirmModal": false, "action": "generate_script", "selectScriptTemplateModal": false});
        helpers.showLoading();
        this.setState({"btnDisabled": true});

        axios(this.props)
            .post(
                `/client/${this.state.client_id_script}/generate/script`,
                {"templates": this.state.selected_templates},
                {}
            )
            .then((response) => this.processGenerateScript(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processGenerateScript (results) {
        if (this.isUnmounted) {
            return;
        }
        let switch_to_client_script = this.state.selected_templates.length === 1 ? this.state.client_id_script : null;
        this.setState({
            "btnDisabled": false,
            "switch_to_client_script": switch_to_client_script,
            "client_id_script": "",
            "doneModalMsg": "Client script generated",
            "selected_templates": [],
            "tr_color": "success"
        });
        if (AccessControl.checkRoutePermissions("/clients", this.props.auth, "view")) {
            if (switch_to_client_script === null) {
                this.fetchDashboard();
            }
        }
        if (AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "incomplete_scripts")) {
            this.fetchIncompleteScripts();
        }
        if (switch_to_client_script === null) {
            this.showUsersNotification();
            if (this.state.client_selected === null) {helpers.hideLoading();}
        }
    }
    showDuplicateScriptDialog() {
        helpers.showLoading();
        axios(this.props)
        .get(`/client/all`)
            .then((response) => this.processGetAllClients(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
    processGetAllClients(response) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "btnDisabled": false,
            "duplicateClientScriptsModal": true,
            "clientsList": response.data,
            "selected_templates": [],
            "from_client_id": "",
            "clientScripts": []
        });
        helpers.hideLoading();
    }
    handleDuplicateScriptClientSelect(id) {
        helpers.showLoading();
        axios(this.props)
        .get('/client/' + id + '/scripts/list')
            .then((response) => this.processDuplicateScriptClientSelect(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
    processDuplicateScriptClientSelect(response) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "clientScripts": response.data.scripts
        });
        helpers.hideLoading();
    }
    handleDuplicateScripts(from_client_id, templates) {
        if (from_client_id === null || from_client_id.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select a client.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }
        if (Object.keys(templates).length === 0 || templates.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select at least one script to duplicate.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to duplicate this client's script(s)? Existing script values will be overwritten!`,
            "confirmModalTitle": "Duplicate Script",
            "action": "duplicate_script",
            "selected_templates": templates,
            "from_client_id": from_client_id
        });
    }
    doHandleDuplicateScripts() {
        this.setState({"confirmModal": false, "action": "duplicate_script", "duplicateClientScriptsModal": false});
        helpers.showLoading();
        this.setState({"btnDisabled": true});
        axios(this.props)
            .post(
                `client/${this.state.client_id_script}/duplicate/${this.state.from_client_id}/scripts`,
                {"script_template_id": this.state.selected_templates},
                {}
            )
            .then((response) => this.processDuplicateScripts(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
    processDuplicateScripts (results) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "btnDisabled": false,
            "client_id_script": "",
            "doneModalMsg": "Client script duplicated",
            "selected_templates": [],
            "from_client_id": "",
            "tr_color": "success"
        });
        this.refreshClientsTable();
        this.showUsersNotification();
        helpers.hideLoading();
    }

    setValue(value, type) {
        if (type === "number") {
            return Number(value);
        } else if (Object.prototype.toString.call(value) === '[object Array]') {
            return helpers.addLineBreaks(value);
        } else if (typeof value === "object") {
            let val = [];
            for(let key in value) {
                val.push(key + ': ' + value[key]);
            }
            return helpers.addLineBreaks(val);
        } else if (type === 'string') {
            return helpers.addLineBreaks(value.split("\n").join('<br>'));
        } else if (type === 'file') {
            try {
                return helpers.addLineBreaks(value.split("\n").join('<br>'));
            } catch(e) {}
        }
        return value;
    }

  render () {
      const {classes} = this.props;
      return (
          <div>
              {this.handleRedirect()}
              <GridContainer>
                {/* {
                    AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "agenda")
                    ?  <GridItem
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <DashboardCalendar
                                processErrorAxios={this.processErrorAxios}
                                setUsersNotification={this.setUsersNotification}
                                {...this.state}
                            />
                        </GridItem>
                    : ""
                } */}
                {
                    AccessControl.checkRoutePermissions("/clients", this.props.auth, "view")
                    ?  <GridItem  xs={12} sm={6} md={6} lg={3}>
                            <Card>
                                <CardHeader color="success" icon>
                                    <CardIcon color="success">
                                        <AccountBox />
                                    </CardIcon>
                                    <p className={classes.cardCategory}>Active Clients</p>
                                    <h1 style={{"color": "black", "marginTop": "1rem", "marginBottom": "0rem", "textAlign": "center"}}>
                                        {this.state.client_count === null ? '...' : this.state.client_count}
                                    </h1>
                                </CardHeader>
                                <CardFooter stats>
                                    <div className={classes.stats}>
                                        {
                                        AccessControl.checkRoutePermissions("/clients", this.props.auth, "add_client")
                                            ?  <Button
                                                variant="contained"
                                                color="info"
                                                className={classes.button}
                                                onClick={() => {
                                                    if (this.state.countries === null) return;
                                                    this.setState(
                                                        {
                                                            "id": "",
                                                            "username": "",
                                                            "password": "",
                                                            "company": "",
                                                            "referrer": "",
                                                            "client_managers": [],
                                                            "client_manager_team": "",
                                                            "lead_operator_id": "",
                                                            "merchant_type_id": "",
                                                            "mcc_code": "",
                                                            "username_valid": "",
                                                            "password_valid": "",
                                                            "company_valid": "",
                                                            "referrer_valid": "",
                                                            "managers_valid": "",
                                                            "client_transaction_methods": [],
                                                            "transaction_methods_valid": "",
                                                            "client_manager_team_valid": "",
                                                            "primary_contact_first_name_valid": "",
                                                            "primary_contact_last_name_valid": "",
                                                            "primary_contact_id": "",
                                                            "contacts": [],
                                                            "action": "create_client",
                                                            "confirmModalTitle": "Create Client",
                                                            clientDetailsModal: true,
                                                            client_selected: null
                                                        }
                                                    )
                                                }}
                                            >
                                                Create Client
                                            </Button>
                                            : ""
                                        }
                                    </div>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    : ""
                }
            </GridContainer>
            {AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "incomplete_scripts")
                ? <GridContainer>
                    <GridItem md={12}>
                        <Card chart className={classes.cardHover}>
                            <CardHeader color="warning" icon>
                                <CardIcon color="warning">
                                    <ListAlt />
                                </CardIcon>
                                <h4
                                    className={classes.cardIconTitle}
                                    style={{"color": "black"}}
                                >
                                    {"Clients with incomplete scripts" + (
                                        this.state.clients !== null && Object.keys(this.state.clients).length > 0
                                            ? " (" + Object.keys(this.state.clients).length + ")" : ""
                                    )}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                {
                                    this.state.clients === null || Object.keys(this.state.clients).length === 0
                                        ? <GridItem
                                            md={12}
                                            style={{"textAlign": "center",
                                                "lineHeight": "100px"}}
                                        >
                                            {this.state.clients === null ? "Loading..." : "No incomplete scripts"}
                                        </GridItem>
                                        : <Table
                                            customCellClasses={[`no-wrap ${classes.right}`]}
                                            tableData={this.state.clients.map((row) => {
                                                return [
                                                    row.id,
                                                    row.username,
                                                    row.company,
                                                    row.scripts.map((script) => {
                                                        return <div key={row.id + '_' + script.id}>
                                                            <Link style={{"color": "black"}} to={{
                                                                "pathname": "/admin/clients",
                                                                "state": {"from": "dashboard", script}
                                                            }}>
                                                                {script.name + ' ' + script.percent + '% completed'}
                                                            </Link>
                                                        </div>;
                                                    })
                                                ];
                                            })}
                                            tableHead={[
                                                "ID",
                                                "Username",
                                                "Business Name",
                                                "Scripts"
                                            ]}
                                        />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                : ""
              }
              {AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "change_events")
                ? <GridContainer>
                    <GridItem md={12}>
                        <Card chart className={classes.cardHover}>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <EventNote />
                                </CardIcon>
                                <h4
                                    className={classes.cardIconTitle}
                                    style={{"color": "black"}}
                                >
                                    {"Last 10 Events"}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                {
                                    this.state.change_events === null || Object.keys(this.state.change_events).length === 0
                                        ? <GridItem
                                            md={12}
                                            style={{"textAlign": "center",
                                                "lineHeight": "100px"}}
                                        >
                                            {this.state.change_events === null ? "Loading..." : "No events"}
                                        </GridItem>
                                        : <Table
                                            customCellClasses={[`no-wrap ${classes.right}`]}
                                            tableData={this.state.change_events.map((row) => {
                                                return [
                                                    row.client ? row.client.id : "N/A",
                                                    row.client ? (row.client.username ? row.client.username : "") : "N/A",
                                                    row.name ? row.name.replace(/^\*/, '') : "",
                                                    row.from === null ? "" : this.setValue(row.from, row.value_type),
                                                    row.to === null ? "" : this.setValue(row.to, row.value_type),
                                                    moment.utc(row.created_at).utcOffset(this.state.user_tz_offset).format('YYYY-MM-DD HH:mm:ss'),
                                                    row.updater ? "(" + row.updater.type + ") " + row.updater.username : "N/A",
                                                    // row.from === null ? "Create" : (row.to === null ? "Delete" : "Update"),
                                                ];
                                            })}
                                            tableHead={[
                                                "ID",
                                                "Username",
                                                "Field",
                                                "From",
                                                "To",
                                                "Date",
                                                "Updated By",
                                                // "Action"
                                            ]}
                                        />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                : ""
              }
              {this.state.clientDetailsModal
                ? <ClientDetailsDialog
                    accessControlManager={this.accessControlManager}
                    handleChange={this.handleChangeByName}
                    handleManualChange={this.handleManualChange}
                    handleMultiple={this.handleMultiple}
                    handleTransactionMethods={this.handleTransactionMethods}
                    handleSelectManagerTeam={this.handleSelectManagerTeam}
                    handleSelectLead={this.handleSelectLead}
                    handleSelectPrimaryContact={this.handleSelectPrimaryContact}
                    handleSelectMerchantType={this.handleSelectMerchantType}
                    handleSelectMCCCode={this.handleSelectMCCCode}
                    onClose={() => this.handleClose("clientDetailsModal")}
                    onYesClick={(contact, contact_documents) => this.handleAcceptClientDetails("confirmModal", contact, contact_documents)}
                    selected_contact={[]}
                    countries={this.state.countries}
                    {...this.state}
                    auth={this.props.auth}
                    contact_details_title={"Create Client"}
                    action={"create_client"}
                />
                : ""
              }
              <SelectScriptTemplates
                  showDuplicateScriptDialog={this.showDuplicateScriptDialog}
                  handleGenerateScript={this.handleGenerateScript}
                  onChange={(templates) => this.setState({'selected_templates': templates})}
                  onClose={() => {
                      this.setState({'selected_templates': []});
                      this.handleClose("selectScriptTemplateModal");
                  }}
                  templates={this.state.selected_templates}
                  {...this.state}
                  showDuplicateButton={true}
              />
              <DuplicateClientScripts
                  handleDuplicateScripts={this.handleDuplicateScripts}
                  onChange={(client_id) => this.handleDuplicateScriptClientSelect(client_id)}
                  onMultipleChange={(templates) => this.setState({'selected_templates': templates})}
                  onClose={() => {
                      this.setState({'selected_templates': []});
                      this.handleClose("duplicateClientScriptsModal");
                  }}
                {...this.state}
              />
              {this.state.doneModalMsg
                  ? <Snackbar
                      close
                      closeNotification={() => {
                          this.handleCloseNotification();
                      }}
                      color={this.state.tr_color}
                      icon={AddAlert}
                      message={this.state.doneModalMsg}
                      open={this.state.tr}
                      place="tr"
                    />
                  : ""}
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />
          </div>
      );
  }
}

Dashboard.propTypes = {
    "classes": PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
    "auth": state.auth.data
});

export default connect(mapStateToProps)(withRouter(withStyles(dashboardStyle)(Dashboard)));
