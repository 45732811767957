export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const DO_LOGOUT = "DO_LOGOUT";
export const DO_REFRESH_TOKEN = "DO_REFRESH_TOKEN";

export const TOKEN_LOGIN_SUCCESS = "TOKEN_LOGIN_SUCCESS";
export const TOKEN_LOGIN_ERROR = "TOKEN_LOGIN_ERROR";

export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const SEND_RESET_EMAIL_SUCCESS = "SEND_RESET_EMAIL_SUCCESS";
export const SEND_RESET_EMAIL_ERROR = "SEND_RESET_EMAIL_ERROR";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";
