export function fetchField ({property, argument}) {

    if (property === "instance") {
        return argument;
    }
    const fullField = property.startsWith("instance")
            ? property.substring(9)
            : property.substring(1),
        nextArrSep = fullField.indexOf("["),
        nextFieldSep = fullField.indexOf("."),
        nextSeparator = nextArrSep !== -1 && nextFieldSep !== -1
          ? Math.min(
              nextArrSep,
              nextFieldSep
          )
          : Math.max(
              nextArrSep,
              nextFieldSep
          );
    if (nextSeparator === -1) {
        return fullField;
    }
    return fullField.substring(
        0,
        nextSeparator
    );
}

const errorHandler = (navTree, transformErrors) => (errors) => {
    const errorsWithNav = errors.map((error) => {
        const field = fetchField(error),
            activeNav = navTree.findActiveNav(field);
        if (activeNav && activeNav.length > 0) {
            return {activeNav, ...error};
        }
        return error;
    });

    if (transformErrors) {
        return transformErrors(errorsWithNav);
    }

    return errorsWithNav;
};

export default errorHandler;
