import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import validations from "customs/helpers/validations";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

class UserDetailsDialog extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            user_roles_open: false
        };
    }

  handleChange = (event) => {
      this.props.handleChange(event);
  };

  handleMultiple = (event) => {
    let selected = event.target.value;
    if (Object.keys(selected).length > 0) {
        selected = selected.filter((item) => item !== 'filter');
    }
    event.target.value = selected;
    this.props.handleMultiple(event);
  };

  handleSelectManagerTeam = (event) => {
      this.props.handleSelectManagerTeam(event);
  };

  handleSelectAcquirers = (event) => {
      this.props.handleSelectAcquirers(event);
  };

  isClientManager (showRoles) {
      if (!(showRoles && this.props.roles)) {
          return false;
      }
      if (
        this.props.user_roles !== null &&
        this.props.user_roles.length > 0 &&
        this.props.roles !== null &&
        this.props.roles.length > 0
      ) {
          let admin_id = this.props.roles.filter((role) => role.name === 'admin')[0].id;
          let manager_id = this.props.roles.filter((role) => role.name === 'client_manager')[0].id;
          if (!this.props.user_roles.includes(admin_id) && this.props.user_roles.includes(manager_id)) {
            return true;
          }

      }
      return false;
  }

  isAcquirer (showRoles) {
    if (!(showRoles && this.props.roles)) {
        return false;
    }
    if (
      this.props.user_roles !== null &&
      this.props.user_roles.length > 0 &&
      this.props.roles !== null &&
      this.props.roles.length > 0
    ) {
        let admin_id = this.props.roles.filter((role) => role.name === 'admin')[0].id;
        let acquirer_id = this.props.roles.filter((role) => role.name === 'acquirer')[0].id;
        if (!this.props.user_roles.includes(admin_id) && this.props.user_roles.includes(acquirer_id)) {
          return true;
        }

    }
    return false;
}

  render () {
      const {classes} = this.props,
          showRoles = this.props.showRoles !== undefined ? this.props.showRoles : true;
      const acquirer_id = this.props.roles && this.props.roles.length > 0 ? this.props.roles.filter((role) => role.name === 'acquirer')[0].id : 0;
      return (
          <Dialog
              aria-describedby="avatar-update-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{"root": `${classes.center} ${classes.modalRoot}`}}
              keepMounted
              onClose={() => this.props.onClose("userDetailsModal")}
              open={this.props.userDetailsModal}
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  {this.props.dialogTitle}
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="avatar-update-modal-slide-description"
                  style={{"maxWidth": "400px"}}
              >
                  <input
                      name="id"
                      type="hidden"
                      value={this.props.id}
                  />
                  <form>
                      <CustomInput
                          error={this.props.username_valid === "error"}
                          formControlProps={{"fullWidth": true}}
                          helperText={
                              this.props.username_valid === "error" && this.props.username
                                  ? validations.usernameErrorMsg()
                                  : undefined
                          }
                          id="username"
                          inputProps={{
                              "disabled": this.props.action === "update",
                              "onChange": this.handleChange,
                              "value": this.props.username ? this.props.username : ""
                          }}
                          labelText="Username"
                          success={this.props.username_valid === "success"}
                      />
                  </form>
                  {this.props.action === "update"
                      ? ""
                      : <CustomInput
                          error={this.props.password_valid === "error"}
                          formControlProps={{"fullWidth": true}}
                          helperText={
                              this.props.password_valid === "error" && this.props.password
                                  ? validations.passwordErrorMsg()
                                  : undefined
                          }
                          id="password"
                          inputProps={{
                              "disabled": this.props.action === "update",
                              "onChange": this.handleChange,
                              "value": this.props.password,
                              "type": "password",
                              "autoComplete": "off"
                          }}
                          labelText="Password"
                          success={this.props.password_valid === "success"}
                        />}
                  <CustomInput
                      error={this.props.first_name_valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      id="first_name"
                      inputProps={{
                          "onChange": this.handleChange,
                          "value": this.props.first_name
                      }}
                      labelText="First Name"
                      success={this.props.first_name_valid === "success"}
                  />
                  <CustomInput
                      error={this.props.last_name_valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      id="last_name"
                      inputProps={{
                          "onChange": this.handleChange,
                          "value": this.props.last_name
                      }}
                      labelText="Last Name"
                      success={this.props.last_name_valid === "success"}
                  />
                  <CustomInput
                      error={this.props.email_valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      helperText={
                          this.props.email_valid === "error" && this.props.email
                              ? validations.emailErrorMsg()
                              : undefined
                      }
                      id="email"
                      inputProps={{
                          "onChange": this.handleChange,
                          "value": this.props.email,
                          "type": "email"
                      }}
                      labelText="Email"
                      success={this.props.email_valid === "success"}
                  />
                  <CustomInput
                      error={this.props.company_valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      id="company"
                      inputProps={{
                          "onChange": this.handleChange,
                          "value": this.props.company
                      }}
                      labelText="Company"
                      success={this.props.company_valid === "success"}
                  />
                  {showRoles
                      ? <FormControl
                          className={classes.selectFormControl}
                          fullWidth
                        >
                          <InputLabel
                              className={classes.selectLabel}
                              error={this.props.roles_valid === "error"}
                              htmlFor="user_roles"
                              success={this.props.roles_valid === "success" ? "success" : ""}
                          >
                              Choose Roles
                          </InputLabel>
                          <Select
                              classes={{"select": classes.select}}
                              inputProps={{"name": "user_roles",
                                  "id": "user_roles"}}
                              MenuProps={{"className": classes.selectMenu}}
                              multiple
                              onChange={this.handleMultiple}
                              value={this.props.user_roles}
                              open={this.state.user_roles_open}
                              onOpen={() => this.setState({user_roles_open: true})}
                              onClose={() => this.setState({user_roles_open: false})}
                          >
                              <MenuItem
                                  classes={{"root": classes.selectMenuItem}}
                                  disabled
                              >
                                  Choose Roles
                              </MenuItem>
                                <div
                                    classes={{"root": classes.selectMenuItem}}
                                    value="filter"
                                    style={{"padding": "0px 10px", "position": "absolute"}}
                                >
                                    <Button
                                        className="edit"
                                        color="info"
                                        onClick={() => {
                                            this.setState({user_roles_open: false});
                                        }}
                                        round
                                        style={{"position": "fixed", "zIndex": "10"}}
                                    >
                                        Close
                                    </Button>
                                </div>
                                <MenuItem
                                    classes={{"root": classes.selectMenuItem}}
                                    disabled
                                    style={{"height": "3rem"}}
                                >
                                    {""}
                                </MenuItem>
                              {this.props.roles
                                  ? this.props.roles.map((prop, key) => (<MenuItem
                                          classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelectedMultiple
                                          }}
                                          key={key}
                                          value={prop.id}
                                          disabled={
                                            this.props.user_roles.length > 0 && (
                                                (this.isAcquirer(showRoles) && prop.id !== acquirer_id) || //Acquirer, disable other roles
                                                (!this.isAcquirer(showRoles) && prop.id === acquirer_id) //Disable acquirer if any other role is selected
                                            )
                                          }
                                      >
                                          {prop.description}
                                      </MenuItem>))
                                  : ""}
                          </Select>
                      </FormControl>
                      : ""}
                  {this.isClientManager(showRoles)
                      ? <FormControl
                          className={classes.selectFormControl}
                          fullWidth
                        >
                          <InputLabel
                              className={classes.selectLabel}
                              htmlFor="user_manager_team"
                          >
                              Assign to teams
                          </InputLabel>
                          <Select
                              classes={{"select": classes.select}}
                              inputProps={{"name": "manager_team",
                                  "id": "manager_team"}}
                              MenuProps={{"className": classes.selectMenu}}
                              onChange={this.handleSelectManagerTeam}
                              value={this.props.user_manager_team}
                              multiple
                          >
                              <MenuItem
                                  classes={{"root": classes.selectMenuItem}}
                                  disabled
                              >
                                  Choose teams
                              </MenuItem>
                              {this.props.manager_teams
                                  ? this.props.manager_teams.map((prop, key) => (<MenuItem
                                          classes={{
                                              root: classes.selectMenuItem,
                                              selected: classes.selectMenuItemSelectedMultiple
                                          }}
                                          key={key}
                                          value={prop.id}
                                      >
                                          {prop.name}
                                      </MenuItem>))
                                  : ""}
                          </Select>
                      </FormControl>
                      : ""}
                      {this.isAcquirer(showRoles)
                        ? <FormControl
                            className={classes.selectFormControl}
                            fullWidth
                        >
                            <InputLabel
                                className={classes.selectLabel}
                                htmlFor="user_acquirers"
                            >
                                Assign to acquirers
                            </InputLabel>
                            <Select
                                classes={{"select": classes.select}}
                                inputProps={{"name": "user_acquirers", "id": "user_acquirers"}}
                                MenuProps={{"className": classes.selectMenu}}
                                onChange={this.handleSelectAcquirers}
                                multiple
                                value={this.props.user_acquirers}
                            >
                                <MenuItem
                                    classes={{"root": classes.selectMenuItem}}
                                    disabled
                                >
                                    Choose acquirers
                                </MenuItem>
                                {this.props.acquirers
                                    ? this.props.acquirers.map((prop, key) => (<MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelectedMultiple
                                            }}
                                            key={key}
                                            value={prop.id}
                                        >
                                            {prop.name}
                                        </MenuItem>))
                                    : ""}
                            </Select>
                        </FormControl>
                        : ""
                      }
                    <CustomInput
                        error={this.props.agent_code_valid === "error"}
                        formControlProps={{"fullWidth": true}}
                        id="agent_code"
                        inputProps={{
                            "onChange": this.handleChange,
                            "value": this.props.agent_code
                        }}
                        labelText="Agent Code"
                        success={this.props.agent_code_valid === "success"}
                    />
              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                  <Button
                      className={classes.modalSmallFooterFirstButton}
                      color="transparent"
                      onClick={() => this.props.onClose("userDetailsModal")}
                  >
                      Cancel
                  </Button>
                  <Button
                      className={
                          `${classes.modalSmallFooterFirstButton
                          } ${
                              classes.modalSmallFooterSecondButton}`
                      }
                      color="success"
                      disabled={this.props.btnDisabled}
                      onClick={this.props.onYesClick}
                      simple
                  >
                      Save
                  </Button>
              </DialogActions>
          </Dialog>
      );
  }
}

export default withStyles(extendedFormsStyle)(UserDetailsDialog);
