import React from "react";
import PropTypes from "prop-types";
import {Redirect, Route, Switch} from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Core components
import AuthNavbar from "client-components/Navbars/AuthNavbar.jsx";
import Footer from "client-components/Footer/Footer.jsx";

import routes from "client-routes.js";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx";

import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";
// import lock from "assets/img/lock.jpeg";
// import error from "assets/img/clint-mckoy.jpg";
// import pricing from "assets/img/bg-pricing.jpeg";

class Pages extends React.Component {
  wrapper = React.createRef();
  componentDidMount () {
      document.body.style.overflow = "unset";
  }

  getRoutes = (routes) => routes.map((prop, key) => {
      if (prop.collapse) {
          return this.getRoutes(prop.views);
      }
      if (prop.layout === "/client/auth") {
          return (
              <Route
                  component={(props) => <prop.component prevPath={this.props.prevPath} {...props} />}
                  key={key}
                  path={prop.layout + prop.path}
              />
          );
      }
      return null;
  });

  getBgImage = () => {
      if (window.location.pathname.indexOf("/client/auth/register-page") !== -1) {
          return register;
      } else if (window.location.pathname.indexOf("/client/auth/login") !== -1 || window.location.pathname.indexOf("/client/auth/reset") !== -1) {
          return login;
    //   } else if (window.location.pathname.indexOf("/client/auth/pricing-page") !== -1) {
    //       return pricing;
    //   } else if (window.location.pathname.indexOf("/client/auth/lock-screen-page") !== -1) {
    //       return lock;
    //   } else if (window.location.pathname.indexOf("/client/auth/error-page") !== -1) {
    //       return error;
      }

  };

  getActiveRoute = (routes) => {
      const activeRoute = "Default Brand Text";
      for (let i = 0; i < routes.length; i++) {
          if (routes[i].collapse) {
              const collapseActiveRoute = this.getActiveRoute(routes[i].views);
              if (collapseActiveRoute !== activeRoute) {
                  return collapseActiveRoute;
              }
          } else if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
              return routes[i].name;
          }
      }
      return activeRoute;
  };

  render () {
      const {classes, ...rest} = this.props;
      return (
          <div>
              <style>
                  {
                      "@media (max-width: 959.95px) {.fillPage {min-height: inherit !important;}"
                  }
              </style>
              <AuthNavbar
                  brandText={this.getActiveRoute(routes)}
                  {...rest}
              />
              <div
                  className={classes.wrapper}
                  ref={this.wrapper}
              >
                  <div
                      className={`${classes.fullPage} fillPage`}
                      style={{"backgroundImage": `url(${this.getBgImage()})`}}
                  >
                      <Switch>
                          {this.getRoutes(routes)}
                          <Redirect
                              from="/client"
                              to="/client/document_library"
                          />
                      </Switch>
                      <Footer white />
                  </div>
              </div>
          </div>
      );
  }
}

Pages.propTypes = {
    "classes": PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
