import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
// React component for creating dynamic tables
import ReactTable from "react-table";

import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import TeamIcon from "@material-ui/icons/GroupWork";
import Add from "@material-ui/icons/Add";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

/*
 * Import Assignment from "@material-ui/icons/Assignment";
 * core components
 */
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Pagination from "customs/components/Pagination";

import ConfirmDialog from "customs/components/ConfirmDialog";
import ManagerTeamDetails from "customs/components/ManagerTeamDetails";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import helpers from "customs/helpers/helpers";
import {doLogout} from "../../store/actions/authActions";

import axios from "axios/axios";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import axiosHelper from "axios/axiosHelper";
import TextField from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

class ManagerTeams extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "pages": 0,
            "btnDisabled": false,
            "tr": false,
            "tr_color": "success",
            "team_selected": null,
            "teamDetailsModal": false,
            "id": "",
            "name": "",
            "description": "",
            "name_valid": "",
            "description_valid": "",
            "action": "",
            "confirmModal": false,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": "",
            "delete_id": "",
            "managers": [],
            "team_managers": []
        };
        this.fetchData = this.fetchData.bind(this);
        this.setTeamsNotification = this.setTeamsNotification.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value,
            [`${event.target.id}_valid`]: ""
        });
    }

    fetchData (state, instance) {
        this.setState({"loading": true});
    }

    componentWillUnmount () {
        this.isUnmounted = true;
        clearTimeout(this.hideNotification);
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

    processErrorAxios (error) {
        axiosHelper.processError(
            this.isUnmounted,
            null,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showTeamsNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );
    }

  hideNotification;
  showTeamsNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false,
          "doneModalMsg": ""});
  }

  setTeamsNotification (msg, color) {
      this.setState({"doneModalMsg": msg,
          "tr_color": color});
      this.showTeamsNotification();
  }

  refreshTeamsTable () {
      this.refReactTeamsTable.fireFetchData();
  }

  CustomTableFilter = ({filter, onChange}) => (<form>
          <TextField
              type="search"
              className="w-100"
              value={filter ? filter.value : ""}
              onChange={(event) => onChange(event.target.value)}
              InputProps={{
                  startAdornment: 
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            
              }}
          />
       </form>)
    ;

  TeamsTableColumns = [
      {"Header": "ID",
          "accessor": "id",
          "width": 70,
          "Filter": this.CustomTableFilter},
      {"Header": "Name",
          "accessor": "name",
          "Filter": this.CustomTableFilter},
      {
          "Header": "Description",
          "accessor": "description",
          "Filter": this.CustomTableFilter
      },
      {
          "Header": "Actions",
          "accessor": "actions",
          "sortable": false,
          "filterable": false,
          "width": 100
      }
  ];

  processTeamsData (res) {
      if (this.isUnmounted) {
          return;
      }
      const {team_selected} = this.state;
      this.setState({"team_selected": null});
      this.setState({
          "managers": res.data.managers,
          "tableData": res.data.data.map((prop, key) => {
              if (
                team_selected &&
                team_selected.id &&
                team_selected.id === prop.id
              ) {
                  this.setState({"team_selected": prop});
              }
              return this.setTeamDetails(
                  prop,
                  key
              );

          }),
          "pages": res.data.last_page,
          "items_data": res.data.from && res.data.to && res.data.total
            ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
            : "",
          "loading": false
      });
  }

  setTeamDetails (prop, key) {
      return {
          "id": prop.id,
          key,
          "name": prop.name,
          "description": prop.description,
          "data": prop,
          "actions":
            <div>
                <Button
                    title={'Edit manager team'}
                    className="edit"
                    color="info"
                    justIcon
                    onClick={() => {

                        const obj = this.state.tableData.find((o) => o.key === key);
                        this.handleTeamDetailsClick(
                            "teamDetailsModal",
                            obj.data
                        );

                    }}
                    round
                    simple
                >
                    <Edit />
                </Button>
                {" "}
                <Button
                    title={'Delete manager team'}
                    className="remove"
                    color="danger"
                    justIcon
                    onClick={() => {

                        const obj = this.state.tableData.find((o) => o.key === key);
                        this.handleTeamDetailsDeleteClick(
                            obj.data.id,
                            obj.data.name
                        );

                    }}
                    round
                    simple
                >
                    <Close />
                </Button>
                {" "}
            </div>
      };
  }

  handleTeamDetailsClick (modal, data) {
      const teamDetails = data !== null
          ? {
              "id": data.id ? data.id : "",
              "name": data.name ? data.name : "",
              "description": data.description ? data.description : "",
              "team_managers": data.managers.map((manager) => manager.id),
              "name_valid": "",
              "description_valid": "",
              "action": "update",
              "confirmModalTitle": "Update Manager Team Details"
          }
          : {
              "id": "",
              "name": "",
              "description": "",
              "team_managers": [],
              "name_valid": "",
              "description_valid": "",
              "action": "new",
              "confirmModalTitle": "Create Manager Team"
          },

          x = [];
      x[modal] = true;
      this.setState({...x,
          ...teamDetails});
  }

  getTeamsData (page, pageSize, sorted, filtered, handleRetrievedData) {
      const url = "/manager_teams/paginate",
          postObject = {
              "page": page + 1,
              "per_page": pageSize,
              "sort": sorted,
              "filter": filtered
          };

      return axios(this.props)
        .post(
              url,
              postObject
          )
          .then((response) => handleRetrievedData(response))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  }

  handleTeamDetailsDeleteClick (id, name) {
      if (id.length === 0) {
          return;
      }
      this.setState({
          "confirmModal": true,
          "confirmModalMsg": `Are you sure you want to delete ${name}?`,
          "confirmModalTitle": "Delete Manager Team",
          "action": "delete",
          "delete_id": id
      });
  }

  doDeleteTeamDetailsClick () {
      this.handleClose("confirmModal");
      helpers.showLoading();
      const fd = new FormData();
      fd.append(
          "_method",
          "DELETE"
      );
      this.setState({"btnDisabled": true});

      axios(this.props)
        .post(
              `/manager_teams/${this.state.delete_id}`,
              fd,
              {
                  "headers": {"Content-Type": "application/x-www-form-urlencoded"}
              }
          )
          .then((response) => this.processDeleteTeamDetails(response))
          .catch((error) => this.processErrorAxios(error));
  }

  processDeleteTeamDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "doneModal": false,
          "teamDetailsModal": false,
          "doneModalMsg": "Manager Team details deleted",
          "doneModalBtn": "OK",
          "tr_color": "success"
      });
      this.showTeamsNotification();
      this.refreshTeamsTable();
      helpers.hideLoading();
  }

  handleAcceptTeamDetails (modal) {
      const name_valid = this.state.name && this.state.name.length > 0,
          description_valid =
      this.state.description && this.state.description.length > 0;
      if (name_valid && description_valid) {
          const x = [];
          x[modal] = true;
          this.setState({
              ...x,
              "confirmModalMsg": "Are you sure?",
              "name_valid": "",
              "description_valid": ""
          });
      } else {
          this.setState({
              "name_valid": name_valid ? "success" : "error",
              "description_valid": description_valid ? "success" : "error"
          });
      }
  }

  doSaveTeamDetailsClick () {
      this.handleClose("confirmModal");
      if (this.state.action === "update" && this.state.id.length === 0) {
      } else {
          helpers.showLoading();
          this.setState({"btnDisabled": true});
          if (this.state.action === "update") {
              const fd = new FormData();
              fd.append(
                  "name",
                  this.state.name
              );
              fd.append(
                  "description",
                  this.state.description
              );
                for (var i = 0; i < this.state.team_managers.length; i++) {
                    fd.append(
                        "team_managers[]",
                        this.state.team_managers[i]
                    );
                }
              fd.append(
                  "_method",
                  "PATCH"
              );
              axios(this.props)
                .post(
                      `/manager_teams/${this.state.id}`,
                      fd,
                      {
                          "headers": {"Content-Type": "application/x-www-form-urlencoded"}
                      }
                  )
                  .then((response) => this.processSaveTeamDetails(response))
                  .catch((error) => this.processErrorAxios(error));
          } else {
              axios(this.props)
                .post(
                      "/manager_teams",
                      {
                          "id": this.state.id,
                          "name": this.state.name,
                          "description": this.state.description,
                          "team_managers": this.state.team_managers
                      }
                  )
                  .then((response) => this.processSaveTeamDetails(response))
                  .catch((error) => this.processErrorAxios(error));
          }
      }
  }

  processSaveTeamDetails (results) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "btnDisabled": false,
          "doneModal": false,
          "teamDetailsModal": false,
          "doneModalMsg": "Manager Team details saved",
          "doneModalBtn": "OK",
          "tr_color": "success"
      });
      this.showTeamsNotification();
      this.refreshTeamsTable();
      helpers.hideLoading();
  }

    handleMultiple = (event) => {
        this.setState({"team_managers": event.target.value});
    };

  render () {
      const authService = new AuthService(
          this.state,
          this.props
      );
      if (!authService.getToken()) {
          return <Redirect to="/auth/login" />;
      }

      if (authService.isPasswordExpired()) {
          return <Redirect to="/admin/changepassword" />;
      }
      const {classes} = this.props,
          {tableData, pages, loading} = this.state;
      return (
          <div>
              <GridContainer>
                  <GridItem xs={12}>
                      <Card>
                          <CardHeader
                              color="success"
                              icon
                          >
                              <CardIcon color="success">
                                  <TeamIcon />
                              </CardIcon>
                              <h4 className={classes.cardIconTitle}>
                                  Manager Teams
                              </h4>
                              <CardIcon
                                  title={'Create manager team'}
                                  className="float-button"
                                  color="info"
                                  onClick={() => this.handleTeamDetailsClick(
                                      "teamDetailsModal",
                                      null
                                  )}
                              >
                                  <Add />
                              </CardIcon>
                              <CardIcon
                                  title={'Refresh manager teams table'}
                                  className="float-button"
                                  color="warning"
                                  onClick={() => this.refreshTeamsTable()}
                              >
                                  <Refresh />
                              </CardIcon>
                          </CardHeader>
                          <CardBody>
                              <ReactTable
                                  className="-striped -highlight"
                                  columns={this.TeamsTableColumns}
                                  data={tableData}
                                  defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                  defaultPageSize={10}
                                  filterable
                                  getTrProps={(state, rowInfo, column, instance) => {

                                      if (typeof rowInfo !== "undefined") {

                                          return {
                                              "onClick": (e, handleOriginal) => {

                                                  this.setState({
                                                      "selected": rowInfo.index
                                                  });
                                                  if (handleOriginal) {

                                                      handleOriginal();

                                                  }

                                                  if (
                                                    this.state.team_selected &&
                                                    rowInfo.original &&
                                                    rowInfo.original.id &&
                                                    rowInfo.original.id !== this.state.team_selected.id
                                                  ) {
                                                      this.setState({"team_selected": null});
                                                  }
                                              },
                                              "style": {
                                              "background":
                                                this.state.team_selected &&
                                                rowInfo.original &&
                                                rowInfo.original.id &&
                                                rowInfo.original.id === this.state.team_selected.id
                                                    ? "lightcyan"
                                                    : null
                                              }
                                          };
                                      }
                                      return {
                                          "onClick": (e, handleOriginal) => {
                                              if (handleOriginal) {
                                                  handleOriginal();
                                              }
                                          }
                                      };
                                  }}
                                  itemsCountText={this.state.items_data}
                                  loading={loading}
                                  manual
                                  onFetchData={(state, instance) => {
                                      this.setState({"loading": true});
                                      this.getTeamsData(
                                          state.page,
                                          state.pageSize,
                                          state.sorted,
                                          state.filtered,
                                          (res) => this.processTeamsData(res)
                                      );
                                  }}
                                  pages={pages}
                                  PaginationComponent={Pagination}
                                  ref={(refReactTeamsTable) => {
                                      this.refReactTeamsTable = refReactTeamsTable;
                                  }}
                                  showPageSizeOptions
                                  showPaginationBottom
                                  showPaginationTop={false}
                              />
                          </CardBody>
                      </Card>
                      <ManagerTeamDetails
                          dialogTitle={
                              this.state.action === "update"
                                  ? "Update Manager Team Details"
                                  : "Create Manager Team"
                          }
                          handleChange={this.handleChange}
                          onClose={() => this.handleClose("teamDetailsModal")}
                          onYesClick={() => this.handleAcceptTeamDetails("confirmModal")}
                          handleMultiple={this.handleMultiple}
                          {...this.state}
                      />
                      <ConfirmDialog
                          confirmModal={this.state.confirmModal}
                          confirmModalMsg={this.state.confirmModalMsg}
                          confirmModalTitle={this.state.confirmModalTitle}
                          onClose={() => this.handleClose("confirmModal")}
                          onYesClick={() => (this.state.action === "delete"
                                      ? this.doDeleteTeamDetailsClick()
                                      : this.doSaveTeamDetailsClick())}
                      />
                      {this.state.doneModalMsg
                          ? <Snackbar
                              close
                              closeNotification={() => {
                                  this.handleCloseNotification();
                              }}
                              color={this.state.tr_color}
                              icon={AddAlert}
                              message={this.state.doneModalMsg}
                              open={this.state.tr}
                              place="tr"
                          />
                          : ""}
                  </GridItem>
              </GridContainer>
          </div>
      );
  }
}

ManagerTeams.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(ManagerTeams));
