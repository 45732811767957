import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Details from "@material-ui/icons/Details";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthServiceClient";
import {doClientLogout} from "../../../store/actions/authActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Refresh from "@material-ui/icons/Refresh";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Form from "@rjsf/material-ui";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomFileWidget from "customs/components/CustomFileWidget";
import CustomCheckboxWidget from "customs/components/CustomCheckboxWidget.tsx";
import Typography from "@material-ui/core/Typography";
import PreviewDocument from "customs/components/ScriptStep/PreviewDocument.jsx";
import SelectFileToPreview from "customs/components/ScriptStep/SelectFileToPreview.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import CustomTextWidget from "customs/components/CustomTextWidget";
import CustomPasswordTextWidget from "customs/components/CustomPasswordTextWidget";
import CustomRadioWidget from "customs/components/CustomRadioWidget.tsx";
import SignaturePad from "customs/components/ScriptStep/SignaturePad.jsx";
import CustomDateWidget from "customs/components/CustomDateWidget.tsx";
import SampleID from "customs/components/ScriptStep/SampleID.jsx";
import Constants from "customs/config/Constants";

import axios from "axios/axios";
import helpers from "customs/helpers/helpers";
import axiosHelper from "axios/axiosHelper";
import validations from "customs/helpers/validations";

const CustomSelectWidget = (props) => {
    let files = ["pdf", "png", "bmp", "jpg", "jpeg"]; //, "txt", "doc", "docx", "xls", "xlsx"
    let isMultiple = props.schema.multiple ? props.schema.multiple : false;
    let value = props.value === undefined || props.value === null ? undefined : (isMultiple ? props.value.split('||') : props.value);
    if (Array.isArray(value) && value.length === 1 && value[0].length === 0) value = undefined;
    return (
        <GridContainer
            key="{{ props.id }}_grid"
        >
            <GridItem md={9}>
                <span id={props.id + '_lbl'} style={{color: "#aaaaaa", fontWeight: "normal", paddingTop: "3px"}}>
                    {typeof props.label === 'object' ? "" : props.label}
                </span>
                {isMultiple ? <style>{"p#" + props.id + " {display: none !important;}"}</style> : <style></style>}
                <Select
                    id={props.id}
                    value={value ? value : (isMultiple ? [] : '')}
                    required={props.required}
                    disabled={props.disabled || props.readonly}
                    readOnly={props.readonly}
                    onChange={(event) => props.onChange(isMultiple ? event.target.value.filter((item) => item.length > 0).sort().join('||') : event.target.value)}
                    placeholder={props.placeholder}
                    options={props.options}
                    classes={{"select": props.classes.select}}
                    MenuProps={{"className": props.classes.selectMenu}}
                    fullWidth
                    label={typeof props.label === 'object' ? "" : props.label}
                    schema={props.schema}
                    inputProps={{
                        "name": props.id,
                        "id": props.id
                    }}
                    multiple={isMultiple}
                    error={isMultiple ? false : props.rawErrors !== undefined && props.rawErrors.length > 0}
                >
                    {props.options.enumOptions
                        ? (isMultiple ? props.options.enumOptions.filter((prop) => prop.value.length > 0) : props.options.enumOptions).map((prop, key) => {
                            return <MenuItem
                                classes={{
                                    root: props.classes.selectMenuItem,
                                    selected: props.classes.selectMenuItemSelectedMultiple
                                }}
                                key={key}
                                value={prop.value}
                            >
                                {prop.label} 
                            </MenuItem>
                        })
                        : ""}
                </Select>
            </GridItem>
            {
                value && value.length > 0
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="success" className={props.classes.marginRight} type="button" id={props.id + '_btn'} onClick={() => {
                        isMultiple
                        ? props.onPreviewMultiple(value, files)
                        : (
                            files.includes(value.split('.').pop().toLowerCase())
                            ? props.onPreview(value)
                            : props.onDownload(value.split(') ')[0].replace('(id: ', ''), value.split(') ')[1])
                        )
                    }} disabled={props.readonly} >
                        {
                            isMultiple
                            ? (value.length === 1 && !files.includes(value[0].split('.').pop().toLowerCase()) ? "Download" : "Preview")
                            : (files.includes(value.split('.').pop().toLowerCase()) ? "Preview" : "Download")
                        }
                    </Button>
                </GridItem>
                : ""
            }
        </GridContainer>
    );
}

const CustomSelect = (props) => {
    return (
        <GridContainer
            key="{{ props.id }}_grid"
        >
            <GridItem md={12}>
                <span id={props.id + '_lbl'} style={{color: "#aaaaaa", fontWeight: "normal", paddingTop: "3px"}}>
                    {typeof props.label === 'object' ? "" : props.label}
                </span>
                <Select
                    id={props.id}
                    value={props.value ? props.value : ''}
                    required={props.required}
                    disabled={props.disabled || props.readonly}
                    readOnly={props.readonly}
                    onChange={(event) => props.onChange(event.target.value)}
                    onBlur={(event) => props.onBlur(event.target.name, event.target.value)}
                    onFocus={props.onFocus}
                    placeholder={props.placeholder}
                    options={props.options}
                    classes={{"select": props.classes.select}}
                    MenuProps={{"className": props.classes.selectMenu}}
                    fullWidth
                    label={typeof props.label === 'object' ? "" : props.label}
                    schema={props.schema}
                    inputProps={{
                        "name": props.id,
                        "id": props.id
                    }}
                >
                    {props.options.enumOptions
                        ? props.options.enumOptions.map((prop, key) => {
                            return <MenuItem
                                classes={{
                                    root: props.classes.selectMenuItem,
                                    selected: props.classes.selectMenuItemSelectedMultiple
                                }}
                                key={key}
                                value={prop.value}
                            >
                                {prop.value} 
                            </MenuItem>
                        })
                        : ""}
                </Select>
            </GridItem>
        </GridContainer>
    );
}

const CustomRadio = (props) => {
    const {label, onShowSampleID, ...p} = props;
    let sample_btn = props.schema.sample_btn ? props.schema.sample_btn : null;
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={12 - (sample_btn !== null ? 3 : 0)}>
                <CustomRadioWidget {...p} label={label} />
            </GridItem>
            {
                sample_btn !== null
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="primary" className={props.classes.marginRight} type="button" id={props.id + '_sample_btn'}
                        onClick={() => {onShowSampleID(props.schema);}} disabled={props.readonly}
                    >
                        {sample_btn.label}
                    </Button>
                  </GridItem>
                : ""
            }
        </GridContainer>
    );
}

const CustomText = (props) => {
    const {label, onShowSampleID, classes, isWatched, setWatched, handleUpdateWatch, getRegistryKey, watched, formatInputValue, value, ...p} = props;
    let sample_btn = props.schema.sample_btn ? props.schema.sample_btn : null;
    let formatting = null;
    let inputValue = value;
    if (p.hasOwnProperty('schema') && p.schema.hasOwnProperty('formatting')) {
        formatting = p.schema.formatting;
        inputValue = formatInputValue(inputValue, formatting);
    }
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={12 - (sample_btn !== null ? 3 : 0)}>
                <CustomTextWidget {...p} label={label} value={inputValue} onChange={(value) => {
                    if (formatting !== null) {
                        value = formatInputValue(value, formatting);
                    }
                    props.onChange(value);
                }} />
            </GridItem>
            {
                sample_btn !== null
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="primary" className={props.classes.marginRight} type="button" id={props.id + '_sample_btn'}
                        onClick={() => {onShowSampleID(props.schema);}} disabled={props.readonly}
                    >
                        {sample_btn.label}
                    </Button>
                  </GridItem>
                : ""
            }
        </GridContainer>
    );
}

const CustomPasswordText = (props) => {
    const {label, onShowSampleID, classes, isWatched, setWatched, handleUpdateWatch, getRegistryKey, watched, formatInputValue, value, ...p} = props;
    let sample_btn = props.schema.sample_btn ? props.schema.sample_btn : null;
    let formatting = null;
    let inputValue = value;
    if (p.hasOwnProperty('schema') && p.schema.hasOwnProperty('formatting')) {
        formatting = p.schema.formatting;
        inputValue = formatInputValue(inputValue, formatting);
    }
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={12 - (sample_btn !== null ? 3 : 0)}>
                <CustomPasswordTextWidget {...p} label={label} value={inputValue} onChange={(value) => {
                    if (formatting !== null) {
                        value = formatInputValue(value, formatting);
                    }
                    props.onChange(value);
                }} />
            </GridItem>
            {
                sample_btn !== null
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="primary" className={props.classes.marginRight} type="button" id={props.id + '_sample_btn'}
                        onClick={() => {onShowSampleID(props.schema);}} disabled={props.readonly}
                    >
                        {sample_btn.label}
                    </Button>
                  </GridItem>
                : ""
            }
        </GridContainer>
    );
}

const LabelledFileField = (props) => {
    let label = 'Or choose and upload a new file';
    if (props.hasOwnProperty('uiSchema') && props.uiSchema.hasOwnProperty('ui:options')) {
        if (props.uiSchema['ui:options'].hasOwnProperty('hide_alt_label') && props.uiSchema['ui:options']['hide_alt_label'] === true) {
            label = props.label;
        } else if (props.uiSchema['ui:options'].hasOwnProperty('alt_label')) {
            label = props.uiSchema['ui:options']['alt_label'];
        }
    }
    let isMultiple = props.schema.hasOwnProperty('multiple') ? props.schema.multiple : false;
    let value = props.value;
    if (Array.isArray(value) && value.length === 1 && (Array.isArray(value[0]) || value[0] === undefined)) value = undefined;
    return (
        <>
        {isMultiple ? <style>{"p#" + props.id + " {display: none !important;}"}</style> : <style></style>}
        <Typography component="div" style={{'paddingBottom': '8px'}}>{label}</Typography>
        <CustomFileWidget {...props} value={value} multiple={isMultiple} />
        </>
    );
};

const LabelledFileSignatureField = (props) => {
    let label = 'Or choose and upload a new file';
    return (
        <>
        <GridContainer
            key="{{ props.id }}_grid"
        >
            <GridItem md={8}>
                <Typography component="div" style={{'paddingBottom': '8px'}}>{label}</Typography>
                <CustomFileWidget {...props} />
            </GridItem>
            <GridItem md={4} style={{"textAlign": "right"}}>
                <Button color="success" className={props.classes.marginRight} type="button" id={props.id + '_btn'} onClick={() => {props.onShowSignaturePad(props.schema);}}
                    disabled={props.readonly}
                >
                    {"Signature Pad"}
                </Button>
            </GridItem>
        </GridContainer>
        </>
    );
};

const CustomInputAsLabelWidget = (props) => {
    let {id, label} = props;
    return (
        <label id={id} style={{fontSize: "16px", fontWeight: "normal", paddingTop: "3px"}}>
            {typeof label === 'object' ? "" : label}
        </label>
    );
}

const CustomBottonWidget = (props) => {
    let {id, label, classes} = props;
    return (
        <span>
            <Button color="success" className={classes.marginRight} type="button" id={id} onClick={() => {props.onClick(props.uiSchema)}}
                disabled={props.readonly}
            >
                {typeof label === 'object' ? "" : label}
            </Button>
        </span>
    );
}

const CustomCheckbox = (props) => {
    const {label, ...p} = props;
    let registry = props.getRegistryValue(props.schema.registry_key);
    let files = ["pdf", "png", "bmp", "jpg", "jpeg"]; //, "txt", "doc", "docx", "xls", "xlsx"
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={8}>
                <CustomCheckboxWidget {...p} label={helpers.addLineBreaks(label + (registry !== null && registry.uploader === 'Client' ? "<br>(Client Supplied)" : ""))} />
            </GridItem>
            <GridItem md={4} style={{"textAlign": "right"}}>
            {
                <Button color={registry !== null ? "success" : "warning"} className={props.classes.marginRight} type="button" id={props.id + '_btn'} onClick={() => {
                        if (registry !== null) {
                            if (registry.filename && files.includes(registry.filename.split('.').pop().toLowerCase())) {
                                props.onPreview('(id: ' + registry.value + ') ' + registry.filename);
                            } else {
                                props.onDownload(registry.value, registry.filename);
                            }
                        }
                    }} disabled={props.readonly}
                >
                    {registry !== null ? (registry.filename && files.includes(registry.filename.split('.').pop().toLowerCase()) ? "Preview" : "Download") : "Not set"}
                </Button>
            }
            </GridItem>
        </GridContainer>
    );
}

const CustomDate = (props) => {
    const {label, onShowSampleID, classes, isWatched, setWatched, handleUpdateWatch, getRegistryKey, ...p} = props;
    let errors = props.rawErrors !== undefined && props.rawErrors.length > 0
        ? props.rawErrors.filter((value) => {return value.toLowerCase().indexOf('is a required property') === -1;}) : [];
    let sample_btn = props.schema.sample_btn ? props.schema.sample_btn : null;
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={12 - (sample_btn !== null ? 3 : 0)}>
                <CustomDateWidget {...p} label={label} />
                {
                    errors.length > 0 && !props.schema.is_optional
                        ? React.createElement("p", {style: {
                            color: "#f44336",
                            fontSize: "0.75rem",
                            marginRop: "3px",
                            textAlign: "left",
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: "400"
                        }}, helpers.addLineBreaks(errors.join('<br>')))
                        : null
                }
            </GridItem>
            {
                sample_btn !== null
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="primary" className={props.classes.marginRight} type="button" id={props.id + '_sample_btn'}
                        onClick={() => {onShowSampleID(props.schema);}} disabled={props.readonly}
                    >
                        {sample_btn.label}
                    </Button>
                  </GridItem>
                : ""
            }
        </GridContainer>
    );
}

class InformationRegistry extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            no_form: false,
            form_fields: [],
            information_registry: [],
            files: {},
            schema: null,
            ui: null,
            formData: {},
            custom_validation: {},
            "previewDocumentModal": false,
            "previewDocumentValue": null,
            "previewDocumentName": null,
            "selectFileToPreviewModal": false,
            "selectFileToPreviewFiles": [],
            "selectFileToPreviewExtensions": [],
            "confirmModal": false,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": "",
            "signaturePadModal": false,
            "signaturePadSchema": null,
            "sampleIDModal": false,
            "sampleIDSchema": null,
            "sampleIDImagePath": null,
        };
        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.processErrorAxios = this.processErrorAxios.bind(this);
        this.fetchRegistry = this.fetchRegistry.bind(this);
        this.buildForm = this.buildForm.bind(this);
        this.reBuildForm = this.reBuildForm.bind(this);
        this.filterFile = this.filterFile.bind(this);
        this.previewDocument = this.previewDocument.bind(this);
        this.previewMultipleDocument = this.previewMultipleDocument.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSaveRegistry = this.handleSaveRegistry.bind(this);
        this.updateRegistryState = this.updateRegistryState.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.showSignaturePad = this.showSignaturePad.bind(this);
        this.showSampleID = this.showSampleID.bind(this);
        this.showLicenseIDSample = this.showLicenseIDSample.bind(this);
        this.showPassportIDSample = this.showPassportIDSample.bind(this);
        this.showMedicareIDSample = this.showMedicareIDSample.bind(this);
        this.formatInputValue = this.formatInputValue.bind(this);
        this.medicareCardNumberFormat = this.medicareCardNumberFormat.bind(this);
        this.monthYearFormat = this.monthYearFormat.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
    }

    previewDocument(value) {
        let name = value.split(') ');
        name.shift();
        this.setState({
            "previewDocumentModal": true,
            "previewDocumentValue": parseInt(value.split(') ')[0].replace('(id: ', '')),
            "previewDocumentName": name.join(') ')
        });
    }

    previewMultipleDocument(files, extensions) {
        if (!files || files.length === 0) return;
        try {
            if (files.length === 1) {
                extensions.includes(files[0].split('.').pop().toLowerCase())
                ? this.previewDocument(files[0])
                : this.downloadDocument(files[0].split(') ')[0].replace('(id: ', ''), files[0].split(') ')[1])
            } else {
                this.setState({
                    "selectFileToPreviewModal": true,
                    "selectFileToPreviewFiles": files,
                    "selectFileToPreviewExtensions": extensions
                });
            }
        } catch(e) {}
    }

    showSignaturePad(schema) {
        this.setState({
            "signaturePadModal": true,
            "signaturePadSchema": schema,
        });
    }

    showSampleID(schema) {
        try {
            this[schema.sample_btn.func](schema.sample_btn);
        } catch(e) {}
    }

    showLicenseIDSample(config) {
        let image_path = Constants.API_URL + '/sample/id/license?state=' +
            (this.state.formData[config['reference_field']] ? this.state.formData[config['reference_field']] : '') + 
            '&field=' + config['field'];
        this.setState({
            "sampleIDModal": true,
            "sampleIDSchema": config,
            "sampleIDImagePath": image_path
        });
    }

    showPassportIDSample(config) {
        let image_path = Constants.API_URL + '/sample/id/passport?field=' + config['field'];
        this.setState({
            "sampleIDModal": true,
            "sampleIDSchema": config,
            "sampleIDImagePath": image_path
        });
    }

    showMedicareIDSample(config) {
        let image_path = Constants.API_URL + '/sample/id/medicare?field=' + config['field'];
        this.setState({
            "sampleIDModal": true,
            "sampleIDSchema": config,
            "sampleIDImagePath": image_path
        });
    }

    filterFile(file, configuration) {
        let validTag = false;
        let validExt = false;
        if (configuration.hasOwnProperty('allowTags')) {
            let file_tags = file.tags.map((tag) => tag.toLowerCase());
            if (file_tags.length > 0) {
                configuration.allowTags.forEach(allowedTag => {
                    if (allowedTag === '*') {
                        validTag = true;
                    } else if (allowedTag.indexOf('*')) {
                        file_tags.forEach(tag => {
                            if (validations.matchRule(tag, allowedTag)) {
                                validTag = true;
                            }
                        })
                    } else if (file_tags.includes(allowedTag.toLowerCase())) {
                        validTag = true;
                    }
                });
            }
        } else {
            validTag = true;
        }
        if (configuration.hasOwnProperty('ui') && configuration.ui.hasOwnProperty('ui:options') && configuration.ui['ui:options'].hasOwnProperty('accept')) {
            let allowed_exts = configuration.ui['ui:options'].accept.split(',').map((ext) => ext.toLowerCase().replace(/\s/g, ''));
            if (allowed_exts.includes('.' + file.filename.split('.').pop().toLowerCase())) {
                validExt = true;
            }
        } else {
            validExt = true;
        }
        return validTag && validExt;
    }

    hideNotification;
    showUsersNotification () {
        if (!this.state.tr) {
            this.setState({"tr": true});
        } else {
            clearTimeout(this.hideNotification);
        }
        this.setHideNotificationTimeout();
    }
    
    setUsersNotification (msg, color) {
        this.setState({"doneModalMsg": msg, "tr_color": color});
        this.showUsersNotification();
    }

    setHideNotificationTimeout () {
        this.hideNotification = setTimeout(
            () => {
                this.handleCloseNotification();
            },
            this.state.tr_color === "success" ? 5000 : 10000
        );
    }

    handleCloseNotification () {
        clearTimeout(this.hideNotification);
        this.setState({"tr": false,
            "doneModalMsg": ""});
    }

    componentDidMount () {
        this._ismounted = true;
        this.fetchRegistry();
    }

    fetchRegistry() {
        helpers.showLoading();
        axios(this.props)
            .get("client/information_registry", {})
            .then((res) => {
                helpers.hideLoading();
                this.updateRegistryState(res);
            })
            .catch((error) => this.processErrorAxios(error, null));
    }

    updateRegistryState(res) {
        this.setState({
            "form_fields": res.data.form_fields,
            "information_registry": res.data.information_registry,
            "files": res.data.files,
            "no_form": res.data.form_fields.length === 0 ? true : false,
        });
        this.buildForm(res.data.form_fields, res.data.information_registry, res.data.files);
    }

    checkFieldRebuildVisibility(properties, formData, registry_entries) {
        if (properties.hasOwnProperty('visibility_config') && properties.visibility_config.hasOwnProperty('fields')) {
            let isAll = properties.visibility_config.hasOwnProperty('all') && properties.visibility_config.all;
            for(let key in properties.visibility_config.fields) {
                let registry = Object.keys(registry_entries).length > 0
                    ? registry_entries.find((entry) => entry.registry_key === key) : undefined;
                let formDataKey = key.split('_').map((val) => val[0].toUpperCase() + val.substring(1)).join('');
                formDataKey = formDataKey[0].toLowerCase() + formDataKey.substring(1);
                let value = formData.hasOwnProperty(formDataKey)
                    ? formData[formDataKey]
                    : (registry !== undefined && registry.hasOwnProperty('value') ? registry.value : undefined);
                if (!isAll && (value === undefined || typeof value === 'object')) {
                    continue;
                }
                if (value === undefined && properties.visibility_config.fields[key] === true) {
                    value = false;
                }
                if (value === "true") {value = true;} else if (value === "false") {value = false;}
                if (typeof properties.visibility_config.fields[key] === 'object') {
                    if (!isAll) {
                        if (!properties.visibility_config.fields[key]['all'] && properties.visibility_config.fields[key]['values'].includes(value)) {
                            return true;
                        }
                    } else {
                        if (!properties.visibility_config.fields[key]['all'] && !properties.visibility_config.fields[key]['values'].includes(value)) {
                            return false;
                        }
                    }
                } else {
                    if (!isAll && value === properties.visibility_config.fields[key]) {
                        return true;
                    } else if (isAll && value !== properties.visibility_config.fields[key]) {
                        return false;
                    }
                }
            }
            if (!isAll) return false;
        }
        return true;
    }

    buildForm(form_fields, information_registry, client_files) {
        let form = {"type": "object", "properties": {}};
        let ui = {"ui:order": []};
        let formData = {};
        let custom_validation = {};

        if (form_fields.length !== 0) {
            form_fields.forEach(field => {
                let property_name = field.configuration.properties.name;
                let properties = JSON.parse(JSON.stringify(field.configuration.properties));

                let isFile = false;
                let files = [];
                let isFileButEmpty = false;
                let isMultiple = properties.multiple ? properties.multiple : false;
                if (properties.field_type !== undefined && (properties.field_type === 'file' || properties.field_type === 'file_sig')) {
                    isFile = true;
                    files.push("");
                    client_files.forEach((file) => {
                        if (this.filterFile(file, field.configuration)) {
                            files.push('(id: ' + file.id + ') ' + file.filename);
                        }
                    });
                    if (files.length > 1) {
                        form.properties[property_name + '_asfileselect'] = {"type": "string", "title": properties.title, "enum": files, "multiple": isMultiple};
                    } else {
                        isFileButEmpty = true;
                    }
                }
                form.properties[property_name] = properties;
                if (field.configuration.hasOwnProperty('custom_validation')) {
                    for(let key in field.configuration.custom_validation) {
                        if (!custom_validation.hasOwnProperty(key)) {
                            custom_validation[key] = field.configuration.custom_validation[key];
                        }
                    }
                }
                if (Object.keys(field.configuration.ui).length > 0) {
                    ui[property_name] = field.configuration.ui;
                }
                if (isFile) {
                    try {
                        ui[property_name]['ui:options']['hide_alt_label'] = isFileButEmpty;
                    } catch(e) {}

                    if (properties.hasOwnProperty('preview_btn') && properties.preview_btn === true) {
                        if (files.length > 1) {
                            if (ui[property_name + '_asfileselect'] === undefined) {
                                ui[property_name + '_asfileselect'] = {};
                            }
                            ui[property_name + '_asfileselect']['ui:widget'] = "previewSelectWidget";
                        }
                    }
                    ui["ui:order"].push(property_name + '_asfileselect');
                }
                ui["ui:order"].push(property_name);
                if (properties.hasOwnProperty('ignore_count') && properties.ignore_count === true) {
                } else {
                    let fieldValue = Object.keys(information_registry).length > 0
                        ? information_registry.find((entry) => entry.registry_key === field.registry_key) : undefined;
                    if (fieldValue !== undefined) {
                        let value = fieldValue.value;
                        if (properties['type'] === 'number') {
                            value = Number(value);
                        } else if (properties['type'] === 'integer') {
                            value = parseInt(value);
                        } else if (properties['type'] === 'boolean') {
                            value = typeof value === "boolean" ? value : value === 'true' || value === '1' || value === 1;
                        }
                        if (isFile) {
                            let selectedFiles = [];
                            for (let i=0; i<Object.keys(client_files).length; i++) {
                                let file = client_files[i];
                                value.split(',').forEach((value) => {
                                    if (file.id === Number(value) || file.id === value) {
                                        selectedFiles.push('(id: ' + file.id + ') ' + file.filename);
                                    }
                                });
                                if (selectedFiles.length > 0) {
                                    formData[property_name + '_asfileselect'] = selectedFiles.join('||');
                                }
                                if (formData.hasOwnProperty(property_name)) {
                                    delete formData[property_name];
                                }
                            }
                        } else {
                            if (formData.hasOwnProperty(property_name) //&&
                            ) {} else {
                                formData[property_name] = value;
                            }
                        }
                    }
                }
                if (!this.checkFieldRebuildVisibility(field.configuration.properties, formData, information_registry)) {
                    if (isFile) {
                        try {
                            delete form.properties[property_name + '_asfileselect'];
                        } catch(e) {}
                    }
                    try {
                        delete form.properties[property_name];
                    } catch(e) {}
                }
            });
        }

        this.setState({"schema": form, ui, formData});
    }

    reBuildForm(form_fields, client_files, formData) {
        let form = this.state.schema;
        let ui = this.state.ui;
        let custom_validation = this.state.custom_validation;
        if (form_fields.length !== 0) {
            form_fields.forEach(field => {
                let property_name = field.configuration.properties.name;
                let properties = JSON.parse(JSON.stringify(field.configuration.properties));

                let isFile = false;
                let isFileButEmpty = false;
                let files = [];
                if (properties.field_type !== undefined && (properties.field_type === 'file' || properties.field_type === 'file_sig')) {
                    isFile = true;
                    files.push("");
                    client_files.forEach((file) => {
                        if (this.filterFile(file, field.configuration)) {
                            files.push('(id: ' + file.id + ') ' + file.filename);
                        }
                    });
                    if (files.length > 1) {
                        let isMultiple = properties.multiple ? properties.multiple : false;
                        form.properties[property_name + '_asfileselect'] = {"type": "string", "title": properties.title, "enum": files, "multiple": isMultiple};
                    } else {
                        isFileButEmpty = true;
                    }
                }
                form.properties[property_name] = properties;
                if (field.configuration.hasOwnProperty('custom_validation')) {
                    for(let key in field.configuration.custom_validation) {
                        if (!custom_validation.hasOwnProperty(key)) {
                            custom_validation[key] = field.configuration.custom_validation[key];
                        }
                    }
                }
                if (Object.keys(field.configuration.ui).length > 0) {
                    ui[property_name] = field.configuration.ui;
                }
                if (isFile) {
                    try {
                        ui[property_name]['ui:options']['hide_alt_label'] = isFileButEmpty;
                    } catch(e) {}

                    if (properties.hasOwnProperty('preview_btn') && properties.preview_btn === true) {
                        if (files.length > 1) {
                            if (ui[property_name + '_asfileselect'] === undefined) {
                                ui[property_name + '_asfileselect'] = {};
                            }
                            ui[property_name + '_asfileselect']['ui:widget'] = "previewSelectWidget";
                        }
                    }
                    if (!ui["ui:order"].includes(property_name + '_asfileselect')) {
                        ui["ui:order"].push(property_name + '_asfileselect');
                    }
                }
                if (!ui["ui:order"].includes(property_name)) {
                    ui["ui:order"].push(property_name);
                }
                if (!this.checkFieldRebuildVisibility(field.configuration.properties, formData, this.state.information_registry)) {
                    if (isFile) {
                        try {
                            delete form.properties[property_name + '_asfileselect'];
                        } catch(e) {}
                    }
                    try {
                        delete form.properties[property_name];
                    } catch(e) {}
                }
            });
        }

        this.setState({"schema": form, ui, custom_validation});
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showUsersNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );
    }
    
    widgets = {
        labelledFileField: (props) => <LabelledFileField
            {...props}
            classes={this.props.classes}
            handleFocusEvent={this.handleFocusEvent}
            handleBlurEvent={this.handleBlurEvent}
        />,
        labelledFileSignatureField: (props) => <LabelledFileSignatureField
            {...props}
            classes={this.props.classes}
            handleFocusEvent={this.handleFocusEvent}
            handleBlurEvent={this.handleBlurEvent}
            handleUpdateWatch={this.props.handleUpdateWatch}
            onShowSignaturePad={this.showSignaturePad}
        />,
        LabelWidget: CustomInputAsLabelWidget,
        button: (props) => <CustomBottonWidget
            {...props}
            classes={this.props.classes}
            onClick={(uiSchema) => {this.props.buttonFieldClick(uiSchema)}}
        />,
        previewSelectWidget: (props) => <CustomSelectWidget
            {...props}
            classes={this.props.classes}
            onPreview={this.previewDocument}
            onPreviewMultiple={this.previewMultipleDocument}
        />,
        CustomCheckbox:  (props) => <CustomCheckbox
            {...props}
            classes={this.props.classes}
            onPreview={this.previewDocument}
            onDownload={this.downloadDocument}
            getRegistryValue={this.getRegistryValue}
        />,
        selectWidget: (props) => <CustomSelect
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
        />,
        textWidget:  (props) => <CustomText
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            onShowSampleID={this.showSampleID}
            formatInputValue={this.formatInputValue}
        />,
        passwordTextWidget:  (props) => <CustomPasswordText
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            onShowSampleID={this.showSampleID}
            formatInputValue={this.formatInputValue}
        />,
        CustomRadio:  (props) => <CustomRadio
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            onShowSampleID={this.showSampleID}
        />,
        dateWidget:  (props) => <CustomDate
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            onShowSampleID={this.showSampleID}
        />,
    };

    renderNoForm(msg) {
        return <div style={{"minHeight": "250px", "position": "relative"}}>
            <div style={{
                "margin": "0",
                "position": "absolute",
                "top": "50%",
                "left": "50%",
                "msTransform": "translate(-50%, -50%)",
                "transform": "translate(-50%, -50%)"
            }}>
                {msg}
            </div>
        </div>;
    }

    formatInputValue(value, formatting) {
        if (typeof this[formatting] === 'function') {
            try {
                value = this[formatting](value);
            } catch(e) {}
        }
        return value;
    }

    medicareCardNumberFormat(value) {
        if (!value) return value;
        value = value.replace(/[^\d]/g, '').substr(0, 10);
        if (value.length < 5) return value;
        let first = value.substr(0, 4);
        let last = value.length > 4 ? value.substr(4).match(/.{1,5}/g).join(' ') : '';
        return first + ' ' + last;
    }

    monthYearFormat(value) {
        if (!value) return value;
        value = value.replace(/[^\d]/g, '').substr(0, 6);;
        if (value.length < 3) return value;
        let first = value.substr(0, 2);
        let last = value.length > 2 ? value.substr(2) : '';
        return first + '/' + last;
    }

    validate(formData, errors) {
        for(let key in this.state.custom_validation) {
            if (formData.hasOwnProperty(key)) {
                if (typeof this.state.custom_validation[key] === 'object') {
                    if (this.state.custom_validation[key].hasOwnProperty('func')) {
                        if (formData.hasOwnProperty(key) && formData[key]) {
                            if (this.state.custom_validation[key]['func'] === 'maxFileSizeValid') {
                                if (!validations.maxFileSizeValid(formData[key], this.state.custom_validation[key]['maxBytes'])) {
                                    errors[key].addError("Invalid file size");
                                } else if (!validations.fileTypeValid(formData[key], this.state.custom_validation[key]['accept'])) {
                                    errors[key].addError("Invalid file type");
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateNumberMax') {
                                if (!validations.validateNumberMax(formData[key], this.state.custom_validation[key]['max'])) {
                                    errors[key].addError("Value should not exceed " + this.state.custom_validation[key]['max']);
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateDateNotFuture') {
                                if (!validations.validateDateNotFuture(formData[key])) {
                                    errors[key].addError("Selected date should not be a future date");
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateMMYYYYDate') {
                                if (!validations.validateMMYYYYDate(formData[key])) {
                                    errors[key].addError("Entered date is invalid");
                                } else if (this.state.custom_validation[key].hasOwnProperty('checkExpired') && this.state.custom_validation[key]['checkExpired'] === true) {
                                    if (validations.isExpired(formData[key], 'MM/YYYY', true)) {
                                        errors[key].addError("Entered date is expired");
                                    }
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateDate') {
                                if (!validations.validateDate(formData[key])) {
                                    errors[key].addError("Entered date is invalid");
                                } else if (this.state.custom_validation[key].hasOwnProperty('checkExpired') && this.state.custom_validation[key]['checkExpired'] === true) {
                                    if (validations.isExpired(formData[key], null, false)) {
                                        errors[key].addError("Entered date is expired");
                                    }
                                }
                            }
                        }
                    } else {
                        for(let sub_key in this.state.custom_validation[key]) {
                            let func = validations[this.state.custom_validation[key][sub_key]['func']];
                            let parent_value = this.state.custom_validation[key][sub_key]['parent_val'];
                            if (
                                typeof func === 'function' &&
                                parent_value.includes(formData[key][key]) &&
                                formData[key].hasOwnProperty(sub_key) &&
                                formData[key][sub_key] !== null &&
                                formData[key][sub_key] !== undefined
                            ) {
                                if (!func(formData[key][sub_key])) {
                                    if (!errors.hasOwnProperty(key)) {
                                        errors[key] = {};
                                    }
                                    if (!errors[key].hasOwnProperty(sub_key)) {
                                        errors[key][sub_key] = {};
                                    }
                                    errors[key][sub_key].addError("Invalid value");
                                }
                            }
                        }
                    }
                } else {
                    let func = validations[this.state.custom_validation[key]];
                    if (typeof func === 'function' && formData.hasOwnProperty(key) && formData[key] !== null && formData[key] !== undefined) {
                        if (!func(formData[key])) {
                            if (!errors.hasOwnProperty(key)) {
                                errors[key] = {};
                            }
                            errors[key].addError("Invalid value");
                        }
                    }
                }
            }
        }
        return errors;
    }

    handleSaveRegistry(data) {
        if (data.form_data && Object.keys(data.form_data).length === 0) {
            this.setState({
                "doneModalMsg": "Nothing to save",
                "tr_color": "warning"
            });
            this.showUsersNotification();
            return;
        }
        helpers.showLoading();
        let fd = new FormData();
        Object.keys(data).forEach((key) => {
            fd = helpers.createFormData(fd, data[key], key);
        });
        return axios(this.props)
            .post("/client/information_registry/save", fd, {"headers": {"Content-Type": "application/x-www-form-urlencoded"}})
            .then((response) => {
                helpers.hideLoading();
                this.setState({
                    "doneModalMsg": "Registry updated",
                    "tr_color": "success"
                });
                this.updateRegistryState(response);
                this.showUsersNotification();
            })
            .catch((error) => {this.processErrorAxios(error, null);});
    }

    handleOnChange = ({formData}) => {
        this.setState({formData});
        this.reBuildForm(this.state.form_fields, this.state.files, formData);
    }

    handleSignatureChange = (key, data) => {
        let formData = this.state.formData;
        
        formData[key] = "";
        this.setState({formData});
        this.reBuildForm(this.state.form_fields, this.state.files, formData);
        
        setTimeout(() => {
            formData[key] = data;
            this.setState({formData});
            this.reBuildForm(this.state.form_fields, this.state.files, formData);
        }, 200);
    }

    errors = null;
    submitFormData() {
        let formData = this.state.formData;

        try {
            if (this.submitForm.state.errors.length > 0) {
                let ignoreError = true;
                this.submitForm.state.errors.forEach((item) => {
                    if (item.stack.toLowerCase().indexOf('should be equal to one of the allowed values') === -1 &&
                        item.stack.toLowerCase().indexOf('should be string') === -1) {
                        ignoreError = false;
                    }
                });
                if (!ignoreError) {
                    this.setState({
                        "doneModalMsg": "Please review the highlighted fields",
                        "tr_color": "danger"
                    });
                    this.showUsersNotification();
                    return;
                }
            }
        } catch(e) {}

        this.setState({
            "formData": formData,
            "confirmModal": true,
            "confirmModalMsg": "Are you sure?",
            "confirmModalTitle": "Save Information Registry",
        });
    }

    render () {

        const authService = new AuthService(
            this.state,
            this.props
        );
        if (!authService.getToken()) {
            return <Redirect to="/client/auth/login" />;
        }

        const {classes} = this.props;
        return (
            <GridContainer>
                <style>{
                    "label {font-size: 1.25rem !important; color: black; background: #d3d3d354;}" +
                    ".MuiInputLabel-animated {font-size: 1rem !important;}" +
                    ".MuiFormLabel-root {background: transparent !important;}" +
                    ".MuiSelect-selectMenu {font: inherit !important;}"
                }</style>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader
                            color="success"
                            icon
                        >
                            <CardIcon color="success">
                                <Details />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>
                                Information Registry
                            </h4>
                            <CardIcon
                                title={'Refresh documents table'}
                                className="float-button"
                                color="warning"
                                onClick={() => this.fetchRegistry()}
                            >
                                <Refresh />
                            </CardIcon>
                        </CardHeader>
                        <CardBody>
                            {
                                this.state.no_form === true
                                ? this.renderNoForm("No information registry assignment, please contact the system administrator.")
                                : (this.state.schema === null || this.state.ui === null
                                    ? this.renderNoForm("Loading...")
                                    : <div style={{"maxWidth": "750px", "margin": "0 auto"}}>
                                        <Form schema={this.state.schema}
                                            uiSchema={this.state.ui}
                                            widgets={this.widgets}
                                            formData={this.state.formData}
                                            onSubmit={({formData}, e) => {
                                                this.errors = null;
                                                return;
                                                // this.setState({
                                                //     "formData": formData,
                                                //     "confirmModal": true,
                                                //     "confirmModalMsg": "Are you sure?",
                                                //     "confirmModalTitle": "Save Information Registry",
                                                // });
                                            }}
                                            onChange={this.handleOnChange}
                                            validate={this.validate}
                                            showErrorList={false}
                                            liveValidate={true}
                                            autoComplete="off"
                                            ref={form => this.submitForm = form}
                                        >
                                                <div style={{float: "right"}}>
                                                    <Button
                                                        type="button"
                                                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                                        color="success"
                                                        // ref={form => this.submitForm = form}
                                                        ref={(form => this.submitBtn = form)}
                                                        onClick={() => this.submitFormData()}
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                        </Form>
                                    </div>
                                )
                            }
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12}>
                    <style>
                        {"@media (min-width: 768px) {.MuiTabs-flexContainer {float: right;}}" +
                    ".CustomTabs-cardTitle-1018 {font-weight: bold;} .MuiTabs-scroller {overflow: auto !important;}" +
                    ".MuiTableCell-head {font-size: 0.9rem !important;}" +
                    ".MuiTableCell-body {padding: 0px 0px 0px 8px! important;}"}
                    </style>
                </GridItem>
                {this.state.doneModalMsg
                    ? <Snackbar
                        close
                        closeNotification={() => {
                            this.handleCloseNotification();
                        }}
                        color={this.state.tr_color}
                        icon={AddAlert}
                        message={this.state.doneModalMsg}
                        open={this.state.tr}
                        place="tr"
                    />
                    : ""}
                {
                    this.state.previewDocumentModal
                    ? <PreviewDocument
                        value={this.state.previewDocumentValue}
                        name={this.state.previewDocumentName}
                        client_id={this.props.auth.client.id}
                        onClose={() => {
                            this.setState({
                                "previewDocumentModal": false,
                                "previewDocumentValue": null,
                                "previewDocumentName": null
                            });
                        }}
                        auth={this.props.auth}
                        previewDocumentModal={this.state.previewDocumentModal}
                        clientArea={true}
                    />
                    : ""
                }
                {
                    this.state.selectFileToPreviewModal
                    ? <SelectFileToPreview
                        files={this.state.selectFileToPreviewFiles}
                        extensions={this.state.selectFileToPreviewExtensions}
                        onClose={() => {
                            this.setState({
                                "selectFileToPreviewModal": false,
                                "selectFileToPreviewFiles": [],
                                "selectFileToPreviewExtensions": []
                            });
                        }}
                        selectFileToPreviewModal={this.state.selectFileToPreviewModal}
                        onPreview={this.previewDocument}
                        onDownload={this.downloadDocument}
                    />
                    : ""
                }
                {
                    this.state.signaturePadModal
                    ? <SignaturePad
                        schema={this.state.signaturePadSchema}
                        onClose={() => {
                            this.setState({
                                "signaturePadModal": false,
                                "signaturePadSchema": null
                            });
                        }}
                        onAccept={(data) => {
                            let name = (this.state.signaturePadSchema.label ? this.state.signaturePadSchema.label : this.state.signaturePadSchema.name)
                                .replace(/[^\w\s]/gi, '').replace(/\s/g, '_').toLowerCase()
                                + "_" + (new Date().getTime()) + ".png";
                            data = data.replace(
                                ";base64",
                                `;name=${encodeURIComponent(name)};base64`
                            );
                            this.handleSignatureChange(this.state.signaturePadSchema.name, data);
                            document.getElementById('root_' + this.state.signaturePadSchema.name).focus();
                        }}
                        signaturePadModal={this.state.signaturePadModal}
                    />
                    : ""
                }
                {
                    this.state.sampleIDModal
                    ? <SampleID
                        schema={this.state.sampleIDSchema}
                        onClose={() => {
                            this.setState({
                                "sampleIDModal": false,
                                "sampleIDSchema": null,
                                "sampleIDImagePath": null
                            });
                        }}
                        sampleIDModal={this.state.sampleIDModal}
                        imagePath={this.state.sampleIDImagePath}
                    />
                    : ""
                }
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.setState({"confirmModal": false})}
                    onYesClick={() => {
                        this.setState({"confirmModal": false});
                        this.handleSaveRegistry({'form_data': this.state.formData});
                    }}
                />
            </GridContainer>
        );

    }
}

InformationRegistry.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doClientLogout": (props) => dispatch(doClientLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(InformationRegistry));
