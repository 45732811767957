import React, { useState } from "react";

import TextField, {
  StandardTextFieldProps as TextFieldProps,
} from "@material-ui/core/TextField";

import { WidgetProps, utils } from "@rjsf/core";
import Icon from '@material-ui/core/Icon';

const { getDisplayLabel } = utils;

export type TextWidgetProps = WidgetProps & Pick<TextFieldProps, Exclude<keyof TextFieldProps, 'onBlur' | 'onFocus'>>;

const CustomPasswordTextWidget = ({
  id,
  placeholder,
  required,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  uiSchema,
  rawErrors = [],
  formContext,
  // registry, // pull out the registry so it doesn't end up in the textFieldProps
  ...textFieldProps
}: TextWidgetProps) => {
  const _onChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) =>
    onChange(value === "" ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }: React.FocusEvent<HTMLInputElement>) =>
    onBlur(id, value);
  const _onFocus = ({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) => onFocus(id, value);

  const displayLabel = getDisplayLabel(
    schema,
    uiSchema
    /* TODO: , rootSchema */
  );

  const [
    showPassword,
    setShowPassword
  ] = useState(false);

  return (
    <TextField
      fullWidth
      id={id}
      placeholder={placeholder}
      label={displayLabel ? label || schema.title : false}
      autoFocus={autofocus}
      required={required}
      disabled={disabled || readonly}
      type={showPassword ? 'string' : 'password'}
      value={value || value === 0 ? value : ""}
      error={rawErrors.length > 0}
      onChange={_onChange}
      onBlur={_onBlur}
      onFocus={_onFocus}
      {...(textFieldProps as TextFieldProps)}
      onWheel={(e) => e.currentTarget.blur()}
      InputProps={{
        endAdornment: <Icon className={"fas fa-eye" + (showPassword ? "" : "-slash") + " eye-watcher"} style={{"position": "absolute", "right": "0", "top": "-8px"}}
          onClick={(e) => {e.preventDefault(); setShowPassword(!showPassword);}}
          />
      }}
    />
  );
};

export default CustomPasswordTextWidget;