import Constants from "customs/config/Constants";

import {EventSourcePolyfill} from "event-source-polyfill";

const eventSource = (url, props) => {

    const token = props.auth.token ? props.auth.token : "";
    return new EventSourcePolyfill(
        Constants.API_URL + url,
        {
            "headers": {"Authorization": `Bearer ${token}`}
        }
    );

};

export default eventSource;
