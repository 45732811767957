import React from "react";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import DownloadGeneratedMatchReportsDialog from "customs/components/ScriptStep/DownloadGeneratedMatchReportsDialog";

class GenerateMatchReportComponent extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            downloadApplicationModal: false,
            downloadApplicationModalMsg: this.props.step.step,

            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": ""
        };
        this.handleGenerateMatchReportDialog = this.handleGenerateMatchReportDialog.bind(this);
        this.allPreviousStepsCompleted = this.allPreviousStepsCompleted.bind(this);
        this.handleDownloadMatchReportsDialog = this.handleDownloadMatchReportsDialog.bind(this);
    }

    handleDownloadMatchReportsDialog(show) {
        this.setState({downloadApplicationModal: show});
    }

    handleGenerateMatchReportDialog(show) {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to continue?`,
            "confirmModalTitle": "Generate " + this.props.step.step,
            "action": "generate_match_report"
        });
    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "generate_match_report") {
            this.props.handleUpdateStep(
                this.props.step.id, this.props.step.client_script_id,
                {'form_data': {'generate' : 'match_report'}},
                () => {}
            );
        }
    }

    allPreviousStepsCompleted() {
        let allCompleted = true;
        Object.keys(this.props.checklist.steps).forEach((key) => {
            try {
                if (this.props.checklist.steps[key].action_type === 'export_webdosh_import' || this.props.checklist.steps[key].action_type === 'generate_invoice') {
                } else if (this.props.checklist.steps[key].action_type !== "generate_match_report" && this.props.checklist.steps[key].status !== 1) {
                    allCompleted = false;
                }
            } catch(e) {}
        });
        return allCompleted;
    }

    render () {
        let generated_files = [];
        if (this.props.step.status === 1 && Object.keys(this.props.step.data).length > 0 && this.props.step.data[0] !== 0) {
            Object.keys(this.props.step.data).forEach((key) => {
                let file = this.props.client_files.find((file) => {
                    return file.id === this.props.step.data[key]['client_file_id'];
                });
                if (file) {
                    generated_files.push(file);
                }
            });
        }
        return <div>
            <Button
                disabled={this.props.readOnly || !this.allPreviousStepsCompleted()}
                color="success"
                onClick={() => this.handleGenerateMatchReportDialog(true)}
            >
                Generate Report
            </Button>
            {
                this.props.step.status === 1 && generated_files.length > 0
                ? <Button
                    disabled={!(this.props.index > 0 ? this.props.checklist.steps[this.props.index - 1].status === 1 : true)}
                    color="primary"
                    onClick={() => this.handleDownloadMatchReportsDialog(true)}
                >
                    Show
                </Button>
                : ""
            }
            <ConfirmDialog
                confirmModal={this.state.confirmModal}
                confirmModalMsg={this.state.confirmModalMsg}
                confirmModalTitle={this.state.confirmModalTitle}
                onClose={() => this.setState({"confirmModal": false})}
                onYesClick={() => this.handleConfirmClick()}
            />
            {
                this.state.downloadApplicationModal
                    ? <DownloadGeneratedMatchReportsDialog
                        generated_files={generated_files}
                        handleDownloadFile={this.props.handleDownloadFile}
                        downloadApplicationModal={this.state.downloadApplicationModal}
                        downloadApplicationModalMsg={this.state.downloadApplicationModalMsg}
                        onClose={() => this.handleDownloadMatchReportsDialog(false)}
                        readOnly={this.props.readOnly}
                    />
                    : ""
            }
        </div>;
    }
}

const GenerateMatchReport = withStyles(extendedFormsStyle)(GenerateMatchReportComponent);

export default GenerateMatchReport;
