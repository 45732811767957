import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import validations from "customs/helpers/validations";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import helpers from "customs/helpers/helpers";
import axios from "axios/axios";
import ConfirmDialog from "customs/components/ConfirmDialog";
import contactDefaults from "customs/components/Contacts/ContactDefaults";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import TagsInput from "react-tagsinput";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import axiosHelper from "axios/axiosHelper";
import AddressAutocomplete from "customs/components/ScriptStep/AddressAutocomplete";

class ContactDetailsWizard extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "contact_id": this.props.selected_contact.id
                ? this.props.selected_contact.id
                : null,
            "details": this.setupDetails(this.props.selected_contact),
            "emails": this.setupEmails(this.props.selected_contact),
            "phones": this.setupPhones(this.props.selected_contact),
            "addresses": [],
            // "notes": this.setupNotes(this.props.selected_contact),
            "websites": this.setupWebsites(this.props.selected_contact),
            "documents": this.setupDocuments(this.props.selected_contact),
            "is_view": this.props.is_view,
            "contact_details_title": this.props.contact_details_title,
            "show_more": false,
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "saveDetailsErrors": [],
            "calling_codes":
        this.props.countries !== null
            ? this.setCallingCodes(this.props.countries)
            : null
        };
        this.handleDownloadContactDocument = this.handleDownloadContactDocument.bind(this);
        this.handleSaveContactDetails = this.handleSaveContactDetails.bind(this);

    }

    setCallingCodes (countries) {
        return countries
            .filter((prop) => prop.country_code !== null)
            .map((prop) => prop.country_code)
            .sort((a, b) => a.geo_country_id.localeCompare(b.geo_country_id));
    }

    componentWillReceiveProps (nextProps) {
        if (this.props.countries === null && nextProps.countries !== null) {
            this.setState({
                "calling_codes": this.setCallingCodes(nextProps.countries)
            });
            helpers.hideLoading();
        }
    }

    componentDidUpdate () {
        if (this.props.countries === null) {
            helpers.showLoading();
        }
    }

    async componentDidMount () {
        this.setState({
            "addresses": await this.setupAddresses(this.props.selected_contact).then((res) => res)
        });
        if (this.props.countries === null) {
            helpers.showLoading();
        }
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

    addSubContactFields (elements, element, state_name) {
        if (element.hasOwnProperty("default")) {
            element.default = false;
        }
        elements.push(element);
        this.setState({[state_name]: elements});
    }

    handleConfirmClick () {
        if (this.state.action === "client_contact") {
            this.doHandleSaveContact();
        }
    }

    handleDownloadContactDocument = (
        event,
        client_id,
        contact_id,
        document_id,
        file_name
    ) => {
      this.props.handleDownloadContactDocument(
          event,
          client_id,
          contact_id,
          document_id,
          file_name
      );
  };

    handleSaveContactDetails = (event, client_id) => {
      if (this.validateFields()) {
          const contact_details = {
              "id": this.state.contact_id,
              client_id,
              "first_name": this.state.details.first_name.value,
              "last_name": this.state.details.last_name.value,
              "company": this.state.details.company.value,
              "position": this.state.details.position.value,
              "tags": JSON.stringify(this.state.details.tags.value),
              "emails": this.state.emails
                .map((prop, key) => ({
                      "id": prop.id,
                      "default": prop.default ? 1 : 0,
                      "email_address": prop.value
                  }))
                  .filter((email) => this.getLength(email.email_address) > 0),
              "phones": this.state.phones
                .map((prop, key) => ({
                      "id": prop.id,
                      "default": prop.default ? 1 : 0,
                      "service": prop.service,
                      "geo_country_id": prop.geo_country_id,
                      "phone_number": prop.value
                  }))
                  .filter((phone) => this.getLength(phone.service) > 0 &&
                    this.getLength(phone.geo_country_id) > 0 &&
                    this.getLength(phone.phone_number) > 0),
              "addresses": this.state.addresses
                .map((prop, key) => ({
                      "id": prop.id,
                      "default": prop.default ? 1 : 0,
                      "address": prop.address,
                      "city": prop.city,
                      "state": prop.state,
                      "geo_country_id": prop.geo_country_id,
                      "postcode": prop.postcode
                  }))
                  .filter((address) => this.getLength(address.address) > 0 &&
                    this.getLength(address.city) > 0 &&
                    this.getLength(address.state) > 0 &&
                    this.getLength(address.geo_country_id) > 0 &&
                    this.getLength(address.postcode) > 0),
              "websites": this.state.websites
                .map((prop, key) => ({"id": prop.id,
                      "website": prop.url}))
                    .filter((website) => this.getLength(website.website) > 0),
            //   "notes": this.state.notes
            //     .map((prop, key) => ({
            //           "id": prop.id,
            //           "author": prop.author,
            //           "subject": prop.subject,
            //           "note": prop.note
            //       }))
            //       .filter((note) => this.getLength(note.note) > 0),
              "documents": this.state.documents
                .map((prop, key) => ({"id": prop.id,
                      "filename": prop.filename,
                      "tags": prop.tags}))
                    .filter((document) => document.id !== null)
          };
          this.setState({
              "confirmModal": true,
              "confirmModalMsg": "Are you sure you want to save contact details?",
              "confirmModalTitle":
          `${contact_details.id === null ? "Create" : "Update"} Contact`,
              "action": "client_contact",
              contact_details
          });
      } else {
          this.props.handleSaveContactError(event);
      }
    };

    getLength (str) {
      if (str === null || str === undefined) {
          return 0;
      }
      return `${str}`.length;
    }

    validateFields () {
      let all_correct = true,
          // DETAILS
          {details} = this.state;
      if (this.getLength(details.first_name.value) === 0) {
          all_correct = false;
          details.first_name.valid = "error";
      }
      if (this.getLength(details.last_name.value) === 0) {
          all_correct = false;
          details.last_name.valid = "error";
      }
      this.setState({details});
      // DETAILS

      // EMAILS
      const emails = this.state.emails.map((prop, key) => {
          prop.valid =
            this.getLength(prop.value) === 0 ||
            validations.validateEmail(prop.value)
            ? ""
            : "error";
          if (prop.valid === "error") {
              all_correct = false;
          }
          return prop;
      });
      this.setState({emails});
      // EMAILS

      // PHONES
      const phones = this.state.phones.map((prop, key) => {
          if (this.getLength(prop.value) > 0) {
              prop.valid = "";
              prop.service_valid = "";
              prop.geo_valid = "";
              if (!validations.validateIfNumber(prop.value)) {
                  prop.valid = "error";
                  all_correct = false;
              } else {
                  if (this.getLength(prop.service) === 0) {
                      prop.service_valid = "error";
                      all_correct = false;
                  }
                  if (this.getLength(prop.geo_country_id) === 0) {
                      prop.geo_valid = "error";
                      all_correct = false;
                  }
              }
          }
          return prop;
      });
      this.setState({phones});
      // PHONES

      // ADDRESSES
      const addresses = this.state.addresses.map((prop, key) => {
          prop.address_valid = "";
          prop.city_valid = "";
          prop.state_valid = "";
          prop.geo_valid = "";
          prop.postcode_valid = "";
          if (
            this.getLength(prop.address) > 0 ||
            this.getLength(prop.city) > 0 ||
            this.getLength(prop.state) > 0 ||
            this.getLength(prop.postcode) > 0
          ) {
              if (this.getLength(prop.address) === 0) {
                  prop.address_valid = "error";
                  all_correct = false;
              }
              if (this.getLength(prop.city) === 0) {
                  prop.city_valid = "error";
                  all_correct = false;
              }
              if (this.getLength(prop.state) === 0) {
                  prop.state_valid = "error";
                  all_correct = false;
              }
              if (this.getLength(prop.geo_country_id) === 0) {
                  prop.geo_valid = "error";
                  all_correct = false;
              }
              if (this.getLength(prop.postcode) === 0) {
                  prop.postcode_valid = "error";
                  all_correct = false;
              }
          }
          return prop;
      });
      this.setState({addresses});
      // ADDRESSES

    //   // NOTES
    //   const notes = this.state.notes.map((prop, key) => {
    //       prop.author_valid = "";
    //       prop.subject_valid = "";
    //       prop.note_valid = "";
    //       if (this.getLength(prop.author) > 0 || this.getLength(prop.subject) > 0) {
    //           if (this.getLength(prop.note) === 0) {
    //               prop.note_valid = "error";
    //               all_correct = false;
    //           }
    //       }
    //       return prop;
    //   });
    //   this.setState({notes});
      // NOTES

      // WEBSITES
      const websites = this.state.websites.map((prop, key) => {
          prop.url_valid = this.getLength(prop.url) === 0 || validations.validateURL(prop.url)
            ? ""
            : "error";
          if (prop.url_valid === "error") {
              all_correct = false;
          }
          return prop;
      });
      this.setState({websites});
      // WEBSITES

      return all_correct;
    }

    doHandleSaveContact () {
      this.setState({"confirmModal": false,
          "action": "client_contact"});
      helpers.showLoading();
      this.setState({"btnDisabled": true});

      let fd = new FormData();
      Object.keys(this.state.contact_details).forEach((key) => {
          fd = helpers.createFormData(
              fd,
              this.state.contact_details[key],
              key
          );
      });
      let url = `/client/${this.state.contact_details.client_id}/contact`;
      if (this.state.contact_details.id !== null) {
          fd.append(
              "_method",
              "PUT"
          );
          url = `${url}/${this.state.contact_details.id}/all`;
      }

      const upload_documents = this.state.documents
        .map((prop, key) => {
              const old_tag = prop.tags ? JSON.parse(JSON.stringify(prop.tags)) : [];
              old_tag.sort();
              const new_tag = prop.tags_tmp
                  ? JSON.parse(JSON.stringify(prop.tags_tmp))
                  : [];
              new_tag.sort();
              return {
                  "id": prop.id,
                  "file": prop.file === null ? "" : prop.file,
                  "tags": old_tag === new_tag ? "" : prop.tags_tmp
              };
          })
          .filter((document) => document.file !== "" ||
          (document.tags !== "" && document.tags !== undefined));

      this.setState({"saveDetailsErrors": []});
      axios(this.props)
        .post(
              url,
              fd,
              {
                  "headers": {"Content-Type": "application/x-www-form-urlencoded"}
              }
          )
          .then((response) => this.processSaveContactDetails(
              response,
              upload_documents,
              this.state.contact_details.client_id,
              -1
          ))
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
    }

    processSaveContactDetails (results, upload_documents, client_id, idx) {
      if (upload_documents.length === 0 || idx >= upload_documents.length - 1) {
          this.props.handleSaveContactDetails(
              results,
              this.state.contact_details.id === null,
              this.state.saveDetailsErrors
          );
      } else {
          this.doUploadDocuments(
              upload_documents,
              client_id,
              results.data.contact.id,
              ++idx,
              results
          );
      }
    }

    doUploadDocuments (documents, client_id, contact_id, idx, prev_result) {
      let fd = new FormData(),
          url = `/client/${client_id}/contact/${contact_id}/document`;
      if (
        documents[idx].id &&
        documents[idx].id !== undefined &&
        documents[idx].id !== null
      ) {
          fd.append(
              "_method",
              "PUT"
          );
          fd.append(
              "id",
              documents[idx].id
          );
          url = `${url}/${documents[idx].id}`;
      }
      if (documents[idx].file !== "") {
          fd.append(
              "file",
              documents[idx].file
          );
      }
      if (documents[idx].tags !== "") {
          fd.append(
              "tags",
              JSON.stringify(documents[idx].tags)
          );
      }
      axios(this.props)
        .post(
              url,
              fd,
              {
                  "headers": {"Content-Type": "application/x-www-form-urlencoded"}
              }
          )
          .then((response) => this.processSaveContactDetails(
              response,
              documents,
              client_id,
              idx
          ))
          .catch((error) => this.processUploadErrorAxios(
              error,
              null,
              documents,
              client_id,
              contact_id,
              idx,
              prev_result
          ));
    }

    processUploadErrorAxios (
      error,
      prop,
      documents,
      client_id,
      contact_id,
      idx,
      prev_result
    ) {
      const default_err = helpers.getErrorMessage(error),
          saveDetailsErrors = default_err === false
            ? this.state.saveDetailsErrors
            : [
                ...this.state.saveDetailsErrors,
                default_err
            ];
      this.setState({saveDetailsErrors});
      if (documents.length === 0 || idx >= documents.length - 1) {
          this.props.handleSaveContactDetails(
              prev_result,
              this.state.contact_details.id === null,
              saveDetailsErrors
          );
      } else {
          this.doUploadDocuments(
              documents,
              client_id,
              contact_id,
              ++idx,
              prev_result
          );
      }
    }

    async handleChange (event, stateName, field, type, key) {
      let state_value = this.state[stateName];
      if (stateName === "details") {
          if (field === "tags") {
              state_value[field].value = event;
          } else {
              state_value[field].value = event.target.value;
              state_value[field].valid = "";
              if (
                  (field === "first_name" || field === "last_name") &&
                  this.getLength(event.target.value) === 0
              ) {
                  state_value[field].valid = "error";
              }
          }
      } else if (type === "file" && field === "tags") {
          state_value[key].tags_tmp = event;
      } else {
          if (field === "default") {
              if (event.target.checked) {
                  state_value.map((prop, key) => prop.default = false);
              }
              state_value[key][field] = event.target.checked;
          } else if (type === "file" && event.target.files[0]) {
              let attach = true;
              try {
                  if (Math.round(event.target.files[0].size / 1024) >= 4096) {
                      this.setState({
                          "btnDisabled": false,
                          "doneModal": false,
                          "doneModalMsg":
                            `${event.target.files[0].name
                            } is too big, please select a file that is less than 4mb`,
                          "doneModalBtn": "OK",
                          "tr_color": "danger"
                      });
                      this.showUsersNotification();
                      attach = false;
                  }
              } catch (e) {}
              if (attach) {
                  state_value[key][`${field}_tmp`] = event.target.files[0].name;
                  state_value[key].file = event.target.files[0];
              }
          } else if (stateName === "phones" && field === "value") {
              try {
                state_value[key][field] = event.target.value.replace(/[^\d]/g, "");
                //   state_value[key][field] =
                //     `${parseInt(
                //         event.target.value.replace(
                //             /^0+/,
                //             ""
                //         ).replace(
                //             /[^\d]/g,
                //             ""
                //         ),
                //         10
                //     )}`;
              } catch (e) {
                  state_value[key][field] = "";
              }
          } else {
            state_value[key][field] = event.hasOwnProperty('target') && event.target.hasOwnProperty('value') ? event.target.value : event;
          }

          if (stateName === "addresses" && field === "geo_country_id") {
              state_value[key].states = await this.getStates(
                event.hasOwnProperty('target') && event.target.hasOwnProperty('value') ? event.target.value : event
              ).then((res) => res);
          }

          state_value = this.doValidate(
              event,
              field,
              type,
              key,
              state_value
          );
      }
      this.setState({"stateName": state_value});
    }

    doValidate (event, field, type, key, state_value) {
      const valid_name =
      field === "value"
          ? "valid"
          : (field === "geo_country_id"
              ? "geo_valid"
              : `${field}_valid`);
      switch (type) {
        case "email":
            state_value[key][valid_name] = validations.validateEmail(event.target.value)
                ? ""
                : "error";
            break;
        case "number":
            if (this.getLength(event.target.value) > 0) {
                state_value[key][valid_name] = validations.validateIfNumber(event.target.value)
                    ? ""
                    : "error";
            }
            break;
        case "url":
            if (this.getLength(event.target.value) > 0) {
                state_value[key][valid_name] = validations.validateURL(event.target.value)
                    ? ""
                    : "error";
            }
            break;
        default:
            break;
      }
      return state_value;
    }

    setupDetails (contact) {
      try {
          if (Object.keys(contact).length > 0) {
              return {
                  "id": contact.id,
                  "first_name": {"value": contact.first_name,
                      "valid": ""},
                  "last_name": {"value": contact.last_name,
                      "valid": ""},
                  "company": {"value": contact.company ? contact.company : "",
                      "valid": ""},
                  "position": {
                      "value": contact.position ? contact.position : "",
                      "valid": ""
                  },
                  "tags": {
                      "value": contact.tags && this.getLength(contact.tags) > 0
                        ? JSON.parse(contact.tags)
                        : [],
                      "valid": ""
                  }
              };
          }
      } catch (e) {}
      return contactDefaults.details();
    }

    createDetailsUI () {
      return (
          <GridContainer
              key="details_grid"
              style={{"borderTop": "#9c27b0 solid 1px"}}
          >
              <GridItem md={6}>
                  <CustomInput
                      error={this.state.details.first_name.valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          "onChange": (event) => this.handleChange(
                              event,
                              "details",
                              "first_name",
                              "text"
                          ),
                          "value": this.state.details.first_name.value,
                          "name": "first_name",
                          "disabled": this.state.is_view
                      }}
                      labelText="First Name"
                      success={this.state.details.first_name.valid === "success"}
                  />
              </GridItem>
              <GridItem md={6}>
                  <CustomInput
                      error={this.state.details.last_name.valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          "onChange": (event) => this.handleChange(
                              event,
                              "details",
                              "last_name",
                              "text"
                          ),
                          "value": this.state.details.last_name.value,
                          "name": "last_name",
                          "disabled": this.state.is_view
                      }}
                      labelText="Last Name"
                      success={this.state.details.last_name.valid === "success"}
                  />
              </GridItem>
              {this.getLength(this.state.details.company.value) > 0 || !this.state.is_view
                  ? <GridItem md={6}>
                      <CustomInput
                          error={this.state.details.company.valid === "error"}
                          formControlProps={{"fullWidth": true}}
                          inputProps={{
                              "onChange": (event) => this.handleChange(
                                  event,
                                  "details",
                                  "company",
                                  "text"
                              ),
                              "value": this.state.details.company.value,
                              "name": "company",
                              "disabled": this.state.is_view
                          }}
                          labelText="Company"
                          success={this.state.details.company.valid === "success"}
                      />
                  </GridItem>
                  : ""}
              {this.getLength(this.state.details.position.value) > 0 || !this.state.is_view
                  ? <GridItem md={6}>
                      <CustomInput
                          error={this.state.details.position.valid === "error"}
                          formControlProps={{"fullWidth": true}}
                          inputProps={{
                              "onChange": (event) => this.handleChange(
                                  event,
                                  "details",
                                  "position",
                                  "text"
                              ),
                              "value": this.state.details.position.value,
                              "name": "position",
                              "disabled": this.state.is_view
                          }}
                          labelText="Position"
                          success={this.state.details.position.valid === "success"}
                      />
                  </GridItem>
                  : ""}
              {this.state.details.tags.value.length > 0 || !this.state.is_view
                  ? <GridItem
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <label className="customRadioTopLabel">
                          Tags
                      </label>
                      <TagsInput
                          disabled={this.state.is_view}
                          onChange={(tags) => this.handleChange(
                              tags,
                              "details",
                              "tags",
                              "text"
                          )}
                          onlyUnique
                          tagProps={{"className": "react-tagsinput-tag primary"}}
                          value={this.state.details.tags.value}
                      />
                  </GridItem>
                  : ""}
          </GridContainer>
      );
    }

    setupEmails (contact) {
      try {

          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.emails).length > 0
          ) {
              return Object.keys(contact.emails).map((prop, key) => ({
                  "id": contact.emails[prop].id,
                  "default": contact.emails[prop].default === 1,
                  "value": contact.emails[prop].email_address,
                  "valid": ""
              }));
          }
      } catch (e) {}
      return contactDefaults.email();
    }

    createEmailsUI () {
      const container = this.state.emails
        .map((prop, key) => (!this.state.is_view || this.getLength(prop.value) > 0
            ? this.generateEmailGrid(prop, key)
            : ""))
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_emails_grid"
              style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add email'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={() => {

                              this.addSubContactFields(
                                  this.state.emails,
                                  contactDefaults.email()[0],
                                  "emails"
                              );

                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_email_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generateEmailGrid (prop, key) {
      const {classes} = this.props,
          grid =
              (<GridContainer key={`emails_grid_${key}`}>
              <GridItem md={1}>
                  <label
                      htmlFor={`emails_radio_${key}`}
                      className="customRadioTopLabel"
                  >
                        Default
                  </label>
                  <FormControlLabel
                      disabled={this.state.is_view}
                      control={
                          <Checkbox
                              checked={prop.default}
                              onChange={(event) => this.handleChange(
                                  event,
                                  "emails",
                                  "default",
                                  "checkbox",
                                  key
                              )}
                              value={key}
                              name={`emails_radio_${key}`}
                              aria-label={key}
                              icon={<FiberManualRecord className={classes.radioUnchecked} />}
                              checkedIcon={
                                  <FiberManualRecord className={classes.radioChecked} />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                  disabled: classes.disabledCheckboxAndRadio
                              }}
                          />
                      }
                      classes={{"label": classes.label,
                          root: classes.labelRoot}}
                  />
              </GridItem>
              <GridItem md={11}>
                  <CustomInput
                      success={prop.valid === "success"}
                      error={prop.valid === "error"}
                      labelText="Email"
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          onChange: (event) => this.handleChange(
                              event,
                              "emails",
                              "value",
                              "email",
                              key
                          ),
                          value: prop.value,
                          name: `email_${key}`,
                          disabled: this.state.is_view,
                          type: "email"
                      }}
                  />
              </GridItem>
          </GridContainer>);
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_emails_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove email'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={() => {

                          let {emails} = this.state;
                          delete emails[key];
                          emails = emails.filter((el) => el != null);
                          if (emails.length === 0 || Object.keys(emails).length === 0) {

                              emails = contactDefaults.email();

                          }
                          this.setState({emails});

                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_email_delete${key}`}
                      />
                  </Button>
              </GridItem>
          </GridContainer>;
    }

    setupPhones (contact) {
      try {
          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.phones).length > 0
          ) {
              return Object.keys(contact.phones).map((prop, key) => ({
                  "id": contact.phones[prop].id,
                  "default": contact.phones[prop].default === 1,
                  "service": contact.phones[prop].service,
                  "geo_country_id": contact.phones[prop].geo_country_id,
                  "value": contact.phones[prop].phone_number,
                  "valid": "",
                  "service_valid": "",
                  "geo_valid": ""
              }));
          }
      } catch (e) {}
      return contactDefaults.phone();
    }

    createPhonesUI () {
      const container = this.state.phones
        .map((prop, key) => (!this.state.is_view || this.getLength(prop.value) > 0
            ? this.generatePhoneGrid(prop, key)
            : ""))
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_phones_grid"
              style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add phone'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={() => {

                              this.addSubContactFields(
                                  this.state.phones,
                                  contactDefaults.phone()[0],
                                  "phones"
                              );

                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_phone_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generatePhoneGrid (prop, key) {
      const {classes} = this.props,
          grid =
              (<GridContainer key={`phones_grid_${key}`}>
              <GridItem
                xs={3}
                md={1}
              >
                  <label
                      htmlFor={`phones_radio_${key}`}
                      className="customRadioTopLabel"
                  >
                    Default
                  </label>
                  <FormControlLabel
                      disabled={this.state.is_view}
                      control={
                          <Checkbox
                              checked={prop.default}
                              onChange={(event) => this.handleChange(
                                  event,
                                  "phones",
                                  "default",
                                  "checkbox",
                                  key
                              )}
                              value={key}
                              name={`phones_radio_${key}`}
                              aria-label={key}
                              icon={<FiberManualRecord className={classes.radioUnchecked} />}
                              checkedIcon={
                                  <FiberManualRecord className={classes.radioChecked} />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                  disabled: classes.disabledCheckboxAndRadio
                              }}
                          />
                      }
                      classes={{"label": classes.label,
                          root: classes.labelRoot}}
                  />
              </GridItem>
              <GridItem
                xs={3}
                md={2}
              >
                  <FormControl
                      disabled={this.state.is_view}
                      fullWidth
                      className={classes.selectFormControl}
                  >
                      <InputLabel
                          success={prop.service_valid === "success" ? "success" : ""}
                          error={prop.service_valid === "error"}
                          htmlFor="contact_services"
                          className={classes.selectLabel}
                      >
                        Service
                      </InputLabel>
                      <Select
                          value={prop.service}
                          onChange={(event) => this.handleChange(
                              event,
                              "phones",
                              "service",
                              "select",
                              key
                          )}
                          MenuProps={{"className": classes.selectMenu}}
                          classes={{"select": classes.select}}
                          inputProps={{
                              name: `services_${key}`,
                              id: `services_${key}`,
                              disabled: this.state.is_view
                          }}
                      >
                          <MenuItem
                              key={`service_phone${key}`}
                              classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value="CELLULAR"
                          >
                              <i
                                key={`i_service_phone${  key}`}
                                className="fa fa-mobile-alt"
                              />
                          </MenuItem>
                          <MenuItem
                              key={`service_landline${key}`}
                              classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value="LANDLINE"
                          >
                              <i
                                  key={`i_service_landline${key}`}
                                  className="fa fa-phone-alt"
                              />
                          </MenuItem>
                      </Select>
                  </FormControl>
              </GridItem>
              <GridItem
                xs={6}
                md={3}
              >
                  <FormControl
                      disabled={this.state.is_view}
                      fullWidth
                      className={classes.selectFormControl}
                  >
                      <InputLabel
                          success={prop.geo_valid === "success" ? "success" : ""}
                          error={prop.geo_valid === "error"}
                          htmlFor="contact_geo"
                          className={classes.selectLabel}
                      >
                        Code
                      </InputLabel>
                      <Select
                          value={prop.geo_country_id}
                          onChange={(event) => this.handleChange(
                              event,
                              "phones",
                              "geo_country_id",
                              "select",
                              key
                          )}
                          MenuProps={{"className": classes.selectMenu}}
                          classes={{"select": classes.select}}
                          inputProps={{
                              name: `services_${key}`,
                              id: `services_${key}`,
                              disabled: this.state.is_view
                          }}
                      >
                          {this.state.calling_codes !== null
                              ? this.state.calling_codes.map((prop, key) => 
                      <MenuItem
                        key={"country_" + key}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={prop.geo_country_id}
                      >
                        {prop.geo_country_id + " (" + prop.calling_code + ")"}
                      </MenuItem>
                    )
                              : ""}
                      </Select>
                  </FormControl>
              </GridItem>
              <GridItem
                xs={12}
                md={6}
              >
                  <CustomInput
                      success={prop.valid === "success"}
                      error={prop.valid === "error"}
                      labelText="Phone Number"
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          onChange: (event) => this.handleChange(
                              event,
                              "phones",
                              "value",
                              "tel",
                              key
                          ),
                          value: prop.value,
                          name: `phone_${key}`,
                          disabled: this.state.is_view,
                          type: "tel"
                      }}
                  />
              </GridItem>
          </GridContainer>);
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_phones_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove phone'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={() => {

                          const {phones} = this.state;
                          delete phones[key];
                          this.setState({
                              "phones":
                  Object.keys(phones).length > 0
                      ? phones
                      : contactDefaults.phone()
                          });

                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_phone_delete${key}`}
                      />
                  </Button>
              </GridItem>
          </GridContainer>;
    }

    async setupAddresses (contact) {
      try {
          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.addresses).length > 0
          ) {
              return Object.keys(contact.addresses).map((prop, key) => {
                  this.processCountryStates(
                      contact.addresses[prop].states,
                      contact.addresses[prop].geo_country_id
                  );
                  return {
                      "id": contact.addresses[prop].id,
                      "default": contact.addresses[prop].default === 1,
                      "address": contact.addresses[prop].address,
                      "city": contact.addresses[prop].city,
                      "state": contact.addresses[prop].state,
                      "states": contact.addresses[prop].states,
                      "geo_country_id": contact.addresses[prop].geo_country_id,
                      "postcode": contact.addresses[prop].postcode,
                      "address_valid": "",
                      "city_valid": "",
                      "state_valid": "",
                      "geo_valid": "",
                      "postcode_valid": ""
                  };
              });
          }
      } catch (e) {}
      return [await this.setAddress(contactDefaults.address()[0]).then((res) => res)];
    }

    async setAddress (address) {
      address.states = await this.getStates(address.geo_country_id).then((res) => res);
      return address;
    }

    async getStates (geo_country_id) {
      if (this.props.countries === null) {
          return null;
      }
      const country = this.props.countries.filter((country) => country.geo_country_id === geo_country_id);
      if (country.length > 0 && country[0].states) {
          return this.setupStatesList(country[0].states);
      }

      helpers.showLoading();
      await axios(this.props)
        .get(`/countries/${geo_country_id}`)
        .then((response) => this.processCountryStates(
              response.data.country_states,
              geo_country_id
          ))
          .catch((error) => {
              this.processErrorAxios(
                  error,
                  null
              );
              return null;
          });
      return await this.getStates(geo_country_id).then((res) => res);
    }

    setupStatesList (states) {
      return states.map((prop, key) => {
          const state_id = prop.geo_region_code.split("-");
          prop.geo_region_code = state_id.length > 1 ? state_id[1] : state_id[0];
          return prop;
      });
    }

    processCountryStates (states, geo_country_id) {
      this.props.countries.map((country, key) => {
          if (country.geo_country_id === geo_country_id) {
              country.states = this.setupStatesList(states);
          }
          return country;
      });
      helpers.hideLoading();
    }

    createAddressesUI () {
      const container = this.state.addresses
        .map((prop, key) => (!this.state.is_view && this.state.show_more) ||
          (this.getLength(prop.address) > 0 ||
            this.getLength(prop.city) > 0 ||
            this.getLength(prop.postcode) > 0 ||
            this.getLength(prop.state) > 0 ||
            (!this.state.is_view && this.state.addresses.length > 1))
              ? this.generateAddressesGrid(prop, key)
              : "")
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_addresses_grid"
              style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add address'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={async () => {

                              this.addSubContactFields(
                                  this.state.addresses,
                                  await this.setAddress(contactDefaults.address()[0]).then((res) => res),
                                  "addresses"
                              );

                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_address_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generateAddressesGrid (prop, key) {
      const {classes} = this.props,
          grid =
              (<GridContainer key={`addresses_grid_${key}`}>
              <GridItem
                xs={3}
                md={1}
              >
                  <label
                      htmlFor={`addresses_radio_${key}`}
                      className="customRadioTopLabel"
                  >
                    Default
                  </label>
                  <FormControlLabel
                      disabled={this.state.is_view}
                      control={
                          <Checkbox
                              checked={prop.default}
                              onChange={(event) => this.handleChange(
                                  event,
                                  "addresses",
                                  "default",
                                  "checkbox",
                                  key
                              )}
                              value={key}
                              name={`addresses_radio_${key}`}
                              aria-label={key}
                              icon={<FiberManualRecord className={classes.radioUnchecked} />}
                              checkedIcon={
                                  <FiberManualRecord className={classes.radioChecked} />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                  disabled: classes.disabledCheckboxAndRadio
                              }}
                          />
                      }
                      classes={{"label": classes.label,
                          root: classes.labelRoot}}
                  />
              </GridItem>
              <GridItem
                xs={9}
                md={7}
              >
                <AddressAutocomplete
                    style={{"paddingTop": "11px"}}
                    {...this.props}
                    title={"Address"}
                    initValue={prop.address === null || prop.address === undefined ? "" : prop.address}
                    auth={this.props.auth}
                    key={`address_${key}`}
                    disabled={this.state.is_view}
                    onBlur={(key, value) => {}}
                    onChange={(value) => {
                        this.handleChange(
                            value,
                            "addresses",
                            "address",
                            "text",
                            key
                        )
                    }}
                    onChangeSelect={(selected) => {
                        if (selected !== null && selected.place_id.length > 0) {
                            try {
                                this.fillFromAddress(selected, key);
                            } catch(e) {}
                        }
                    }}
                />
              </GridItem>
              <GridItem
                xs={12}
                md={4}
              >
                  <CustomInput
                      success={prop.city_valid === "success"}
                      error={prop.city_valid === "error"}
                      labelText="City"
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          onChange: (event) => this.handleChange(
                              event,
                              "addresses",
                              "city",
                              "text",
                              key
                          ),
                          value: prop.city,
                          name: `city_${key}`,
                          disabled: this.state.is_view
                      }}
                  />
              </GridItem>
              <GridItem
                xs={12}
                md={4}
              >
                  <FormControl
                      disabled={this.state.is_view}
                      fullWidth
                      className={classes.selectFormControl}
                  >
                      <InputLabel
                          success={prop.geo_valid === "success" ? "success" : ""}
                          error={prop.geo_valid === "error"}
                          htmlFor="contact_countries"
                          className={classes.selectLabel}
                      >
                        Country
                      </InputLabel>
                      <Select
                          value={prop.geo_country_id}
                          onChange={(event) => this.handleChange(
                              event,
                              "addresses",
                              "geo_country_id",
                              "select",
                              key
                          )}
                          MenuProps={{"className": classes.selectMenu}}
                          classes={{"select": classes.select}}
                          inputProps={{
                              name: `states_${key}`,
                              id: `states_${key}`,
                              disabled: this.state.is_view
                          }}
                      >
                          {this.props.countries !== null
                              ? this.props.countries.map((prop, key) => 
                                <MenuItem
                                    key={"country_" + key}
                                    classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={prop.geo_country_id}
                                >
                                    {prop.name}
                                </MenuItem>
                                )
                              : ""}
                      </Select>
                  </FormControl>
              </GridItem>
              <GridItem
                xs={12}
                md={5}
              >
                  <FormControl
                      disabled={this.state.is_view}
                      fullWidth
                      className={classes.selectFormControl}
                  >
                      <InputLabel
                          success={prop.state_valid === "success" ? "success" : ""}
                          error={prop.state_valid === "error"}
                          htmlFor="contact_states"
                          className={classes.selectLabel}
                      >
                        State
                      </InputLabel>
                      <Select
                          value={
                              prop.states == null
                                  ? ""
                                  : prop.states.filter((state) => state.geo_region_code === prop.state).length > 0
                                      ? prop.state
                                      : ""
                          }
                          onChange={(event) => this.handleChange(
                              event,
                              "addresses",
                              "state",
                              "select",
                              key
                          )}
                          MenuProps={{"className": classes.selectMenu}}
                          classes={{"select": classes.select}}
                          inputProps={{
                              name: `states_${key}`,
                              id: `states_${key}`,
                              disabled: this.state.is_view
                          }}
                      >
                          {prop.states !== null && prop.states !== undefined
                              ? prop.states.map((prop, key) => 
                                <MenuItem
                                    key={"country_" + key}
                                    classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={prop.geo_region_code}
                                >
                                    {prop.name
                                    .toLowerCase()
                                    .replace(/(?:^|\s)\S/g, function(a) {
                                        return a.toUpperCase();
                                    })}
                                </MenuItem>
                                )
                              : ""}
                      </Select>
                  </FormControl>
              </GridItem>
              <GridItem
                xs={12}
                md={3}
              >
                  <CustomInput
                      success={prop.postcode_valid === "success"}
                      error={prop.postcode_valid === "error"}
                      labelText="Postcode"
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          onChange: (event) => this.handleChange(
                              event,
                              "addresses",
                              "postcode",
                              "text",
                              key
                          ),
                          value: prop.postcode,
                          name: `postcode_${key}`,
                          disabled: this.state.is_view
                      }}
                  />
              </GridItem>
          </GridContainer>);
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_address_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove address'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={async () => {

                          const {addresses} = this.state;
                          delete addresses[key];
                          this.setState({
                              "addresses":
                  Object.keys(addresses).length > 0
                      ? addresses
                      : [await this.setAddress(contactDefaults.address()[0]).then((res) => res)]
                          });

                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_address_delete${key}`}
                      />
                  </Button>
              </GridItem>
          </GridContainer>;
    }

    fillFromAddress(address, key) {
        try {
            helpers.showLoading();
            axios(this.props)
                .post('/address/details', {'id': address.place_id})
                .then((response) => {
                    try {
                        this.handleChange(response.data.result.address, "addresses", "address", "text", key);
                    } catch(e) {}
                    try {
                        this.handleChange(response.data.result.city,"addresses", "city", "text", key);
                    } catch(e) {}
                    try {
                        this.handleChange(response.data.result.country_code,"addresses", "geo_country_id", "text", key);
                    } catch(e) {}
                    try {
                        this.handleChange(response.data.result.state,"addresses", "state", "select", key);
                    } catch(e) {}
                    try {
                        this.handleChange(response.data.result.post_code,"addresses", "postcode", "text", key);
                    } catch(e) {}

                    helpers.hideLoading();
                })
                .catch((error) => helpers.hideLoading());
        } catch(e) {}
    }

    // setupNotes (contact) {
    //   try {
    //       if (
    //         Object.keys(contact).length > 0 &&
    //         Object.keys(contact.notes).length > 0
    //       ) {
    //           return Object.keys(contact.notes).map((prop, key) => ({
    //               "id": contact.notes[prop].id,
    //               "author": contact.notes[prop].author,
    //               "subject": contact.notes[prop].subject,
    //               "note": contact.notes[prop].note,
    //               "author_valid": "",
    //               "subject_valid": "",
    //               "note_valid": ""
    //           }));
    //       }
    //   } catch (e) {}
    //   return contactDefaults.note();
    // }

    // createNotesUI () {
    //   const container = this.state.notes
    //     .map((prop, key) => (!this.state.is_view && this.state.show_more) ||
    //       (this.getLength(prop.author) > 0 ||
    //         this.getLength(prop.subject) > 0 ||
    //         this.getLength(prop.note) > 0) ||
    //       (!this.state.is_view && this.state.notes.length > 1)
    //           ? this.generateNotesGrid(prop, key)
    //           : "")
    //       .filter((item) => Object.keys(item).length > 0);

    //   return Object.keys(container).length > 0
    //       ? <GridContainer
    //           key="main_notes_grid"
    //           style={{"borderTop": "#9c27b0 solid 1px"}}
    //         >
    //           <GridItem md={12}>
    //               {container}
    //           </GridItem>
    //           {this.state.is_view
    //               ? ""
    //               : <GridItem
    //                   className="div-pad-r-7"
    //                   md={12}
    //               >
    //                   <Button
    //                       title={'Note address'}
    //                       className="small-button div-marg-tb-0 div-float-right"
    //                       color="success"
    //                       justIcon
    //                       onClick={() => {

    //                           this.addSubContactFields(
    //                               this.state.notes,
    //                               contactDefaults.note()[0],
    //                               "notes"
    //                           );

    //                       }}
    //                       simple
    //                   >
    //                       <i
    //                           className="fa fa-plus fa-sm"
    //                           key="i_notes_add"
    //                       />
    //                   </Button>
    //                 </GridItem>}
    //       </GridContainer>
    //       : container;
    // }

    // generateNotesGrid (prop, key) {
    //   const grid =
    //       <GridContainer key={`notes_grid_${  key}`}>
    //       <GridItem xs={8}
    //           md={6}>
    //           <CustomInput
    //               success={prop.author_valid === "success"}
    //               error={prop.author_valid === "error"}
    //               labelText="Author"
    //               formControlProps={{fullWidth: true}}
    //               inputProps={{
    //                   onChange: (event) => this.handleChange(event,
    //                     "notes",
    //                     "author",
    //                     "text",
    //                     key),
    //                   value: prop.author,
    //                   name: `author_${  key}`,
    //                   disabled: this.state.is_view
    //               }}
    //           />
    //       </GridItem>
    //       <GridItem xs={8}
    //           md={6}>
    //           <CustomInput
    //               success={prop.subject_valid === "success"}
    //               error={prop.subject_valid === "error"}
    //               labelText="Subject"
    //               formControlProps={{fullWidth: true}}
    //               inputProps={{
    //                   onChange: (event) => this.handleChange(event,
    //                     "notes",
    //                     "subject",
    //                     "text",
    //                     key),
    //                   value: prop.subject,
    //                   name: `subject_${  key}`,
    //                   disabled: this.state.is_view
    //               }}
    //           />
    //       </GridItem>
    //       <GridItem xs={8}
    //           md={12}>
    //           <CustomInput
    //               success={prop.note_valid === "success"}
    //               error={prop.note_valid === "error"}
    //               labelText="Note"
    //               formControlProps={{fullWidth: true}}
    //               inputProps={{
    //                   onChange: (event) => this.handleChange(event,
    //                     "notes",
    //                     "note",
    //                     "text",
    //                     key),
    //                   value: prop.note,
    //                   name: `note_${  key}`,
    //                   disabled: this.state.is_view
    //               }}
    //           />
    //       </GridItem>
    //   </GridContainer>;
    //   return this.state.is_view
    //       ? grid
    //       : <GridContainer key={`container_notes_grid_${key}`}>
    //           <GridItem md={11}>
    //               {grid}
    //           </GridItem>
    //           <GridItem
    //               className="div-pad-lr-0"
    //               md={1}
    //           >
    //               <Button
    //                   title={'Remove note'}
    //                   className="small-button"
    //                   color={prop.id === null ? "warning" : "danger"}
    //                   justIcon
    //                   onClick={() => {

    //                       const {notes} = this.state;
    //                       delete notes[key];
    //                       this.setState({
    //                           "notes":
    //               Object.keys(notes).length > 0 ? notes : contactDefaults.note()
    //                       });

    //                   }}
    //                   simple
    //               >
    //                   <i
    //                       className={
    //                           prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
    //                       }
    //                       key={`i_note_delete${key}`}
    //                   />
    //               </Button>
    //           </GridItem>
    //       </GridContainer>;
    // }

    setupWebsites (contact) {
      try {
          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.websites).length > 0
          ) {
              return Object.keys(contact.websites).map((prop, key) => ({
                  "id": contact.websites[prop].id,
                  "url": contact.websites[prop].url,
                  "url_valid": ""
              }));
          }
      } catch (e) {}
      return contactDefaults.website();
    }

    createWebsitesUI () {
      const container = this.state.websites
        .map((prop, key) => (!this.state.is_view && this.state.show_more) ||
          this.getLength(prop.url) > 0 ||
          (!this.state.is_view && this.state.websites.length > 1)
              ? this.generateWebsitesGrid(prop, key)
              : "")
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_websites_grid"
              style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add url'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={() => {

                              this.addSubContactFields(
                                  this.state.websites,
                                  contactDefaults.website()[0],
                                  "websites"
                              );

                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_websites_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generateWebsitesGrid (prop, key) {
      const grid =
          <GridContainer key={`websites_grid_${  key}`}>
          <GridItem xs={8}
              md={12}>
              <CustomInput
                  success={prop.url_valid === "success"}
                  error={prop.url_valid === "error"}
                  labelText="URL"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                      onChange: (event) => this.handleChange(event,
                        "websites",
                        "url",
                        "url",
                        key),
                      value: prop.url,
                      name: `url_${  key}`,
                      disabled: this.state.is_view
                  }}
              />
          </GridItem>
      </GridContainer>;
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_websites_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove url'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={() => {

                          const {websites} = this.state;
                          delete websites[key];
                          this.setState({
                              "websites":
                  Object.keys(websites).length > 0
                      ? websites
                      : contactDefaults.website()
                          });

                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_website_delete${key}`}
                      />
                  </Button>
              </GridItem>
          </GridContainer>;
    }

    setupDocuments (contact) {
      try {
          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.documents).length > 0
          ) {
              return Object.keys(contact.documents).map((prop, key) => ({
                  "id": contact.documents[prop].id,
                  "filename": contact.documents[prop].filename,
                  "storage_filename": contact.documents[prop].storage_filename,
                  "filename_valid": "",
                  "filename_tmp": "",
                  "file": null,
                  "tags": contact.documents[prop].tags,
                  "tags_tmp": contact.documents[prop].tags
              }));
          }
      } catch (e) {}
      return contactDefaults.document();
    }

    createDocumentsUI () {
      const container = this.state.documents
        .map((prop, key) => (!this.state.is_view && this.state.show_more) ||
          this.getLength(prop.filename) > 0 ||
          (!this.state.is_view && this.state.documents.length > 1)
              ? this.generateDocumentsGrid(prop, key)
              : "")
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_documents_grid"
              style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add file'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={() => {
                              this.addSubContactFields(
                                  this.state.documents,
                                  contactDefaults.document()[0],
                                  "documents"
                              );
                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_document_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generateDocumentsGrid (prop, key) {
      const grid =
          <GridContainer
          key={`documents_grid_${  key}`}
          style={{borderBottom: "1px solid #ded9d9"}}
      >
          <GridItem>
              {prop.id !== null
                ? <div>
                    {prop.filename}
                    {this.props.readOnly ? "" : <Button
                        title={'Download file'}
                        justIcon
                        color="facebook"
                        simple
                        onClick={event =>
                        this.handleDownloadContactDocument(
                            event,
                            this.props.client_id,
                            this.state.contact_id,
                            prop.id,
                            prop.filename
                        )
                        }
                    >
                        <i key={"i_document_" + key} className="fa fa-download fa-sm" />
                    </Button>}
                    </div>
                : ""
              }
              {this.state.is_view
                ? ""
                : <div
                    style={
                        prop.id === null
                        ? { marginTop: "20px" }
                        : { paddingBottom: "0px" }
                    }
                    >
                    <div className={"upload-btn-wrapper"} style={{ width: "100%" }}>
                        <button className={"btn"} style={{ marginRight: "10px" }}>
                        Choose a{prop.id === null ? "" : " replacement"} file
                        </button>
                        <input
                        type="file"
                        name="newAvatarImage"
                        onChange={event =>
                            this.handleChange(
                            event,
                            "documents",
                            "filename",
                            "file",
                            key
                            )
                        }
                        accept="*"
                        value=""
                        />
                        <label htmlFor={"document_" + key} style={{ color: "#495057" }}>
                        <b style={{ color: prop.id === null ? "#4caf50" : "red" }}>
                            {this.getLength(prop.filename_tmp) > 0
                            ? prop.id === null
                                ? "New "
                                : "Update "
                            : ""}
                        </b>
                        {prop.filename_tmp}
                        </label>
                    </div>
                  </div>
              }
              <div>
                  <span
                      className="customRadioTopLabel"
                      style={{paddingTop: "0px",
                        display: "inline"}}
                  >
                    Tags:{" "}
                  </span>
                  <TagsInput
                      value={prop.tags_tmp ? prop.tags_tmp : []}
                      onChange={(tags) => this.handleChange(tags,
                        "documents",
                        "tags",
                        "file",
                        key)}
                      tagProps={{className: "react-tagsinput-tag primary"}}
                      onlyUnique
                      disabled={this.state.is_view}
                  />
              </div>
          </GridItem>
      </GridContainer>;
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_documents_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove file'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={() => {
                          const documents = this.state.documents.filter((document) => document.id !== prop.id);
                          this.setState({
                              "documents": documents.length > 0 ? documents : contactDefaults.document()
                          });
                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_document_delete${key}`}
                      />
                  </Button>
                  {prop.id !== null && this.getLength(prop.filename_tmp) > 0
                      ? <Button
                          title={'Restore previous file'}
                          className="small-button div-marg-tb-0"
                          color="warning"
                          justIcon
                          onClick={() => {

                              const {documents} = this.state;
                              documents[key].filename_tmp = "";
                              documents[key].file = null;
                              this.setState({documents});

                          }}
                          simple
                      >
                          <i
                              className="fa fa-undo fa-sm"
                              key={`i_document_undo${key}`}
                          />
                        </Button>
                      : ""}
              </GridItem>
          </GridContainer>;
    }

    processErrorAxios (error, prop) {
      axiosHelper.processError(
          this.isUnmounted,
          prop,
          error,
          (state) => {
              this.setState(state);
          },
          () => {
              this.showUsersNotification();
          }
      );
    }

    hideNotification;
    showUsersNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
    }

    setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
    }

    handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false,
          "doneModalMsg": ""});
    }

    render () {
      const {classes} = this.props;
      return (
          <Dialog
              aria-describedby="avatar-update-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{"root": `${classes.center} ${classes.modalRoot}`}}
              keepMounted
              onClose={() => this.props.onClose("contact_details_wizard")}
              open={this.props.contact_details_wizard}
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  {this.state.contact_details_title}
              </DialogTitle>
              <style>
                  {".div-pad-lr-0 {padding-left: 0px !important; padding-right: 0px !important;}" +
                    ".div-marg-tb-0 {margin-top: 0px !important; margin-bottom: 0px !important;}" +
                    ".div-pad-r-7 {padding-right: 7px !important;}" +
                    ".div-float-right {float: right}"}
              </style>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="avatar-update-modal-slide-description"
                  style={{"maxWidth": "600px",
                      "maxHeight": "600px"}}
              >
                  {this.createDetailsUI()}
                  {this.createEmailsUI()}
                  {this.createPhonesUI()}
                  {this.createAddressesUI()}
                  {/* {this.createNotesUI()} */}
                  {this.createWebsitesUI()}
                  {this.createDocumentsUI()}
              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                  {!this.state.is_view
                      ? <Button
                          className={classes.modalSmallFooterFirstButton}
                          color="transparent"
                          onClick={() => this.setState({"show_more": !this.state.show_more})}
                        >
                          Show
                          {" "}
                          {this.state.show_more ? "less" : "more"}
                      </Button>
                      : ""}
                  <Button
                      className={classes.modalSmallFooterFirstButton}
                      color="transparent"
                      onClick={() => this.props.onClose("contact_details_wizard")}
                  >
                      {this.state.is_view ? "Close" : "Cancel"}
                  </Button>
                  {!this.state.is_view
                      ? <Button
                          className={
                              `${classes.modalSmallFooterFirstButton
                              } ${
                                  classes.modalSmallFooterSecondButton}`
                          }
                          color="success"
                          disabled={this.props.btnDisabled}
                          onClick={(event) => this.handleSaveContactDetails(
                              event,
                              this.props.client_id
                          )}
                          simple
                        >
                          Save
                      </Button>
                      : ""}
              </DialogActions>
              <ConfirmDialog
                  confirmModal={this.state.confirmModal}
                  confirmModalMsg={this.state.confirmModalMsg}
                  confirmModalTitle={this.state.confirmModalTitle}
                  onClose={() => this.handleClose("confirmModal")}
                  onYesClick={() => this.handleConfirmClick()}
              />
              {this.state.doneModalMsg
                  ? <Snackbar
                      close
                      closeNotification={() => {

                          this.handleCloseNotification();

                      }}
                      color={this.state.tr_color}
                      icon={AddAlert}
                      message={this.state.doneModalMsg}
                      open={this.state.tr}
                      place="tr"
                    />
                  : ""}
          </Dialog>
      );
    }
}

export default withStyles(extendedFormsStyle)(ContactDetailsWizard);
