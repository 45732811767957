import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import TagsInput from "react-tagsinput";
import AutoCompleteTags from "customs/components/AutoCompleteTags";
import Table from "components/Table/Table.jsx";

import helpers from "customs/helpers/helpers";
import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";

class ClientFile extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "confirmModal": false,
            "confirmModalTitle": "",
            "tag_list": [],
            "confirmModalMsg": "Are you sure?",
            "file_id":
        this.props.selected_file && this.props.selected_file.id
            ? this.props.selected_file.id
            : null,
            "filename":
        this.props.selected_file && this.props.selected_file.filename
            ? this.props.selected_file.filename
            : null,
            "filename_tmp": "",
            "file": null,
            "tags":
        this.props.selected_file && this.props.selected_file.tags
            ? this.props.selected_file.tags
            : []
        };
        this.handleSaveFileDetails = this.handleSaveFileDetails.bind(this);

    }

    getTagsData () {
        axios(this.props)
            .get("/files/tags")
            .then((response) => this.processTagsData(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processTagsData (res) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({"tag_list": res.data.tags});
    }

    componentDidMount () {
        this.getTagsData();
    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "client_file") {
            this.doSaveFileDetails();
        }
    }

    handleSaveFileDetails () {
        let cont = false;
        if (this.state.file_id) {
            // Update
            const old_tag = this.props.selected_file && this.props.selected_file.tags
                ? JSON.parse(JSON.stringify(this.props.selected_file.tags))
                : [];
            old_tag.sort();
            const new_tag = JSON.parse(JSON.stringify(this.state.tags));
            new_tag.sort();
            if (
                this.state.filename_tmp.length === 0 &&
                JSON.stringify(old_tag) === JSON.stringify(new_tag)
            ) {
                this.props.setUsersNotification(
                    "No changes were made",
                    "warning"
                );
            } else {
                cont = true;
            }
        } else {
            // New
            if (this.state.filename_tmp.length === 0) {
                this.props.setUsersNotification(
                    "Please choose a file",
                    "warning"
                );
            } else {
                cont = true;
            }
        }

        if (cont) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure you want to save file details?",
                "confirmModalTitle": `${this.state.file_id === null ? "Create" : "Update"} File`, "action": "client_file"
            });
        }
    }

    doSaveFileDetails () {
        helpers.showLoading();
        let fd = new FormData(),
            url = `/client/${this.props.client_id}/file`;
        if (this.state.file_id) {
            fd.append(
                "_method",
                "PUT"
            );
            url = `${url}/${this.state.file_id}`;
        }

        if (this.state.file) {
            fd.append(
                "file",
                this.state.file
            );
        }
        if (this.state.tags) {
            fd.append(
                "tags",
                JSON.stringify(this.state.tags)
            );
        }
        axios(this.props)
            .post(
                url,
                fd,
                {
                    "headers": {"Content-Type": "application/x-www-form-urlencoded"}
                }
            )
            .then((response) => {
                if (this.isUnmounted) {
                    return;
                }
                helpers.hideLoading();
                this.props.onClose("client_file_details");
                this.props.setUsersNotification(
                    "File saved",
                    "success"
                );
                this.props.refreshClientContactsTable(
                    null,
                    this.props.client_id
                );
            })
            .catch((error) => this.processErrorAxios(error));
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            (msg, color) => {
                this.props.setUsersNotification(
                    msg,
                    color
                );
            }
        );
    }

    handleFileChange (event) {
        let attach = true;
        try {
            if (Math.round(event.target.files[0].size / 1024) >= 4096) {
                this.props.setUsersNotification(
                    `${event.target.files[0].name
                    } is too big, please select a file that is less than 4mb`,
                    "danger"
                );
                attach = false;
            }
        } catch (e) {}

        if (attach) {
            this.setState({
                "filename_tmp": event.target.files[0].name,
                "file": event.target.files[0]
            });
        }

    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="avatar-update-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{"root": `${classes.center} ${classes.modalRoot}`}}
                fullWidth
                keepMounted
                maxWidth="sm"
                onClose={() => this.props.onClose("client_file_details")}
                open={this.props.client_file_details}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.file_dialog_title}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="avatar-update-modal-slide-description"
                    style={{"width": "100%",
                        "overflowX": "hidden"}}
                >
                    <GridContainer
                        key="details_grid"
                        style={{"borderTop": "#9c27b0 solid 1px"}}
                    >
                        <GridItem
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            {this.state.file_id && !this.props.readOnly
                                ? <div>
                                    <span style={{"fontWeight": "bold"}}>
                                        {this.state.filename}
                                    </span>
                                    <Button
                                        title={'Download file'}
                                        color="facebook"
                                        justIcon
                                        onClick={(event) => this.props.handleDownloadFile(
                                            event,
                                            this.props.client_id,
                                            this.props.selected_file
                                        )}
                                        simple
                                    >
                                        <i
                                            className="fa fa-download fa-sm"
                                            key="i_document"
                                        />
                                    </Button>
                                </div>
                                : ""}
                        </GridItem>
                        <GridItem md={12}>
                            {this.props.is_view || this.props.selected_file.is_application || this.props.selected_file.is_match_report
                                ? ""
                                : <div
                                    style={
                                        this.state.file_id
                                            ? {"paddingBottom": "0px"}
                                            : {"marginTop": "20px"}
                                    }
                                  >
                                    <div
                                        className="upload-btn-wrapper"
                                        style={{"width": "100%"}}
                                    >
                                        <button
                                            className="btn"
                                            style={{"marginRight": "10px"}}
                                        >
                                            Choose a
                                            {this.state.file_id ? " new" : ""}
                                            {" "}
                                            file
                                        </button>
                                        <input
                                            accept="*"
                                            name="newAvatarImage"
                                            onChange={(event) => this.handleFileChange(event)}
                                            type="file"
                                            value=""
                                        />
                                        <label
                                            htmlFor="document"
                                            style={{"color": "#495057"}}
                                        >
                                            <b
                                                style={{
                                                    "color": this.state.file_id ? "red" : "#4caf50"
                                                }}
                                            >
                                                {this.state.filename_tmp
                                                    ? this.state.file_idl
                                                        ? "Update "
                                                        : "New "
                                                    : ""}
                                            </b>
                                            {this.state.filename_tmp}
                                        </label>
                                        {this.state.file_id && this.state.filename_tmp.length > 0
                                            ? <Button
                                                title={'Restore previous file'}
                                                className="div-marg-tb-0"
                                                color="warning"
                                                justIcon
                                                onClick={() => {

                                                    this.setState({"filename_tmp": "",
                                                        "file": null});

                                                }}
                                                simple
                                            >
                                                <i
                                                    className="fa fa-undo fa-sm"
                                                    key="i_document_undo"
                                                />
                                              </Button>
                                            : ""}
                                    </div>
                                </div>}
                        </GridItem>
                        <span style={{"padding": "1rem 15px 0 15px",
                            "fontWeight": "bold"}}
                        >
                            Tags
                        </span>
                        <GridItem
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <AutoCompleteTags
                                onChange={(tags) => this.setState({tags})}
                                tag_list={this.state.tag_list}
                                tags={this.state.tags}
                            />
                        </GridItem>
                    </GridContainer>
                    {this.props.is_view
                        ? <GridContainer
                            key="details_grid_version"
                            style={{"paddingTop": "1rem"}}
                          >
                            <GridItem
                                key="details_grid_version_lbl"
                                md={12}
                                sm={12}
                                style={{"fontWeight": "bold"}}
                                xs={12}
                            >
                                File Versions
                            </GridItem>
                            {this.props.selected_file && this.props.selected_file.versions
                                ? <GridItem
                                    key="details_grid_version_tbl"
                                    md={12}
                                    sm={12}
                                    style={{"fontWeight": "bold"}}
                                    xs={12}
                                >
                                    <Table
                                        customCellClasses={[`no-wrap ${classes.right}`]}
                                        customClassesForCells={[2]}
                                        customHeadCellClasses={[classes.right]}
                                        customHeadClassesForCells={[2]}
                                        style={{"width": "100%"}}
                                        tableData={this.props.selected_file.versions.map((prop, key) => [
                                            prop.filename ? prop.filename : "",
                                            prop.created_at ? prop.created_at : "",
                                            prop.uploader ? "(" + prop.uploader.type + ") " + prop.uploader.username : "",
                                            [
                                                prop.path && !this.props.readOnly
                                                    ? <Button
                                                        title={'Download file'}
                                                        className="view"
                                                        color="facebook"
                                                        justIcon
                                                        key={`btnF${key}`}
                                                        onClick={(event) => this.props.handleDownloadVersionFile(
                                                            event,
                                                            this.props.client_id,
                                                            this.state.file_id,
                                                            prop
                                                        )}
                                                        round
                                                        simple
                                                      >
                                                        <i
                                                            className="fa fa-download fa-sm"
                                                            key={`i_document_${key}`}
                                                        />
                                                    </Button>
                                                    : ""

                                            ]
                                        ])}
                                        tableHead={[
                                            "File Name",
                                            "Date",
                                            "Uploader",
                                            "Actions"
                                        ]}
                                    />
                                  </GridItem>
                                : <GridItem
                                    key="details_grid_version_non"
                                    md={12}
                                    sm={12}
                                    xs={12}
                                >
                                    No versions found
                                  </GridItem>}
                        </GridContainer>
                        : ""}
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("client_file_details")}
                    >
                        Close
                    </Button>
                    {!this.props.is_view
                        ? <Button
                            className={
                                `${classes.modalSmallFooterFirstButton
                                } ${
                                    classes.modalSmallFooterSecondButton}`
                            }
                            color="success"
                            disabled={this.props.btnDisabled}
                            onClick={(event) => this.handleSaveFileDetails()}
                            simple
                          >
                            Save
                        </Button>
                        : ""}
                </DialogActions>
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.setState({"confirmModal": false})}
                    onYesClick={() => this.handleConfirmClick()}
                />
                {this.state.doneModalMsg
                    ? <Snackbar
                        close
                        closeNotification={() => {

                            this.handleCloseNotification();

                        }}
                        color={this.state.tr_color}
                        icon={AddAlert}
                        message={this.state.doneModalMsg}
                        open={this.state.tr}
                        place="tr"
                      />
                    : ""}
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(ClientFile);
