import React from "react";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";

class ExportWebdoshImportFileComponent extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            downloadApplicationModal: false,
            downloadApplicationModalMsg: this.props.step.step,

            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": ""
        };
        this.handleExportWebdoshImportFileDialog = this.handleExportWebdoshImportFileDialog.bind(this);
        this.allPreviousStepsCompleted = this.allPreviousStepsCompleted.bind(this);
        this.hasApprovedGeneratedApplication = this.hasApprovedGeneratedApplication.bind(this);
        this.downloadExportFile = this.downloadExportFile.bind(this);
    }

    handleExportWebdoshImportFileDialog(show) {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to continue?`,
            "confirmModalTitle": this.props.step.step,
            "action": "export_webdosh_import"
        });
    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "export_webdosh_import") {
            this.props.handleUpdateStep(
                this.props.step.id, this.props.step.client_script_id,
                {'form_data': {'generate' : 'webdosh_import'}},
                () => {
                    this.downloadExportFile();
                }
            );
        }
    }

    downloadExportFile() {
        let filename = (this.props.client_company + "_" + this.props.checklist.name + "_export").replace(/[^a-z0-9]/gi, '_') + ".json";
        const FileDownload = require("js-file-download");
        FileDownload(
            JSON.stringify(this.props.step.data.data),
            filename
        );
    }

    allPreviousStepsCompleted() {
        let allCompleted = true;
        Object.keys(this.props.checklist.steps).forEach((key) => {
            try {
                if (this.props.checklist.steps[key].action_type !== "export_webdosh_import" && this.props.checklist.steps[key].status !== 1) {
                    allCompleted = false;
                }
            } catch(e) {}
        });
        return allCompleted;
    }

    hasApprovedGeneratedApplication() {
        let hasApprovedGeneratedApplication = false;
        Object.keys(this.props.checklist.steps).forEach((key) => {
            try {
                if (this.props.checklist.steps[key].action_type === 'generate_application') {
                    let step = this.props.checklist.steps[key];
                    if (step.status === 1 && Object.keys(step.data).length > 0 && step.data[0] !== 0) {
                        Object.keys(step.data).forEach((key) => {
                            let file = this.props.client_files.find((file) => {
                                return file.id === step.data[key]['client_file_id'];
                            });
                            if (file.status === 'APPROVED') {
                                hasApprovedGeneratedApplication = true;
                            }
                        });
                    }
                }
            } catch(e) {}
        });
        return hasApprovedGeneratedApplication;
    }

    render () {
        let exported = Object.keys(this.props.step.data).length > 0 && this.props.step.data[0] !== 0;
        let hasApprovedApplication = this.hasApprovedGeneratedApplication();
        return <div>
            <Button
                disabled={exported || !hasApprovedApplication || this.props.readOnly || !this.allPreviousStepsCompleted()}
                color="success"
                onClick={() => this.handleExportWebdoshImportFileDialog(true)}
            >
                {exported ? 'Already Exported' : (hasApprovedApplication ? 'Export' : 'No approved application')}
            </Button>
            <ConfirmDialog
                confirmModal={this.state.confirmModal}
                confirmModalMsg={this.state.confirmModalMsg}
                confirmModalTitle={this.state.confirmModalTitle}
                onClose={() => this.setState({"confirmModal": false})}
                onYesClick={() => this.handleConfirmClick()}
            />
        </div>;
    }
}

const ExportWebdoshImportFile = withStyles(extendedFormsStyle)(ExportWebdoshImportFileComponent);

export default ExportWebdoshImportFile;
