import React from "react";
import PropTypes from "prop-types";
import {NavLink, Redirect} from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import CameraAlt from "@material-ui/icons/CameraAlt";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import ConfirmDialog from "customs/components/ConfirmDialog";
import UpdateAvatarDialog from "customs/components/UpdateAvatarDialog";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import avatar from "assets/img/default-avatar.png";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import {
    doUpdateProfile,
    doUpdateProfileData
} from "../../store/actions/authActions";

import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";
import timezone_helper from "customs/helpers/timezone_helpers";

import Constants from "customs/config/Constants";

import Switch from "@material-ui/core/Switch";
import AccessControl from "customs/components/AccessControl";

class UserProfile extends React.Component {

    constructor (props) {

        super(props);
        const user = props.auth && props.auth.user ? props.auth.user : {};
        this.state = {
            "email_valid": "",
            "first_name_valid": "",
            "last_name_valid": "",
            "company_valid": "",
            "username": user && user.username ? user.username : "",
            "email": user && user.email ? user.email : "",
            "first_name": user && user.first_name ? user.first_name : "",
            "last_name": user && user.last_name ? user.last_name : "",
            "company": user && user.company ? user.company : "",
            "timezone": user && user.timezone && user.timezone !== null ? user.timezone : "",
            "phone_number_valid": "",
            "phone_number": user && user.phone_number ? user.phone_number : "",
            "confirmModal": false,
            "confirmModalTitle": "",
            "avatarModal": false,
            "image": null,
            "position": {"x": 0.5, "y": 0.5},
            "scale": 1,
            "rotate": 0,
            "borderRadius": 100,
            "preview": null,
            "width": 270,
            "height": 270,
            "tr": false,
            "profileUpdated": null,
            "newAvatar": null,
            "newAvatarBlob": null,
            "email_event_notification": user && user.email_event_notification ? (user.email_event_notification ? true : false) : false,
            "push_event_notification": user && user.push_event_notification ? (user.push_event_notification ? true : false) : false,
            "email_event_digest": user && user.email_event_digest ? (user.email_event_digest ? true : false) : false,
            "email_watch_event_notification": user && user.email_watch_event_notification ? (user.email_watch_event_notification ? true : false) : false,
            "push_watch_event_notification": user && user.push_watch_event_notification ? (user.push_watch_event_notification ? true : false) : false,
            "sms_event_notification": user && user.sms_event_notification ? (user.sms_event_notification ? true : false) : false,
            "sms_watch_event_notification": user && user.sms_watch_event_notification ? (user.sms_watch_event_notification ? true : false) : false,
            
            "push_manager_login_notification": user && user.push_manager_login_notification ? (user.push_manager_login_notification ? true : false) : false,
            "email_manager_login_notification": user && user.email_manager_login_notification ? (user.email_manager_login_notification ? true : false) : false,
            "sms_manager_login_notification": user && user.sms_manager_login_notification ? (user.sms_manager_login_notification ? true : false) : false,
            "push_client_login_notification": user && user.push_client_login_notification ? (user.push_client_login_notification ? true : false) : false,
            "email_client_login_notification": user && user.email_client_login_notification ? (user.email_client_login_notification ? true : false) : false,
            "sms_client_login_notification": user && user.sms_client_login_notification ? (user.sms_client_login_notification ? true : false) : false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleAvatarScale = this.handleAvatarScale.bind(this);
    }

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value,
            [`${event.target.id}_valid`]: ""
        });
    }

    doUpdateProfileClick () {
        helpers.showLoading();
        this.handleClose("confirmModal");

        let fd = {};
        if (this.userHasSelectedANewAvatar() && this.state.newAvatarBlob) {
            fd = new FormData();
            fd.append(
                "profile_pic",
                this.state.newAvatarBlob,
                this.state.image.name
            );
            fd.append(
                "id",
                this.props.auth.user.id
            );
            fd.append(
                "email",
                this.state.email
            );
            fd.append(
                "phone_number",
                this.state.phone_number
            );
            fd.append(
                "first_name",
                this.state.first_name
            );
            fd.append(
                "last_name",
                this.state.last_name
            );
            fd.append(
                "company",
                this.state.company
            );
            fd.append(
                "email_event_notification",
                this.state.email_event_notification ? 1 : 0
            );
            fd.append(
                "email_event_digest",
                this.state.email_event_digest ? 1 : 0
            );
            fd.append(
                "push_event_notification",
                this.state.push_event_notification ? 1 : 0
            );
            fd.append(
                "email_watch_event_notification",
                this.state.email_watch_event_notification ? 1 : 0
            );
            fd.append(
                "push_watch_event_notification",
                this.state.push_watch_event_notification ? 1 : 0
            );
            fd.append(
                "sms_event_notification",
                this.state.sms_event_notification ? 1 : 0
            );
            fd.append(
                "sms_watch_event_notification",
                this.state.sms_watch_event_notification ? 1 : 0
            );
            fd.append(
                "timezone",
                this.state.timezone.length > 0 ? this.state.timezone : null
            );
            
            fd.append(
                "push_manager_login_notification",
                this.state.push_manager_login_notification ? 1 : 0
            );
            fd.append(
                "email_manager_login_notification",
                this.state.email_manager_login_notification ? 1 : 0
            );
            fd.append(
                "sms_manager_login_notification",
                this.state.sms_manager_login_notification ? 1 : 0
            );
            fd.append(
                "push_client_login_notification",
                this.state.push_client_login_notification ? 1 : 0
            );
            fd.append(
                "email_client_login_notification",
                this.state.email_client_login_notification ? 1 : 0
            );
            fd.append(
                "sms_client_login_notification",
                this.state.sms_client_login_notification ? 1 : 0
            );
        } else {
            fd = {
                "id": this.props.auth.user.id,
                "email": this.state.email,
                "phone_number": this.state.phone_number,
                "first_name": this.state.first_name,
                "last_name": this.state.last_name,
                "email_event_notification": this.state.email_event_notification ? 1 : 0,
                "push_event_notification": this.state.push_event_notification ? 1 : 0,
                "email_event_digest": this.state.email_event_digest ? 1 : 0,
                "email_watch_event_notification": this.state.email_watch_event_notification ? 1 : 0,
                "push_watch_event_notification": this.state.push_watch_event_notification ? 1 : 0,
                "sms_event_notification": this.state.sms_event_notification ? 1 : 0,
                "sms_watch_event_notification": this.state.sms_watch_event_notification ? 1 : 0,
                "company": this.state.company,
                "timezone": this.state.timezone.length > 0 ? this.state.timezone : null,
                
                "push_manager_login_notification": this.state.push_manager_login_notification ? 1 : 0,
                "email_manager_login_notification": this.state.email_manager_login_notification ? 1 : 0,
                "sms_manager_login_notification": this.state.sms_manager_login_notification ? 1 : 0,
                "push_client_login_notification": this.state.push_client_login_notification ? 1 : 0,
                "email_client_login_notification": this.state.email_client_login_notification ? 1 : 0,
                "sms_client_login_notification": this.state.sms_client_login_notification ? 1 : 0
            };
        }

        this.props.doUpdateProfile(
            fd,
            {...this.props}
        );
    }

    handleUpdateProfileClick (modal) {
        const email_valid = validations.validateEmail(this.state.email),
            phone_number_valid = this.state.phone_number.replace(/[^\d]/g, ""),
            first_name_valid = this.state.first_name.length > 0 && this.state.first_name.length <= 255,
            last_name_valid = this.state.last_name.length > 0 && this.state.last_name.length <= 255,
            company_valid = this.state.company.length > 0 && this.state.company.length <= 255;
        if (email_valid && first_name_valid && last_name_valid && company_valid) {
            const x = [];
            x[modal] = true;
            this.setState({
                ...x,
                "confirmModalTitle": "Update Profile",
                "email_valid": "",
                "first_name_valid": "",
                "last_name_valid": "",
                "company_valid": "",
                "phone_number_valid": ""
            });
        } else {
            this.setState({
                "email_valid": email_valid ? "success" : "error",
                "phone_number_valid": phone_number_valid ? "success" : "error",
                "first_name_valid": first_name_valid > 0 ? "success" : "error",
                "last_name_valid": last_name_valid > 0 ? "success" : "error",
                "company_valid": company_valid > 0 ? "success" : "error"
            });
        }
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

  setEditorRef = (avatar_editor) => this.avatar_editor = avatar_editor;

  handleUpdateAvatarClick (event) {
      event.preventDefault();
      this.setState({
          "avatarModal": true,
          "newAvatar": null,
          "newAvatarBlob": null,
          "image": null,
          "position": {"x": 0.5,
              "y": 0.5},
          "scale": 1,
          "rotate": 0
      });
  }

  handleNewAvatar = (e) => {
      this.setState({
          "image": e.target && e.target.files ? e.target.files[0] : null
      });
  };

  handleAvatarScale = (e) => {
      if (this.userHasSelectedANewAvatar()) {
          const scale = parseFloat(e.target.value);
          this.setState({scale});
      }
  };

  handleAvatarPositionChange = (position) => {
      if (this.userHasSelectedANewAvatar()) {
          this.setState({position});
      }
  };

  handleAvatarRotateLeft = () => {
      if (this.userHasSelectedANewAvatar()) {
          this.setState({"rotate": this.state.rotate - 90});
      }
  };

  handleAvatarRotateRight = () => {
      if (this.userHasSelectedANewAvatar()) {
          this.setState({"rotate": this.state.rotate + 90});
      }
  };

  userHasSelectedANewAvatar () {
      return Boolean(this.state.image);
  }

  handleAcceptNewAvatar = () => {
      if (this.userHasSelectedANewAvatar() && this.avatar_editor) {
          this.setState({
              "newAvatar": this.avatar_editor
                .getImageScaledToCanvas()
                .toDataURL(
                      "image/png",
                      1.0
                  )
          });
          this.avatar_editor.getImageScaledToCanvas().toBlob(
              (blob) => {
                  this.setState({"newAvatarBlob": blob});
              },
              "image/png",
              100
          );
      }
      this.handleClose("avatarModal");
  };

  hideNotification;
  showUpdateProfileNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.setState({"newAvatar": null, "newAvatarBlob": null});
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseProfileNotification();
          },
          this.state.profileUpdated === "Profile updated" ? 5000 : 10000
      );
  }

  handleCloseProfileNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false});
  }

  componentWillUnmount () {
      clearTimeout(this.hideNotification);
  }

  render () {
      const authService = new AuthService(
          this.state,
          this.props
      );
      if (!authService.getToken()) {
          return (
              <div>
                  <Redirect to="/auth/login" />
              </div>
          );
      }

      const {classes} = this.props,
          profileUpdated = this.props.auth && this.props.auth && this.props.auth.authMessage
            ? this.props.auth.authMessage
            : "";
      if (profileUpdated) {
          this.props.auth.authMessage = "";
          setTimeout(
              () => {
                  this.setState({profileUpdated});
                  this.props.doUpdateProfileData(
                      this.props.auth,
                      {...this.props}
                  );
                  this.showUpdateProfileNotification();
                  helpers.hideLoading();
              },
              100
          );
      }
      return (
          <div>
              {authService.isPasswordExpired()
                  ? <Redirect to="/admin/changepassword" />
                  : ""}
              <GridContainer>
                  <GridItem
                      md={8}
                      sm={12}
                      xs={12}
                  >
                      <Card>
                          <CardHeader
                              color="success"
                              icon
                          >
                              <CardIcon color="success">
                                  <PermIdentity />
                              </CardIcon>
                              <h4 className={classes.cardIconTitle}>
                                  Edit Profile
                              </h4>
                          </CardHeader>
                          <CardBody>
                              <GridContainer>
                                  <GridItem
                                      md={6}
                                      sm={12}
                                      xs={12}
                                  >
                                      <CustomInput
                                          formControlProps={{
                                              "fullWidth": true
                                          }}
                                          id="username"
                                          inputProps={{
                                              "disabled": true,
                                              "value": this.state.username
                                          }}
                                          labelText="Username"
                                      />
                                  </GridItem>
                              </GridContainer>
                              <GridContainer>
                                  <GridItem
                                      md={6}
                                      sm={12}
                                      xs={12}
                                  >
                                      <CustomInput
                                          error={this.state.email_valid === "error"}
                                          formControlProps={{
                                              "fullWidth": true
                                          }}
                                          helperText={
                                              this.state.email_valid === "error" && this.state.email
                                                  ? validations.emailErrorMsg()
                                                  : undefined
                                          }
                                          id="email"
                                          inputProps={{
                                              "onChange": this.handleChange,
                                              "value": this.state.email
                                          }}
                                          labelText="Email address"
                                          success={this.state.email_valid === "success"}
                                      />
                                  </GridItem>
                                <GridItem
                                      md={6}
                                      sm={12}
                                      xs={12}
                                >
                                    <CustomInput
                                        error={this.state.phone_number_valid === "error"}
                                        formControlProps={{
                                            "fullWidth": true
                                        }}
                                        helperText={
                                            this.state.phone_number_valid === "error" && this.state.phone_number
                                                ? 'Phone number is invalid'
                                                : undefined
                                        }
                                        id="phone_number"
                                        inputProps={{
                                            "onChange": this.handleChange,
                                            "value": this.state.phone_number
                                        }}
                                        labelText="Phone Number"
                                        success={this.state.phone_number_valid === "success"}
                                    />
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                  <GridItem
                                      md={6}
                                      sm={12}
                                      xs={12}
                                  >
                                      <CustomInput
                                          error={this.state.first_name_valid === "error"}
                                          formControlProps={{
                                              "fullWidth": true
                                          }}
                                          id="first_name"
                                          inputProps={{
                                              "onChange": this.handleChange,
                                              "value": this.state.first_name
                                          }}
                                          labelText="First Name"
                                          success={this.state.first_name_valid === "success"}
                                      />
                                  </GridItem>
                                  <GridItem
                                      md={6}
                                      sm={12}
                                      xs={12}
                                  >
                                      <CustomInput
                                          error={this.state.last_name_valid === "error"}
                                          formControlProps={{
                                              "fullWidth": true
                                          }}
                                          id="last_name"
                                          inputProps={{
                                              "onChange": this.handleChange,
                                              "value": this.state.last_name
                                          }}
                                          labelText="Last Name"
                                          success={this.state.last_name_valid === "success"}
                                      />
                                  </GridItem>
                              </GridContainer>
                              <GridContainer>
                                  <GridItem
                                      md={6}
                                      sm={12}
                                      xs={12}
                                  >
                                      <CustomInput
                                          error={this.state.company_valid === "error"}
                                          formControlProps={{
                                              "fullWidth": true
                                          }}
                                          id="company"
                                          inputProps={{
                                              "onChange": this.handleChange,
                                              "value": this.state.company
                                          }}
                                          labelText="Company"
                                          success={this.state.company_valid === "success"}
                                      />
                                  </GridItem>
                                  <GridItem
                                      md={6}
                                      sm={12}
                                      xs={12}
                                  >
                                      <label className="customRadioTopLabel">
                                          Timezone
                                      </label>
                                      <Select
                                          classes={{"select": classes.select}}
                                          fullWidth
                                          inputProps={{
                                              "name": "timezone",
                                              "id": "timezone",
                                              "stye": {"width": "100%"}
                                          }}
                                          MenuProps={{"className": classes.selectMenu}}
                                          onChange={(event) => this.setState({"timezone": event.target.value})}
                                          value={this.state.timezone}
                                      >
                                          {
                                          this.props.auth.user.timezones
                                          .map((timezone) => {
                                              let offset = timezone_helper.getUtcOffset(timezone);
                                              return offset !== null
                                                ? {"timezone": timezone, "offset": offset}
                                                : offset;
                                          })
                                          .filter((timezone) => timezone !== null)
                                          .map((prop, key) => (<MenuItem
                                                classes={{"root": classes.selectMenuItem}}
                                                key={`rangeKey${key}`}
                                                value={prop['timezone']}
                                              >
                                                {prop['timezone'].replace("_", " ")}{" "}({prop['offset']})
                                          </MenuItem>))
                                          }
                                      </Select>
                                  </GridItem>
                              </GridContainer>
                              {
                                AccessControl.checkRoutePermissions("/profile", this.props.auth, "notifications")
                                ? <GridContainer>
                                    <GridItem
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <h4>Notifications</h4>
                                    </GridItem>
                                    <GridItem
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                            <style>
                                                {".notification-column {padding: 2px 10px;} .notification-border {border-right: solid gray 1px;}"}
                                            </style>
                                            <table style={{"borderCollapse": "collapse"}}>
                                                <thead>
                                                    <tr>
                                                        <th className={'notification-column notification-border'}>&nbsp;</th>
                                                        <th className={'notification-column notification-border'}>Real-time Push</th>
                                                        <th className={'notification-column notification-border'}>Real-time Email</th>
                                                        <th className={'notification-column notification-border'}>Real-time SMS</th>
                                                        <th className={'notification-column'}>Daily Digest</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className={'notification-column notification-border'}>Script Change Event</td>
                                                        <td className={'notification-column notification-border'}>
                                                            {/* <Switch
                                                                checked={this.state.push_event_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({push_event_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            /> */}
                                                            {"n/a"}
                                                        </td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.email_event_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({email_event_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.sms_event_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({sms_event_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column'}>
                                                            {
                                                                AccessControl.checkRoutePermissions("/profile", this.props.auth, "notification_digest")
                                                                    ? <Switch
                                                                        checked={this.state.email_event_digest ? true : false}
                                                                        onChange={(event) => {
                                                                            this.setState({email_event_digest: event.target.checked});
                                                                        }}
                                                                        value="on"
                                                                        classes={{
                                                                            switchBase: classes.switchBase,
                                                                            checked: classes.switchChecked,
                                                                            thumb: classes.switchIcon,
                                                                            track: classes.switchBar
                                                                        }}
                                                                    />
                                                                    : "n/a"
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className={'notification-column notification-border'}>Watched Event</td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.push_watch_event_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({push_watch_event_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.email_watch_event_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({email_watch_event_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.sms_watch_event_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({sms_watch_event_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column'}>n/a</td>
                                                    </tr>
                                                    {
                                                    AccessControl.checkRoutePermissions("/profile", this.props.auth, "manager_login_notification")
                                                    ? <tr>
                                                        <td className={'notification-column notification-border'}>Manager Login</td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.push_manager_login_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({push_manager_login_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.email_manager_login_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({email_manager_login_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.sms_manager_login_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({sms_manager_login_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column'}>n/a</td>
                                                    </tr>
                                                    : <tr style={{"display": "none"}}><td></td><td></td><td></td><td></td></tr>
                                                    }
                                                    <tr>
                                                        <td className={'notification-column notification-border'}>Client Login</td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.push_client_login_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({push_client_login_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.email_client_login_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({email_client_login_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column notification-border'}>
                                                            <Switch
                                                                checked={this.state.sms_client_login_notification ? true : false}
                                                                onChange={(event) => {
                                                                    this.setState({sms_client_login_notification: event.target.checked});
                                                                }}
                                                                value="on"
                                                                classes={{
                                                                    switchBase: classes.switchBase,
                                                                    checked: classes.switchChecked,
                                                                    thumb: classes.switchIcon,
                                                                    track: classes.switchBar
                                                                }}
                                                            />
                                                        </td>
                                                        <td className={'notification-column'}>n/a</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                    </GridItem>
                                </GridContainer>
                                : ""
                              }
                              <Button
                                  className={classes.updateProfileButton}
                                  color="success"
                                  onClick={() => this.handleUpdateProfileClick("confirmModal")}
                              >
                                  Update Profile
                              </Button>
                              <Clearfix />
                          </CardBody>
                      </Card>
                  </GridItem>
                  <GridItem
                      md={4}
                      sm={12}
                      xs={12}
                  >
                      <Card profile>
                          <CardAvatar
                              profile
                              style={{"maxWidth": "200px",
                                  "maxHeight": "200px"}}
                          >
                              <span
                                    title={'Update avatar'}
                                    style={{
                                        "position": "absolute",
                                        "width": "30px",
                                        "top": "140px",
                                        "color": "lightslategray",
                                        "cursor": "pointer"
                                    }}
                              >
                                  <CameraAlt
                                      onClick={(event) => this.handleUpdateAvatarClick(event)}
                                  />
                              </span>
                              <NavLink
                                  onClick={(event) => this.handleUpdateAvatarClick(event)}
                                  to="#"
                              >
                                  <img
                                      alt="..."
                                      id="profile-avatar"
                                      src={
                                          this.state.newAvatar
                                              ? this.state.newAvatar
                                              : this.props.auth &&
                                                this.props.auth.user &&
                                                this.props.auth.user.pic
                                                  ? `${Constants.API_URL
                                                  }/avatar/${
                                                      this.props.auth.user.pic}`
                                                  : avatar
                                      }
                                      style={{"background": "#eeeeee"}}
                                  />
                              </NavLink>
                          </CardAvatar>
                          <CardBody
                              profile
                              style={{"color": "red",
                                  "textAlign": "left"}}
                          >
                              {this.state.newAvatar
                                  ? "Please click UPDATE PROFILE to save your new avatar."
                                  : ""}
                          </CardBody>
                      </Card>
                  </GridItem>
                  <ConfirmDialog
                      confirmModal={this.state.confirmModal}
                      confirmModalTitle={this.state.confirmModalTitle}
                      onClose={() => this.handleClose("confirmModal")}
                      onYesClick={() => this.doUpdateProfileClick()}
                  />
                  {this.state.profileUpdated
                      ? <Snackbar
                          close
                          closeNotification={() => {

                              this.handleCloseProfileNotification();

                          }}
                          color={
                              this.state.profileUpdated === "Profile updated"
                                  ? "success"
                                  : "danger"
                          }
                          icon={AddAlert}
                          message={this.state.profileUpdated}
                          open={this.state.tr}
                          place="tr"
                      />
                      : ""}
                  <UpdateAvatarDialog
                      handleAcceptNewAvatar={() => this.handleAcceptNewAvatar()}
                      handleAvatarPositionChange={() => this.handleAvatarPositionChange()}
                      handleAvatarRotateLeft={(event) => this.handleAvatarRotateLeft(event)}
                      handleAvatarRotateRight={(event) => this.handleAvatarRotateRight(event)}
                      handleAvatarScale={(event) => this.handleAvatarScale(event)}
                      handleNewAvatar={(event) => this.handleNewAvatar(event)}
                      onClose={() => this.handleClose("avatarModal")}
                      setEditorRef={this.setEditorRef}
                      {...this.state}
                  />
              </GridContainer>
          </div>
      );
  }
}

UserProfile.propTypes = {
    "classes": PropTypes.object,
    "force_render": PropTypes.number
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data,
        "force_render": Math.random()
    }),

    mapDispatchToProps = (dispatch) => ({
        "doUpdateProfile": (params, props) => dispatch(doUpdateProfile(
            params,
            props
        )),
        "doUpdateProfileData": (params, props) => dispatch(doUpdateProfileData(
            params,
            props
        ))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(userProfileStyles)(UserProfile));
