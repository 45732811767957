import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Snack from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import snackbarContentStyle from "assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.jsx";

import helpers from "customs/helpers/helpers";

function Snackbar ({...props}) {

    const {classes, message, color, close, icon, place, open} = props;
    let action = [];
    const messageClasses = cx({
        [classes.iconMessage]: icon !== undefined
    });
    if (close !== undefined) {

        action = [
            <IconButton
                aria-label="Close"
                className={classes.iconButton}
                color="inherit"
                key="close"
                onClick={() => props.closeNotification()}
            >
                <Close className={classes.close} />
            </IconButton>
        ];

    }
    const iconClasses = cx({
        [classes.icon]: classes.icon,
        [classes.infoIcon]: color === "info",
        [classes.successIcon]: color === "success",
        [classes.warningIcon]: color === "warning",
        [classes.dangerIcon]: color === "danger",
        [classes.primaryIcon]: color === "primary",
        [classes.roseIcon]: color === "rose"
    });
    return (
        <Snack
            action={action}
            anchorOrigin={{
                "vertical": place.indexOf("t") === -1 ? "bottom" : "top",
                "horizontal":
          place.indexOf("l") !== -1
              ? "left"
              : place.indexOf("c") !== -1
                  ? "center"
                  : "right"
            }}
            classes={{
                "anchorOriginTopCenter": classes.top20,
                "anchorOriginTopRight": classes.top40,
                "anchorOriginTopLeft": classes.top40
            }}
            ContentProps={{
                "classes": {
                    "root": `${classes.root} ${classes[color]}`,
                    "message": classes.message
                }
            }}
            message={
                <div>
                    {icon !== undefined ? <props.icon className={iconClasses} /> : null}
                    <span className={messageClasses}>
                        {
                            message !== null && React.isValidElement(message)
                                ? message : helpers.addLineBreaks(message)
                        }
                    </span>
                </div>
            }
            open={open}
            style={{"zIndex": "10001"}}
        />
    );

}

Snackbar.defaultProps = {
    "color": "info"
};

Snackbar.propTypes = {
    "classes": PropTypes.object.isRequired,
    "message": PropTypes.node.isRequired,
    "color": PropTypes.oneOf([
        "info",
        "success",
        "warning",
        "danger",
        "primary",
        "rose"
    ]),
    "close": PropTypes.bool,
    "icon": PropTypes.object,
    "place": PropTypes.oneOf([
        "tl",
        "tr",
        "tc",
        "br",
        "bl",
        "bc"
    ]),
    "open": PropTypes.bool,
    "closeNotification": PropTypes.func
};

export default withStyles(snackbarContentStyle)(Snackbar);
