import React from "react";
import {/*Label,*/ getFieldComponent} from "./utils";

const navField = (NavComponent) => (props) => {

    let {
            // schema,
            "uiSchema": {navConfs, origUiSchema},
            "registry": {fields},
            // required,
            // idSchema,
            // name
        } = props,
        FieldUI = getFieldComponent(
            props.schema,
            origUiSchema,
            fields
        ),
        fieldConf = {...props,
            "uiSchema": origUiSchema};
    return (
        <>
            {navConfs.map((navConf, i) => <NavComponent {...navConf} key={i} />)}
            <FieldUI {...fieldConf} />
        </>
    );

};

export default navField;
