import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import TagsInput from "react-tagsinput";
import ReactTable from "react-table";
import History from "@material-ui/icons/History";
import Pagination from "customs/components/Pagination";
import UserFile from "customs/components/UserFile";

import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";
import helpers from "customs/helpers/helpers";

class FileSearch extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "tags": [],
            "search_tags": [],
            "files": [],
            "pages": 0,
            "is_view": false,
            "user_file_details": false,
            "file_dialog_title": "",
            "selected_file": []
        };
        this.handleViewFile = this.handleViewFile.bind(this);
        this.handleDownloadFile = this.handleDownloadFile.bind(this);
        this.handleDownloadVersionFile = this.handleDownloadVersionFile.bind(this);

    }

  handleViewFile = (event, user_id, file) => {
      this.setState({
          "user_file_details": true,
          "selected_file": file,
          "is_view": true,
          "file_dialog_title": "File Details"
      });
  };

  columns = [
      {
          "Header": "File Name",
          "accessor": "filename",
          "sortable": false,
          "filterable": false
      },
      {"Header": "Tags",
          "accessor": "tags",
          "sortable": false,
          "filterable": false},
      {
          "Header": "Account Name",
          "accessor": "name",
          "sortable": false,
          "filterable": false
      },
      {
          "Header": "Account Type",
          "accessor": "account_type",
          "sortable": false,
          "filterable": false
      },
      {
          "Header": "Actions",
          "accessor": "actions",
          "sortable": false,
          "filterable": false,
          "width": 100
      }
  ];

  refreshFilesTable () {
      this.refReactFilesTable.fireFetchData();
  }

  getFilesData (page, pageSize, sorted, filtered, handleRetrievedData) {
      if (this.state.search_tags !== this.state.tags) {
          page = 0;
      }
      this.setState({"search_tags": this.state.tags});
      const url = "/files/search",
          postObject = {
              "page": page + 1,
              "per_page": pageSize,
              "sort": sorted,
              "filter": filtered,
              "tags": this.state.tags
          };

      return axios(this.props)
        .post(
            url,
            postObject
        )
        .then((response) => handleRetrievedData(response))
        .catch((error) => this.processErrorAxios(
            error,
            null
        ));
  }

  processFilesData (res) {
      if (this.isUnmounted) {
          return;
      }
      this.setState({
          "files": res.data.data.map((prop, key) => this.setFileDetails(
              prop,
              key
          )),
          "pages": res.data.last_page,
          "items_data":
        res.data.from && res.data.to && res.data.total
            ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
            : "",
          "loading": false
      });
  }

  getName (prop) {
      try {
          return (
              `${prop[prop.account_type].id
              }: ${
                  prop[prop.account_type].first_name
              } ${
                  prop[prop.account_type].last_name}`
          );
      } catch (e) {}
      return "";
  }

  handleDownloadFile = (event, user_id, file) => {
      event.preventDefault();
      if (file.account_type === "user") {
          this.doDownloadFile(
              `user/${file.user_id}/file/${file.id}/download`,
              file.filename
          );
      } else if (file.account_type === "client") {
          this.doDownloadFile(
              `client/${file.client_id}/file/${file.id}/download`,
              file.filename
          );
      } else if (file.account_type === "contact") {
          this.doDownloadFile(
              `client/${
                  file.contact.client_id
              }/contact/${
                  file.contact_id
              }/document/${
                  file.id}`,
              file.filename
          );
      }
  };

  handleDownloadVersionFile = (event, user_id, file_id, version_file, file) => {
      event.preventDefault();
      if (file.account_type === "user") {
          this.doDownloadFile(
              `user/${
                  file.user_id
              }/file/${
                  file.id
              }/download/${
                  version_file.id}`,
              version_file.filename
          );
      } else if (file.account_type === "client") {
          this.doDownloadFile(
              `client/${
                  file.client_id
              }/file/${
                  file.id
              }/download/${
                  version_file.id}`,
              version_file.filename
          );
      }
  };

  doDownloadFile (url, filename) {
      const FileDownload = require("js-file-download");
      helpers.showLoading();
      axios(this.props)
        .get(
            url,
            {"responseType": "blob",
                "timeout": 30000}
        )
        .then((response) => {
            helpers.hideLoading();
            FileDownload(
                response.data,
                filename
            );
        })
        .catch((error) => this.processErrorAxios(
            error,
            null
        ));
  }

  setFileDetails (prop, key) {
      return {
          key,
          "filename": prop.filename,
          "tags": prop.tags != null && prop.tags.length > 0
            ? <TagsInput
                disabled
                onlyUnique
                tagProps={{"className": "react-tagsinput-tag primary"}}
                value={prop.tags}
              />
            : "",
          "name": this.getName(prop),
          "account_type": prop.account_type.charAt(0).toUpperCase() + prop.account_type.slice(1),
          "data": prop,
          "actions":
            <div>
                <Button
                    title={'Download file'}
                    className="view"
                    color="facebook"
                    justIcon
                    key={`btnF${key}`}
                    onClick={(event) => this.handleDownloadFile(
                        event,
                        "",
                        prop
                    )}
                    round
                    simple
                    style={{"float": "right"}}
                >
                    <i
                        className="fa fa-download fa-sm"
                        key={`i_document_${key}`}
                    />
                </Button>
                {prop.account_type !== "contact"
                    ? <Button
                        title={'File details'}
                        className="view"
                        color="warning"
                        justIcon
                        key={`btnA${key}`}
                        onClick={(event) => this.handleViewFile(
                            event,
                            this.props.auth.user.id,
                            prop
                        )}
                        round
                        simple
                        style={{"float": "right"}}
                    >
                        <History />
                    </Button>
                    : ""}
                {" "}
            </div>
      };
  }

  processErrorAxios (error, prop) {
      axiosHelper.processError(
          this.isUnmounted,
          prop,
          error,
          (state) => {
              this.setState(state);
          },
          (msg, color) => {
              this.props.setUsersNotification(
                  msg,
                  color
              );
          }
      );
  }

  render () {
      const {classes} = this.props,
          {files, pages, loading} = this.state;
      return (
          <Dialog
              aria-describedby="avatar-update-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{"root": `${classes.center} ${classes.modalRoot}`}}
              fullWidth
              keepMounted
              maxWidth="lg"
              onClose={() => this.props.onClose()}
              open={this.props.doSearch}
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  Search Files
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="avatar-update-modal-slide-description"
                  style={{"width": "100%",
                      "overflowX": "hidden"}}
              >
                  <GridContainer
                      key="details_grid"
                      style={{"borderTop": "#9c27b0 solid 1px"}}
                  >
                      <GridItem
                          md={12}
                          sm={12}
                          xs={12}
                      >
                          <p
                              style={{
                                  "padding": "0.8rem 0.8rem 0rem 0.8rem",
                                  "margin": "0px",
                                  "fontSize": "1.1rem",
                                  "fontWeight": "bold"
                              }}
                          >
                              Enter Search Terms
                          </p>
                      </GridItem>
                      <GridItem
                          md={10}
                          sm={12}
                          xs={12}
                      >
                          <TagsInput
                              inputProps={{
                                  "className": "react-tagsinput-input react-tagsinput-input-search",
                                  "placeholder": "Add tag to search"
                              }}
                              onChange={(tags) => this.setState({tags})}
                              onlyUnique
                              style={{"width": "100%"}}
                              tagProps={{
                                  "className": "react-tagsinput-tag react-tagsinput-search react-tagsinput-tag-search primary"
                              }}
                              value={this.state.tags}
                          />
                      </GridItem>
                      <GridItem
                          md={2}
                          sm={12}
                          style={{
                              "display": "flex",
                              "alignItems": "center",
                              "justifyContent": "flex-end"
                          }}
                          xs={12}
                      >
                          <Button
                              title={'Search files'}
                              className="view"
                              color="facebook"
                              key="btnSearch"
                              onClick={() => {

                                  this.refreshFilesTable();

                              }}
                              simple
                              style={{"float": "right",
                                  "padding": "13px"}}
                          >
                              <i className="fa fa-search fa-2x" />
                              <span
                                  style={{"fontSize": "1rem",
                                      "margin": "0",
                                      "paddingLeft": "4px"}}
                              >
                                  Search
                              </span>
                          </Button>
                      </GridItem>
                      <GridItem
                          md={12}
                          sm={12}
                          style={{"borderTop": "1px solid rgb(156, 39, 176)"}}
                          xs={12}
                      >
                          <ReactTable
                              className="-striped -highlight"
                              columns={this.columns}
                              data={files}
                              defaultPageSize={5}
                              getTrProps={(state, rowInfo, column, instance) => {
                                  if (typeof rowInfo !== "undefined") {
                                      return {
                                          "onClick": (e, handleOriginal) => {
                                              this.setState({
                                                  "selected": rowInfo.index
                                              });
                                              if (handleOriginal) {
                                                  handleOriginal();
                                              }
                                          }
                                      };
                                  }
                                  return {
                                      "onClick": (e, handleOriginal) => {
                                          if (handleOriginal) {
                                              handleOriginal();
                                          }
                                      }
                                  };
                              }}
                              itemsCountText={this.state.items_data}
                              loading={loading}
                              manual
                              onFetchData={(state, instance) => {
                                  if (this.state.tags.length > 0) {
                                      this.setState({"loading": true});
                                  }
                                  this.getFilesData(
                                      state.page,
                                      state.pageSize,
                                      state.sorted,
                                      state.filtered,
                                      (res) => this.processFilesData(res)
                                  );
                              }}
                              pages={pages}
                              PaginationComponent={Pagination}
                              ref={(refReactFilesTable) => {
                                  this.refReactFilesTable = refReactFilesTable;
                              }}
                              showPageSizeOptions
                              showPaginationBottom
                              showPaginationTop={false}
                          />
                      </GridItem>
                      {this.state.user_file_details
                          ? <UserFile
                              auth={this.props.auth}
                              handleDownloadFile={this.handleDownloadFile}
                              handleDownloadVersionFile={this.handleDownloadVersionFile}
                              onClose={() => this.setState({"user_file_details": false})}
                              refreshUserContactsTable={this.props.refreshUserContactsTable}
                              setUsersNotification={this.props.setUsersNotification}
                              user_id={this.props.auth.user.id}
                              UserFile={this.state.user_file_details}
                              {...this.state}
                            />
                          : ""}
                  </GridContainer>
              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                  <Button
                      className={classes.modalSmallFooterFirstButton}
                      color="transparent"
                      onClick={() => this.props.onClose()}
                  >
                      Close
                  </Button>
              </DialogActions>
          </Dialog>
      );
  }
}

export default withStyles(extendedFormsStyle)(FileSearch);
