import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

// Core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import helpers from "customs/helpers/helpers";
import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import validations from "customs/helpers/validations";

class AcquirerDetails extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "acquirer_id": this.props.acquirer_details && this.props.acquirer_details.id
                ? this.props.acquirer_details.id
                : null,
            "acquirer_name": this.props.acquirer_details && this.props.acquirer_details.name
                ? this.props.acquirer_details.name
                : "",
            "acquirer_method": this.props.acquirer_details && this.props.acquirer_details.method
                ? this.props.acquirer_details.method
                : "",
            "acquirer_template": this.props.acquirer_details && this.props.acquirer_details.template
                ? this.props.acquirer_details.template
                : "",
            "acquirer_documents": this.props.acquirer_details && this.props.acquirer_details.documents
                ? this.props.acquirer_details.documents
                : "",
            "acquirer_email": this.props.acquirer_details && this.props.acquirer_details.method_config && this.props.acquirer_details.method_config.email
                ? this.props.acquirer_details.method_config.email
                : "",
            "acquirer_subject": this.props.acquirer_details && this.props.acquirer_details.method_config && this.props.acquirer_details.method_config.subject
                ? this.props.acquirer_details.method_config.subject
                : "",
            "acquirer_api_url": this.props.acquirer_details && this.props.acquirer_details.method_config && this.props.acquirer_details.method_config.url
                ? this.props.acquirer_details.method_config.url
                : "",
            "acquirer_api_method": this.props.acquirer_details && this.props.acquirer_details.method_config && this.props.acquirer_details.method_config.method
                ? this.props.acquirer_details.method_config.method
                : "",
            "acquirer_api_auth_type": this.props.acquirer_details && this.props.acquirer_details.method_config && this.props.acquirer_details.method_config.auth_type
                ? this.props.acquirer_details.method_config.auth_type
                : "",
            "acquirer_api_authorization": this.props.acquirer_details && this.props.acquirer_details.method_config && this.props.acquirer_details.method_config.authorization
                ? this.props.acquirer_details.method_config.authorization
                : "",
            "acquirer_api_headers": this.props.acquirer_details && this.props.acquirer_details.method_config && this.props.acquirer_details.method_config.headers
                ? this.setHeaders(this.props.acquirer_details.method_config.headers)
                : this.setHeaders(null),
            "acquirer_api_documents": this.props.acquirer_details && this.props.acquirer_details.documents
                ? this.setDocuments(this.props.acquirer_details.documents)
                : this.setDocuments(null),
        };
        this.doValidateAcquirer = this.doValidateAcquirer.bind(this);

    }

    setHeaders(headers) {
        if (headers !== null) {
            try {
                if (Object.keys(headers).length > 0) {
                    return Object.keys(headers).map((prop, key) => ({
                        "key": headers[prop].key,
                        "value": headers[prop].value
                    }));
                }
            } catch (e) {}
        }
        return [{"key": "", "value": ""}];
    }

    setDocuments(documents) {
        if (documents !== null) {
            try {
                if (Object.keys(documents).length > 0) {
                    return Object.keys(documents).map((prop, key) => ({
                        "id": documents[prop].id !== undefined ? documents[prop].id : null,
                        "title": documents[prop].title,
                        "description": documents[prop].description === null ? "" : documents[prop].description,
                        "accepts": documents[prop].accepts,
                        "is_required": documents[prop].is_required
                    }));
                }
            } catch (e) {}
        }
        return [{"id": null, "title": "", "description": "", "accepts": [".pdf"], "is_required": 1}];
    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "save_acquirer") {
            this.doSaveAcquirerDetails();
        }
    }

    doValidateAcquirer() {
        let cont = true;
        let errors = [];
        if (this.state.acquirer_name.length === 0) {
            this.setState({"acquirer_name_valid": "error"});
            errors.push("Name");
            cont = false;
        }
        if (this.state.acquirer_method.length === 0) {
            this.setState({"acquirer_method_valid": "error"});
            errors.push("Method");
            cont = false;
        } else if (this.state.acquirer_method === 'email') {
            if (this.state.acquirer_email.length === 0 || !validations.validateEmail(this.state.acquirer_email)) {
                this.setState({"acquirer_email_valid": "error"});
                errors.push("Email");
                cont = false;
            }
        } else if (this.state.acquirer_method === 'api') {
            if (this.state.acquirer_api_url.length === 0 || !validations.validateURL(this.state.acquirer_api_url)) {
                this.setState({"acquirer_api_url_valid": "error"});
                errors.push("URL");
                cont = false;
            }
            if (this.state.acquirer_api_method.length === 0) {
                this.setState({"acquirer_api_method_valid": "error"});
                errors.push("Verb");
                cont = false;
            }
            if (this.state.acquirer_api_auth_type.length === 0) {
                this.setState({"acquirer_api_auth_type_valid": "error"});
                errors.push("Authentication type");
                cont = false;
            }
            if (this.state.acquirer_api_auth_type === "basic auth" && this.state.acquirer_api_authorization.length === 0) {
                this.setState({"acquirer_api_authorization_valid": "error"});
                errors.push("Authorization");
                cont = false;
            }
        }
        if (this.state.acquirer_template.length === 0) {
            this.setState({"acquirer_template_valid": "error"});
            errors.push("Template");
            cont = false;
        }

        if (errors.length > 0) {
            this.props.setUsersNotification(
                "Please complete the following:<br>" + errors.join('<br>'),
                "danger"
            );
        }

        return cont;
    }

    handleSaveAcquirerDetails () {
        if (this.doValidateAcquirer()) {
            this.setState({
                "acquirer_name_valid": "",
                "acquirer_method_valid": "",
                "acquirer_email_valid": "",
                "acquirer_api_url_valid": "",
                "acquirer_api_method_valid": "",
                "acquirer_api_auth_type_valid": "",
                "acquirer_api_authorization_valid": "",
                "acquirer_template_valid": "",
                "confirmModal": true,
                "confirmModalMsg": "Are you sure you want to save acquirer details?",
                "confirmModalTitle": `${this.state.acquirer_id === null ? "Create" : "Update"} Acquirer`,
                "action": "save_acquirer"
            });
        }
    }

    doSaveAcquirerDetails () {
        this.setState({"confirmModal": false, "action": ""});
        helpers.showLoading();
        this.setState({"btnDisabled": true});

        let fd = new FormData();
        fd.append("name", this.state.acquirer_name);
        fd.append("method", this.state.acquirer_method);
        fd.append("template", this.state.acquirer_template);
        if (this.state.acquirer_method === 'email') {
            fd.append("method_config[email]", this.state.acquirer_email);
            if (this.state.acquirer_subject.length > 0) fd.append("method_config[subject]", this.state.acquirer_subject);
        } else if (this.state.acquirer_method === 'api') {
            fd.append("method_config[url]", this.state.acquirer_api_url);
            fd.append("method_config[method]", this.state.acquirer_api_method);
            fd.append("method_config[auth_type]", this.state.acquirer_api_auth_type);
            if (this.state.acquirer_api_auth_type === "basic auth") fd.append("method_config[authorization]", this.state.acquirer_api_authorization);
            if (Object.keys(this.state.acquirer_api_headers).length > 0) {
                let idx = 0;
                this.state.acquirer_api_headers.forEach((header) => {
                    if (header.key.length > 0 || header.value.length > 0) {
                        fd.append("method_config[headers][" + idx + "][key]", header.key);
                        fd.append("method_config[headers][" + idx + "][value]", header.value);
                        idx++;
                    }
                });
            }
        }
        if (Object.keys(this.state.acquirer_api_documents).length > 0) {
            let idx = 0;
            this.state.acquirer_api_documents.forEach((document) => {
                if (document.title.length > 0 && document.accepts.length > 0) {
                    if (document.id !== null) {
                        fd.append("documents[" + idx + "][id]", document.id);
                    }
                    fd.append("documents[" + idx + "][title]", document.title);
                    fd.append("documents[" + idx + "][description]", document.description === null ? "" : document.description);
                    document.accepts.forEach((accept) => {
                        if (this.props.config_doc_types.includes(accept)) {
                            fd.append("documents[" + idx + "][accepts][]", accept);
                        }
                    });
                    fd.append("documents[" + idx + "][is_required]", document.is_required);
                    idx++;
                }
            });
        }
        
        let url = `/acquirer`;
        if (this.state.acquirer_id !== null) {
            fd.append("_method", "PATCH");
            url = `${url}/${this.state.acquirer_id}`;
        }

        axios(this.props)
            .post(url, fd, {"headers": {"Content-Type": "application/x-www-form-urlencoded"}})
            .then((response) => {
                if (this.isUnmounted) {
                    return;
                }
                helpers.hideLoading();
                this.props.setUsersNotification(
                    "Acquirer saved",
                    "success"
                );
                this.props.refreshAcquirersTable();
                this.props.onClose("acquirerDetailsModal");
            })
            .catch((error) => this.processErrorAxios(error));
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            (msg, color) => {
                this.props.setUsersNotification(
                    typeof msg === 'string' ? msg : msg.join('<br>'),
                    color
                );
            }
        );
    }

    change (event, stateName, validate) {
        this.setState({[stateName]: event.target.value});
        if (validate) {
            this.setState({
                [`${stateName}_valid`]: event.target.value.length === 0 ? "error" : ""
            });
        }
    }

    addSubFields (elements, element, stateName) {
        elements.push(element);
        this.setState({[stateName]: elements});
    }

    async handleChange (event, stateName, field, type, key) {
        let state_value = this.state[stateName];

        if (type === "checkbox") {
            state_value[key][field] = event.target.checked ? 1 : 0;
        } else {
            state_value[key][field] = event.target.value;
        }

        this.setState({[stateName]: state_value});
    }

    createConfigHeadersUI () {
        const container = this.state.acquirer_api_headers.map((prop, key) => (!this.props.is_view || this.getLength(prop.value) > 0
              ? this.generateConfigHeaderGrid(prop, key)
              : ""))
            .filter((item) => Object.keys(item).length > 0);
  
        return Object.keys(container).length > 0
            ? <GridContainer key="main_header_grid" style={{"padding": "0 15px"}}>
                <GridItem md={12}>
                    Additional Headers
                </GridItem>
                <GridItem md={12}>
                    {container}
                </GridItem>
                {this.props.is_view
                    ? ""
                    : <GridItem
                        className="div-pad-r-3"
                        md={12}
                    >
                        <Button
                            title={'Add header'}
                            className="small-button div-marg-tb-0 div-float-right"
                            color="success"
                            justIcon
                            onClick={() => {
                                this.addSubFields(this.state.acquirer_api_headers, this.setHeaders(null)[0], "acquirer_api_headers");
                            }}
                            simple
                        >
                            <i
                                className="fa fa-plus fa-sm"
                                key="i_config_header_add"
                            />
                        </Button>
                      </GridItem>}
            </GridContainer>
            : container;
    }

    generateConfigHeaderGrid (prop, key) {
        const grid = (<GridContainer key={`headers_grid_${key}`}>
                <GridItem md={6}>
                    <CustomInput
                        labelText="Key"
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "onChange": (event) => this.handleChange(
                                event,
                                "acquirer_api_headers",
                                "key",
                                "string",
                                key
                            ),
                            "value": prop.key,
                            "name": `key_${key}`,
                            "disabled": this.props.is_view
                        }}
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        labelText="Value"
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "onChange": (event) => this.handleChange(
                                event,
                                "acquirer_api_headers",
                                "value",
                                "string",
                                key
                            ),
                            "value": prop.value,
                            "name": `value_${key}`,
                            "disabled": this.props.is_view
                        }}
                    />
                </GridItem>
            </GridContainer>);
        return this.props.is_view
            ? grid
            : <GridContainer key={`container_headers_grid_${key}`}>
                <GridItem md={11}>
                    {grid}
                </GridItem>
                <GridItem
                    className="div-pad-lr-0"
                    md={1}
                >
                    <Button
                        title={'Delete header'}
                        className="small-button"
                        color={prop.id === null ? "warning" : "danger"}
                        justIcon
                        onClick={() => {
                            let {acquirer_api_headers} = this.state;
                            delete acquirer_api_headers[key];
                            if (acquirer_api_headers.length === 0 || Object.keys(acquirer_api_headers).length === 0) {
                                acquirer_api_headers = this.setHeaders(null);
                            }
                            this.setState({acquirer_api_headers});
                        }}
                        simple
                    >
                        <i
                            className={
                                prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                            }
                            key={`i_header_delete${key}`}
                        />
                    </Button>
                </GridItem>
            </GridContainer>;
    }

    getLength (str) {
        if (str === null || str === undefined) {
            return 0;
        }
        return `${str}`.length;
    }

    createDocumentsUI () {
        const container = this.state.acquirer_api_documents.map((prop, key) => (!this.props.is_view || this.getLength(prop.title) > 0
              ? this.generateDocumentGrid(prop, key)
              : ""))
            .filter((item) => Object.keys(item).length > 0);
  
        return Object.keys(container).length > 0
            ? <GridContainer key="main_document_grid" style={{"padding": "0 15px"}}>
                <GridItem md={12} style={{"marginBottom": "10px"}}>
                    Additional Documents
                </GridItem>
                <GridContainer style={{
                    "borderRight": "solid 1px #d8d8d8",
                    "borderLeft": "solid 1px #d8d8d8",
                    "margin": "0 15px"
                }}>
                    <GridItem md={12}>
                        {container}
                    </GridItem>
                {this.props.is_view
                    ? ""
                    : <GridItem
                        className="div-pad-r-3"
                        md={12}
                    >
                        <Button
                            title={'Add document'}
                            className="small-button div-marg-tb-0 div-float-right"
                            color="success"
                            justIcon
                            onClick={() => {
                                this.addSubFields(this.state.acquirer_api_documents, this.setDocuments(null)[0], "acquirer_api_documents");
                            }}
                            simple
                        >
                            <i
                                className="fa fa-plus fa-sm"
                                key="i_config_document_add"
                            />
                        </Button>
                      </GridItem>}
                </GridContainer>
            </GridContainer>
            : container;
    }

    generateDocumentGrid (prop, key) {
        const {classes} = this.props;
        const grid = (<GridContainer key={`documents_grid_${key}`}>
                <GridItem md={12}>
                    <CustomInput
                        labelText="Title"
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "onChange": (event) => this.handleChange(
                                event,
                                "acquirer_api_documents",
                                "title",
                                "string",
                                key
                            ),
                            "value": prop.title,
                            "name": `title_${key}`,
                            "disabled": this.props.is_view
                        }}
                    />
                </GridItem>
                <GridItem md={8}>
                    <FormControl
                        className={classes.selectFormControl}
                        fullWidth
                    >
                        <InputLabel
                            className={classes.selectLabel}
                            htmlFor={`accepts_${key}`}
                        >
                            Accepted Extensions
                        </InputLabel>
                        <Select
                            classes={{"select": classes.select}}
                            inputProps={{
                                "name": `accepts_${key}`,
                                "id": `accepts_${key}`,
                                "disabled": this.props.is_view
                            }}
                            MenuProps={{"className": classes.selectMenu}}
                            multiple
                            onChange={(event) => this.handleChange(
                                event,
                                "acquirer_api_documents",
                                "accepts",
                                "string",
                                key
                            )}
                            value={prop.accepts}
                        >
                            <MenuItem
                                classes={{"root": classes.selectMenuItem}}
                                disabled
                            >
                                Choose accepted extensions
                            </MenuItem>
                            {this.props.config_doc_types
                                ? this.props.config_doc_types.map((prop, key) => <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelectedMultiple
                                        }}
                                        key={"accepts_" + key}
                                        value={prop}
                                    >
                                        {prop}
                                    </MenuItem>)
                                : ""}
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem md={1}>
                    <label
                        htmlFor={`accepts_${key}`}
                        className="customRadioTopLabel"
                    >
                        Required
                    </label>
                    <FormControlLabel
                        disabled={this.props.is_view}
                        control={
                            <Checkbox
                                checked={prop.is_required === 1}
                                onChange={(event) => this.handleChange(
                                    event,
                                    "acquirer_api_documents",
                                    "is_required",
                                    "checkbox",
                                    key
                                )}
                                value={prop.is_required === 1}
                                name={`accepts_${key}`}
                                aria-label={key}
                                icon={<Check className={classes.uncheckedIcon} />}
                                checkedIcon={<Check className={classes.checkedIcon} />}
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot,
                                    disabled: classes.disabledCheckboxAndRadio
                                }}
                            />
                        }
                        classes={{"label": classes.label,
                            root: classes.labelRoot}}
                    />
                </GridItem>
                { this.props.is_view && (prop.description === "" || prop.description === null)
                    ? ""
                    : <GridItem md={12}>
                        <CustomInput
                            labelText="Description"
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "onChange": (event) => this.handleChange(
                                    event,
                                    "acquirer_api_documents",
                                    "description",
                                    "string",
                                    key
                                ),
                                "multiline": true,
                                "rows": 2,
                                "value": prop.description,
                                "name": `description_${key}`,
                                "disabled": this.props.is_view
                            }}
                        />
                    </GridItem>
                }
            </GridContainer>);
        return this.props.is_view
            ? grid
            : <GridContainer key={`container_documents_grid_${key}`}>
                <GridItem md={11}>
                    {grid}
                </GridItem>
                <GridItem
                    className="div-pad-lr-0"
                    md={1}
                >
                    <Button
                        title={'Delete document'}
                        className="small-button"
                        color={prop.id === null ? "warning" : "danger"}
                        justIcon
                        onClick={() => {
                            let {acquirer_api_documents} = this.state;
                            delete acquirer_api_documents[key];
                            if (acquirer_api_documents.length === 0 || Object.keys(acquirer_api_documents).length === 0) {
                                acquirer_api_documents = this.setDocuments(null);
                            }
                            this.setState({acquirer_api_documents});
                        }}
                        simple
                    >
                        <i
                            className={
                                prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                            }
                            key={`i_document_delete${key}`}
                        />
                    </Button>
                </GridItem>
            </GridContainer>;
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="avatar-update-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{"root": `${classes.center} ${classes.modalRoot}`}}
                fullWidth
                keepMounted
                maxWidth="sm"
                onClose={() => this.props.onClose("acquirerDetailsModal")}
                open={this.props.acquirerDetailsModal}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.acquirer_dialog_title}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="avatar-update-modal-slide-description"
                    style={{"width": "100%",
                        "overflowX": "hidden"}}
                >
                    <GridContainer
                        key="details_grid"
                        style={{"borderTop": "#9c27b0 solid 1px"}}
                    >
                        <GridItem md={12}>
                            <CustomInput
                                formControlProps={{"fullWidth": true}}
                                error={this.state.acquirer_name_valid === "error"}
                                inputProps={{
                                    "onChange": (event) => this.change(
                                        event,
                                        "acquirer_name",
                                        false
                                    ),
                                    "value": this.state.acquirer_name,
                                    "name": "Name",
                                    "disabled": this.props.is_view
                                }}
                                labelText="Name"
                            />
                        </GridItem>
                        <GridItem md={12}>
                            <FormControl
                                className={classes.selectFormControl}
                                fullWidth
                            >
                                <InputLabel
                                    className={classes.selectLabel}
                                    htmlFor="acquirer_method"
                                >
                                    Method
                                </InputLabel>
                                <Select
                                    classes={{"select": classes.select}}
                                    inputProps={{
                                        "name": "acquirer_method",
                                        "id": "acquirer_method",
                                        "disabled": this.props.is_view
                                    }}
                                    MenuProps={{"className": classes.selectMenu}}
                                    onChange={(event) => this.change(event, "acquirer_method", false)}
                                    value={this.state.acquirer_method}
                                    error={this.state.acquirer_method_valid === "error"}
                                >
                                    <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        disabled
                                    >
                                        Choose a method
                                    </MenuItem>
                                    {this.props.methods
                                        ? this.props.methods.map((prop, key) => <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelectedMultiple
                                                }}
                                                key={"method_" + key}
                                                value={prop}
                                            >
                                                {prop === 'api' ? 'API' : startCase(toLower(prop))}
                                            </MenuItem>)
                                        : ""}
                                </Select>
                            </FormControl>
                        </GridItem>
                        <GridContainer style={{
                            "borderRight": "solid 1px #d8d8d8",
                            "borderLeft": "solid 1px #d8d8d8",
                            "margin": "0 15px"
                        }}>
                            {/* EMAIL METHOD */}
                            <GridItem md={12}
                                style={{
                                    "display": this.state.acquirer_method !== 'email' ? "none" : "block"
                                }}
                            >
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "onChange": (event) => this.change(
                                            event,
                                            "acquirer_email",
                                            false
                                        ),
                                        "value": this.state.acquirer_email,
                                        "name": "Email Address",
                                        "disabled": this.props.is_view
                                    }}
                                    labelText="Email Address"
                                    error={this.state.acquirer_email_valid === "error"}
                                />
                            </GridItem>
                            <GridItem md={12}
                                style={{
                                    "display": this.state.acquirer_method !== 'email' || (this.props.is_view && this.state.acquirer_subject === "") ? "none" : "block"
                                }}
                            >
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "onChange": (event) => this.change(
                                            event,
                                            "acquirer_subject",
                                            false
                                        ),
                                        "value": this.state.acquirer_subject,
                                        "name": "Email Subject",
                                        "disabled": this.props.is_view
                                    }}
                                    labelText="Email Subject"
                                />
                            </GridItem>
                            {/* EMAIL METHOD */}
                            {/* API METHOD */}
                            <GridItem md={12}
                                style={{
                                    "display": this.state.acquirer_method !== 'api' ? "none" : "block"
                                }}
                            >
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "onChange": (event) => this.change(
                                            event,
                                            "acquirer_api_url",
                                            false
                                        ),
                                        "value": this.state.acquirer_api_url,
                                        "name": "URL",
                                        "disabled": this.props.is_view
                                    }}
                                    labelText="URL"
                                    error={this.state.acquirer_api_url_valid === "error"}
                                />
                            </GridItem>
                            <GridItem md={4}
                                style={{
                                    "display": this.state.acquirer_method !== 'api' ? "none" : "block"
                                }}
                            >
                                <FormControl
                                    className={classes.selectFormControl}
                                    fullWidth
                                >
                                    <InputLabel
                                        className={classes.selectLabel}
                                        htmlFor="acquirer_api_method"
                                    >
                                        Verb
                                    </InputLabel>
                                    <Select
                                        classes={{"select": classes.select}}
                                        inputProps={{
                                            "name": "acquirer_api_method",
                                            "id": "acquirer_api_method",
                                            "disabled": this.props.is_view
                                        }}
                                        MenuProps={{"className": classes.selectMenu}}
                                        onChange={(event) => this.change(event, "acquirer_api_method", false)}
                                        value={this.state.acquirer_api_method}
                                        error={this.state.acquirer_api_method_valid === "error"}
                                    >
                                        <MenuItem
                                            classes={{"root": classes.selectMenuItem}}
                                            disabled
                                        >
                                            Choose a verb
                                        </MenuItem>
                                        {this.props.config_methods
                                            ? this.props.config_methods.map((prop, key) => <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple
                                                    }}
                                                    key={"method_" + key}
                                                    value={prop}
                                                >
                                                    {prop === 'api' ? 'API' : startCase(toLower(prop))}
                                                </MenuItem>)
                                            : ""}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem md={8}
                                style={{
                                    "display": this.state.acquirer_method !== 'api' ? "none" : "block",
                                }}
                            >
                                <FormControl
                                    className={classes.selectFormControl}
                                    fullWidth
                                >
                                    <InputLabel
                                        className={classes.selectLabel}
                                        htmlFor="acquirer_api_auth_type"
                                    >
                                        Authentication Type
                                    </InputLabel>
                                    <Select
                                        classes={{"select": classes.select}}
                                        inputProps={{
                                            "name": "acquirer_api_auth_type",
                                            "id": "acquirer_api_auth_type",
                                            "disabled": this.props.is_view
                                        }}
                                        MenuProps={{"className": classes.selectMenu}}
                                        onChange={(event) => this.change(event, "acquirer_api_auth_type", false)}
                                        value={this.state.acquirer_api_auth_type}
                                        error={this.state.acquirer_api_auth_type_valid === "error"}
                                    >
                                        <MenuItem
                                            classes={{"root": classes.selectMenuItem}}
                                            disabled
                                        >
                                            Choose an authentication type
                                        </MenuItem>
                                        {this.props.config_auths
                                            ? this.props.config_auths.map((prop, key) => <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelectedMultiple
                                                    }}
                                                    key={"method_" + key}
                                                    value={prop}
                                                >
                                                    {prop}
                                                </MenuItem>)
                                            : ""}
                                    </Select>
                                </FormControl>
                            </GridItem>
                            <GridItem md={4}
                                style={{
                                    "display": this.state.acquirer_method !== 'api' || this.state.acquirer_api_auth_type !== 'basic auth' ? "none" : "block"
                                }}
                            >
                            </GridItem>
                            <GridItem md={8}
                                style={{
                                    "display": this.state.acquirer_method !== 'api' || this.state.acquirer_api_auth_type !== 'basic auth' ? "none" : "block"
                                }}
                            >
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "onChange": (event) => this.change(
                                            event,
                                            "acquirer_api_authorization",
                                            false
                                        ),
                                        "value": this.state.acquirer_api_authorization,
                                        "name": "Authorization",
                                        "disabled": this.props.is_view
                                    }}
                                    labelText="Authorization"
                                    error={this.state.acquirer_api_authorization_valid === "error"}
                                />
                            </GridItem>
                            <style>
                                {".div-pad-lr-0 {padding-left: 0px !important; padding-right: 0px !important;}" +
                                    ".div-marg-tb-0 {margin-top: 0px !important; margin-bottom: 0px !important;}" +
                                    ".div-pad-r-3 {padding-right: 3px !important;}" +
                                    ".div-float-right {float: right}"}
                            </style>
                            {this.state.acquirer_method === 'api' ? this.createConfigHeadersUI() : ""}
                            {/* API METHOD */}
                        </GridContainer>
                        <GridItem md={12}>
                            <FormControl
                                className={classes.selectFormControl}
                                fullWidth
                            >
                                <InputLabel
                                    className={classes.selectLabel}
                                    htmlFor="acquirer_template"
                                >
                                    Template
                                </InputLabel>
                                <Select
                                    classes={{"select": classes.select}}
                                    inputProps={{
                                        "name": "acquirer_template",
                                        "id": "acquirer_template",
                                        "disabled": true //this.props.is_view
                                    }}
                                    MenuProps={{"className": classes.selectMenu}}
                                    onChange={(event) => this.change(event, "acquirer_template", false)}
                                    value={this.state.acquirer_template}
                                    error={this.state.acquirer_template_valid === "error"}
                                >
                                    <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        disabled
                                    >
                                        Choose a template
                                    </MenuItem>
                                    {this.props.templates
                                        ? Object.keys(this.props.templates).map((key) => <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelectedMultiple
                                                }}
                                                key={"template_" + key}
                                                value={key}
                                            >
                                                {this.props.templates[key].name}
                                            </MenuItem>)
                                        : ""}
                                </Select>
                            </FormControl>
                        </GridItem>
                        {/* {this.createDocumentsUI()} */}
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("acquirerDetailsModal")}
                    >
                        Close
                    </Button>
                    {!this.props.is_view
                        ? <Button
                            className={
                                `${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`
                            }
                            color="success"
                            disabled={this.props.btnDisabled}
                            onClick={(event) => this.handleSaveAcquirerDetails()}
                            simple
                          >
                            Save
                        </Button>
                        : ""}
                </DialogActions>
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.setState({"confirmModal": false})}
                    onYesClick={() => this.handleConfirmClick()}
                />
                {this.state.doneModalMsg
                    ? <Snackbar
                        close
                        closeNotification={() => {

                            this.handleCloseNotification();

                        }}
                        color={this.state.tr_color}
                        icon={AddAlert}
                        message={typeof this.state.doneModalMsg === 'string' ? this.state.doneModalMsg : this.state.doneModalMsg.join('<br>')}
                        open={this.state.tr}
                        place="tr"
                      />
                    : ""}
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(AcquirerDetails);
