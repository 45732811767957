import React from "react";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Chip from "@material-ui/core/Chip";
import Badge from "@material-ui/core/Badge";

import PublicIcon from "@material-ui/icons/Public";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
// import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import Pages from "../../layouts/Auth";
import NoteIcon from "@material-ui/icons/Note";
import AttachmentIcon from "@material-ui/icons/Attachment";
import ContactsIcon from "@material-ui/icons/Contacts";

// import Avatar from "@material-ui/core/Avatar";
// import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Description from "@material-ui/icons/Description";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import Card from "components/Card/Card.jsx";

const Transition = React.forwardRef((props, ref) => (<Slide direction="up"
ref={ref}
{...props} />));

function OverviewComponent (props) {

    const [
            open,
            setOpen
        ] = React.useState(false),
        [
            currentTab,
            setCurrentTab
        ] = React.useState(0),
        clientDetails = props.getClients(),
        styles = props,
        handleClickOpen = () => {
            setOpen(true);
        },

        handleClose = () => {
            setOpen(false);
        },

        generateNotes = () => {
            if (clientDetails.data.notes.length === 0) {
                return <GridContainer
                    key={"client_contact_grid0"}
                    style={{"borderTop": "#9c27b0 solid 1px"}}
                >
                    <GridItem md={12}>
                        <Typography style={{"paddingTop": "8px"}}>
                            No notes
                        </Typography>
                    </GridItem>
                    </GridContainer>
            }

            return clientDetails.data.notes.map((note, index) => (
                <GridContainer
                    key={"client_contact_grid"+index}
                    style={{"borderTop": "#9c27b0 solid 1px"}}
                >
                    <ListItem key={"note_"+index}>
                        <ListItemText
                            primary={note.subject + ' (' + note.author + ')'}
                            secondary={note.note}
                        />
                    </ListItem>
                </GridContainer>
            ))
        },

        // generateCheckList = () => clientDetails.data.checklist_summary.map((checklist, index) => (<GridItem md={3} key={"checklist_"+index}>
        //         <GridContainer
        //             alignItems="center"
        //             // justifyContent="center"
        //         >
        //             <Box
        //                 position="relative"
        //                 display="inline-flex"
        //             >
        //                 <CircularProgress
        //                     value={parseFloat(checklist.progress)}
        //                     variant="static"
        //                 />
        //                 <Box
        //                     alignItems="center"
        //                     bottom={0}
        //                     display="flex"
        //                     justifyContent="center"
        //                     left={0}
        //                     position="absolute"
        //                     right={0}
        //                     top={0}
        //                 >
        //                     <Typography
        //                         color="textSecondary"
        //                         component="div"
        //                         variant="caption"
        //                     >
        //                         {checklist.completed_steps}
        //                     </Typography>
        //                 </Box>
        //             </Box>
        //             <Box paddingLeft={2}>
        //                 <Typography
        //                     variant="button"
        //                     component="div"
        //                 >
        //                     {checklist.checklist_name}
        //                 </Typography>
        //             </Box>
        //         </GridContainer>
        //     </GridItem>)),

        generateContacts = (primary) => {
            let contacts = clientDetails.data.contacts.filter((contact) => {
                return primary ? clientDetails.data.primary_contact_id === contact.id : clientDetails.data.primary_contact_id !== contact.id;
            });
            if (contacts.length === 0) {
                return <GridContainer
                    key={"client_contact_grid0"}
                    style={{"borderTop": "#9c27b0 solid 1px"}}
                >
                    <GridItem md={12}>
                        <Typography style={{"paddingTop": "8px"}}>
                            No other contacts
                        </Typography>
                    </GridItem>
                    </GridContainer>
            }
            return contacts.map((value, index) => (<div key={"contact_"+index} style={{"paddingBottom": "8px"}}>
                <GridContainer
                    key={"client_contact_grid"+index}
                    style={{"borderTop": "#9c27b0 solid 1px"}}
                >
                    <GridItem md={12}>
                        <Typography style={{"paddingTop": "8px"}}>
                            {`${value.first_name  } ${  value.last_name}`}
                        </Typography>
                    </GridItem>
                    <GridItem md={6}>
                        <CustomInput
                            formControlPr
                            inputProps={{
                            "value": value.company,
                            "name": "company",
                            "disabled": true
                            }}
                            labelText="Company"
                            ops={{fullWidth: true}}
                        />
                    </GridItem>
                    <GridItem md={6}>
                        <CustomInput
                            formControlProps={{fullWidth: true}}
                            inputProps={{
                            "value": value.position,
                            "name": "position",
                            "disabled": true
                            }}
                            labelText="Position"
                        />
                    </GridItem>
                </GridContainer>
                {value.emails.map((v, index) => 
                    <Chip
                        key={"email_"+index}
                        color="primary"
                        size="small"
                        className={styles.chip}
                        // classes={styles}
                        icon={<AlternateEmailIcon />}
                        label={v.email_address}
                    />
                )}
                {value.phones.map((v, index) => 
                    <Chip
                        key={"phone_"+index}
                        color="primary"
                        size="small"
                        className={styles.chip}
                        // classes={styles}
                        icon={<PhoneIcon />}
                        label={
                        "(" + v.country_code.calling_code + ") " + v.phone_number
                        }
                    />
                )}
                <style>
                    {".MuiChip-label {user-select: all !important;} .MuiChip-root {margin: 1px;}"}
                </style>
            </div>));
        }

    return (
        <>
            <Button
                className="overview"
                color="primary"
                justIcon
                onClick={handleClickOpen}
                round
                simple
            >
                <PublicIcon />
            </Button>
            <Dialog
                aria-describedby="dialog-slide-description"
                aria-labelledby="dialog-slide-title"
                keepMounted
                maxWidth="sm"
                onClose={handleClose}
                open={open}
                TransitionComponent={Transition}
            >
                <DialogTitle style={{"paddingBottom": "0px"}}>
                    {clientDetails.company}
                </DialogTitle>
                <DialogContent>
                    <CustomTabs
                        changeTabCallBack={(tab) =>setCurrentTab(tab)}
                        headerColor="primary"
                        tabs={[
                            {
                                "tabName": "Details",
                                "tabContent":
                                <GridContainer style={{"minHeight": "250px"}}>
                                    <Card style={{"boxShadow": "none", "margin": "0px"}}>
                                        <Box
                                            display="flex"
                                            flexDirection="row-reverse"
                                        >
                                            <Box p={1} title={"Files"}>
                                                <Badge
                                                    badgeContent={clientDetails.data.files.length > 0 ? clientDetails.data.files.length : ""}
                                                    {...(clientDetails.data.files.length > 0 ? {"color": "primary"} : [])}
                                                    showZero
                                                >
                                                    <AttachmentIcon />
                                                </Badge>
                                            </Box>
                                            <Box p={1} title={"Notes"}>
                                                <Badge
                                                    badgeContent={clientDetails.data.notes.length > 0 ? clientDetails.data.notes.length : ""}
                                                    {...(clientDetails.data.notes.length > 0 ? {"color": "primary"} : [])}
                                                    showZero
                                                >
                                                    <NoteIcon />
                                                </Badge>
                                            </Box>
                                            <Box p={1} title={"Applications"}>
                                                <Badge
                                                    badgeContent={clientDetails.data.applicationsOverview.length > 0 ? clientDetails.data.applicationsOverview.length : ""}
                                                    {...(clientDetails.data.applicationsOverview.length > 0 ? {"color": "error"} : [])}
                                                    showZero
                                                >
                                                    <Description />
                                                </Badge>
                                            </Box>
                                            <Box p={1} title={"Contacts"}>
                                                <Badge
                                                    badgeContent={clientDetails.data.contacts.length > 0 ? clientDetails.data.contacts.length : ""}
                                                    {...(clientDetails.data.contacts.length > 0 ? {"color": "secondary"} : [])}
                                                    showZero
                                                >
                                                    <ContactsIcon />
                                                </Badge>
                                            </Box>
                                        </Box>
                                        {/* Client Details */}
                                        {clientDetails.username
                                            ? <GridContainer
                                                key={"client_detail_grid"+props.id}
                                                style={{"borderTop": "#9c27b0 solid 1px"}}
                                            >
                                                {/* <GridItem md={6}>
                                                    <CustomInput
                                                        formControlProps={{"fullWidth": true}}
                                                        inputProps={{
                                                            "value": clientDetails.data && clientDetails.data.abn ? clientDetails.data.abn : '',
                                                            "name": "abn",
                                                            "disabled": true
                                                        }}
                                                        labelText="ABN/ACN"
                                                    />
                                                </GridItem> */}
                                                <GridItem md={12}>
                                                    <CustomInput
                                                        formControlProps={{"fullWidth": true}}
                                                        inputProps={{
                                                            "value": clientDetails.username,
                                                            "name": "username",
                                                            "disabled": true
                                                        }}
                                                        labelText="Username"
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            : ""
                                        }
                                        
                                        <Typography>
                                            {'Primary Contact'}
                                        </Typography>
                                        {generateContacts(true)}
                                        {/* {clientDetails.data.notes && clientDetails.data.notes.length > 0
                                            ? <div><h4>Notes</h4><List>{generateNotes()}</List></div>
                                            : ''} */}
                                    </Card>
                                </GridContainer>
                            },
                            {
                                "tabName": "Other Contacts",
                                "tabContent":
                                <GridContainer style={{"minHeight": "250px"}}>
                                    <Card style={{"boxShadow": "none"}}>
                                        {generateContacts(false)}
                                    </Card>
                                </GridContainer>
                            },
                            {
                                "tabName": "Notes",
                                "tabContent":
                                <GridContainer style={{"minHeight": "250px"}}>
                                    <Card style={{"boxShadow": "none"}}>
                                        {generateNotes()}
                                    </Card>
                                </GridContainer>
                            }
                        ]}
                        value={currentTab}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

const Overview = withStyles(extendedFormsStyle)(OverviewComponent);

export default Overview;
