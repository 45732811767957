import React from "react";

import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Form from "@rjsf/material-ui";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import CustomFileWidget from "../CustomFileWidget";
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import DialogActions from "@material-ui/core/DialogActions";
import ConfirmDialog from "customs/components/ConfirmDialog";
import PreviewDocument from "customs/components/ScriptStep/PreviewDocument.jsx";
import SelectFileToPreview from "customs/components/ScriptStep/SelectFileToPreview.jsx";
import SignaturePad from "customs/components/ScriptStep/SignaturePad.jsx";
import CustomCheckboxWidget from "../CustomCheckboxWidget.tsx";
import CustomRadioWidget from "../CustomRadioWidget.tsx";
import CustomTextWidget from "../CustomTextWidget.tsx";
import CustomPasswordTextWidget from "../CustomPasswordTextWidget.tsx";
import CustomTextareaWidget from "../CustomTextareaWidget.tsx";
import CustomDateWidget from "../CustomDateWidget.tsx";
import CustomRangeWidget from "../CustomRangeWidget.tsx";
import helpers from "customs/helpers/helpers";
import axios from "../../../axios/client-axios";
import axiosHelper from "axios/axiosHelper";
import AddressAutocomplete from "customs/components/ScriptStep/AddressAutocomplete";
import Icon from '@material-ui/core/Icon';
import SampleID from "customs/components/ScriptStep/SampleID.jsx";
import Constants from "customs/config/Constants";
import validations from "customs/helpers/validations";

const CustomSelectWidget = (props) => {
    let files = ["pdf", "png", "bmp", "jpg", "jpeg"]; //, "txt", "doc", "docx", "xls", "xlsx"
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    let isMultiple = props.schema.multiple ? props.schema.multiple : false;
    let value = props.value === undefined || props.value === null ? undefined : (isMultiple ? props.value.split('||') : props.value);
    if (Array.isArray(value) && value.length === 1 && value[0].length === 0) value = undefined;
    return (
        <GridContainer
            key="{{ props.id }}_grid"
        >
            <GridItem md={8 + (value && value.length > 0 ? 0 : 3)}>
                <span id={props.id + '_lbl'} style={{color: "#aaaaaa", fontWeight: "normal", paddingTop: "3px"}}>
                    {typeof props.label === 'object' ? "" : props.label}
                </span>
                {isMultiple ? <style>{"p#" + props.id + " {display: none !important;}"}</style> : <style></style>}
                <Select
                    id={props.id}
                    value={value ? value : (isMultiple ? [] : '')}
                    required={props.required}
                    disabled={props.disabled || props.readonly}
                    readOnly={props.readonly}
                    onChange={(event) => props.onChange(isMultiple ? event.target.value.filter((item) => item.length > 0).sort().join('||') : event.target.value)}
                    onBlur={(event) => props.onBlur(event.target.name, isMultiple ? event.target.value.filter((item) => item.length > 0).sort().join('||') : event.target.value)}
                    onFocus={props.onFocus}
                    placeholder={props.placeholder}
                    options={props.options}
                    classes={{"select": props.classes.select}}
                    MenuProps={{"className": props.classes.selectMenu}}
                    fullWidth
                    label={typeof props.label === 'object' ? "" : props.label}
                    schema={props.schema}
                    inputProps={{
                        "name": props.id,
                        "id": props.id
                    }}
                    multiple={isMultiple}
                    error={isMultiple ? false : props.rawErrors !== undefined && props.rawErrors.length > 0}
                >
                    {props.options.enumOptions
                        ? (isMultiple ? props.options.enumOptions.filter((prop) => prop.value.length > 0) : props.options.enumOptions).map((prop, key) => {
                            return <MenuItem
                                classes={{
                                    root: props.classes.selectMenuItem,
                                    selected: props.classes.selectMenuItemSelectedMultiple
                                }}
                                key={key}
                                value={prop.value}
                            >
                                {prop.label} 
                            </MenuItem>
                        })
                        : ""}
                </Select>
            </GridItem>
            {
                value && value.length > 0
                    ? <GridItem md={3} style={{"textAlign": "right"}}>
                        <Button color="success" className={props.classes.marginRight} type="button" id={props.id + '_btn'} onClick={() => {
                            isMultiple
                            ? props.onPreviewMultiple(value, files)
                            : (
                                files.includes(value.split('.').pop().toLowerCase())
                                ? props.onPreview(value)
                                : props.onDownload(value.split(') ')[0].replace('(id: ', ''), value.split(') ')[1])
                            )
                        }} disabled={props.readonly} >
                            {
                                isMultiple
                                ? (value.length === 1 && !files.includes(value[0].split('.').pop().toLowerCase()) ? "Download" : "Preview")
                                : (files.includes(value.split('.').pop().toLowerCase()) ? "Preview" : "Download")
                            }
                        </Button>
                    </GridItem>
                    : ""
            }
            <GridItem md={1}>
                {
                    props.isWatched
                    ? ""
                    : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                        onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
                }
            </GridItem>
        </GridContainer>
    );
}

const CustomSelect = (props) => {
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    return (
        <GridContainer
            key="{{ props.id }}_grid"
        >
            <GridItem md={11}>
                <span id={props.id + '_lbl'} style={{color: "#aaaaaa", fontWeight: "normal", paddingTop: "3px"}}>
                    {typeof props.label === 'object' ? "" : props.label}
                </span>
                <Select
                    id={props.id}
                    value={props.value ? props.value : ''}
                    required={props.required}
                    disabled={props.disabled || props.readonly}
                    readOnly={props.readonly}
                    onChange={(event) => props.onChange(event.target.value)}
                    onBlur={(event) => props.onBlur(event.target.name, event.target.value)}
                    onFocus={props.onFocus}
                    placeholder={props.placeholder}
                    options={props.options}
                    classes={{"select": props.classes.select}}
                    MenuProps={{"className": props.classes.selectMenu}}
                    fullWidth
                    label={typeof props.label === 'object' ? "" : props.label}
                    schema={props.schema}
                    inputProps={{
                        "name": props.id,
                        "id": props.id
                    }}
                >
                    {props.options.enumOptions
                        ? props.options.enumOptions.map((prop, key) => {
                            return <MenuItem
                                classes={{
                                    root: props.classes.selectMenuItem,
                                    selected: props.classes.selectMenuItemSelectedMultiple
                                }}
                                key={key}
                                value={prop.value}
                            >
                                {prop.label} 
                            </MenuItem>
                        })
                        : ""}
                </Select>
            </GridItem>
            <GridItem md={1}>
                {
                    props.isWatched
                    ? ""
                    : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                        onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
                }
            </GridItem>
        </GridContainer>
    );
}

const CustomMccSelect = (props) => {
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    const [
        opened,
        setOpened
    ] = React.useState(false),
    [
        optionsFilter,
        setOptionsFilter
    ] = React.useState("");
    let value = Array.isArray(props.value) && props.value.length > 0 ? props.value : (props.value ? [props.value] : []);
    let mcc_details = undefined;
    try {mcc_details = value.length > 0 ? props.schema.mccs.filter((mcc) => mcc.code === value[0])[0] : undefined;} catch(e) {}
    return (
        <GridContainer
            key="{{ props.id }}_grid"
        >
            <GridItem md={11}>
                <span id={props.id + '_lbl'} style={{color: "#aaaaaa", fontWeight: "normal", paddingTop: "3px"}}>
                    {typeof props.label === 'object' ? "" : props.label}
                </span>
                <Select
                    id={props.id}
                    value={value}
                    required={props.required}
                    disabled={props.disabled || props.readonly}
                    readOnly={props.readonly}
                    onChange={(event) => {
                        let selected = event.target.value;
                        let filterSelected = false;
                        if (Object.keys(selected).length > 0) {
                            filterSelected = selected.includes('filter');
                            selected = selected.filter((item) => item.length > 0).filter((item) => item !== 'filter');
                            if (props.value) {
                                selected = selected.filter((item) => item !== props.value);
                            }
                        }
                        try {
                            if (selected[0] === props.value) {
                                return;
                            }
                        } catch(e) {}
                        if (!filterSelected) {
                            try {setOpened(false);} catch(e) {}
                        }
                        props.onChange(Object.keys(selected).length > 0 ? selected[0] : props.value);

                    }}
                    onBlur={(event) => props.onBlur(event.target.name, event.target.value)}
                    onFocus={props.onFocus}
                    placeholder={props.placeholder}
                    options={props.options}
                    classes={{"select": props.classes.select}}
                    MenuProps={{"className": props.classes.selectMenu}}
                    fullWidth
                    label={typeof props.label === 'object' ? "" : props.label}
                    schema={props.schema}
                    inputProps={{
                        "name": props.id,
                        "id": props.id
                    }}
                    onClose={() => {setOptionsFilter(""); setOpened(false);}}
                    onOpen={() => {setOptionsFilter(""); setOpened(true);}}
                    open={opened}
                    multiple
                    title={mcc_details === undefined ? '' : props.setMccTooltip(mcc_details)}
                >
                    <MenuItem
                        classes={{"root": props.classes.selectMenuItem}}
                        disabled
                    >
                        Choose an MCC
                    </MenuItem>
                    <MenuItem
                        classes={{"root": props.classes.selectMenuItem}}
                        value="filter"
                    >
                        <input type="text"
                            style={{"width": "100%", "padding": "10px 5px"}}
                            placeholder={"Search MCC"}
                            onChange={(event) => {setOptionsFilter(event.target.value)}}
                            />
                    </MenuItem>
                    {props.schema.mccs
                        ? props.schema.mccs
                            .filter((mcc) => {
                                let f = (optionsFilter !== undefined ? optionsFilter : "").toLowerCase();
                                return mcc.code.toLowerCase().includes(f) ||
                                    mcc.title.toLowerCase().includes(f) ||
                                    mcc.description.toLowerCase().includes(f) ||
                                    mcc.inclusions.toLowerCase().includes(f) ||
                                    mcc.similar.toLowerCase().includes(f)
                            })
                            .map((prop, key) => {
                                return <MenuItem
                                    classes={{
                                        root: props.classes.selectMenuItem,
                                        selected: props.classes.selectMenuItemSelectedMultiple
                                    }}
                                    key={key}
                                    value={prop.code}
                                    title={props.setMccTooltip(prop)}
                                >
                                    {'(' + prop.code + ') ' + prop.title.slice(0, 80)} 
                                </MenuItem>
                            })
                        : ""}
                </Select>
            </GridItem>
            <GridItem md={1}>
                {
                    props.isWatched
                    ? ""
                    : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                        onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
                }
            </GridItem>
        </GridContainer>
    );
}

const LabelledFileField = (props) => {
    let label = 'Or choose and upload a new file';
    let ignore_watch = props.isWatched;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    if (props.hasOwnProperty('uiSchema') && props.uiSchema.hasOwnProperty('ui:options')) {
        if (props.uiSchema['ui:options'].hasOwnProperty('hide_alt_label') && props.uiSchema['ui:options']['hide_alt_label'] === true) {
            label = props.label;
        } else if (props.uiSchema['ui:options'].hasOwnProperty('alt_label')) {
            label = props.uiSchema['ui:options']['alt_label'];
            ignore_watch = true;
        } else {
            ignore_watch = true;
        }
    }
    let isMultiple = props.schema.hasOwnProperty('multiple') ? props.schema.multiple : false;
    let value = props.value;
    if (Array.isArray(value) && value.length === 1 && (Array.isArray(value[0]) || value[0] === undefined)) value = undefined;
    return (
        <>
        <GridContainer
            key="{{ props.id }}_grid"
        >
            {isMultiple ? <style>{"p#" + props.id + " {display: none !important;}"}</style> : <style></style>}
            <GridItem md={11}>
                <Typography component="div" style={{'paddingBottom': '8px'}}>{label}</Typography>
                {/* {title !== null
                    ? <Typography
                            color="textSecondary"
                            component="div"
                            variant="caption"
                        >{title}</Typography>
                    : ''} */}
                <CustomFileWidget {...props} value={value} multiple={isMultiple} onFocus={(key, value) => {props.handleFocusEvent(key, value)}} onBlur={(key, value) => {props.handleBlurEvent(key, value)}}/>
            </GridItem>
            <GridItem md={1}>
                {
                    ignore_watch
                    ? ""
                    : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                        onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
                }
            </GridItem>
        </GridContainer>
        </>
    );
};

const LabelledFileSignatureField = (props) => {
    let label = 'Or choose and upload a new file';
    let ignore_watch = props.isWatched;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    if (props.hasOwnProperty('uiSchema') && props.uiSchema.hasOwnProperty('ui:options')) {
        if (props.uiSchema['ui:options'].hasOwnProperty('hide_alt_label') && props.uiSchema['ui:options']['hide_alt_label'] === true) {
            label = props.label;
        } else if (props.uiSchema['ui:options'].hasOwnProperty('alt_label')) {
            label = props.uiSchema['ui:options']['alt_label'];
            ignore_watch = true;
        } else {
            ignore_watch = true;
        }
    }
    return (
        <>
        <GridContainer
            key="{{ props.id }}_grid"
        >
            <GridItem md={7}>
                <Typography component="div" style={{'paddingBottom': '8px'}}>{label}</Typography>
                <CustomFileWidget {...props} onFocus={(key, value) => {props.handleFocusEvent(key, value)}} onBlur={(key, value) => {props.handleBlurEvent(key, value)}}/>
            </GridItem>
            <GridItem md={4} style={{"textAlign": "right"}}>
                <Button color="success" className={props.classes.marginRight} type="button" id={props.id + '_btn'} onClick={() => {props.onShowSignaturePad(props.schema);}}
                    disabled={props.readonly}
                >
                    {"Signature Pad"}
                </Button>
            </GridItem>
            <GridItem md={1}>
                {
                    ignore_watch
                    ? ""
                    : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                        onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
                }
            </GridItem>
        </GridContainer>
        </>
    );
};

const CustomInputAsLabelWidget = (props) => {
    let {id, label} = props;
    return (
        <label id={id} style={{fontSize: "16px", fontWeight: "normal", paddingTop: "3px"}}>
            {typeof label === 'object' ? "" : label}
        </label>
    );
}

const CustomBottonWidget = (props) => {
    let {id, label, classes} = props;
    return (
        <span>
            <Button color="success" className={classes.marginRight} type="button" id={id} onClick={() => {props.onClick(props.uiSchema)}}
                disabled={props.readonly}
            >
                {typeof label === 'object' ? "" : label}
            </Button>
        </span>
    );
}

const CustomCheckboxPreview = (props) => {
    const {label, ...p} = props;
    let registry = props.schema.hasOwnProperty('registry_key')
        ? props.getRegistryValue(props.schema.registry_key)
        : (props.schema.hasOwnProperty('verify_config') ? props.getRegistryValueFromVerifyConfig(props.schema.verify_config) : null);
    let files = ["pdf", "png", "bmp", "jpg", "jpeg"]; //, "txt", "doc", "docx", "xls", "xlsx"
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    let selectedFiles = registry !== null && registry.hasOwnProperty('files')
        ? registry.files.map((file) => '(id: ' + file.value + ') ' + file.filename) : [];
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={8}>
                <CustomCheckboxWidget {...p} label={helpers.addLineBreaks(label + (registry !== null && registry.uploader === 'Client' ? "<br>(Client Supplied)" : ""))} />
            </GridItem>
            <GridItem md={3} style={{"textAlign": "right"}}>
            {
                selectedFiles && selectedFiles.length > 0
                ? <Button color="success" className={props.classes.marginRight} type="button" id={props.id + '_btn'} onClick={() => {
                    props.onPreviewMultiple(selectedFiles, files)
                }} disabled={props.readonly} >
                    {selectedFiles.length === 1 && !files.includes(selectedFiles[0].split('.').pop().toLowerCase()) ? "Download" : "Preview"}
                </Button>
                : <Button color={registry !== null ? "success" : "warning"} className={props.classes.marginRight} type="button" id={props.id + '_btn'} onClick={() => {
                        if (registry !== null) {
                            if (registry.filename && files.includes(registry.filename.split('.').pop().toLowerCase())) {
                                props.onPreview('(id: ' + registry.value + ') ' + registry.filename);
                            } else {
                                props.onDownload(registry.value, registry.filename);
                            }
                        }
                    }} disabled={props.readonly}
                >
                    {registry !== null ? (registry.filename && files.includes(registry.filename.split('.').pop().toLowerCase()) ? "Preview" : "Download") : "Not set"}
                </Button>
            }
            </GridItem>
            <GridItem md={1}>
                {
                    props.isWatched
                    ? ""
                    : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                        onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
                }
            </GridItem>
        </GridContainer>
    );
}

const CustomCheckbox = (props) => {
    const {label, ...p} = props;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={11}>
                <CustomCheckboxWidget {...p} label={label} />
            </GridItem>
            <GridItem md={1}>
                {
                    props.isWatched
                    ? ""
                    : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                        onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
                }
            </GridItem>
        </GridContainer>
    );
}

const CustomRadio = (props) => {
    const {label, onShowSampleID, ...p} = props;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    let sample_btn = props.schema.sample_btn ? props.schema.sample_btn : null;
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={11 - (sample_btn !== null ? 3 : 0)}>
                <CustomRadioWidget {...p} label={label} />
            </GridItem>
            {
                sample_btn !== null
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="primary" className={props.classes.marginRight} type="button" id={props.id + '_sample_btn'}
                        onClick={() => {onShowSampleID(props.schema);}} disabled={props.readonly}
                    >
                        {sample_btn.label}
                    </Button>
                  </GridItem>
                : ""
            }
            <GridItem md={1}>
            {
                props.isWatched
                ? ""
                : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                    onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
            }
            </GridItem>
        </GridContainer>
    );
}

const CustomText = (props) => {
    const {label, onShowSampleID, classes, isWatched, setWatched, handleUpdateWatch, getRegistryKey, watched, formatInputValue, value, ...p} = props;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isBeingWatched = props.setWatched(props.watched, key);
    let formatting = null;
    let inputValue = value;
    let sample_btn = props.schema.sample_btn ? props.schema.sample_btn : null;
    if (p.hasOwnProperty('schema') && p.schema.hasOwnProperty('formatting')) {
        formatting = p.schema.formatting;
        inputValue = formatInputValue(inputValue, formatting);
    }
    let errors = props.rawErrors !== undefined && props.rawErrors.length > 0
        ? props.rawErrors.filter((value) => {return value.toLowerCase().indexOf('is a required property') === -1;}) : []
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={11 - (sample_btn !== null ? 3 : 0)}>
                <CustomTextWidget {...p} label={label} value={inputValue} onChange={(value) => {
                    if (formatting !== null) {
                        value = formatInputValue(value, formatting);
                    }
                    props.onChange(value);
                }} />
                {
                    errors.length > 0 && !props.schema.is_optional
                        ? React.createElement("p", {style: {
                            color: "#f44336",
                            fontSize: "0.75rem",
                            marginRop: "3px",
                            textAlign: "left",
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: "400"
                        }}, helpers.addLineBreaks(errors.join('<br>')))
                        : null
                }
            </GridItem>
            {
                sample_btn !== null
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="primary" className={props.classes.marginRight} type="button" id={props.id + '_sample_btn'}
                        onClick={() => {onShowSampleID(props.schema);}} disabled={props.readonly}
                    >
                        {sample_btn.label}
                    </Button>
                  </GridItem>
                : ""
            }
            <GridItem md={1}>
            {
                props.isWatched
                ? ""
                : <Icon className={"fas fa-eye" + (isBeingWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                    onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isBeingWatched);}} />
            }
            </GridItem>
        </GridContainer>
    );
}

const CustomPasswordText = (props) => {
    const {label, onShowSampleID, classes, isWatched, setWatched, handleUpdateWatch, getRegistryKey, watched, formatInputValue, value, ...p} = props;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isBeingWatched = props.setWatched(props.watched, key);
    let formatting = null;
    let inputValue = value;
    let sample_btn = props.schema.sample_btn ? props.schema.sample_btn : null;
    if (p.hasOwnProperty('schema') && p.schema.hasOwnProperty('formatting')) {
        formatting = p.schema.formatting;
        inputValue = formatInputValue(inputValue, formatting);
    }
    let errors = props.rawErrors !== undefined && props.rawErrors.length > 0
        ? props.rawErrors.filter((value) => {return value.toLowerCase().indexOf('is a required property') === -1;}) : []
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={11 - (sample_btn !== null ? 3 : 0)}>
                <CustomPasswordTextWidget {...p} label={label} value={inputValue} onChange={(value) => {
                    if (formatting !== null) {
                        value = formatInputValue(value, formatting);
                    }
                    props.onChange(value);
                }} />
                {
                    errors.length > 0 && !props.schema.is_optional
                        ? React.createElement("p", {style: {
                            color: "#f44336",
                            fontSize: "0.75rem",
                            marginRop: "3px",
                            textAlign: "left",
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: "400"
                        }}, helpers.addLineBreaks(errors.join('<br>')))
                        : null
                }
            </GridItem>
            {
                sample_btn !== null
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="primary" className={props.classes.marginRight} type="button" id={props.id + '_sample_btn'}
                        onClick={() => {onShowSampleID(props.schema);}} disabled={props.readonly}
                    >
                        {sample_btn.label}
                    </Button>
                  </GridItem>
                : ""
            }
            <GridItem md={1}>
            {
                props.isWatched
                ? ""
                : <Icon className={"fas fa-eye" + (isBeingWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                    onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isBeingWatched);}} />
            }
            </GridItem>
        </GridContainer>
    );
}

const CustomTextarea = (props) => {
    const {label, classes, isWatched, setWatched, handleUpdateWatch, getRegistryKey, watched, formatInputValue, value, ...p} = props;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isBeingWatched = props.setWatched(props.watched, key);
    let formatting = null;
    let inputValue = value;
    if (p.hasOwnProperty('schema') && p.schema.hasOwnProperty('formatting')) {
        formatting = p.schema.formatting;
        inputValue = formatInputValue(inputValue, formatting);
    }
    let errors = props.rawErrors !== undefined && props.rawErrors.length > 0
        ? props.rawErrors.filter((value) => {return value.toLowerCase().indexOf('is a required property') === -1;}) : []
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={11}>
                <CustomTextareaWidget {...p} label={label} value={inputValue} onChange={(value) => {
                    if (formatting !== null) {
                        value = formatInputValue(value, formatting);
                    }
                    props.onChange(value);
                }} />
                {
                    errors.length > 0 && !props.schema.is_optional
                        ? React.createElement("p", {style: {
                            color: "#f44336",
                            fontSize: "0.75rem",
                            marginRop: "3px",
                            textAlign: "left",
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: "400"
                        }}, helpers.addLineBreaks(errors.join('<br>')))
                        : null
                }
            </GridItem>
            <GridItem md={1}>
            {
                props.isWatched
                ? ""
                : <Icon className={"fas fa-eye" + (isBeingWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                    onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isBeingWatched);}} />
            }
            </GridItem>
        </GridContainer>
    );
}

const CustomDate = (props) => {
    const {label, onShowSampleID, classes, isWatched, setWatched, handleUpdateWatch, getRegistryKey, watched, ...p} = props;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isBeingWatched = props.setWatched(props.watched, key);
    let errors = props.rawErrors !== undefined && props.rawErrors.length > 0
        ? props.rawErrors.filter((value) => {return value.toLowerCase().indexOf('is a required property') === -1;}) : [];
    let sample_btn = props.schema.sample_btn ? props.schema.sample_btn : null;
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={11 - (sample_btn !== null ? 3 : 0)}>
                <CustomDateWidget {...p} label={label} />
                {
                    errors.length > 0 && !props.schema.is_optional
                        ? React.createElement("p", {style: {
                            color: "#f44336",
                            fontSize: "0.75rem",
                            marginRop: "3px",
                            textAlign: "left",
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: "400"
                        }}, helpers.addLineBreaks(errors.join('<br>')))
                        : null
                }
            </GridItem>
            {
                sample_btn !== null
                ? <GridItem md={3} style={{"textAlign": "right"}}>
                    <Button color="primary" className={props.classes.marginRight} type="button" id={props.id + '_sample_btn'}
                        onClick={() => {onShowSampleID(props.schema);}} disabled={props.readonly}
                    >
                        {sample_btn.label}
                    </Button>
                  </GridItem>
                : ""
            }
            <GridItem md={1}>
            {
                props.isWatched
                ? ""
                : <Icon className={"fas fa-eye" + (isBeingWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                    onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isBeingWatched);}} />
            }
            </GridItem>
        </GridContainer>
    );
}

const RangeWidget = (props) => {
    const {label, classes, isWatched, setWatched, handleUpdateWatch, getRegistryKey, watched, ...p} = props;
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isBeingWatched = props.setWatched(props.watched, key);
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={11}>
                <CustomRangeWidget {...p} label={label} />
            </GridItem>
            <GridItem md={1}>
            {
                props.isWatched
                ? ""
                : <Icon className={"fas fa-eye" + (isBeingWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                    onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isBeingWatched);}} />
            }
            </GridItem>
        </GridContainer>
    );
}

const AutoCompleteAddressWidget = (props) => {
    let name = (props.id.lastIndexOf("root_", 0) === 0 ? props.id.substr("root_".length) : props.id).replace('_asfileselect', '');
    let key = props.schema.watch_key ? props.schema.watch_key : name;
    let isWatched = props.setWatched(props.watched, key);
    return (
        <GridContainer
            key="{{ this.props.step.id }}_grid"
        >
            <GridItem md={11}>
                <AddressAutocomplete
                    {...props}
                    title={props.label}
                    initValue={props.value === null || props.value === undefined ? "" : props.value}
                    auth={props.auth}
                    key={props.id}
                    onBlur={(key, value) => props.handleBlurEvent(key, value)}
                    onChange={(value) => {
                        props.onChange(value)
                    }}
                    onChangeSelect={(selected) => {
                        if (selected !== null && selected.place_id.length > 0 && typeof props[props['uiSchema']['ui:action']['func']] === 'function') {
                            try {
                                props[props['uiSchema']['ui:action']['func']](props['uiSchema']['ui:action'], selected, props.handleFillEvent);
                            } catch(e) {}
                        }
                    }}
                />
            </GridItem>
            <GridItem md={1}>
            {
                props.isWatched
                ? ""
                : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher eye-watcher-fields"} style={{"float": "center"}}
                    onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('entry', props.getRegistryKey(name), !isWatched);}} />
            }
            </GridItem>
        </GridContainer>
    );
}

class ChecklistFormDialog extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            data: props.data,
            doClose: false,
            saving: false,
            uploading: false,
            hasChanges: false,
            lastFocusElement: null,
            "confirmModal": false,
            "confirmModalTitle": "Unsaved Changes",
            "confirmModalMsg": "There are unsaved changes, are you sure you want to close the form?",
            "previewDocumentModal": false,
            "previewDocumentValue": null,
            "previewDocumentName": null,
            "selectFileToPreviewModal": false,
            "selectFileToPreviewFiles": [],
            "selectFileToPreviewExtensions": [],
            "signaturePadModal": false,
            "signaturePadSchema": null,
            "sampleIDModal": false,
            "sampleIDSchema": null,
            "sampleIDImagePath": null,
        };
        this.handleBlurEvent = this.handleBlurEvent.bind(this);
        this.handleFocusEvent = this.handleFocusEvent.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.previewDocument = this.previewDocument.bind(this);
        this.previewMultipleDocument = this.previewMultipleDocument.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
        this.getRegistryValue = this.getRegistryValue.bind(this);
        this.submitFormData = this.submitFormData.bind(this);
        this.getRegistryValueFromVerifyConfig = this.getRegistryValueFromVerifyConfig.bind(this);
        this.getRegistryKey = this.getRegistryKey.bind(this);
        this.formatInputValue = this.formatInputValue.bind(this);
        this.abnFormat = this.abnFormat.bind(this);
        this.acnFormat = this.acnFormat.bind(this);
        this.bsbFormat = this.bsbFormat.bind(this);
        this.numberFormat = this.numberFormat.bind(this);
        this.medicareCardNumberFormat = this.medicareCardNumberFormat.bind(this);
        this.monthYearFormat = this.monthYearFormat.bind(this);
        this.showSignaturePad = this.showSignaturePad.bind(this);
        this.showSampleID = this.showSampleID.bind(this);
        this.showLicenseIDSample = this.showLicenseIDSample.bind(this);
        this.showPassportIDSample = this.showPassportIDSample.bind(this);
        this.showMedicareIDSample = this.showMedicareIDSample.bind(this);
        this.handleFillEvent = this.handleFillEvent.bind(this);
    }

    componentWillReceiveProps (nextProps) {
        this.setState({data: nextProps.data});
    }
    
    widgets = {
        labelledFileField: (props) => <LabelledFileField
            {...props}
            classes={this.props.classes}
            handleFocusEvent={this.handleFocusEvent}
            handleBlurEvent={this.handleBlurEvent}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
        />,
        labelledFileSignatureField: (props) => <LabelledFileSignatureField
            {...props}
            classes={this.props.classes}
            handleFocusEvent={this.handleFocusEvent}
            handleBlurEvent={this.handleBlurEvent}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            onShowSignaturePad={this.showSignaturePad}
        />,
        LabelWidget: CustomInputAsLabelWidget,
        button: (props) => <CustomBottonWidget
            {...props}
            classes={this.props.classes}
            onClick={(uiSchema) => {this.props.buttonFieldClick(uiSchema, this.handleFillEvent)}}
        />,
        previewSelectWidget: (props) => <CustomSelectWidget
            {...props}
            classes={this.props.classes}
            onPreview={this.previewDocument}
            onPreviewMultiple={this.previewMultipleDocument}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
        />,
        CustomCheckboxPreview:  (props) => <CustomCheckboxPreview
            {...props}
            classes={this.props.classes}
            onPreview={this.previewDocument}
            onPreviewMultiple={this.previewMultipleDocument}
            onDownload={this.downloadDocument}
            getRegistryValue={this.getRegistryValue}
            getRegistryValueFromVerifyConfig={this.getRegistryValueFromVerifyConfig}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
        />,
        AutoCompleteAddress:  (props) => <AutoCompleteAddressWidget
            {...props}
            auth={this.props.auth}
            classes={this.props.classes}
            handleBlurEvent={this.handleBlurEvent}
            fillFromAddress={this.props.fillFromAddress}
            handleFillEvent={this.handleFillEvent}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
        />,
        CustomCheckbox:  (props) => <CustomCheckbox
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
        />,
        selectWidget: (props) => <CustomSelect
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
        />,
        selectMccWidget: (props) => <CustomMccSelect
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            setMccTooltip={this.setMccTooltip}
        />,
        CustomRadio:  (props) => <CustomRadio
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            onShowSampleID={this.showSampleID}
        />,
        textWidget:  (props) => <CustomText
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            formatInputValue={this.formatInputValue}
            onShowSampleID={this.showSampleID}
        />,
        passwordTextWidget:  (props) => <CustomPasswordText
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            formatInputValue={this.formatInputValue}
            onShowSampleID={this.showSampleID}
        />,
        textareaWidget:  (props) => <CustomTextarea
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            formatInputValue={this.formatInputValue}
        />,
        dateWidget:  (props) => <CustomDate
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
            onShowSampleID={this.showSampleID}
        />,
        rangeWidget:  (props) => <RangeWidget
            {...props}
            classes={this.props.classes}
            watched={this.props.watched}
            isWatched={this.props.isWatched || this.props.ignored_watch}
            setWatched={this.setWatched}
            handleUpdateWatch={this.props.handleUpdateWatch}
            getRegistryKey={this.getRegistryKey}
        />,
    };

    setMccTooltip(mcc) {
        let tooltip = '', addNL = '';
        if (mcc.description !== undefined && mcc.description !== null && mcc.description.length > 0) {
            tooltip = 'Description: ' + mcc.description;
            addNL = '\n\n';
        }
        if (mcc.inclusions !== undefined && mcc.inclusions !== null && mcc.inclusions.length > 0) {
            tooltip += addNL + 'Inclusions: ' + mcc.inclusions;
        }
        if (mcc.similar !== undefined && mcc.similar !== null && mcc.similar.length > 0) {
            tooltip += addNL + 'Similar: ' + mcc.similar;
        }
        return tooltip;
    }

    setWatched(watched, key) {
        try {
            let watch = watched.filter((watch) => watch.name.toLowerCase() === key.toLowerCase() && watch.status === 1);
            return Object.keys(watch).length === 1 ? watch[0] : null;
        } catch(e) {}
        return null;
    }

    handleBlurEvent(key, value) {
        var element = document.getElementById(key);
        try {
            if (element instanceof HTMLInputElement && element.getAttribute('type') === 'checkbox') {
                value = element.checked;
            }
        } catch(e) {}
        let keys = (key.lastIndexOf("root_", 0) === 0 ? key.substr("root_".length) : key).split('_');
        let registry_key = '', default_value = null;
        this.props.step.checklist_template.checklist_field_templates.forEach(field => {
            if (field.configuration.properties.name === keys[0]) {
                registry_key = field.registry_key;
                if (field.configuration.properties.hasOwnProperty('enum') && value === undefined) {
                    value = "";
                }
                if (field.configuration.properties.hasOwnProperty('default')) {
                    default_value = field.configuration.properties.default;
                }
            }
        });
        if (value === null || value === undefined || (Array.isArray(value) && value.length === 1 && value[0] === undefined)) {
            return true;
        }
        let prevValue = null;
        this.props.registry_entries.forEach(field => {
            if (field.registry_key === registry_key) {
                prevValue = field.value;
                try {
                    if (field.field_template.configuration.properties.field_type !== undefined &&
                        (field.field_template.configuration.properties.field_type === 'file' || field.field_template.configuration.properties.field_type === 'file_sig')) {
                            let properties = this.props.schema.properties[key.replace('root_', '')];
                            if (properties.hasOwnProperty('multiple') && properties.multiple === true) {
                                let values = value.split('||');
                                value = values.map((v, k) => parseInt(v.split(') ')[0].replace('(id: ', ''))).toString();
                            } else {
                                value = parseInt(value.split(') ')[0].replace('(id: ', ''));
                            }
                    } else if (field.field_template.configuration.properties.type === 'boolean') {
                        prevValue = typeof field.value !== 'boolean' ? (field.value === 'true') : field.value;
                    }
                } catch(e) {}
            }
        });
        try {
            if (prevValue === null) {
                if (default_value !== null) {
                    prevValue = default_value;
                } else if (this.props.step.checklist_template.hasOwnProperty('field_defaults') && Object.keys(this.props.step.checklist_template.field_defaults).length > 0) {
                    for(let default_key in this.props.step.checklist_template.field_defaults) {
                        if (this.props.step.checklist_template.field_defaults[default_key]['registry'] === registry_key) {
                            prevValue = this.props.step.checklist_template.field_defaults[default_key]['value'];
                            break;
                        }
                    }
                }
            }
        } catch(e) {}
        let changed = false;
        if (prevValue === null) {
            changed = (typeof value === 'boolean' && value === true) || value.length > 0;
        } else {
            if (keys.length > 1 && typeof prevValue === "object") {
                changed = prevValue.hasOwnProperty(keys[1]) ? (prevValue[keys[1]] + "") !== (value + "") : true;
            } else {
                changed = (prevValue + "") !== (value + "");
            }
        }
        if (changed) {
            this.setState({hasChanges: true, doClose: false});
            if (!this.state.saving) {
                setTimeout(() => {
                    // this.submitForm.click();
                    this.submitFormData();
                }, 200);
            }
            return true;
        }
        return false;
    }

    handleFillEvent() {
        this.setState({hasChanges: true, doClose: false});
        if (!this.state.saving) {
            setTimeout(() => {this.submitFormData();}, 100);
        }
    }

    handleFocusEvent(key, value) {
        if (this.state.lastFocusElement !== null && this.state.lastFocusElement !== key) {
            try {
                if (
                    document.getElementById(this.state.lastFocusElement).tagName.toUpperCase() === "LABEL" ||
                    (document.getElementById(this.state.lastFocusElement).tagName.toUpperCase() === "INPUT" &&
                        this.state.data[this.state.lastFocusElement.substr("root_".length)].startsWith('data:'))
                ) {
                    this.handleBlurEvent(this.state.lastFocusElement, this.state.data[this.state.lastFocusElement.substr("root_".length)]);
                }
            } catch(e) {}
        }
        this.setState({"lastFocusElement": key});
    }

    previewDocument(value) {
        let name = value.split(') ');
        name.shift();
        this.setState({
            "previewDocumentModal": true,
            "previewDocumentValue": parseInt(value.split(') ')[0].replace('(id: ', '')),
            "previewDocumentName": name.join(') ')
        });
    }

    previewMultipleDocument(files, extensions) {
        if (!files || files.length === 0) return;
        try {
            if (files.length === 1) {
                extensions.includes(files[0].split('.').pop().toLowerCase())
                ? this.previewDocument(files[0])
                : this.downloadDocument(files[0].split(') ')[0].replace('(id: ', ''), files[0].split(') ')[1])
            } else {
                this.setState({
                    "selectFileToPreviewModal": true,
                    "selectFileToPreviewFiles": files,
                    "selectFileToPreviewExtensions": extensions
                });
            }
        } catch(e) {}
    }

    showSignaturePad(schema) {
        this.setState({
            "signaturePadModal": true,
            "signaturePadSchema": schema,
        });
    }

    showSampleID(schema) {
        try {
            this[schema.sample_btn.func](schema.sample_btn);
        } catch(e) {}
    }

    showLicenseIDSample(config) {
        let image_path = Constants.API_URL + '/sample/id/license?state=' +
            (this.state.data[config['reference_field']] ? this.state.data[config['reference_field']] : '') + 
            '&field=' + config['field'];
        this.setState({
            "sampleIDModal": true,
            "sampleIDSchema": config,
            "sampleIDImagePath": image_path
        });
    }

    showPassportIDSample(config) {
        let image_path = Constants.API_URL + '/sample/id/passport?field=' + config['field'];
        this.setState({
            "sampleIDModal": true,
            "sampleIDSchema": config,
            "sampleIDImagePath": image_path
        });
    }

    showMedicareIDSample(config) {
        let image_path = Constants.API_URL + '/sample/id/medicare?field=' + config['field'];
        this.setState({
            "sampleIDModal": true,
            "sampleIDSchema": config,
            "sampleIDImagePath": image_path
        });
    }

    downloadDocument(value, filename) {
        const FileDownload = require("js-file-download");
        helpers.showLoading();
        axios(this.props)
          .get(
                'client/' + this.props.checklist.client_id + '/file/' + value + '/download',
                {
                    "responseType": "blob",
                    "timeout": 30000
                }
            )
            .then((response) => {
              helpers.hideLoading();
              FileDownload(
                  response.data,
                  filename
              );
            })
            .catch((error) => axiosHelper.processError(
                this.isUnmounted,
                null,
                error,
                (state) => {this.setState(state);},
                () => {}
            ));
    }

    getRegistryValue(registry_key) {
        let registry = null;
        this.props.registry_entries.forEach(field => {
            if (field.registry_key === registry_key) {
                registry = {};
                registry['value'] = field.value;
                registry['client_assigned'] = field.client_assigned;
            }
        });
        if (registry !== null) {
            try {
                let files = [];
                registry.value.split(',').filter((file_id) => file_id.length > 0).map((file_id) => {
                    this.props.files.forEach((file) => {
                        if (parseInt(file_id) === file.id) {
                            registry['filename'] = file.filename;
                            registry['uploader'] = file.uploader.type;
                            files.push({
                                'filename': file.filename,
                                'uploader': file.uploader.type,
                                'value': file.id
                            });
                        }
                    });
                    return file_id;
                });
                if (files.length > 0) {
                    registry['files'] = files;
                }
            } catch(e) {}
        }

        return registry;
    }

    getRegistryValueFromVerifyConfig(config) {
        let registry = null;
        if (config) {
            config.forEach((v) => {
                try {
                    let registry_value = this.getRegistryValue(v.parent_key);
                    if (registry_value !== null && registry_value.value === v.parent_value) {
                        registry = this.getRegistryValue(v.source);
                    }
                } catch(e) {}
            });
        }

        return registry;
    }

    getRegistryKey(name) {
        let registry_key = null;
        this.props.step.checklist_template.checklist_field_templates.some((field, index) => {
            if (field.configuration.properties.name === name) {
                registry_key = field.registry_key;
                return true;
            }
            return false;
        });
        return registry_key;
    };

    formatInputValue(value, formatting) {
        if (typeof formatting === 'object') {
            if (typeof this[formatting['func']] === 'function') {
                try {
                    value = this[formatting['func']](value, formatting);
                } catch(e) {}
            }
        } else if (typeof this[formatting] === 'function') {
            try {
                value = this[formatting](value);
            } catch(e) {}
        }
        return value;
    }

    abnFormat(value) {
        return helpers.abnFormat(value);
        // if (!value) return value;
        // value = value.replace(/[^\d]/g, '').substr(0, 11);;
        // if (value.length < 3) return value;
        // let first = value.substr(0, 2);
        // let last = value.length > 2 ? value.substr(2).match(/.{1,3}/g).join(' ') : '';
        // return first + ' ' + last;
    }

    acnFormat(value) {
        if (!value) return value;
        value = value.replace(/[^\d]/g, '').substr(0, 9);
        return value.match(/.{1,3}/g).join(' ');
    }

    bsbFormat(value) {
        if (!value) return value;
        value = value.replace(/[^\d]/g, '').substr(0, 6);
        return value.match(/.{1,3}/g).join('-');
    }

    numberFormat(value) {
        if (!value) return value;
        var n = value.toString().replace(/^(-)|[^0-9.,]+/g, '$1').replace(/,/g, '').split(".");
        n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return n.join(".");
    }

    medicareCardNumberFormat(value) {
        if (!value) return value;
        value = value.replace(/[^\d]/g, '').substr(0, 10);
        if (value.length < 5) return value;
        let first = value.substr(0, 4);
        let last = value.length > 4 ? value.substr(4).match(/.{1,5}/g).join(' ') : '';
        return first + ' ' + last;
    }

    monthYearFormat(value) {
        if (!value) return value;
        value = value.replace(/[^\d]/g, '').substr(0, 6);;
        if (value.length < 3) return value;
        let first = value.substr(0, 2);
        let last = value.length > 2 ? value.substr(2) : '';
        return first + '/' + last;
    }

    maxLengthFormat(value, formatting) {
        if (!value) return value;
        return value.substr(0, formatting['maxLength']);
    }

    handleClose() {
        let proceed = true;
        if (this.state.lastFocusElement !== null) {
            try {
                if (
                    document.getElementById(this.state.lastFocusElement).tagName.toUpperCase() === "LABEL" ||
                    (document.getElementById(this.state.lastFocusElement).tagName.toUpperCase() === "INPUT" &&
                        this.state.data[this.state.lastFocusElement.substr("root_".length)].startsWith('data:'))
                ) {
                    proceed = !this.handleBlurEvent(this.state.lastFocusElement, this.state.data[this.state.lastFocusElement.substr("root_".length)]);
                }
            } catch(e) {}
        }

        setTimeout(() => {
            if (proceed && !this.state.saving) {
                if (this.state.hasChanges) {
                    this.setState({"confirmModal": true});
                } else {
                    this.props.close(false);
                };
            }
        }, 200);
    }

    errors = null;
    submitFormData() {
        let cont = true, formData = this.state.data;
        // try {
        //     formData = this.submitForm.state.formData;
        //     this.submitForm.state.errors.forEach((item) => {
        //         if (item.hasOwnProperty('stack') && item.stack.toLowerCase().indexOf('is a required property') === -1) {
        //             cont = false;
        //         }
        //     });
        // } catch(e) {
        //     this.submitBtn.click();
        //     cont = this.errors === null;
        // }
        try {
            formData = this.submitForm.state.formData;
            this.submitForm.state.errors.forEach((item) => {
                let key = item.stack.substr(0, item.stack.indexOf(':'));
                if (
                    item.hasOwnProperty('stack') &&
                    (item.stack.toLowerCase().indexOf('is a required property') === -1 ||
                    item.stack.toLowerCase().indexOf('invalid file size') === -1 ||
                    item.stack.toLowerCase().indexOf('invalid file type') === -1)
                ) {
                    try {
                        if (item.stack.toLowerCase().indexOf('invalid file size') === -1 || item.stack.toLowerCase().indexOf('invalid file type') === -1) {
                            let isArray = Array.isArray(formData[key]);
                            formData[key] = (isArray ? formData[key] : [formData[key]]).filter(data => {
                                try {
                                    return validations.maxFileSizeValid(data, this.props.custom_validation[key]['maxBytes']) &&
                                        validations.fileTypeValid(data, this.props.custom_validation[key]['accept']);
                                } catch(e) {}
                                return true;
                            });
                            if (formData[key].length === 0) {
                                delete formData[key];
                            } else if (!isArray) {
                                formData[key] = formData[key][0];
                            }
                        } else {
                            delete formData[key];
                        }
                    } catch(e1) {}
                }
                if (this.state.data.hasOwnProperty(key) && item.stack.toLowerCase().indexOf('should be equal to one of the allowed values') !== -1) {
                    try {
                        formData[key] = this.state.data[key];
                    } catch(e1) {}
                }
            });
        } catch(e) {}

        if (cont) {
            if (formData && Object.keys(formData).length === 0) {this.setState({hasChanges: false}); return;};
            let uploading = false;
            try {
                for (let field in formData) {
                    try {
                        if (
                            field !== undefined && field !== null && formData[field] &&
                            (
                                (typeof formData[field] === 'string' && formData[field].startsWith('data:') && formData[field].indexOf(';base64,') > 0) ||
                                (Array.isArray(formData[field]) && formData[field].length > 0 && formData[field][0].startsWith('data:') && formData[field][0].indexOf(';base64,') > 0)
                            )
                        ) {
                            uploading = true;
                            break;
                        }
                    } catch(e) {}
                }
            } catch(e) {}
            this.setState({saving: true, uploading: uploading, hasChanges: false});
            this.props.handleUpdateStep(
                this.props.id, this.props.client_script_id,
                {'form_data': formData, 'form_count': this.props.formFieldCount},
                () => {
                    this.setState({saving: false, uploading: false});
                    if (this.state.doClose) {
                        this.props.close(false);
                    } else if (this.state.hasChanges) {
                        setTimeout(() => {
                            // this.submitForm.click();
                            this.submitFormData();
                        }, 100);
                    }
                },
                this.state.doClose
            );
        }
    }

    render () {
        const {classes} = this.props;
        return <Dialog
            aria-describedby="avatar-update-modal-slide-description"
            aria-labelledby="small-modal-slide-title"
            classes={{"root": `${classes.center} ${classes.modalRoot}`}}
            fullWidth
            keepMounted
            maxWidth="sm"
            onClose={() => this.handleClose()}
            open={this.props.checklistModal}
        >
            <DialogTitle
                className={`${classes.modalBody} ${classes.modalSmallBody}`}
                style={{"paddingBottom": "16px"}}
            >
                {this.props.title}
                {
                    this.props.ignored_watch
                    ? ""
                    : <Icon className={"fas fa-eye" + (this.props.isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher"}
                        onClick={(e) => {e.preventDefault(); this.props.handleUpdateWatch('step', this.props.step.step, !this.props.isWatched);}} />
                }
                <div style={{"float": "right"}}>
                    {this.state.saving ? (this.state.uploading ? 'Saving and uploading file(s)...' : 'Saving...') : ''}
                </div>
            </DialogTitle>
            <DialogContent
                className={`${classes.modalBody} ${classes.modalSmallBody}`}
                id="avatar-update-modal-slide-description"
                style={{"width": "100%", "overflowX": "hidden"}}
            >
                <GridContainer
                    key="details_grid"
                    style={{"borderTop": "#9c27b0 solid 1px"}}
                >
                    <GridItem md={12}>
                        <Form schema={this.props.schema}
                            uiSchema={this.props.ui}
                            widgets={this.widgets}
                            formData={this.state.data}
                            onChange={this.props.onChange}
                            onSubmit={({formData}, e) => {
                                this.errors = null;
                                return;
                                // if (formData && Object.keys(formData).length === 0) {this.setState({hasChanges: false}); return;};
                                // this.setState({saving: true, hasChanges: false});
                                // this.props.handleUpdateStep(
                                //     this.props.id, this.props.client_script_id,
                                //     {'form_data': formData, 'form_count': this.props.formFieldCount},
                                //     () => {
                                //         this.setState({saving: false});
                                //         if (this.state.doClose) {
                                //             this.props.close(false);
                                //         } else if (this.state.hasChanges) {
                                //             setTimeout(() => {this.submitForm.click();}, 100);
                                //         }
                                //     },
                                //     this.state.doClose
                                // );
                            }}
                            onError={(errors) => {this.errors = "with errors"; this.setState({saving: false, uploading: false, hasChanges: false});}}
                            onBlur={(key, value) => {this.handleBlurEvent(key, value)}}
                            onFocus={(key, value) => {this.handleFocusEvent(key, value)}}
                            validate={this.props.validate}
                            showErrorList={false}
                            liveValidate={true}
                            autoComplete="off"
                            ref={form => this.submitForm = form}
                        >
                                <div style={{float: "right"}}>
                                    <Button
                                        type="submit"
                                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                        color="success"
                                        simple
                                        style={{"display": "none"}}
                                        // ref={form => this.submitForm = form}
                                        ref={(form => this.submitBtn = form)}
                                    >
                                        Save
                                    </Button>
                                </div>
                        </Form>
                    </GridItem>
                </GridContainer>
                <style>
                {
                    "p.Mui-required {display: none !important;}" +
                    ".MuiListItem-dense {padding-top: 0px; padding-bottom: 0px;}" +
                    "label.Mui-error.Mui-required {color: rgba(0, 0, 0, 0.54) !important}" + 
                    "span.MuiInputLabel-asterisk.Mui-error {color: rgba(0, 0, 0, 0.54) !important}" +
                    ".MuiSelect-selectMenu {font: inherit !important;}" + 
                    ".MuiDialog-paperWidthSm {max-width: 650px;}"
                }
                </style>
            </DialogContent>
            <DialogActions
                className={
                    classes.modalFooter +
                    " " +
                    classes.modalFooterCenter
                }
                >
                <Button
                    className={classes.modalSmallFooterFirstButton}
                    color="transparent"
                    onClick={() => this.handleClose()}
                >
                    {"Close"}
                </Button>
                {!this.state.hasChanges || this.state.saving //this.props.readOnly
                ? ""
                : <Button
                    onClick={() => {
                        this.setState({doClose: true});
                        setTimeout(() => {
                            // this.submitForm.click();
                            this.submitFormData();
                        }, 200);
                    }}
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color="success"
                    simple
                >
                    Save
                </Button>}
            </DialogActions>
            <ConfirmDialog
                confirmModal={this.state.confirmModal}
                confirmModalMsg={this.state.confirmModalMsg}
                confirmModalTitle={this.state.confirmModalTitle}
                onClose={() => this.setState({"confirmModal": false})}
                onYesClick={() => {this.setState({"confirmModal": false}); this.props.close(false);}}
            />
            {
                this.state.previewDocumentModal
                ? <PreviewDocument
                    value={this.state.previewDocumentValue}
                    name={this.state.previewDocumentName}
                    client_id={this.props.checklist.client_id}
                    onClose={() => {
                        this.setState({
                            "previewDocumentModal": false,
                            "previewDocumentValue": null,
                            "previewDocumentName": null
                        });
                    }}
                    auth={this.props.auth}
                    previewDocumentModal={this.state.previewDocumentModal}
                />
                : ""
            }
            {
                this.state.selectFileToPreviewModal
                ? <SelectFileToPreview
                    files={this.state.selectFileToPreviewFiles}
                    extensions={this.state.selectFileToPreviewExtensions}
                    onClose={() => {
                        this.setState({
                            "selectFileToPreviewModal": false,
                            "selectFileToPreviewFiles": [],
                            "selectFileToPreviewExtensions": []
                        });
                    }}
                    selectFileToPreviewModal={this.state.selectFileToPreviewModal}
                    onPreview={this.previewDocument}
                    onDownload={this.downloadDocument}
                />
                : ""
            }
            {
                this.state.signaturePadModal
                ? <SignaturePad
                    schema={this.state.signaturePadSchema}
                    onClose={() => {
                        this.setState({
                            "signaturePadModal": false,
                            "signaturePadSchema": null
                        });
                    }}
                    onAccept={(data) => {
                        let name = (this.state.signaturePadSchema.label ? this.state.signaturePadSchema.label : this.state.signaturePadSchema.name)
                            .replace(/[^\w\s]/gi, '').replace(/\s/g, '_').toLowerCase()
                            + "_" + (new Date().getTime()) + ".png";
                        data = data.replace(
                            ";base64",
                            `;name=${encodeURIComponent(name)};base64`
                        );
                        this.props.handleSignatureChange(this.state.signaturePadSchema.name, data);
                        document.getElementById('root_' + this.state.signaturePadSchema.name).focus();
                    }}
                    signaturePadModal={this.state.signaturePadModal}
                />
                : ""
            }
            {
                this.state.sampleIDModal
                ? <SampleID
                    schema={this.state.sampleIDSchema}
                    onClose={() => {
                        this.setState({
                            "sampleIDModal": false,
                            "sampleIDSchema": null,
                            "sampleIDImagePath": null
                        });
                    }}
                    sampleIDModal={this.state.sampleIDModal}
                    imagePath={this.state.sampleIDImagePath}
                />
                : ""
            }
        </Dialog>;
    }

}

export default withStyles(extendedFormsStyle)(ChecklistFormDialog);