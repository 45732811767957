import React from "react";
// Nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// Material-ui icons
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";

// Core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx";

function AdminNavbar ({...props}) {

    const {classes, color, rtlActive, brandText} = props,
        appBarClasses = cx({
            [` ${classes[color]}`]: color
        }),
        sidebarMinimize =
    `${classes.sidebarMinimize
    } ${
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive
        })}`;
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden
                    implementation="css"
                    smDown
                >
                    <div className={sidebarMinimize}>
                        {props.miniActive
                            ? <Button
                                title={'Open sidebar drawer'}
                                color="white"
                                justIcon
                                onClick={props.sidebarMinimize}
                                round
                              >
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                            : <Button
                                title={'Close sidebar drawer'}
                                color="white"
                                justIcon
                                onClick={props.sidebarMinimize}
                                round
                              >
                                <MoreVert className={classes.sidebarMiniIcon} />
                            </Button>}
                    </div>
                </Hidden>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                    <Button
                        className={classes.title}
                        color="transparent"
                        href="#"
                    >
                        {brandText}
                    </Button>
                </div>
                <Hidden
                    implementation="css"
                    smDown
                >
                    <AdminNavbarLinks rtlActive={rtlActive} />
                </Hidden>
                <Hidden
                    implementation="css"
                    mdUp
                >
                    <Button
                        aria-label="open drawer"
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    );

}

AdminNavbar.propTypes = {
    "classes": PropTypes.object.isRequired,
    "color": PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger"
    ]),
    "rtlActive": PropTypes.bool,
    "brandText": PropTypes.string,
    "miniActive": PropTypes.bool,
    "handleDrawerToggle": PropTypes.func,
    "sidebarMinimize": PropTypes.func
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
