import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import authNavbarStyle from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.jsx";
import { withRouter } from "react-router-dom";

class AuthNavbar extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "open": false
        };

    }

  handleDrawerToggle = () => {

      this.setState({"open": !this.state.open});

  };

  // Verifies if routeName is the one active (in browser input)
  activeRoute (routeName) {

      return window.location.href.indexOf(routeName) > -1;

  }

  componentDidUpdate (e) {

      if (e.history.location.pathname !== e.location.pathname) {

          this.setState({"open": false});

      }

  }

  render () {

      const {classes, color} = this.props,
          appBarClasses = cx({
              [` ${classes[color]}`]: color
          });
      return (
          <AppBar
              className={classes.appBar + appBarClasses}
              position="static"
          >
              <Toolbar />
          </AppBar>
      );

  }

}

AuthNavbar.propTypes = {
    "classes": PropTypes.object.isRequired,
    "color": PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger"
    ]),
    "brandText": PropTypes.string
};

export default withRouter(withStyles(authNavbarStyle)(AuthNavbar));
