import React from "react";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

function defaultButton (props) {
    return <button {...props}>
        {props.children}
        </button>;
}

export default class Pagination extends React.Component {
    constructor (props) {
        super();
        this.changePage = this.changePage.bind(this);
        this.state = {
            "visiblePages": this.getVisiblePages(
                null,
                props.pages
            )
        };
    }

  static propTypes = {
      "pages": PropTypes.number,
      "page": PropTypes.number,
      "PageButtonComponent": PropTypes.any,
      "onPageChange": PropTypes.func,
      "previousText": PropTypes.string,
      "nextText": PropTypes.string,
      "onPageSizeChange": PropTypes.func,
      "pageSize": PropTypes.number,
      "pageSizeOptions": PropTypes.array,
      "showPageSizeOptions": PropTypes.bool,
      "showTotalPages": PropTypes.bool,
      "itemsCountText": PropTypes.string
  };

  componentWillReceiveProps (nextProps) {
      if (this.props.pages !== nextProps.pages) {
          this.setState({
              "visiblePages": this.getVisiblePages(
                  null,
                  nextProps.pages
              )
          });
      }

      this.changePage(nextProps.page + 1);

  }

  filterPages = (visiblePages, totalPages) => visiblePages.filter((page) => page <= totalPages);

  getVisiblePages = (page, total) => {
      if (total < 7) {
          return this.filterPages(
              [
                  1,
                  2,
                  3,
                  4,
                  5,
                  6
              ],
              total
          );
      }
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
          return [
              1,
              page - 1,
              page,
              page + 1,
              total
          ];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
          return [
              1,
              total - 3,
              total - 2,
              total - 1,
              total
          ];
      }
      return [
          1,
          2,
          3,
          4,
          5,
          total
      ];
  };

  changePage (page) {
      const activePage = this.props.page + 1;
      if (page === activePage) {
          return;
      }

      const visiblePages = this.getVisiblePages(
          page,
          this.props.pages
      );

      this.setState({
          "visiblePages": this.filterPages(
              visiblePages,
              this.props.pages
          )
      });

      this.props.onPageChange(page - 1);
  }

  render () {
      const {PageButtonComponent = defaultButton} = this.props,
          {visiblePages} = this.state,
          activePage = this.props.page + 1;

      return (
          <GridContainer>
              <GridItem
                  md={6}
                  xs={12}
              >
                  <div
                      className="Table__pagination"
                      style={{"justifyContent": "flex-start"}}
                  >
                      {this.props.showPageSizeOptions &&
                      <span
                          className="select-wrap -pageSizeOptions"
                          style={{"paddingRight": "10px"}}
                      >
                          <select
                              onChange={(e) => this.props.onPageSizeChange(Number(e.target.value))}
                              style={{
                                  "padding": "5px 10px 5px 10px",
                                  "marginLeft": "2px",
                                  "borderColor": "#ccc",
                                  "borderRadius": "3px"
                              }}
                              value={this.props.pageSize}
                          >
                              {this.props.pageSizeOptions.map((option, i) => <option
                                      key={i}
                                      value={option}
                                  >
                                      {option}
                                      {" "}
                                      {this.props.rowsText}
                                  </option>)}
                          </select>
                          {" "}
                          {" per page"}
                          
                          {this.props.itemsCountText
                          ? <span
                              className="select-wrap -pageSizeOptions"
                              style={{"paddingRight": "10px",
                                  "paddingTop": "4px"}}
                            >
                              {this.props.itemsCountText ? ", showing records " + this.props.itemsCountText : ""}
                          </span>
                          : " "}
                      </span>}
                  </div>
              </GridItem>
              <GridItem
                  md={6}
                  xs={12}
              >
                  <div className="Table__pagination">
                      <div className="Table__prevPageWrapper">
                          <PageButtonComponent
                              className="Table__pageButton Table__pageButton_prev"
                              disabled={activePage === 1}
                              onClick={() => {
                                  if (activePage === 1) {
                                      return;
                                  }
                                  this.changePage(activePage - 1);
                              }}
                          >
                              {this.props.previousText}
                          </PageButtonComponent>
                      </div>
                      <div className="Table__visiblePagesWrapper">
                          {visiblePages.map((page, index, array) => <PageButtonComponent
                                  className={
                                      activePage === page
                                          ? "Table__pageButton Table__pageButton--active"
                                          : "Table__pageButton"
                                  }
                                  key={"pagination_" + page}
                                  onClick={this.changePage.bind(null, page)}
                              >
                                  {array[index - 1] + 2 < page ? `...${page}` : page}
                              </PageButtonComponent>)}
                      </div>
                      <div className="Table__nextPageWrapper">
                          <PageButtonComponent
                              className="Table__pageButton Table__pageButton_next"
                              disabled={activePage === this.props.pages}
                              onClick={() => {
                                  if (activePage === this.props.pages) {
                                      return;
                                  }
                                  this.changePage(activePage + 1);
                              }}
                          >
                              {this.props.nextText}
                          </PageButtonComponent>
                      </div>
                  </div>
              </GridItem>
          </GridContainer>
      );
  }
}
