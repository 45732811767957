import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";

// Core components
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

import {connect} from "react-redux";
import {doLogout} from "../../store/actions/authActions";
import AuthService from "customs/auth/AuthService";
import Echo from "laravel-echo";
import Constants from "customs/config/Constants";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Icon from '@material-ui/core/Icon';
import AccessControl from "customs/components/AccessControl";

import moment from "moment";
import "moment-timezone";

let notification_source = null;
let notification_source_listening = false;

class HeaderLinks extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "openNotification": false,
            "openProfile": false,
            "notifications": this.getCurrentNotifications(),
            "notification_source_token": props.auth.token ? props.auth.token : "",
            "tr_message": "",
            "tr_color": "info",
            "tr": false
        };
        this.handleEventError = this.handleEventError.bind(this);
        this.getCurrentNotifications = this.getCurrentNotifications.bind(this);
        this.setCurrentNotifications = this.setCurrentNotifications.bind(this);

    }

  handleClickNotification = () => {

      this.setState({"openNotification": !this.state.openNotification});

  };

  handleCloseNotification = () => {
    let notifications = this.getCurrentNotifications();
    notifications.map((prop, key) => {
        prop.seen = 1;
        return prop;
    });
      this.setState({
          "openNotification": false,
          "notifications": notifications
      });

      this.setDocTitle(notifications.filter((item) => item.seen === 0).length);
      this.setCurrentNotifications(notifications);
  };

  setDocTitle(count) {
    document.title = "MAPS" + (count > 0 ? "*" : "");
  }

  handleClickProfile = () => {

      this.setState({"openProfile": !this.state.openProfile});

  };

  handleCloseProfile = () => {

      this.setState({"openProfile": false});

  };

  activeRoute = (routeName) => (window.location.href.indexOf(routeName) > -1 ? "active" : "");

  handleGotoProfile = () => {

      this.handleCloseProfile();
      if (!this.activeRoute("/profile")) {

          this.props.history.push("/admin/profile");

      }

  };

  handleGotoChangePassword = () => {

      this.handleCloseProfile();
      if (!this.activeRoute("/changepassword")) {

          this.props.history.push("/admin/changepassword");

      }

  };

  handleLogoutClick = () => {

      this.handleCloseProfile();
      this.props.doLogout({...this.props});
      try {

          console.clear();

      } catch (err) {}

  };

  handleGotoNotifications = (script, client_id, type, clear_cnt = true) => {

    if (clear_cnt) this.handleCloseNotification();
    //   if (!this.activeRoute("/notifications")) {

    //       this.props.history.push("/admin/notifications");

    //   }

        if (client_id === null || type === null || type === 'manager_login') return;
        this.props.history.push({
            pathname: '/admin/clients',
            state: script === null ? {} : {"from": "dashboard", "script": script}
        });

  };

  componentDidMount () {

      if (new AuthService(
          this.state,
          this.props
      ).getToken()) {

          if (!notification_source) {

              this.initializeNotificationSource();

          }
          this.addEventSourceListener();

      } else {

          this.closeNotificationConnection();

      }

  }

  componentWillUnmount () {

      this.isUnmounted = true;
      this.closeNotificationConnection();

  }

  componentDidUpdate () {

      const token = this.props.auth.token ? this.props.auth.token : "";
      if (token.length > 0 && this.state.notification_source_token !== token) {

          this.setState({"notification_source_token": token});
          this.closeNotificationConnection();
          this.initializeNotificationSource();

      }

  }

  initializeNotificationSource () {

    require("pusher-js");
    let authUser = new AuthService(
        this.state,
        this.props
    );
    let options = {
        broadcaster: 'pusher',
        key: Constants.WS_KEY,
        cluster: Constants.WS_CLUSTER,
        forceTLS: true,
        encrypted: true,
        authEndpoint: Constants.API_URL + '/broadcasting/auth',
        auth: {
            headers: {
                Authorization: `Bearer ${authUser.getToken()}`,
                Accept: 'application/json',
            },
        },
    };
    
    notification_source = new Echo(options);
  }

  getCurrentNotifications() {
    let authUser = new AuthService(
        this.state,
        this.props
    );
    
    let notifications = localStorage.getItem('admin_' + authUser.getUserId() + '_notifications');
    return notifications ? JSON.parse(notifications) : [];
  }

  setCurrentNotifications(notifications) {
    let authUser = new AuthService(
        this.state,
        this.props
    );

    localStorage.setItem('admin_' + authUser.getUserId() + '_notifications', JSON.stringify(notifications));
  }

  handleEventError = function (e) {

      if (this.isUnmounted) {

          return;

      }
      if (e.status === 401) {

          this.setState({"notification_source_token": ""});
          this.closeNotificationConnection();

      }

  };

  setNotificationDetails(data) {
    if (data === undefined || data === null) return "";
    return <div>
        {data.type === "watch_event" ? <Icon className={"fas fa-eye eye-watcher-notification"} /> : ""}
        {data.message ? data.message : (data.title ? data.title : "")}<br/>
        {data.changes && Object.keys(data.changes).length > 0
        ? data.changes.map((change, key) => {
            return <span key={key}>
                {change.script + (data.hasOwnProperty('level') && data.level === 'script' ? '' : (change.step ? ' -> ' + change.step : ''))}<br/>
                {
                    data.hasOwnProperty('level') && (data.level === 'script' || data.level === 'step')
                    ? ""
                    : (change.changes && change.changes.length > 0 ? <span key={key + '_field'}>{"Fields:"}</span> : "")
                }
                {
                    data.hasOwnProperty('level') && (data.level === 'script' || data.level === 'step')
                    ? ""
                    : (change.changes && change.changes.length > 0
                        ? <ul style={{"marginTop": "0px", "marginBottom": "0px"}}>{change.changes.map((field, field_key) => {
                                return <li key={key + '_' + field_key}>{field}</li>
                            })}</ul>
                        : "")
                }
            </span>;
        })
        : ""}
    </div>;
  }

  processChangeAndWatchEvents(data) {
    const user_tz_offset = this.props.auth && this.props.auth.user ? moment.tz(this.props.auth.user.timezone).utcOffset() : null;
    let date = moment.utc(data.datetime).utcOffset(user_tz_offset).format('YYYY-MM-DD HH:mm:ss');
    let notifications = this.getCurrentNotifications();
    notifications = [
        {"title": data.message, "date": date, "seen": 0, "changes": data.changes, "type": data.type, "client_id": data.client_id, "script": data.script, "level": data.level},
        ...notifications
    ];
    this.setState({
        "notifications": notifications,
    });
    this.setDocTitle(notifications.filter((item) => item.seen === 0).length);
    this.setCurrentNotifications(notifications);

    if (window.location.pathname.indexOf("/admin/clients") !== -1
        && this.props.auth && this.props.auth.selectedClient
        && data.client_id === this.props.auth.selectedClient) {
        this.setState({
            "tr_message": <div onClick={() => {
                    this.handleGotoNotifications(data.script ? data.script : null, data.client_id, data.type, false);
                    this.handleClosePopupNotification();
                }}>
                <span style={{"fontSize": "13px", "fontStyle": "italic"}}>{date}</span>
                {this.setNotificationDetails(data)}
            </div>,
            "tr_color": "warning",
            "tr": true
        });
        this.showUsersNotification();
    }
  }

  processManagerLoginEvents(data) {
    const user_tz_offset = this.props.auth && this.props.auth.user ? moment.tz(this.props.auth.user.timezone).utcOffset() : null;
    let date = moment.utc(data.datetime).utcOffset(user_tz_offset).format('YYYY-MM-DD HH:mm:ss');
    let notifications = this.getCurrentNotifications();
    notifications = [
        {"title": data.message, "date": date, "seen": 0, "changes": [], "type": data.type, "client_id": undefined, "script": undefined, "level": undefined},
        ...notifications
    ];
    this.setState({
        "notifications": notifications,
    });
    this.setDocTitle(notifications.filter((item) => item.seen === 0).length);
    this.setCurrentNotifications(notifications);

    this.setState({
        "tr_message": <div onClick={() => {
                this.handleGotoNotifications(null, null, data.type, false);
                this.handleClosePopupNotification();
            }}>
            <span style={{"fontSize": "13px", "fontStyle": "italic"}}>{date}</span>
            {this.setNotificationDetails(data)}
        </div>,
        "tr_color": "success",
        "tr": true
    });
    this.showUsersNotification();
  }

  addEventSourceListener () {

      if (!notification_source_listening) {
        let authUser = new AuthService(
            this.state,
            this.props
        );
        notification_source.private(`App.User.${authUser.getUserId()}`).listen(".changelog", data => {
            if (data.message.length !== 0) {
                this.processChangeAndWatchEvents(data);
            }
        }).listen(".watch_event", data => {
            if (data.message.length !== 0) {
                this.processChangeAndWatchEvents(data);
            }
        }).listen(".manager_login", data => {
            if (AccessControl.checkRoutePermissions("/profile", this.props.auth, "manager_login_notification") && data.message.length !== 0) {
                this.processManagerLoginEvents(data);
            }
        });
        notification_source_listening = true;

      }

  }

  hideNotification;
  showUsersNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleClosePopupNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  handleClosePopupNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false, "tr_message": ""});
  }

  closeNotificationConnection () {

      this.setState({"notifications": []});
      this.setCurrentNotifications([]);
      if (notification_source) {
        notification_source.disconnect();
        notification_source = null;
      }
      notification_source_listening = false;

  }

  render () {

      const {classes, rtlActive} = this.props,
          {openNotification, openProfile} = this.state,
          dropdownItem = classNames(
              classes.dropdownItem,
              classes.primaryHover,
              {[classes.dropdownItemRTL]: rtlActive}
          ),
          wrapper = classNames({
              [classes.wrapperRTL]: rtlActive
          }),
          managerClasses = classNames({
              [classes.managerClasses]: true
          }),
          {notifications} = this.state,
          new_notification_cnt = notifications.filter((item) => item.seen === 0);
      return (
          <div className={wrapper}>
              <div className={managerClasses}>
                  <Button
                      aria-haspopup="true"
                      aria-label="Notifications"
                      aria-owns={openNotification ? "notification-menu-list" : null}
                      buttonRef={(node) => {

                          this.anchorNotification = node;

                      }}
                      className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                      color="transparent"
                      justIcon
                      title={'Show Notifications'}
                      muiClasses={{
                          "label": rtlActive ? classes.labelRTL : ""
                      }}
                      onClick={this.handleClickNotification}
                  >
                      <Notifications
                          className={
                              `${classes.headerLinksSvg
                              } ${
                                  rtlActive
                                      ? `${classes.links} ${classes.linksRTL}`
                                      : classes.links}`
                          }
                      />
                      {new_notification_cnt.length > 0
                          ? <span className={classes.notifications}>
                              {new_notification_cnt.length}
                          </span>
                          : ""}
                      <Hidden
                          implementation="css"
                          mdUp
                      >
                          <span
                              className={classes.linkText}
                              onClick={this.handleClickNotification}
                          >
                              {rtlActive ? "إعلام" : "Notification"}
                          </span>
                      </Hidden>
                  </Button>
                  <Popper
                      anchorEl={this.anchorNotification}
                      className={classNames({
                          [classes.popperClose]: !openNotification,
                          [classes.popperResponsive]: true,
                          [classes.popperNav]: true
                      })}
                      disablePortal
                      open={openNotification}
                      placement="bottom"
                      transition
                  >
                      {({TransitionProps}) => (<Grow
                              {...TransitionProps}
                              id="notification-menu-list"
                              style={{
                                  "transformOrigin": "0 0 0",
                                  "overflowY": "auto",
                                  "maxHeight": "500px"
                              }}
                          >
                              <Paper className={classes.dropdown}>
                                  <ClickAwayListener onClickAway={this.handleCloseNotification}>
                                      <MenuList role="menu">
                                          {notifications.length > 0 
                                                ? notifications.map((prop, key) => (
                                                    <MenuItem
                                                    key={key}
                                                    className={
                                                        dropdownItem +
                                                        (prop.seen === 0 ? " dropdownItemNew" : "")
                                                    }
                                                    onClick={() => this.handleGotoNotifications(prop.script ? prop.script : null, prop.client_id, prop.type, true)}
                                                    >
                                                    <span style={{"fontSize": "12px", "fontStyle": "italic"}}>{prop.date}</span>
                                                    {this.setNotificationDetails(prop)}
                                                    </MenuItem>
                                                ))
                                            :                         <MenuItem
                                                  onClick={() => this.handleGotoNotifications(null, null, null, true)}
                                                  className={dropdownItem}
                                              >
                                                  {"No new notifications"}
                                              </MenuItem>}
                                      </MenuList>
                                  </ClickAwayListener>
                              </Paper>
                          </Grow>)}
                  </Popper>
              </div>

              <div className={managerClasses}>
                  <Button
                      aria-haspopup="true"
                      aria-label="Person"
                      aria-owns={openNotification ? "profile-menu-list" : null}
                      buttonRef={(node) => {

                          this.anchorProfile = node;

                      }}
                      className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                      color="transparent"
                      justIcon
                      muiClasses={{
                          "label": rtlActive ? classes.labelRTL : ""
                      }}
                      onClick={this.handleClickProfile}
                  >
                      <Person
                          className={
                              `${classes.headerLinksSvg
                              } ${
                                  rtlActive
                                      ? `${classes.links} ${classes.linksRTL}`
                                      : classes.links}`
                          }
                      />
                      <Hidden
                          implementation="css"
                          mdUp
                      >
                          <span
                              className={classes.linkText}
                              onClick={this.handleClickProfile}
                          >
                              {rtlActive ? "الملف الشخصي" : "Profile"}
                          </span>
                      </Hidden>
                  </Button>
                  <Popper
                      anchorEl={this.anchorProfile}
                      className={classNames({
                          [classes.popperClose]: !openProfile,
                          [classes.popperResponsive]: true,
                          [classes.popperNav]: true
                      })}
                      disablePortal
                      open={openProfile}
                      placement="bottom"
                      transition
                  >
                      {({TransitionProps}) => (<Grow
                              {...TransitionProps}
                              id="profile-menu-list"
                              style={{"transformOrigin": "0 0 0"}}
                          >
                              <Paper className={classes.dropdown}>
                                  <ClickAwayListener onClickAway={this.handleCloseProfile}>
                                      <MenuList role="menu">
                                          <MenuItem
                                              className={dropdownItem}
                                              onClick={this.handleGotoProfile.bind(this)}
                                          >
                                              {rtlActive ? "الملف الشخصي" : "Profile"}
                                          </MenuItem>
                                          <Divider light />
                                          <MenuItem
                                              className={dropdownItem}
                                              onClick={this.handleGotoChangePassword.bind(this)}
                                          >
                                              {rtlActive ? "" : "Change Password"}
                                          </MenuItem>
                                          <Divider light />
                                          <MenuItem
                                              className={dropdownItem}
                                              onClick={this.handleLogoutClick.bind(this)}
                                          >
                                              {rtlActive ? "الخروج" : "Log out"}
                                          </MenuItem>
                                      </MenuList>
                                  </ClickAwayListener>
                              </Paper>
                          </Grow>)}
                  </Popper>
              </div>
              {this.state.tr_message
                ? <Snackbar
                    close
                    closeNotification={() => {
                        this.handleClosePopupNotification();
                    }}
                    color={this.state.tr_color}
                    icon={AddAlert}
                    message={this.state.tr_message}
                    open={this.state.tr}
                    place="tr"
                />
                : ""
            }
          </div>
      );

  }

}

HeaderLinks.propTypes = {
    "classes": PropTypes.object.isRequired,
    "rtlActive": PropTypes.bool
};

const mapStateToProps = (state, props) => ({
        "auth": state.auth.data
    }),

    mapDispatchToProps = (dispatch) => ({
        "doLogout": (props) => dispatch(doLogout(props))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(adminNavbarLinksStyle)(HeaderLinks)));
