import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class FillFromContacts extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            contact: "",
            contacts: this.props.contacts
        };
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.contacts !== null || nextProps.contacts !== undefined) {
            this.setState({
                "contacts": nextProps.contacts
            });
        }
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="update-profile-confirm-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => this.props.onClose("fillFromContactsModal")}
                open={this.props.fillFromContactsModal}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    Select Contact
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="update-profile-confirm-modal-slide-description"
                >
                    <FormControl
                        disabled={this.state.is_view}
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            className={classes.selectLabel}
                        >
                            Select Contact
                        </InputLabel>
                        <Select
                            classes={{"select": classes.select}}
                            fullWidth
                            inputProps={{
                                "name": "contact",
                                "id": "contact",
                            }}
                            MenuProps={{"className": classes.selectMenu}}
                            onChange={(event) => this.setState({'contact': event.target.value})}
                            value={this.state.contact}
                        >
                            {this.state.contacts !== null
                                ? this.state.contacts.map((prop, key) => {
                                    return (
                                        <MenuItem
                                            classes={{"root": classes.selectMenuItem}}
                                            key={`contact_${key}`}
                                            value={prop.key}
                                            title={prop.tip}
                                            style={{"lineHeight": "1.5"}}
                                        >
                                            {prop.value} <br/> {prop.name ? <small>{"Name: "} {prop.name}</small> : null}
                                    </MenuItem>);})
                                : ""
                            }
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("fillFromContactsModal")}
                    >
                        Close
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        disabled={this.props.btnDisabled}
                        onClick={(event) => this.props.handleFillFromContact(this.state.contact)}
                        simple
                        >
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(FillFromContacts);
