import React from "react";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";

class GenerateCredentialsComponent extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": ""
        };
        this.handleConfirmClick = this.handleConfirmClick.bind(this);
        this.handleGenerateCredentialsDialog = this.handleGenerateCredentialsDialog.bind(this);
        this.doHandleGenerateCredentials = this.doHandleGenerateCredentials.bind(this);
    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "generate_credentials") {
            this.doHandleGenerateCredentials();
        }
    }

    handleGenerateCredentialsDialog() {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to continue?`,
            "confirmModalTitle": "Generate Credentials",
            "action": "generate_credentials"
        });
    }

    doHandleGenerateCredentials() {
        this.props.handleUpdateStep(
            this.props.step.id, this.props.step.client_script_id,
            {'form_data': {'generate': 'credentials'}},
            () => {}
        );
    }

    handleGeneratePasswordDialog() {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to continue?`,
            "confirmModalTitle": "Reset Password",
            "action": "generate_credentials"
        });
    }

    render () {
        return <div>
            {
            this.props.username === undefined || this.props.username === null || this.props.username.length === 0
                ? <Button
                    disabled={this.props.readOnly || !(this.props.index > 0 ? this.props.checklist.steps[this.props.index - 1].status === 1 : true)}
                    color="success"
                    onClick={() => this.handleGenerateCredentialsDialog()}
                >
                    Generate Credentials
                </Button>
                : <Button
                    disabled={this.props.readOnly || !(this.props.index > 0 ? this.props.checklist.steps[this.props.index - 1].status === 1 : true)}
                    color="primary"
                    onClick={() => this.handleGeneratePasswordDialog()}
                >
                    Reset Password
                </Button>
            }
            <ConfirmDialog
                confirmModal={this.state.confirmModal}
                confirmModalMsg={this.state.confirmModalMsg}
                confirmModalTitle={this.state.confirmModalTitle}
                onClose={() => this.setState({"confirmModal": false})}
                onYesClick={() => this.handleConfirmClick()}
            />
        </div>;
    }
}

const GenerateCredentials = withStyles(extendedFormsStyle)(GenerateCredentialsComponent);

export default GenerateCredentials;
