import React from "react";
import {GENERIC_NAV} from "../utils";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    "root": {
        "width": "100%"
    },
    "button": {
        "marginRight": theme.spacing(1)
    },
    "completed": {
        "display": "inline-block"
    },
    "instructions": {
        "marginTop": theme.spacing(1),
        "marginBottom": theme.spacing(1)
    }
}));

function Navs ({"navs": {links}, onNavChange}) {

    const classes = useStyles();
    let relLinks = links.filter(({nav}) => nav !== GENERIC_NAV),
        [
            activeStep,
            setActiveStep
        ] = React.useState(links.findIndex(({isActive}) => isActive === true));

    const handleClick = (clickedNav) => {

        setActiveStep(links.findIndex(({nav}) => nav === clickedNav));
        onNavChange(clickedNav);

    };

    return (
        <div className={classes.root}>
            <Stepper
                activeStep={activeStep}
                alternativeLabel
                nonLinear
            >
                {relLinks.map(({nav, name, icon, isActive}, i) => (<Step key={i/*name ? name : nav*/}>
                        <StepButton onClick={() => handleClick(nav)}>
                            {name ? name : nav}
                        </StepButton>
                    </Step>))}
            </Stepper>
        </div>
    );

}

export default Navs;
