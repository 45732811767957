import helpers from "customs/helpers/helpers";

const axiosHelper = {
    processError (
        isUnmounted,
        prop,
        error,
        stateCallback,
        showUsersNotificationCallback,
        doLogoutCallback
    ) {
        if (isUnmounted) {
            return;
        }
        if (prop !== null) {
            stateCallback({"prop": []});
        }
        const default_err = helpers.getErrorMessage(error);
        if (default_err === false) {
            if (doLogoutCallback !== null) {
                doLogoutCallback();
            }
        } else {
            if (stateCallback !== null) {
                stateCallback({
                    "btnDisabled": false,
                    "doneModal": false,
                    "doneModalMsg": default_err,
                    "doneModalBtn": "OK",
                    "tr_color": "danger"
                });
            }
            if (showUsersNotificationCallback !== null) {
                if (showUsersNotificationCallback.length === 2) {
                    showUsersNotificationCallback(
                        default_err,
                        "danger"
                    );
                } else {
                    showUsersNotificationCallback();
                }
            }
        }
        helpers.hideLoading();
    }
};

export default axiosHelper;
