import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from "axios/axios";

class AddressAutocomplete extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            "open": false,
            "options": [],
            "loading": false,
            "selected": null,
            "inputted": this.props.initValue,
            "initValue": this.props.initValue,
            "defaultOption": this.props.hasOwnProperty('initValue') && this.props.initValue.length > 0
                ? [{ "id": 0, "title": this.props.initValue, "main_text": this.props.initValue, "place_id": "" }]
                : null
        }
        this.searchAutoComplete = this.searchAutoComplete.bind(this);
    }
    componentWillReceiveProps (nextProps) {
        if (nextProps.hasOwnProperty('initValue')) {
            this.setState({
                "defaultOption": nextProps.initValue.length > 0
                    ? [{ "id": 0, "title": nextProps.initValue, "main_text": nextProps.initValue, "place_id": "" }]
                    : null,
                "inputted": nextProps.initValue,
                "initValue": nextProps.initValue,
                "selected": null
            });
        }
    }

    searchAutoComplete(event, search) {
        if (event !== null) event.preventDefault();
        if (search === null || search === undefined || search.length < 2) {
            this.setState({"loading": false, "options": []});
            return;
        }
        let active = true;

        if (this.state.loading) {
            return;
        }
        this.setState({"loading": true});

        (async () => {
            const response = await axios(this.props)
                .post('/address/search',
                    {"search": search}
                );
            const data = await response.data;
            let predictions = data.hasOwnProperty('predictions') ? data.predictions : [];

            if (active) {
                let options = [];
                predictions.forEach((data, key) => {
                    options.push({
                        "id": key,
                        "title": data.description,
                        "main_text": data.hasOwnProperty('structured_formatting') && data.structured_formatting.hasOwnProperty('main_text')
                                        ? data.structured_formatting.main_text : data.description,
                        "place_id":  data.place_id
                    });
                })
                this.setState({options});
            }
            this.setState({"loading": false});
        })();

        return () => {
            active = false;
            this.setState({"loading": false});
        };
    }

    render () {
        const displayText = this.state.inputted.length === 0 ? this.state.initValue : this.state.inputted;
        const options = this.state.options.length === 0 && this.state.inputted.length === 0 && displayText.length > 0
            ? this.state.defaultOption
            : this.state.options;
        const highlighRequired = this.props.required && (displayText === null || displayText === undefined || displayText.length === 0);
        return (
            <Autocomplete
                // className={highlighRequired ? "MuiInput-underline Mui-error" : ""}
                defaultValue={this.state.initValue.length === 0 || this.state.defaultOption === null || this.state.defaultOption.length === 0
                        ? null : this.state.defaultOption[0]}
                fullWidth
                value={this.state.inputted}
                multiple={false}
                disabled={this.props.hasOwnProperty('disabled') ? this.props.disabled : false}
                options={options === null || options.length === 0 ? [] : options}
                getOptionLabel={(option) => this.state.selected === null ? displayText : option.main_text}
                getOptionSelected={(option, value) => value.id === option.id}
                style={this.props.style ? this.props.style : { width: "100%" }}
                onChange={(e, v) => {
                    if (v === null) {
                        this.setState({"inputted": ""});
                        this.props.onChange("");
                    } else {
                        this.props.onChange(v.main_text);
                        this.props.onChangeSelect(v);
                    }
                    this.setState({"selected": v});
                }}
                onInputChange={(event, newValue) => {
                    this.props.onChange(newValue);
                    this.setState({"inputted": newValue, "selected": null});
                    if (this.state.initValue === newValue) return;
                    this.searchAutoComplete(event, newValue);
                }}
                freeSolo={true}
                renderOption={(option, { selected }) => <React.Fragment>{option.title}</React.Fragment>}
                renderInput={(params) => (
                    <TextField
                        className={highlighRequired ? "MuiInput-underline Mui-error" : ""}
                        required={this.props.required}
                        autoComplete="off"
                        {...params}
                        label={this.props.title}
                        onBlur={() => this.props.onBlur(this.props.id, this.state.selected !== null ? this.state.selected.main_text : displayText)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                            <React.Fragment>
                                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                            ),
                        }}
                        // InputLabelProps={{
                        //     style: { color: highlighRequired ? "red" : "" },
                        // }}
                    />
                )}
            />
        );

    }

}

export default withStyles(extendedFormsStyle)(AddressAutocomplete);
