import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import PreviewDocument from "customs/components/ScriptStep/PreviewDocument.jsx";
import helpers from "customs/helpers/helpers";
import axios from "../../axios/client-axios";
import axiosHelper from "axios/axiosHelper";
import ChangeApplicationStatusDialog from "customs/components/ChangeApplicationStatusDialog";
import AddApplicationPageNoteDialog from "customs/components/AddApplicationPageNoteDialog";
import ConfirmDialog from "customs/components/ConfirmDialog";
import moment from "moment";

class ViewApplicationStatusDialog extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
            "currentTab": 0,
            "previewDocumentModal": false,
            "previewDocumentValue": null,
            "previewDocumentName": null,
            "changeStatus": false,
            "application_status": null,
            "selected_application_id": null,
            "current_status": null,
            "application_details": "",
            "tr_color": "success",
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "verify_page": "",
            "verify_ubo_idx": "",
            "current_verified": "",
            "page_notes": null,
            "approval_message": [],
            "addNote": false,
            "new_note": "",
            "add_note_page": null,
            "verify_pages": [],
            "pages_count": 0,
            "downloading_match_report": false,
            "match_report_viewed": false,

            "show_message_dialog": false,
            "message_dialog_title": "Message",
            "message_dialog_contents": null,
            "is_new_message": false,
            "show_new_message": false,
            "new_message_subject": '',
            "new_message_body": '',
            "new_message_attachments": null
        };
        this.changeStatus = this.changeStatus.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleStatusChangeClose = this.handleStatusChangeClose.bind(this);
        this.handleAddNote = this.handleAddNote.bind(this);
        this.checkIfVerified = this.checkIfVerified.bind(this);
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }
    
    handleConfirmClick () {
        if (this.state.action === "set_verified") {
            this.doSetVerifyPage(this.state.verify_page, this.state.current_verified);
        } else if (this.state.action === 'generate_match_report') {
            this.doGenerateMatchReport();
        } else if (this.state.action === 'delete_message') {
            this.doDeleteMessage();
        } else if (this.state.action === 'send_message') {
            this.doSendMessage();
        } else if (this.state.action === 'send_id_verification_request') {
            this.doSendVerifyIdRequest();
        } else if (this.state.action === 'check_id_verification_status') {
            this.doCheckVerifyIdStatus();
        }
    }

    handleStatusChange(id, status, message, additional_fields_values) {
        this.props.handleStatusChange(id, status, message, additional_fields_values, ()=>{
            this.handleStatusChangeClose();
            this.setState({"additional_fields_values": [], "message": ""});
            clearTimeout(this.timer);
            this.timer = null;
            this.props.onClose("applicationView");
        });
    }

    handleStatusChangeClose () {
        this.setState({
            "changeStatus": false,
            "application_status": this.props.application_status,
            "selected_application_id": this.props.selected_application_id
        });
    }

    handlePageNoteClose () {
        this.setState({
            "addNote": false,
            "new_note": "",
            "add_note_page": null
        });
    }

    handleAddNote(id, message, page) {
        this.props.handleAddNote(
            id,
            message,
            page,
            ()=>{
                this.handlePageNoteClose();
                this.setState({"new_note": "", "add_note_page": null});
            }
        );
    }

    componentWillReceiveProps(props) {
        try {
            if (document.getElementById('view-application-dialog') !== null) {
                document.getElementById('view-application-dialog').querySelector('.MuiDialog-paperWidthSm').style.maxWidth = '850px';
                document.getElementById('view-application-dialog').querySelector('.MuiDialog-paperWidthSm').style.height = '95vh';
            }
        } catch(e) {}

        if (props.application_status !== this.state.application_status) {
            this.setState({"application_status" : props.application_status});
        }
        if (props.selected_application_id !== this.state.selected_application_id) {
            this.setState({"selected_application_id" : props.selected_application_id, "currentTab": 0});
        }
        this.setState({
            "current_status" : props.application_status,
            "application_details" : props.application_details,
            "page_notes" : props.page_notes,
            "approval_message" : props.approval_message
        });
        if (props.applicationView === false) {
            try {
                clearTimeout(this.timer);
                this.timer = null;
            } catch(e) {}
        } else {
            if (this.timer === null || this.timer === undefined) {
                this.timer = setTimeout(() => this.fetchApplication(), 15000);
            }
        }

        let application_history = props.application_history;
        application_history = application_history.filter((history) => history.details !== null && history.details.hasOwnProperty('request_field_values'));
        let has_requests = application_history.length > 0;
        let pages = [1, 2, 3, 4, 5, 6];
        if (has_requests) pages.push('requested');
        this.setState({"verify_pages": pages, "pages_count": pages.length + 2});

        if (this.state.downloading_match_report === true) {
            this.setState({"downloading_match_report": false});
        } else {
            this.setState({'match_report_viewed': false});
            try {
                if (props.selected_approval && props.selected_approval.details) {
                    if (props.selected_approval.details.hasOwnProperty('match_report_viewed')) {
                        let viewers = Array.isArray(props.selected_approval.details.match_report_viewed)
                            ? props.selected_approval.details.match_report_viewed
                            : JSON.parse(props.selected_approval.details.match_report_viewed);
                        this.setState({'match_report_viewed': viewers.includes(props.auth.user.id)});
                    }
                }
            } catch(e) {}
        }
    }
    
    fetchApplication(hideLoading = false) {
        if (this.props.applicationView === true) {
            this.props.viewApplication(null, this.props.selected_approval, this.props.application_status, false, () => {
                this.timer = setTimeout(() => this.fetchApplication(), 15000);
                if (hideLoading) helpers.hideLoading();
            }, this.props.application_message_direction, this.props.application_message_sort);
        } else {
            try {
                clearTimeout(this.timer);
                this.timer = null;
            } catch(e) {}
        }
    }

    changeStatus (event) {
        event.preventDefault();
        this.setState({
            "changeStatus": true,
            "application_status": this.state.application_status,
            "selected_application_id": this.state.selected_application_id
        });
    }

    previewDocument(id, file_name) {
        this.setState({
            "previewDocumentModal": true,
            "previewDocumentValue": id,
            "previewDocumentName": file_name
        });
    }

    downloadDocument(id, filename, url = null, callback = null) {
        const FileDownload = require("js-file-download");
        helpers.showLoading();
        axios(this.props)
          .get(
                url === null ? 'client/' + this.props.selected_client_id + '/file/' + id + '/download' : url,
                {
                    "responseType": "blob",
                    "timeout": 30000
                }
            )
            .then((response) => {
                helpers.hideLoading();
                if (["pdf", "png", "bmp", "jpg", "jpeg"].includes(filename.split('.').pop().toLowerCase())) {
                    var fileURL = window.URL.createObjectURL(response.data);
                    let tab = window.open();
                    tab.location.href = fileURL;
                } else {
                    FileDownload(
                        response.data,
                        filename
                    );
                }
                if (callback !== null) try {callback();} catch(e) {}
            })
            .catch((error) => axiosHelper.processError(
                this.isUnmounted,
                null,
                error,
                (state) => {this.setState(state);},
                () => {}
            ));
    }

    setCurrentTab(tab) {
        this.setState({currentTab: tab});
    }

    getRegistry(registry_key) {
        let registry = null;
        this.props.registry_entries.forEach(field => {
            if (field.registry_key === registry_key) {
                registry = field;
            }
        });
        return registry;
    }

    getRegistryValue(registry_key) {
        let registry = this.getRegistry(registry_key);
        if (registry !== null) {
            registry = {
                'value': registry.value,
                'client_assigned': registry.client_assigned
            };
        }
        return registry;
    }

    renderCustomFieldLabel(key) {
        let label = '';
        this.props.available_fields.forEach(field => {
            if (field.registry_key === key) {
                label = field.configuration.properties.title;
            }
        });
        return label;
    }

    renderValue(key) {
        let registry = this.getRegistryValue(key);
        return registry ? registry['value'] : '';
    }

    renderMCC(key) {
        let registry = this.getRegistryValue(key);
        let mcc = this.props.mccs.filter((mcc) => mcc.code === registry['value']);
        return mcc.length > 0 ? ('(' + registry['value'] + ') ' + mcc[0]['title']) : '';
    }

    renderPage1(classes) {
        let business_address_same_as_mailing = parseInt(this.renderValue('business_address_same_as_mailing'));
        if (isNaN(business_address_same_as_mailing)) business_address_same_as_mailing = 1;
        let new_company = parseInt(this.renderValue('new_company'));
        if (isNaN(new_company)) new_company = null;
        return (
            <GridContainer
                key="details_grid_1"
                style={{padding: '0 15px'}}
            >
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": helpers.abnFormat(this.renderValue('abn')),
                            "name": "abn",
                            "disabled": true
                        }}
                        labelText="Australian Business Number (ABN)"
                    />
                </GridItem>
                <GridItem md={12}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('business_type'),
                            "name": "business_type",
                            "disabled": true
                        }}
                        labelText="Entity Type"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('legal_name'),
                            "name": "legal_name",
                            "disabled": true
                        }}
                        labelText="Company Name"
                    />
                </GridItem>
                <GridItem md={12}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={new_company === 1}
                                    value={new_company === 1}
                                    name={'new_company'}
                                    aria-label={'new_company'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                New Company
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={new_company === 0}
                                        value={new_company === 0}
                                        name={'existing_company'}
                                        aria-label={'existing_company'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                Existing Company
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                <GridItem md={12}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderMCC('mcc'),
                            "name": "mcc",
                            "disabled": true
                        }}
                        labelText="Describe Business"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('website'),
                            "name": "website",
                            "disabled": true
                        }}
                        labelText="Business Website URL"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('legal_address'),
                            "name": "legal_address",
                            "disabled": true
                        }}
                        labelText="Registered Business Address"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('suburb'),
                            "name": "suburb",
                            "disabled": true
                        }}
                        labelText="City"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('state'),
                            "name": "state",
                            "disabled": true
                        }}
                        labelText="State"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('post_code'),
                            "name": "post_code",
                            "disabled": true
                        }}
                        labelText="Post Code"
                    />
                </GridItem>
                <GridItem md={12}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={business_address_same_as_mailing === 1}
                                    value={business_address_same_as_mailing === 1}
                                    name={'business_address_same_as_mailing'}
                                    aria-label={'business_address_same_as_mailing'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Is the mailing address the same as the registered business address?
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                {
                    business_address_same_as_mailing !== 1
                    ? <GridItem md={6}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('other_address'),
                                "name": "other_address",
                                "disabled": true
                            }}
                            labelText="Mailing Address"
                        />
                    </GridItem>
                    : ""
                }
                {
                    business_address_same_as_mailing !== 1
                    ? <GridItem md={6}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('other_suburb'),
                                "name": "other_suburb",
                                "disabled": true
                            }}
                            labelText="City"
                        />
                    </GridItem>
                    : ""
                }
                {
                    business_address_same_as_mailing !== 1
                    ? <GridItem md={6}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('other_state'),
                                "name": "other_state",
                                "disabled": true
                            }}
                            labelText="State"
                        />
                    </GridItem>
                    : ""
                }
                {
                    business_address_same_as_mailing !== 1
                    ? <GridItem md={6}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('other_post_code'),
                                "name": "other_post_code",
                                "disabled": true
                            }}
                            labelText="Post Code"
                        />
                    </GridItem>
                    : ""
                }
            </GridContainer>
        );
    }
    
    formatNumber(value) {
        if (!value) return value;
        var n = value.toString().replace(/^(-)|[^0-9.,]+/g, '$1').replace(/,/g, '').split(".");
        n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return n.join(".");
    }

    renderFiles(key, title, highlight_empty = false) {
        let registry = this.getRegistry(key);
        let value = this.renderValue(key);
        if (registry === null || value.length === 0) {
            return <GridItem key={'grid_' + key} md={12}>
                <p key={'grid_' + key + '_1'} style={{color: ""/*highlight_empty ? "red" : ""*/}}>{title}</p>
                <p key={'grid_' + key + '_2'} style={{paddingLeft: "15px", color: highlight_empty ? "red" : ""}}>No uploaded file(s)</p>
            </GridItem>;
        }
        
        return <GridItem key={'grid_' + key} md={12}>
            <p key={'grid_' + key + '_1'}>{title}</p>
            {
                value.split(',').map((id, k) => {
                    let file = null;
                    try {
                        this.props.client_files.forEach(field => {
                            if (parseInt(field.id) === parseInt(id)) {
                                file = field;
                            }
                        });
                    } catch(e) {}
                    return file === null
                        ? ''
                        : <p key={'grid_' + key + '_2_' + k} style={{paddingLeft: "15px"}}>
                            <i className={"fas fa-eye eye-watcher-notification"} onClick={() => {
                                // return ["pdf", "png", "bmp", "jpg", "jpeg"].includes(file.filename.split('.').pop().toLowerCase())
                                //     ? this.previewDocument(id, file.filename)
                                //     : this.downloadDocument(id, file.filename);
                                return this.downloadDocument(id, file.filename);
                            }} />{file.filename}
                        </p>;
                })
            }
        </GridItem>;
    }

    renderPage2(classes) {
        let trust_fields = parseInt(this.renderValue('trust_fields'));
        if (isNaN(trust_fields)) trust_fields = 1;
        let terminated_merchant_facilities = parseInt(this.renderValue('terminated_merchant_facilities'));
        if (isNaN(terminated_merchant_facilities)) terminated_merchant_facilities = 1;
        let merchant_statement_fields = parseInt(this.renderValue('merchant_statement_fields'));
        if (isNaN(merchant_statement_fields)) merchant_statement_fields = 1;
        let match_report = null;
        try {this.props.client_files.forEach(field => {if (field.is_match_report === true) match_report = field;});} catch(e) {}
        return (
            <GridContainer
                key="details_grid_2"
                style={{padding: '0 15px'}}
            >
                {this.renderFiles('bas_statements_key', 'Upload Most Recent Business Activity Statements (BAS)', true)}
                {this.renderFiles('tax_returns_key', 'Upload Most Recent Tax Return', true)}
                <GridItem md={12}>
                    Does your company structure have a trust?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={trust_fields === 1}
                                    value={trust_fields === 1}
                                    name={'trust_fields'}
                                    aria-label={'trust_fields'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={trust_fields === 0}
                                        value={trust_fields === 0}
                                        name={'trust_fields_no'}
                                        aria-label={'trust_fields_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                {
                    trust_fields === 1
                    ? this.renderFiles('trust_deeds', 'Upload Trust Deed/S', true)
                    : ''
                }
                <GridItem md={12}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('trust_name'),
                            "name": "trust_name",
                            "disabled": true,
                            style: {borderBottom: this.renderValue('trust_name').toString().length > 0 ? "" : "solid red 1px"}
                        }}
                        labelText="Trust Name"
                    />
                </GridItem>
                <GridItem md={12}>
                    Have you ever had a merchant facility terminated?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={terminated_merchant_facilities === 1}
                                    value={terminated_merchant_facilities === 1}
                                    name={'terminated_merchant_facilities'}
                                    aria-label={'terminated_merchant_facilities'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={terminated_merchant_facilities === 0}
                                        value={terminated_merchant_facilities === 0}
                                        name={'terminated_merchant_facilities_no'}
                                        aria-label={'terminated_merchant_facilities_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                <GridItem md={12}>
                    <p>Match Report</p>
                    {
                        match_report !== null
                        ? <p style={{margin: "15px"}}>
                            <i className={"fas fa-eye eye-watcher-notification"} onClick={() => {
                                this.setState({'downloading_match_report': true});
                                return this.downloadDocument(
                                    match_report.id,
                                    match_report.filename,
                                    'client/' + this.props.selected_client_id + '/application/' + this.state.selected_application_id + '/file/' + match_report.id + '/download',
                                    () => {this.setState({'match_report_viewed': true})}
                                );
                            }} />{match_report.filename}
                        </p>
                        : <Button
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color={"success"}
                            onClick={(event) => this.generateMatchReport()}
                            disabled={!this.props.updateApplication}
                            >
                                Generate Match Report
                        </Button>
                    }
                </GridItem>
                <GridItem md={12}>
                    Do you currently have any merchant facilities?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={merchant_statement_fields === 1}
                                    value={merchant_statement_fields === 1}
                                    name={'merchant_statement_fields'}
                                    aria-label={'merchant_statement_fields'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={merchant_statement_fields === 0}
                                        value={merchant_statement_fields === 0}
                                        name={'merchant_statement_fields_no'}
                                        aria-label={'merchant_statement_fields_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                {
                    merchant_statement_fields
                    ? this.renderFiles('recent_merchant_statements_key', 'Upload last 3 months merchant statements', true)
                    : ''
                }
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": '$' + this.formatNumber(this.renderValue('estimated_annual_turnover')),
                            "name": "estimated_annual_turnover",
                            "disabled": true
                        }}
                        labelText="Estimated Annual Card Revenue"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('average_number_of_credit_card_sales_per_month'),
                            "name": "average_number_of_credit_card_sales_per_month",
                            "disabled": true
                        }}
                        labelText="Average number of credit card sales per month"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": "$" + this.formatNumber(((this.renderValue('estimated_annual_turnover').toString().replace(/\D/g, "") / 12) / this.renderValue('average_number_of_credit_card_sales_per_month').toString().replace(/\D/g, "")).toFixed(2)),
                            "name": "average_number_of_credit_card_sales_per_month",
                            "disabled": true
                        }}
                        labelText="Average Transaction Amount"
                    />
                </GridItem>
                <GridItem md={6}>
                    {/* Spacer */}
                </GridItem>
            </GridContainer>
        );
    }

    renderPage3(classes) {
        let do_you_take_deposit = parseInt(this.renderValue('do_you_take_deposit'));
        if (isNaN(do_you_take_deposit)) do_you_take_deposit = 1;
        return (
            <GridContainer
                key="details_grid_3"
                style={{padding: '0 15px'}}
            >
                <GridItem md={12}>
                    Percentage breakdown of card payments
                </GridItem>
                <GridItem md={4}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('card_present') + '%',
                            "name": "card_present",
                            "disabled": true
                        }}
                        labelText="Card present/Face-to-face"
                    />
                </GridItem>
                <GridItem md={4}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('moto') + '%',
                            "name": "moto",
                            "disabled": true
                        }}
                        labelText="Mail Order/Telephone Order"
                    />
                </GridItem>
                <GridItem md={4}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('internet') + '%',
                            "name": "internet",
                            "disabled": true
                        }}
                        labelText="Internet/E-commerce"
                    />
                </GridItem>
                <GridItem md={12}>
                    Do you take deposits from your customer?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={do_you_take_deposit === 1}
                                    value={do_you_take_deposit === 1}
                                    name={'do_you_take_deposit'}
                                    aria-label={'do_you_take_deposit'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={do_you_take_deposit === 0}
                                        value={do_you_take_deposit === 0}
                                        name={'do_you_take_deposit_no'}
                                        aria-label={'do_you_take_deposit_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                {
                    do_you_take_deposit
                    ? <GridItem md={12}>
                        How long from the time of deposit payment are the goods/serviced received?
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('zero_days') + '%',
                                "name": "zero_days",
                                "disabled": true
                            }}
                            labelText="0 Days"
                        />
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('seven_days') + '%',
                                "name": "seven_days",
                                "disabled": true
                            }}
                            labelText="1-7 Days"
                        />
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('fourteen_days') + '%',
                                "name": "fourteen_days",
                                "disabled": true
                            }}
                            labelText="8-14 Days"
                        />
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('thirty_days') + '%',
                                "name": "thirty_days",
                                "disabled": true
                            }}
                            labelText="15-30 Days"
                        />
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('sixty_days') + '%',
                                "name": "sixty_days",
                                "disabled": true
                            }}
                            labelText="31-60 Days"
                        />
                    </GridItem>
                    : ''
                }
                {
                    do_you_take_deposit
                    ? <GridItem md={4}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('more') + '%',
                                "name": "more",
                                "disabled": true
                            }}
                            labelText="61 Days+"
                        />
                    </GridItem>
                    : ''
                }
            </GridContainer>
        );
    }

    renderPage4(classes) {
        let subscription_membership_transactions = parseInt(this.renderValue('subscription_membership_transactions'));
        if (isNaN(subscription_membership_transactions)) subscription_membership_transactions = 1;
        let frequency_of_subscriptions_value = this.renderValue('weekly');
        let frequency_of_subscriptions = 'Weekly';
        if (parseInt(frequency_of_subscriptions_value) === 0) {
            frequency_of_subscriptions_value = this.renderValue('monthly');
            frequency_of_subscriptions = 'Monthly';
        }
        if (parseInt(frequency_of_subscriptions_value) === 0) {
            frequency_of_subscriptions_value = this.renderValue('bi_annually');
            frequency_of_subscriptions = 'Bi-annually';
        }
        if (parseInt(frequency_of_subscriptions_value) === 0) {
            frequency_of_subscriptions_value = this.renderValue('annually');
            frequency_of_subscriptions = 'Annually';
        }
        if (parseInt(frequency_of_subscriptions_value) === 0) {
            frequency_of_subscriptions_value = this.renderValue('other');
            frequency_of_subscriptions = 'Other';
        }
        let facility_required_nexxtap = parseInt(this.renderValue('facility_required_nexxtap'));
        if (isNaN(facility_required_nexxtap)) facility_required_nexxtap = 1;
        let facility_required_online_ecomm = parseInt(this.renderValue('facility_required_online_ecomm'));
        if (isNaN(facility_required_online_ecomm)) facility_required_online_ecomm = 1;
        let absorb_surcharge = parseInt(this.renderValue('absorb_surcharge'));
        if (isNaN(absorb_surcharge)) absorb_surcharge = 1;
        let customer_pays_surcharge = parseInt(this.renderValue('customer_pays_surcharge'));
        if (isNaN(customer_pays_surcharge)) customer_pays_surcharge = 1;
        return (
            <GridContainer
                key="details_grid_4"
                style={{padding: '0 15px'}}
            >
                <GridItem md={12}>
                    Do you take subscription/membership transactions?
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={subscription_membership_transactions === 1}
                                    value={subscription_membership_transactions === 1}
                                    name={'subscription_membership_transactions'}
                                    aria-label={'subscription_membership_transactions'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Yes
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                    <FormControlLabel
                        disabled={true}
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                        checked={subscription_membership_transactions === 0}
                                        value={subscription_membership_transactions === 0}
                                        name={'subscription_membership_transactions_no'}
                                        aria-label={'subscription_membership_transactions_no'}
                                        icon={<Check className={classes.uncheckedIcon} />}
                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot,
                                            disabled: classes.disabledCheckboxAndRadio
                                        }}
                                />
                                No
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                {
                    subscription_membership_transactions
                    ?  <GridItem md={6}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('term_of_your_subscription'),
                                "name": "term_of_your_subscription",
                                "disabled": true
                            }}
                            labelText="Term of Subscription"
                        />
                    </GridItem>
                    : ''
                }
                {
                    subscription_membership_transactions
                    ?  <GridItem md={6}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": frequency_of_subscriptions,
                                "name": "frequency_of_subscriptions",
                                "disabled": true
                            }}
                            labelText="Frequency of Subscriptions"
                        />
                    </GridItem>
                    : ''
                }
                {
                    subscription_membership_transactions
                    ?  <GridItem md={12}>
                        <CustomInput
                            formControlProps={{"fullWidth": true}}
                            inputProps={{
                                "value": this.renderValue('transaction_information_notes'),
                                "multiline": true,
                                "rows": 3,
                                "disabled": true
                            }}
                            labelText="Notes"
                        />
                    </GridItem>
                    : ''
                }
                <GridItem md={12}>
                    Facility Required
                </GridItem>
                <GridItem md={12}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={facility_required_nexxtap === 1}
                                    value={facility_required_nexxtap === 1}
                                    name={'facility_required_nexxtap'}
                                    aria-label={'facility_required_nexxtap'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Nexxtap
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                <GridItem md={12}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={facility_required_online_ecomm === 1}
                                    value={facility_required_online_ecomm === 1}
                                    name={'facility_required_online_ecomm'}
                                    aria-label={'facility_required_online_ecomm'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Online processing/E-commerce
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                <GridItem md={12}>
                    Surcharge
                </GridItem>
                <GridItem md={12}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={absorb_surcharge === 1}
                                    value={absorb_surcharge === 1}
                                    name={'absorb_surcharge'}
                                    aria-label={'absorb_surcharge'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Absorb surcharge
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
                <GridItem md={12}>
                    <FormControlLabel
                        control={
                            <div>
                                <Checkbox
                                    disabled={true}
                                    checked={customer_pays_surcharge === 1}
                                    value={customer_pays_surcharge === 1}
                                    name={'customer_pays_surcharge'}
                                    aria-label={'customer_pays_surcharge'}
                                    icon={<Check className={classes.uncheckedIcon} />}
                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                    classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                        disabled: classes.disabledCheckboxAndRadio
                                    }}
                                />
                                Customer pays surcharge
                            </div>
                        }
                        classes={{"label": classes.label, root: classes.labelRoot}}
                    />
                </GridItem>
            </GridContainer>
        );
    }

    renderPage5(classes) {
        return (
            <GridContainer
                key="details_grid_5"
                style={{padding: '0 15px'}}
            >
                <GridItem md={12}>
                    Settlement Bank Account Information
                </GridItem>
                <GridItem md={12}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_funding_account_name'),
                            "name": "dc_funding_account_name",
                            "disabled": true
                        }}
                        labelText="Account Name"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_funding_bsb'),
                            "name": "dc_funding_bsb",
                            "disabled": true
                        }}
                        labelText="BSB"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_funding_account_number'),
                            "name": "dc_funding_account_number",
                            "disabled": true
                        }}
                        labelText="Account Number"
                    />
                </GridItem>
                <GridItem md={12}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_funding_bank_name'),
                            "name": "dc_funding_bank_name",
                            "disabled": true
                        }}
                        labelText="Bank Name"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_branch_address'),
                            "name": "dc_branch_address",
                            "disabled": true
                        }}
                        labelText="Bank Address"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_city'),
                            "name": "dc_city",
                            "disabled": true
                        }}
                        labelText="City"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_state'),
                            "name": "dc_state",
                            "disabled": true
                        }}
                        labelText="State"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('dc_post_code'),
                            "name": "dc_post_code",
                            "disabled": true
                        }}
                        labelText="Post Code"
                    />
                </GridItem>
                {this.renderFiles('bank_statements_key', 'Upload Most Recent Bank Statement', true)}
            </GridContainer>
        );
    }

    renderUbo(number, classes) {
        let verify_id = this.renderValue('bo' + number.toString() + '_verify_id_transaction_id');
        let verify = {
            'status': this.renderValue('bo' + number.toString() + '_verify_id_status'),
            'label': this.renderValue('bo' + number.toString() + '_verify_id_status_label'),
            'tooltip': this.renderValue('bo' + number.toString() + '_verify_id_status_tooltip'),
        };
        return <GridItem md={12}>   
            <GridContainer
                key={"ubo_grid_" + number.toString()}
            >
                <GridItem md={12}>
                    {"Beneficial Owner " + number.toString()}
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_first_name'),
                            "name": 'bo' + number.toString() + '_first_name',
                            "disabled": true
                        }}
                        labelText="First Name"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_last_name'),
                            "name": 'bo' + number.toString() + '_last_name',
                            "disabled": true
                        }}
                        labelText="Last Name"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_position'),
                            "name": 'bo' + number.toString() + '_position',
                            "disabled": true
                        }}
                        labelText="Position"
                    />
                </GridItem>
                <GridItem md={6}>
                    {/* Spacer */}
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_residential_address'),
                            "name": 'bo' + number.toString() + '_residential_address',
                            "disabled": true
                        }}
                        labelText="Address"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_suburb'),
                            "name": 'bo' + number.toString() + '_suburb',
                            "disabled": true
                        }}
                        labelText="City"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_state'),
                            "name": 'bo' + number.toString() + '_state',
                            "disabled": true
                        }}
                        labelText="State"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_post_code'),
                            "name": 'bo' + number.toString() + '_post_code',
                            "disabled": true
                        }}
                        labelText="Post Code"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_mobile_number'),
                            "name": 'bo' + number.toString() + '_mobile_number',
                            "disabled": true
                        }}
                        labelText="Contact Number"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('bo' + number.toString() + '_email'),
                            "name": 'bo' + number.toString() + '_email',
                            "disabled": true
                        }}
                        labelText="Email Address"
                    />
                </GridItem>
                {
                    this.props.updateApplication
                    ? <GridItem md={12}>
                        <div style={{width: "100%", paddingBottom: "1rem"}}>
                            {
                                verify_id !== null && verify_id.length > 0
                                ? <div style={{width: "100%"}}>
                                    <div style={{width: "100%"}}>
                                        Verify ID status: 
                                        <span style={{paddingLeft: "0.25rem", fontWeight: "bold"}} title={verify.tooltip.length > 0 ? verify.tooltip : verify.status.toUpperCase()}>
                                            {verify.label.length > 0 ? verify.label : (verify.status.length > 0 ? verify.status.toUpperCase() : 'PENDING')}
                                        </span>
                                    </div>
                                    <Button
                                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                        color={"success"}
                                        onClick={(event) => this.checkVerifyIdStatus(number)}
                                        >
                                            Check/Update ID Verification Status
                                    </Button>
                                </div>
                                : <Button
                                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                    color={"success"}
                                    onClick={(event) => this.sendVerifyId(number)}
                                    >
                                        Send Request for ID Verification
                                </Button>
                            }
                        </div>
                    </GridItem>
                    : ''
                }
            </GridContainer>
        </GridItem>
    }

    sendVerifyId(idx) {
        if (this.props.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Send request for ID Verification?",
                "action": "send_id_verification_request",
                "verify_ubo_idx": idx
            });
        }
    }

    doSendVerifyIdRequest() {
        this.setState({"confirmModal": false});
        const url = 'client/' + this.props.selected_client_id + '/application/' + this.state.selected_application_id + "/verify/ubo/" + this.state.verify_ubo_idx;

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                {}
            )
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "verify_ubo_idx": ""
                });
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.props.setUsersNotification(
                        response.data.error instanceof Array
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Unable to send Verify ID request, please try again."
                        )
                        : (
                            response.data.error instanceof String
                            ? response.data.error
                            : "Unable to send Verify ID request, please try again."
                        ),
                        "danger"
                    );
                } else {
                    this.props.setUsersNotification("Verify ID request sent.", "success");
                }
                this.fetchApplication(true);
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    checkVerifyIdStatus(idx) {
        if (this.props.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Check ID Verification status?",
                "action": "check_id_verification_status",
                "verify_ubo_idx": idx
            });
        }
    }

    doCheckVerifyIdStatus() {
        this.setState({"confirmModal": false});
        const url = 'client/' + this.props.selected_client_id + '/application/' + this.state.selected_application_id + "/verify/ubo/" + this.state.verify_ubo_idx + "/status";

        helpers.showLoading();
        return axios(this.props)
            .get(url)
            .then((response) => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "verify_ubo_idx": ""
                });
                if (response.hasOwnProperty('data') && response.data.hasOwnProperty('error')) {
                    this.props.setUsersNotification(
                        response.data.error instanceof Array
                        ? (
                            response.data.error.hasOwnProperty('message')
                            ? response.data.error.message
                            : "Unable to send Verify ID request, please try again."
                        )
                        : (
                            response.data.error instanceof String
                            ? response.data.error
                            : "Unable to send Verify ID request, please try again."
                        ),
                        "danger"
                    );
                }
                this.fetchApplication(true);
            })
            .catch((error) => {
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    renderPage6(classes) {
        let number_of_beneficials = parseInt(this.renderValue('number_of_beneficials'));
        if (isNaN(number_of_beneficials)) number_of_beneficials = 1;
        return (
            <GridContainer
                key="details_grid_6"
                style={{padding: '0 15px'}}
            >
                <GridItem md={12}>
                    Ultimate Beneficial Owners
                </GridItem>
                <Button
                    style={{margin: "15px"}}
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color={"success"}
                    onClick={(event) => alert('Not available yet')}
                    >
                        ASIC Search
                </Button>
                {this.renderUbo(1, classes)}
                {number_of_beneficials > 1 ? this.renderUbo(2, classes) : ''}
                {number_of_beneficials > 2 ? this.renderUbo(3, classes) : ''}
                {number_of_beneficials > 3 ? this.renderUbo(4, classes) : ''}
                {this.renderFiles('additional_supporting_doc_key', 'Upload Additional Information')}
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('how_did_you_hear_about_us'),
                            "name": "how_did_you_hear_about_us",
                            "disabled": true
                        }}
                        labelText="How did you hear about us?"
                    />
                </GridItem>
                <GridItem md={6}>
                    {/* Spacer */}
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('promo_code'),
                            "name": "promo_code",
                            "disabled": true
                        }}
                        labelText="Promo Code"
                    />
                </GridItem>
                <GridItem md={6}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('agent_code'),
                            "name": "agent_code",
                            "disabled": true
                        }}
                        labelText="Agent Code"
                    />
                </GridItem>
                <GridItem md={12}>
                    <CustomInput
                        formControlProps={{"fullWidth": true}}
                        inputProps={{
                            "value": this.renderValue('additional_comments_notes'),
                            "multiline": true,
                            "rows": 3,
                            "disabled": true
                        }}
                        labelText="Additional Comments"
                    />
                </GridItem>
            </GridContainer>
        );
    }

    renderPageRequested(classes, application_history) {
        let fields = [];
        application_history.forEach((application) => {
            application.details.request_field_values.forEach((field) => {
                if (!fields.includes(field['key'])) fields.push(field['key']);
            });
        });
        return (
            <GridContainer
                key="details_grid_requested"
                style={{padding: '0 15px'}}
            >
            {
                fields.map((field, key) => {
                    let field_config = null;
                    this.props.available_fields.forEach(f => {
                        if (f.registry_key === field) {
                            field_config = f;
                        }
                    });

                    let value = this.renderValue(field);
                    if (field_config === null) return '';
                    if (field_config.configuration.ui['ui:widget'] === 'CustomCheckbox') {
                        value = parseInt(value);
                        if (isNaN(value)) value = null;
                        return <GridItem key={key} md={12}>
                            <FormControlLabel
                                control={
                                    <div>
                                        <Checkbox
                                            disabled={true}
                                            checked={value === 1}
                                            value={value === 1}
                                            name={'cb_value_' + key}
                                            aria-label={'cb_value)' + key}
                                            icon={<Check className={classes.uncheckedIcon} />}
                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot,
                                                disabled: classes.disabledCheckboxAndRadio
                                            }}
                                        />
                                        {field_config.configuration.properties.title}
                                    </div>
                                }
                                classes={{"label": classes.label, root: classes.labelRoot}}
                            />
                        </GridItem>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'CustomRadio') {
                        return <GridContainer key={"details_grid_" + key}>
                            <p key={key} style={{paddingLeft: "15px"}}>{field_config.configuration.properties.title}</p>
                            {
                                field_config.configuration.properties.enum.map((item, key) => {
                                    let checked = value === item;
                                    let label = item;
                                    if (field_config.configuration.properties.hasOwnProperty('enumNames') && field_config.configuration.properties.enumNames.hasOwnProperty(key)) {
                                        label = field_config.configuration.properties.enumNames[key];
                                    }

                                    return <GridItem key={key} md={12}>
                                        <FormControlLabel
                                            control={
                                                <div>
                                                    <Checkbox
                                                        disabled={true}
                                                        checked={checked}
                                                        value={checked}
                                                        name={'rd_value_' + key}
                                                        aria-label={'rd_value_' + key}
                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                        classes={{
                                                            checked: classes.radio,
                                                            root: classes.radioRoot,
                                                            disabled: classes.disabledCheckboxAndRadio
                                                        }}
                                                    />
                                                    {label}
                                                </div>
                                            }
                                            classes={{"label": classes.label, root: classes.labelRoot}}
                                        />
                                    </GridItem>;
                                })
                            }
                        </GridContainer>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'LabelWidget') {
                        return <p key={key} style={{paddingLeft: "15px"}}>{field_config.configuration.properties.title}</p>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'textareaWidget') {
                        return <GridItem key={key} md={12}>
                            <CustomInput
                                formControlProps={{"fullWidth": true}}
                                inputProps={{
                                    "value": this.renderValue(field),
                                    "name": field,
                                    "multiline": true,
                                    "rows": 3,
                                    "disabled": true
                                }}
                                labelText={this.renderCustomFieldLabel(field)}
                            />
                        </GridItem>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'labelledFileField') {
                        let registry = this.getRegistry(field_config.registry_key);
                        let value = this.renderValue(field_config.registry_key);
                        return <GridItem key={key} md={12}>
                            {
                                registry === null || value.length === 0
                                ? <div style={{borderBottom: "1px dotted #D2D2D2", margin: "1rem 0"}}>
                                        <p key={'grid_' + key + '_1'} style={{color: ""}}>{field_config.name}</p>
                                        <p key={'grid_' + key + '_2'} style={{paddingLeft: "15px", color: "red"}}>No uploaded file(s)</p>
                                    </div>
                                : <div style={{borderBottom: "1px dotted #D2D2D2", margin: "1rem 0"}}>
                                    <p key={'grid_' + key + '_1'}>{field_config.name}</p>
                                    {
                                        value.split(',').map((id, k) => {
                                            let file = null;
                                            try {
                                                this.props.client_files.forEach(field => {
                                                    if (parseInt(field.id) === parseInt(id)) {
                                                        file = field;
                                                    }
                                                });
                                            } catch(e) {}
                                            return file === null
                                                ? ''
                                                : <p key={'grid_' + key + '_2_' + k} style={{paddingLeft: "15px"}}>
                                                    <i className={"fas fa-eye eye-watcher-notification"} onClick={() => {
                                                        return this.downloadDocument(id, file.filename);
                                                    }} />{file.filename}
                                                </p>;
                                        })
                                    }
                                </div>
                            }
                        </GridItem>;
                    } else if (field_config.configuration.ui['ui:widget'] === 'selectWidget') {
                        if (field_config.configuration.properties.hasOwnProperty('enum') && field_config.configuration.properties.hasOwnProperty('enumNames')) {
                            field_config.configuration.properties.enum.forEach((item, key) => {
                                if (value === item && field_config.configuration.properties.enumNames.hasOwnProperty(key)) {
                                    value = field_config.configuration.properties.enumNames[key];
                                }
                            });
                        }
                    }
                    
                    return <GridItem key={key} md={12}>
                            <CustomInput
                                formControlProps={{"fullWidth": true}}
                                inputProps={{
                                    "value": value,
                                    "name": field,
                                    "disabled": true
                                }}
                                labelText={this.renderCustomFieldLabel(field)}
                            />
                        </GridItem>;
                })
            }
            </GridContainer>
        );
    }

    setVerified(page, current_verified) {
        if (this.props.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Set Page as " + (current_verified ? 'Unverified' : 'Verified') + "?",
                "action": "set_verified",
                "verify_page": page,
                "current_verified": current_verified
            });
        }
    }

    checkIfVerified(page) {
        let details = this.props.selected_approval !== null && this.props.selected_approval.hasOwnProperty('details') ? this.props.selected_approval.details : {};
        details = details !== null && details.hasOwnProperty('verified') ? details.verified : {};
        return details.hasOwnProperty(page) ? (parseInt(details[page]) === 1 ? true : false) : false;
    }

    setVerifiedButton(page, classes) {
        let details = this.props.selected_approval !== null && this.props.selected_approval.hasOwnProperty('details') ? this.props.selected_approval.details : {};
        details = details !== null && details.hasOwnProperty('verified') ? details.verified : {};
        let page_verified = this.checkIfVerified(page);
        let page_verified_by = '';
        if (this.props.selected_approval !== null && this.props.selected_approval.hasOwnProperty('details')) {
            details = this.props.selected_approval !== null && this.props.selected_approval.hasOwnProperty('details') ? this.props.selected_approval.details : {};
            details = details !== null && details.hasOwnProperty('verified_by_user') ? details.verified_by_user : {};
            if (details.hasOwnProperty(page)) {
                let verified_by = details[page];
                verified_by.reverse().forEach(user => {
                    let verified_state = parseInt(user.state) === 1 ? true : false;
                    let reason = '';
                    try {
                        if (user.hasOwnProperty('reason') && user.reason.toString().length > 0) {
                            reason = "\r\n     - " + user.reason;
                        }
                    } catch(e) {}
                    page_verified_by += user.date + " - Marked as " + (verified_state ? "verified" : "not verified") + " by " + user.author + " (" + user.author_type + ")" + reason +"\r\n";
                });
            }
        }
        return <Button
            // style={{marginTop: "15px"}}
            style={{float: "left"}}
            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
            color={page_verified ? "success" : "danger"}
            disabled={!this.props.updateApplication}
            onClick={(event) => {
                if (page === 2) {
                    if (!page_verified && this.state.match_report_viewed === false) {
                        this.props.setUsersNotification("Please view the match report before verifying the page.", "danger");
                    } else {
                        this.setVerified(page, page_verified);
                    }
                } else {
                    this.setVerified(page, page_verified);
                }
            }}
            simple
            title={page_verified_by}
            >
            <span>{page_verified ? <i className="fas fa-check-circle"></i> : <i className="fas fa-times-circle"></i>} {page_verified ? "Verified" : "Not verified"}</span>
        </Button>;
    }

    doSetVerifyPage(page, current_verified) {
        this.setState({"confirmModal": false});
        const url = "/applications/" + this.state.selected_application_id + "/page/verification",
            postObject = {"page": page, "verified" : current_verified ? 0 : 1};

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "verify_page": "",
                    "current_verified": ""
                });

                this.props.refreshApprovalsTable();
            })
            .catch((error) => {
                console.log(error);
                helpers.hideLoading();
                axiosHelper.processError(
                    this.isUnmounted,
                    null,
                    error,
                    (state) => {this.setState(state);},
                    () => {}
                );
            });
    }

    generateMatchReport() {
        if (this.props.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Generate Match Report?",
                "action": "generate_match_report"
            });
        }
    }

    doGenerateMatchReport(page, current_verified) {
        this.setState({"confirmModal": false});
        const url = "/client/" + this.props.selected_client_id + '/script/' + this.props.selected_client_script_id + '/step/' + this.props.selected_client_script_step_id;
        let data = {'form_data': {'generate' : 'match_report'}};

        helpers.showLoading();
        return axios(this.props)
            .post(url, data)
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": ""
                });

                // this.props.refreshApprovalsTable();
                this.props.viewApplication(null, this.props.selected_approval, this.props.application_status, true, null, this.props.application_message_direction, this.props.application_message_sort);
            })
            .catch((error) => {
                helpers.hideLoading();
            });
    }

    showAddNote(event, page) {
        event.preventDefault();
        this.setState({
            "addNote": true,
            "new_note": "",
            "add_note_page": page
        });
    }

    renderNotes(page, classes, header = '', showAdd = true) {
        let notes = this.state.page_notes && this.state.page_notes.hasOwnProperty(page.toString()) ? this.state.page_notes[page] : null;
        if (notes === null) {} else {
            notes.sort(function(a,b) {
                return a['id'] - b['id'];
            });
        }

        return (
            <GridContainer
                key={"details_grid_notes_page_" + page}
                style={{padding: '0 15px 15px 15px'}}
            >
                <table style={{padding: "0 15px", width: "100%"}}>
                    <thead>
                        <tr key={"tr_head1"}>
                            <th colSpan={2} style={{"textAlign": "left", "paddingBottom": "10px"}}>
                                {header.length > 0 ? header : "Notes"}
                            </th>
                            <th style={{"textAlign": "right", "paddingBottom": "10px"}}>
                                &nbsp;
                                {
                                    this.props.updateApplication && showAdd
                                    ? <Button
                                        style={{padding: "5px"}}
                                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                        color={"success"}
                                        disabled={!this.props.updateApplication}
                                        onClick={(event) => this.showAddNote(event, page)}
                                        simple
                                        >
                                        <i style={{paddingRight: "3px"}} className="fa fa-plus fa-sm" /> Add Note
                                    </Button>
                                    : null
                                }
                            </th>
                        </tr>
                        <tr key={"tr_head"}>
                            <td style={{"textAlign": "left", "paddingBottom": "10px", width: "50%"}}>Message</td>
                            <td style={{"textAlign": "left", "paddingBottom": "10px", width: "25%"}}>Author</td>
                            <td style={{"textAlign": "left", "paddingBottom": "10px", width: "25%"}}>Date</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        notes !== null
                        ? notes.map((note, key) => {
                            return <tr key={"tr_" + key}>
                                <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{helpers.addLineBreaks(note.notes.replace(/[\n]/g, '<br>'))}</td>
                                <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{note.author + " (" + note.author_type + ")"}</td>
                                <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{moment.utc(note.date).format('DD-MM-YYYY HH:mm:ss')}</td>
                            </tr>
                        })
                        : <tr key={"tr_body"}>
                            <td colSpan={3} style={{"textAlign": "center"}}>No notes</td>
                        </tr>
                    }
                    </tbody>
                </table>
            </GridContainer>
        );
    }

    renderPageNotes(classes, pages) {
        return pages.map((page, key) => {
            return this.renderNotes(page, classes, (page === 'requested' ? 'Requested Page' : 'Page ' + page.toString()), false)
        });
    }

    renderPageMassages(classes) {
        let messages = this.state.approval_message && this.state.approval_message.hasOwnProperty('data') ? this.state.approval_message.data : [];

        return <GridContainer
            key={"details_grid_messages_page"}
            style={{padding: '0 15px 15px 15px'}}
        >
            <table style={{padding: "0 15px", width: "100%"}}>
                <thead>
                    <tr key={"tr_head1"}>
                        <th colSpan={2} style={{"textAlign": "left", "paddingBottom": "10px"}}>
                            Messages
                        </th>
                        <th style={{"textAlign": "right", "paddingBottom": "10px"}}>
                            &nbsp;
                            {
                                this.props.updateApplication
                                ? <Button
                                    style={{padding: "5px"}}
                                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                    color={"success"}
                                    disabled={!this.props.updateApplication}
                                    onClick={(event) => this.showMessageBox('New Message', null, true, true)}
                                    simple
                                    >
                                    <i style={{paddingRight: "3px"}} className="fa fa-plus fa-sm" /> New Message
                                </Button>
                                : null
                            }
                        </th>
                    </tr>
                    <tr key={"tr_head"}>
                        <th style={{"textAlign": "left", "paddingBottom": "10px", width: "50%"}}>
                            <span style={{ cursor: "pointer", paddingRight: '3px' }} onClick={()=>{
                                this.props.viewApplication(null, this.props.selected_approval, this.props.application_status, true, null, this.props.application_message_sort === 'subject' ? (this.props.application_message_direction === 'desc' ? 'asc' : 'desc') : 'desc', 'subject');
                            }}>Subject</span>
                            {
                                this.props.application_message_sort === 'subject'
                                ? (this.props.application_message_direction === 'desc' ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>)
                                : ""
                            }
                        </th>
                        <th style={{"textAlign": "left", "paddingBottom": "10px", width: "25%"}}>Sender</th>
                        <th style={{"textAlign": "left", "paddingBottom": "10px", width: "25%"}}>
                            <span style={{ cursor: "pointer", paddingRight: '3px' }} onClick={()=>{
                                this.props.viewApplication(null, this.props.selected_approval, this.props.application_status, true, null, this.props.application_message_sort === 'received' ? (this.props.application_message_direction === 'desc' ? 'asc' : 'desc') : 'desc', 'received');
                            }}>Date</span>
                            {
                                this.props.application_message_sort === 'received'
                                ? (this.props.application_message_direction === 'desc' ? <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>)
                                : ""
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
                    messages !== null && messages.length > 0
                    ? messages.map((message, key) => {
                        return <tr key={"tr_" + key}>
                            <td style={{"textAlign": "left", "paddingBottom": "10px"}}>
                                { message.attachments.length > 0 ? <i style={{paddingRight: "3px"}} className="fas fa-paperclip fa-sm" /> : '' }<span style={{ cursor: "pointer" }} onClick={()=>{this.showMessageBox('Message', message);}}>{message.subject}</span>
                            </td>
                            <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{message.sender_details.sender + (message.sender_details.sender_type.toString().length > 0 ? ' (' + message.sender_details.sender_type + ')' : '')}</td>
                            <td style={{"textAlign": "left", "paddingBottom": "10px"}}>{moment.utc(message.created_at).format('DD-MM-YYYY HH:mm:ss')}</td>
                        </tr>
                    })
                    : <tr key={"tr_body"}>
                        <td colSpan={3} style={{"textAlign": "center"}}>No messages</td>
                    </tr>
                }
                </tbody>
            </table>
        </GridContainer>
    }

    setupTabs(classes) {
        let application_history = this.props.application_history;
        application_history = application_history.filter((history) => history.details !== null && history.details.hasOwnProperty('request_field_values'));
        let has_requests = application_history.length > 0;
        let pages = [1, 2, 3, 4, 5, 6];
        if (has_requests) pages.push('requested');

        let tabs = [
            {
                "tabName": "Page 1",
                "tabStyle": this.checkIfVerified(1) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {},
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}}>
                    {this.renderPage1(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(1, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(1, classes)}
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            disabled={this.state.btnDisabled}
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 2",
                "tabStyle": this.checkIfVerified(2) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {},
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}}>
                    {this.renderPage2(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(2, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(2, classes)}
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 3",
                "tabStyle": this.checkIfVerified(3) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {},
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}}>
                    {this.renderPage3(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(3, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(3, classes)}
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 4",
                "tabStyle": this.checkIfVerified(4) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {},
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}}>
                    {this.renderPage4(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(4, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(4, classes)}
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 5",
                "tabStyle": this.checkIfVerified(5) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {},
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}}>
                    {this.renderPage5(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(5, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(5, classes)}
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            },
            {
                "tabName": "Page 6",
                "tabStyle": this.checkIfVerified(6) ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {},
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}}>
                    {this.renderPage6(classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    {this.renderNotes(6, classes)}
                    <hr style={{width: "100%", margin: "5px 15px"}}/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {this.setVerifiedButton(6, classes)}
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        {/* {
                            has_requests
                            ? */}
                            <Button
                                // style={{marginTop: "15px"}}
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="success"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                                simple
                                >
                                Next&#8250;
                            </Button>
                            {/* :""
                        } */}
                    </div>
                </GridContainer>
            },
        ];

        if (has_requests) {
            tabs.push(
                {
                    "tabName": "Requested",
                    "tabStyle": this.checkIfVerified('requested') ? { backgroundColor: "rgba(30, 219, 3, 0.9)" } : {},
                    "tabContent":
                    <GridContainer style={{"minHeight": "150px"}}>
                        {this.renderPageRequested(classes, application_history)}
                        <hr style={{width: "100%", margin: "5px 15px"}}/>
                        {this.renderNotes('requested', classes)}
                        <hr style={{width: "100%", margin: "5px 15px"}}/>
                        <div style={{width: '100%', textAlign: 'right'}}>
                            {this.setVerifiedButton('requested', classes)}
                            <Button
                                // style={{marginTop: "15px"}}
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="info"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                                simple
                            >
                                &#8249;Back
                            </Button>
                            <Button
                                // style={{marginTop: "15px"}}
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="success"
                                onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                                simple
                                >
                                Next&#8250;
                            </Button>
                        </div>
                    </GridContainer>
                }
            );
        }

        tabs.push(
            {
                "tabName": "Notes",
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}}>
                    {this.renderPageNotes(classes, pages)}
                    <div style={{width: '100%', textAlign: 'right'}}>
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="success"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab+1)}
                            simple
                            >
                            Next&#8250;
                        </Button>
                    </div>
                </GridContainer>
            }
        );

        tabs.push(
            {
                "tabName": "Messages",
                "tabContent":
                <GridContainer style={{"minHeight": "150px"}}>
                    {this.renderPageMassages(classes, pages)}
                    <div style={{width: '100%', textAlign: 'right'}}>
                        <Button
                            // style={{marginTop: "15px"}}
                            className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                            color="info"
                            onClick={(event) => this.setCurrentTab(this.state.currentTab-1)}
                            simple
                        >
                            &#8249;Back
                        </Button>
                        {/* {
                            this.props.updateApplication
                            ? <Button
                                // style={{marginTop: "15px"}}
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color="facebook"
                                disabled={this.state.btnDisabled}
                                onClick={(event) => {this.changeStatus(event);}}
                                simple
                                >
                                Change Status
                            </Button>
                            : ''
                        } */}
                    </div>
                </GridContainer>
            }
        );

        return tabs;
    }

    showMessageBox(title, contents = null, is_new_message = false, show_new_message = false) {
        let new_message_subject = '';
        if (this.state.show_new_message !== show_new_message && contents !== null) {
            new_message_subject = is_new_message ? "" : "Re: " + contents.subject;
        }

        this.setState({
            "show_message_dialog": true,
            "message_dialog_title": title,
            "message_dialog_contents": contents,
            "is_new_message": is_new_message,
            "show_new_message": show_new_message,
            "new_message_subject": new_message_subject,
            "new_message_body": '',
            "new_message_attachments": null
        });
    }

    renderMessageBody(body) {
        if (body !== null) {
            let parts = body.split('\\n');
            if (parts.length < 1) {
                parts = body.split('\n');
            }
            return parts.map((part, key) => {
                return <span key={key}>{part.replaceAll('&nbsp;', '\u00A0')}<br/></span>;
            });
        }
        return '';
    }

    renderMessageAttachments(attachments) {
        return attachments.map((attachment, key) => {
            let file = null;
            try {
                this.props.client_files.forEach(field => {
                    if (parseInt(field.id) === parseInt(attachment.file_id)) {
                        file = field;
                    }
                });
            } catch(e) {}
            return file === null
                ? ''
                : <p key={'grid_message_attachment_' + key}>
                    <i className={"fas fa-eye eye-watcher-notification"} onClick={() => {
                        return this.downloadDocument(attachment.file_id, file.filename);
                    }} />{file.filename}
                </p>;
        });
    }

    deleteMessage() {
        if (this.props.updateApplication) {
            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Delete Message",
                "action": "delete_message"
            });
        }
    }

    doDeleteMessage() {
        this.setState({"confirmModal": false});
        const url = 'applications/' + this.state.selected_application_id + '/message/' + this.state.message_dialog_contents.id + '/delete';

        helpers.showLoading();
        return axios(this.props)
            .post(url, {})
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "show_message_dialog": false
                });
                this.props.viewApplication(null, this.props.selected_approval, this.props.application_status, true, null, this.props.application_message_direction, this.props.application_message_sort);
                this.props.setUsersNotification("Message deleted.", "success");
            })
            .catch((error) => {
                this.processErrorAxios(error);
                helpers.hideLoading();
            });
    }

    showReplyForm() {
        this.setState({
            "is_new_message": false,
            "show_new_message": true
        });
    }

    sendMessage() {
        if (this.props.updateApplication) {
            if (this.state.new_message_subject.length === 0) {
                this.props.setUsersNotification("Message subject is required.", "danger");
                return;
            }
            if (this.state.new_message_body.length === 0) {
                this.props.setUsersNotification("Message body is required.", "danger");
                return;
            }

            this.setState({
                "confirmModal": true,
                "confirmModalMsg": "Are you sure?",
                "confirmModalTitle": "Send Message",
                "action": "send_message"
            });
        }
    }

    doSendMessage() {
        this.setState({"confirmModal": false});
        const url = 'applications/' + this.state.selected_application_id + '/message';
        helpers.showLoading();

        let fd = new FormData();
        fd.append('subject', this.state.new_message_subject);
        fd.append('body', this.state.new_message_body);
        fd.append('reply_to', this.state.is_new_message ? '' : (this.state.message_dialog_contents ? this.state.message_dialog_contents.id : ''));
        if (this.state.new_message_attachments !== null && this.state.new_message_attachments.length > 0) {
            for (let i=0; i<this.state.new_message_attachments.length; i++) {
                const file = this.state.new_message_attachments[i];
                if (file.size > 4194304 || file.fileSize > 4194304) {
                } else {
                    fd.append('files[]', file);
                }
            };
        }

        return axios(this.props)
            .post(url, fd, {headers: {
                'Content-Type': 'multipart/form-data'
              }})
            .then(() => {
                this.setState({
                    "confirmModal": false,
                    "action": "",
                    "show_message_dialog": false
                });
                this.props.viewApplication(null, this.props.selected_approval, this.props.application_status, true, null, this.props.application_message_direction, this.props.application_message_sort);
                this.props.setUsersNotification("Message sent.", "success");
            })
            .catch((error) => {
                this.processErrorAxios(error);
                helpers.hideLoading();
            });
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            (msg, color) => {
                this.props.setUsersNotification(
                    typeof msg === 'string' ? msg : msg.join('<br>'),
                    color
                );
            }
        );
    }

  render () {
      const {classes} = this.props;
      return (
          <Dialog
              aria-describedby="update-profile-confirm-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{
                  "root": `${classes.center} ${classes.modalRoot}`,
                  "paper": `${classes.modal} ${classes.modalSmall}`
              }}
              fullWidth
              keepMounted
              onClose={() => {this.setState({"additional_fields_values": [], "message": ""}); clearTimeout(this.timer); this.timer = null; this.props.onClose("applicationView");}}
              open={this.props.applicationView}
              id="view-application-dialog"
          >
            {/* <style>
            {
                ".MuiDialog-paperWidthSm {max-width: 800px !important;}"
            }
            </style> */}
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  Application Details {this.state.current_status !== null ? <span style={{"color": this.props.colors[this.state.current_status] }}>({helpers.fixStatusLabel(this.state.current_status)})</span> : ''}
                  <br/>
                  {this.state.application_details}
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="update-profile-confirm-modal-slide-description"
                  style={{overflow: "hidden"}}
              >

                <CustomTabs
                    marginBottom={"5px"}
                    changeTabCallBack={(tab) => this.setCurrentTab(tab)}
                    headerColor="primary"
                    tabs={this.setupTabs(classes)}
                    value={this.state.currentTab}
                    bodyStyle={{"overflow": "hidden auto", "height": "67vh", "paddingBottom": "4rem"}}
                />
                {
                    this.state.previewDocumentModal
                    ? <PreviewDocument
                        value={this.state.previewDocumentValue}
                        name={this.state.previewDocumentName}
                        client_id={this.props.selected_client_id}
                        onClose={() => {
                            this.setState({
                                "previewDocumentModal": false,
                                "previewDocumentValue": null,
                                "previewDocumentName": null
                            });
                        }}
                        auth={this.props.auth}
                        previewDocumentModal={this.state.previewDocumentModal}
                    />
                    : ''
                }
                <ChangeApplicationStatusDialog
                    checkIfVerified={this.checkIfVerified}
                    onClose={() => this.handleStatusChangeClose()}
                    handleStatusChange={this.handleStatusChange}
                    {...this.state}
                    available_fields={this.props.available_fields}
                    canApprove={this.props.canApprove}
                    mcc={this.getRegistryValue('mcc')}
                    high_risk_mccs={this.props.high_risk_mccs}
                />
                <AddApplicationPageNoteDialog
                    onClose={() => this.handlePageNoteClose()}
                    handleAddNote={this.handleAddNote}
                    {...this.state}
                />
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />

                <Dialog
                    aria-describedby="message-modal-slide-description"
                    aria-labelledby="small-modal-slide-title"
                    classes={{
                        "root": `${classes.center} ${classes.modalRoot}`,
                        "paper": `${classes.modal} ${classes.modalSmall}`
                    }}
                    fullWidth
                    keepMounted
                    onClose={() => {this.setState({"show_message_dialog": false});}}
                    open={this.state.show_message_dialog}
                    id="message-dialog"
                >
                    <DialogTitle
                        className={`${classes.modalBody} ${classes.modalSmallBody}`}
                        style={{"paddingBottom": "0px"}}
                    >
                        {this.state.message_dialog_title}
                    </DialogTitle>
                    <DialogContent
                        className={`${classes.modalBody} ${classes.modalSmallBody}`}
                        id="message-modal-slide-description"
                        style={{overflow: "hidden"}}
                    >

                        <GridContainer style={{display: this.state.is_new_message ? "none" : ""}}>
                            <GridItem md={12}>
                                <b>Subject:</b> {this.state.message_dialog_contents !== null ? this.state.message_dialog_contents.subject : ''}
                            </GridItem>
                            <GridItem md={12}>
                                <div style={{minHeight: "150px", margin: "10px 15px"}}>
                                    {this.state.message_dialog_contents !== null ? this.renderMessageBody(this.state.message_dialog_contents.body) : ''}
                                </div>
                            </GridItem>
                            {
                                this.state.message_dialog_contents !== null && this.state.message_dialog_contents.attachments !== null && this.state.message_dialog_contents.attachments.length > 0
                                ? <GridItem md={12}>
                                    <p><b>Attachments:</b></p>
                                    {this.renderMessageAttachments(this.state.message_dialog_contents.attachments)}
                                </GridItem>
                                : ''
                            }
                        </GridContainer>
                        
                        <GridContainer style={{display: this.state.show_new_message ? "" : "none"}}>
                            <GridItem md={12}>
                                <b>Compose Message</b>
                            </GridItem>
                            <GridItem md={12}>
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "onChange": (event) => {
                                            this.setState({"new_message_subject": event.target.value});
                                        },
                                        "value": this.state.new_message_subject,
                                        "name": "new_message_subject",
                                    }}
                                    labelText="Subject"
                                />
                            </GridItem>
                            <GridItem md={12}>
                                <CustomInput
                                    formControlProps={{"fullWidth": true}}
                                    inputProps={{
                                        "onChange": (event) => {
                                            this.setState({"new_message_body": event.target.value});
                                        },
                                        "value": this.state.new_message_body,
                                        "name": "new_message_body",
                                        "multiline": true,
                                        "rows": 3
                                    }}
                                    labelText="Body"
                                />
                            </GridItem>
                            <GridItem md={12}>
                                <div
                                    className="upload-btn-wrapper"
                                    style={{"width": "100%"}}
                                >
                                    <button
                                        className="btn"
                                        style={{"marginRight": "10px"}}
                                    >
                                        <i className="fas fa-upload"></i> Choose file(s) to upload
                                    </button>
                                    <input
                                        accept=".pdf, .txt, .doc, .docx, .xls, .xlsx, .gif, .png, .bmp, .jpg, .jpeg"
                                        name="new_message_attachments"
                                        id="new_message_attachments"
                                        onChange={(event) => {
                                            let attachments = [];
                                            let errored_files = [];
                                            try {
                                                event.target.files.forEach(file => {
                                                    if (Math.round(file.size / 1024) >= 4096) {
                                                        errored_files.push(file);
                                                    } else {
                                                        attachments.push(file);
                                                    }
                                                });
                                            } catch (e) {}

                                            if (errored_files.length > 0) {
                                                this.props.setUsersNotification(
                                                    "One or more selected files exceeds the limit of 4MB per file and will not be uploaded",
                                                    "danger"
                                                );
                                            }

                                            this.setState({"new_message_attachments" : attachments});
                                        }}
                                        type="file"
                                        value=""
                                        multiple
                                    />
                                </div>
                                <div style={{display: this.state.new_message_attachments !== null && this.state.new_message_attachments.length > 0 ? "" : "none"}}>
                                    <p
                                        htmlFor="document"
                                        style={{"color": "#495057"}}
                                    >
                                        {this.state.new_message_attachments !== null && this.state.new_message_attachments.length > 0
                                            ? helpers.addLineBreaks(this.state.new_message_attachments.map((file, k) => {
                                                return file.name.toString();
                                            }).join("<br>"))
                                            : ""}
                                        <span style={{cursor: "pointer"}} onClick={() => {this.setState({new_message_attachments: null})}}>[Clear Attachment(s)]</span>
                                    </p>
                                </div>
                            </GridItem>
                            <GridItem md={12}>
                                <Button
                                    className={classes.modalSmallFooterFirstButton}
                                    color="transparent"
                                    onClick={() => {this.state.is_new_message ? this.setState({"show_message_dialog": false}) : this.setState({"show_new_message": false});}}
                                    style={{float: "right"}}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.modalSmallFooterFirstButton}
                                    color="success"
                                    onClick={() => this.sendMessage()}
                                    style={{float: "right"}}
                                    simple
                                >
                                    Send
                                </Button>
                            </GridItem>
                        </GridContainer>

                    </DialogContent>
                    <DialogActions
                        className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                        style={{display: this.state.is_new_message ? "none" : ""}}
                    >
                        {
                            this.props.updateApplication && !this.state.is_new_message
                            ? <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color={"danger"}
                                simple
                                onClick={(event) => this.deleteMessage()}
                                >
                                    Delete
                            </Button>
                            : ""
                        }
                        {
                            this.props.updateApplication && !this.state.is_new_message && !this.state.show_new_message
                            ? <Button
                                className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                                color={"success"}
                                simple
                                onClick={(event) => this.showReplyForm()}
                                >
                                    Reply
                            </Button>
                            : ""
                        }
                        <Button
                            className={classes.modalSmallFooterFirstButton}
                            color="transparent"
                            onClick={() => {this.setState({"show_message_dialog": false});}}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                {/* {
                    this.props.updateApplication
                    ? <Button
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color="facebook"
                        disabled={this.state.btnDisabled}
                        onClick={(event) => {this.changeStatus(event);}}
                        simple
                        >
                        Change Status
                    </Button>
                    : ''
                } */}
                {
                    this.state.pages_count === (this.state.currentTab+1) && this.props.updateApplication
                    ? <Button
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color="facebook"
                        disabled={this.state.btnDisabled}
                        onClick={(event) => {this.changeStatus(event);}}
                        simple
                        >
                        Change Status
                    </Button>
                    : ''
                }
                <Button
                    className={classes.modalSmallFooterFirstButton}
                    color="transparent"
                    onClick={() => {this.setState({"additional_fields_values": [], "message": ""}); clearTimeout(this.timer); this.timer = null; this.props.onClose("applicationView");}}
                >
                    Close
                </Button>
              </DialogActions>
          </Dialog>
      );
  }
}

export default withStyles(extendedFormsStyle)(ViewApplicationStatusDialog);
