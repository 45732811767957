import moment from "moment";

const username_rule = /^\w+$/,
    password_rule = /^.*(?=.{3,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\d])(?=.*[!@#$%&=?_.,:;*\\-]).*$/,
    email_rule = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    number_rule = /^[0-9]+$/,
    url_rule = /^(http|https):\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)+$/,
    url_rule_wo_http = /^(?:(ftp|http|https):\/\/)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)+$/,
    domain_rule = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
    business_name_rule = /^[a-zA-Z][A-Za-z0-9!"#$%&'()*+,./:;<=>?@[\] ^_`{|}~-]{4,254}$/,

    validations = {
        "validateUsername" (str) {
            return (
                str && str.length >= 6 && str.length <= 20 && str.match(username_rule)
            );
        },
        "validatePassword" (str) {
            return (
                str && str.length >= 8 && str.length <= 255 && str.match(password_rule)
            );
        },
        "validateEmail" (str) {
            return str && str.length <= 255 && str.match(email_rule);
        },
        "validateIfNumber" (str) {
            return str && `${str}`.match(number_rule);
        },
        "validateURL" (str) {
            str = str.toLowerCase();
            return str && str.match(url_rule);
        },
        "validateURLNoHttp" (str) {
            str = str.toLowerCase();
            return str && str.match(url_rule_wo_http);
        },
        "validateDomain" (str) {
            return str && str.match(domain_rule);
        },
        "validateBusinessName" (str) {
            return str && str.match(business_name_rule) && str.replace(/[^a-z]+/gi, "").length >= 5;
        },
        "validateABN" (num) {
            /**
             * @param num {String|Number} value abn to validate
             * @return {Boolean} Is ABN Valid
             *
             * Subtract 1 from the first (left-most) digit in 51824753556 giving 41824753556
             * Multiply digits by "weighting factor" : (4 * 10) + (1 * 1) + (8 * 3) + (2 * 5) + (4 * 7) + (7 * 9) + (5 * 11) + (3 * 13) + (5 * 15) + (5 * 17) + (6 * 19)
             * Sum the resulting 11 products giving 534
             *Divide 534 by 89 giving 6, remainder 0
             * As the remainder is zero, 51824753556 is valid according to the ABN algorithm
             */
            const weights = [
                10,
                1,
                3,
                5,
                7,
                9,
                11,
                13,
                15,
                17,
                19
            ]; // ABN Weights
            // Convert to string and remove all white space
            num = num.toString().replace(
                /\s/g,
                ""
            );
            // Split it to number array
            num = num.split("").map((n) => parseInt(n));
            // Subtract 1 from the first (left-most) digit of the ABN to give a new 11 digit number
            num[0] -= 1;
            // Multiply each of the digits in this new number by a "weighting factor" based on its position
            num = num.map((n, i) => n * weights[i]);
            // Sum the resulting 11 products
            const total = num.reduce(
                (acc, n) => acc + n,
                0
            );
            // Divide the sum total by 89, noting the remainder
            if (total % 89 === 0) {
                return true;
            }
            return false;
        },
        "validateACN" (num) {
            /**
             * Via
             * https://asic.gov.au/for-business/registering-a-company/steps-to-register-a-company/australian-company-numbers/australian-company-number-digit-check/
             * @param num {String|Number} value abn to validate
             * @return {Boolean} Is ACN Valid
             */
            const weights = [
                8,
                7,
                6,
                5,
                4,
                3,
                2,
                1
            ];
            // Convert to string and remove all white space
            num = num.toString().replace(
                /\s/g,
                ""
            );
            // Split it to number array
            num = num.split("").map((n) => parseInt(n));
            // Set the check digit and remove it
            const checkDigit = num.pop();
            // Apply weighting to digits 1 to 8.
            num = num.map((n, i) => n * weights[i]);
            // Sum the products
            const total = num.reduce(
                    (acc, n) => acc + n,
                    0
                ),
                // Divide by 10 to obtain remainder
                calculatedCheckDigit = (10 - total % 10) % 10;
            // CalculatedCheckDigit should match check digit
            if (calculatedCheckDigit === checkDigit) {
                return true;
            }
            return false;
        },
        "usernameErrorMsg" () {
            return "Must be 6 to 20 characters long and should be alphanumeric only";
        },
        "passwordErrorMsg" () {
            return "Must be at least 8 alphanumeric characters with uppercase letter(s) and special character(s)";
        },
        "emailErrorMsg" () {
            return "Must be a valid email address";
        },
        "businessNameErrorMsg" () {
            return "Must start with a letter, contains at least 5 letters with uppercase letter(s) and special character(s)";
        },
        "matchRule" (str, rule) {
            var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
            return new RegExp("^" + rule.split("*").map(escapeRegex).join(".*") + "$").test(str);
        },
        "maxFileSizeValid" (bytes, maxSize) {
            let allValid = true;
            (Array.isArray(bytes) ? bytes : [bytes]).forEach(data => {
                try {
                    let start = data.indexOf(';base64,') + 1;
                    if (start > 0) start = start + 7;
                    if (!(maxSize > ((data.length - start) * (3/4) - (data.endsWith('==') ? 2 : 1)))) {allValid = false};
                } catch(e) {}
            });
            return allValid;
        },
        "fileTypeValid" (bytes, accept) {
            if (!(accept && accept.length > 0)) return true;

            let allValid = true;
            (Array.isArray(bytes) ? bytes : [bytes]).forEach(data => {
                try {
                    if (!accept.includes('.' + bytes.split(';')[1].split('.').pop().toLowerCase())) {allValid = false};
                } catch(e) {}
            });
            return allValid;
        },
        "validateNumberMax" (value, max) {
            return parseFloat(value.toString().replace(/^(-)|[^0-9.]+/g, '$1')) <= parseFloat(max.toString().replace(/^(-)|[^0-9.]+/g, '$1'));
        },
        "validateDate" (value) {
            try {
                return moment(value).isValid();
            } catch(e) {}
            return true;
        },
        "validateDateNotFuture" (value) {
            try {
                return moment().isSameOrAfter(moment(value));
            } catch(e) {}
            return true;
        },
        "validateMMYYYYDate" (value) {
            try {
                if (value.length < 7) return false; 
                return moment(value, 'MM/YYYY').isValid();
            } catch(e) {}
            return true;
        },
        "isExpired" (value, format, useEndOfMonth = false) {
            try {
                let date = moment(value, format);
                if (useEndOfMonth) {
                    date = date.endOf('month');
                }
                date = date.endOf('day');
                return moment().endOf('day').isAfter(date);
            } catch(e) {}
            return false;
        },
        "validateGroupTotal" (formData, fields, max) {
            return this.getGroupTotal(formData, fields) <= max;
        },
        "getGroupTotal" (formData, fields) {
            let total = 0;
            for(let key in fields) {
                if (formData.hasOwnProperty(fields[key]) && formData[fields[key]]) {
                    total = total + parseFloat(formData[fields[key]].toString().replace(/^(-)|[^0-9.]+/g, '$1'));
                }
            }
            return total;
        },
        "computeEstimatedAnnualTurnover" (formData, fields) {
            let val = 1;
            let all_exists = true;
            for(let key in fields) {
                if (formData.hasOwnProperty(fields[key]) && formData[fields[key]]) {
                    val = val * parseFloat(formData[fields[key]].toString().replace(/^(-)|[^0-9.]+/g, '$1'));
                } else {
                    all_exists = false;
                }
            }
            return all_exists ? val.toString() : null;
        },
    };

export default validations;
