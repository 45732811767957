import LoginPage from "views/Pages/Clients/LoginPage.jsx";
import DocumentLibrary from "views/Pages/Clients/DocumentLibrary.jsx";
import InformationRegistry from "views/Pages/Clients/InformationRegistry.jsx";

import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Details from "@material-ui/icons/Details";

import SendResetEmail from "views/Pages/Clients/SendResetEmail.jsx";
import ResetPassword from "views/Pages/Clients/ResetPassword.jsx";

const dashRoutes = [
    {
        "path": "/login",
        "name": "Login Page",
        "component": LoginPage,
        "layout": "/client/auth",
        "invisible": true
    },
    {
        "path": "/reset/:token",
        "name": "Password Reset",
        "component": ResetPassword,
        "layout": "/client/auth",
        "invisible": true
    },
    {
        "path": "/reset",
        "name": "Password Reset",
        "component": SendResetEmail,
        "layout": "/client/auth",
        "invisible": true
    },
    {
        "path": "/document_library",
        "name": "Document Library",
        "icon": LibraryBooks,
        "component": DocumentLibrary,
        "layout": "/client"
    },
    {
        "path": "/information_registry",
        "name": "Information Registry",
        "icon": Details,
        "component": InformationRegistry,
        "layout": "/client"
    }
];
export default dashRoutes;
