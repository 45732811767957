export const http_post = (
    http_client,
    path,
    params,
    action_success,
    action_fail,
    callback,
    add_header
) => {
    http_client
        .post(
            path,
            params,
            add_header ? add_header : {}
        )
        .then((response) => {
            callback({"type": action_success,
                "data": response.data});
        })
        .catch((error) => {
            callback({
                "type": action_fail,
                "data": error.response && error.response.data ? error.response.data : null
            });
        });
};
