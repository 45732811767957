import React from "react";
import PropTypes from "prop-types";
// import {makeStyles} from "@material-ui/core/styles";
// import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
// import PersonIcon from "@material-ui/icons/Person";
// import AddIcon from "@material-ui/icons/Add";
// import {blue} from "@material-ui/core/colors";
import CardIcon from "../../components/Card/CardIcon";
import Add from "@material-ui/icons/Add";
// import axios from "../../axios/axios";

// const useStyles = makeStyles({
//     "avatar": {
//         "backgroundColor": blue[100],
//         "color": blue[600]
//     }
// });

function SimpleDialog (props) {

    const /*classes = useStyles(),*/
        {onClose, /*selectedValue,*/ open, options} = props,

        handleClose = () => {

            onClose(null);

        },

        handleListItemClick = (value) => {

            onClose(value);

        };

    return (
        <Dialog
            aria-labelledby="simple-dialog-title"
            onClose={handleClose}
            open={open}
        >
            <DialogTitle id="simple-dialog-title">
                Add a checklist
            </DialogTitle>
            <List>
                {options.map((option) => (<ListItem
                        button
            key={option.id}
            onClick={() => handleListItemClick(option.id)}
                    >
                        <ListItemText primary={option.name} />
                    </ListItem>))}
            </List>
        </Dialog>
    );

}

SimpleDialog.propTypes = {
    "onClose": PropTypes.func.isRequired,
    "open": PropTypes.bool.isRequired,
    // "selectedValue": PropTypes.string.isRequired
};

export default function SimpleDialogDemo (props) {

    const [
            open,
            setOpen
        ] = React.useState(false),
        [
            selectedValue,
            setSelectedValue
        ] = React.useState(null),
        {options, client_id, handleAddForm} = props,

        handleClickOpen = () => {

            setOpen(true);

        },

        handleClose = (value) => {

            setOpen(false);
            if (value !== null) {
                setSelectedValue(value);
                handleAddForm(
                    client_id,
                    value
                );
            }

        };

    return (
        <>
            <CardIcon
                title={'Add a checklist'}
                className="float-button"
                color="info"
                onClick={handleClickOpen}
            >
                <Add />
            </CardIcon>
            <SimpleDialog
                onClose={handleClose}
                open={open}
                options={options}
                selectedValue={selectedValue}
            />
        </>
    );

}
