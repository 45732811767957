import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepButton from "@material-ui/core/StepButton";
import StepComponent from "./ProcessComponent";
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
    "root": {
        "width": "100%"
    },
    "button": {
        "marginTop": theme.spacing(1),
        "marginRight": theme.spacing(1)
    },
    "actionsContainer": {
        "marginBottom": theme.spacing(2)
    },
    "resetContainer": {
        "padding": theme.spacing(3)
    }
}));

function ProcessStepper (props) {
    const {checklist, registry_entries, watched, ignored_watch} = props,
        classes = useStyles(),
        steps = checklist.steps ? checklist.steps.map((element) => element.step) : []
        ;

    function displayQuestion(step, type) {
        if (
            step.action_type === 'checklist' ||
            step.action_type === 'generate_application' ||
            step.action_type === 'generate_match_report' ||
            step.action_type === 'export_webdosh_import' ||
            step.action_type === 'generate_invoice'
        ) {
        } else {
            if (step.hasOwnProperty('action_config') && step.action_config !== null) {
                if (step.action_config.hasOwnProperty('question')) {
                    return step.action_config.question;
                } else {
                    let questions = '', addNL = '';
                    if (Object.keys(step.action_config).length > 0) {
                        step.action_config.forEach((element, key) => {
                            questions = questions + addNL + element.question;
                            addNL = '<br><br>';
                        });
                    }
                    return questions;
                }
            }
        }
        return '';
    }

    function displayAnswer(step, type) {
        if (
            step.action_type === 'checklist' ||
            step.action_type === 'generate_application' ||
            step.action_type === 'generate_match_report' ||
            step.action_type === 'export_webdosh_import' ||
            step.action_type === 'generate_invoice'
        ) {
        } else {
            if (step.hasOwnProperty('action_config') && step.action_config !== null) {
                if (step.action_config.hasOwnProperty('question')) {
                    if (step.action_config.hasOwnProperty('answers') && Object.keys(step.action_config.answers).length > 0) {
                        if (type === 'checkbox') {
                            return step.action_config.answers[1];
                        }
                    }
                    // if (step.hasOwnProperty('data') && Object.keys(step.data).length > 0) {
                    //     if (step.action_config.hasOwnProperty('answers') && Object.keys(step.action_config.answers).length > 0) {
                    //         answer = step.action_config.answers[step.data[0]];
                    //     } else {
                    //         answer = step.data[0];
                    //     }
                    // }
                    return '';
                } else {
                    let questions = '', addNL = '';
                    if (Object.keys(step.action_config).length > 0) {
                        step.action_config.forEach((element, key) => {
                            // let answer = '';
                            // if (step.hasOwnProperty('data') && Object.keys(step.data).length > 0) {
                            //     if (element.hasOwnProperty('answers') && Object.keys(element.answers).length > 0) {
                            //         answer = element.answers.hasOwnProperty(step.data[key]) ? element.answers[step.data[key]] : '';
                            //     } else {
                            //         answer = step.data[key];
                            //     }
                            // }
                            questions = questions + addNL + element.question;// + (answer.length > 0 ? '<br><b>Answer</b>: ' + answer : '');
                            addNL = '<br><br>';
                        });
                    }
                    return questions;
                }
            }
        }
        return '';
    }

    function stepAnswered(step, type) {
        if (
            step.action_type === 'checklist' ||
            step.action_type === 'generate_application' ||
            step.action_type === 'generate_match_report' ||
            step.action_type === 'export_webdosh_import' ||
            step.action_type === 'generate_invoice'
        ) {
            return false;
        } else {
            if (step.hasOwnProperty('action_config') && step.action_config !== null) {
                if (step.action_config.hasOwnProperty('question')) {
                    let answer = '';
                    if (step.hasOwnProperty('data') && Object.keys(step.data).length > 0) {
                        if (step.action_config.hasOwnProperty('answers') && Object.keys(step.action_config.answers).length > 0) {
                            answer = step.action_config.answers[step.data[0]];
                        } else {
                            answer = step.data[0];
                        }
                    }
                    return answer !== undefined && answer !== null && answer.length > 0;
                } else {
                    let answers = 0;
                    if (Object.keys(step.action_config).length > 0) {
                        try {
                            step.action_config.forEach((element, key) => {
                                let answer = '';
                                if (step.hasOwnProperty('data') && Object.keys(step.data).length > 0) {
                                    if (element.hasOwnProperty('answers') && Object.keys(element.answers).length > 0) {
                                        answer = element.answers.hasOwnProperty(step.data[key]) ? element.answers[step.data[key]] : '';
                                    } else {
                                        answer = step.data[key];
                                    }
                                }
                                answers += answer !== undefined && answer !== null && answer.length > 0 ? 1 : 0;
                            });
                        } catch(e) {}
                    }
                    return answers > 0;
                }
            }
        }
        return false;
    }

    function setWatched(step) {
        try {
            if (Object.keys(
                watched.filter((watch) => watch.event === 'step' && watch.element.toLowerCase() === step.step.toLowerCase() && watch.status === 1)
            ).length === 1) return true;
        } catch(e) {}
        return false;
    }

    let prev_status = 1;
    return (
        <div className={classes.root}>
            <Stepper
                nonLinear
                orientation="vertical"
            >
                {steps.map((label, index) => {
                    let step = checklist.steps[index];
                    let isWatched = setWatched(step);
                    let type = step.hasOwnProperty('action_config') && step.action_config !== null && step.action_config.hasOwnProperty('type')
                        ? step.action_config.type : '';
                    let stepper = <Step
                        completed={step.status === 1 && prev_status === 1}
                        active={stepAnswered(step)}
                        expanded
                        key={label}
                    >
                        <StepButton>
                            {label}
                            {
                                ignored_watch
                                    ? ""
                                    : <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher"}
                                        onClick={(e) => {e.preventDefault(); props.handleUpdateWatch('step', step.step, !isWatched);}} />
                            }
                        </StepButton>
                        <StepContent>
                            <StepComponent
                                {...props}
                                displayQuestion={displayQuestion}
                                displayAnswer={displayAnswer}
                                stepAnswered={stepAnswered}
                                checklist={checklist}
                                watched={watched}
                                isWatched={isWatched}
                                ignored_watch={ignored_watch}
                                registry_entries={registry_entries}
                                step={step}
                                type={type}
                                index={index}
                                stepTitle={step.step}
                            />
                        </StepContent>
                    </Step>;
                    prev_status = step.status === 1 && prev_status ? 1 : 0;
                    return stepper;
                })}
            </Stepper>
        </div>
    );
}

const Process = withStyles(extendedFormsStyle)(ProcessStepper);

export default Process;
