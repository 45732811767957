import React from "react";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import ChecklistFormDialog from './ChecklistFormDialog';
import helpers from "customs/helpers/helpers";
import validations from "customs/helpers/validations";
import {findVal} from "customs/forms/utils";
import axios from "axios/axios";
import FillFromContacts from "customs/components/ScriptStep/FillFromContacts.jsx";
import moment from "moment";

class ChecklistComponent extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            registry_entries: props.registry_entries,
            form: {"type": "object", "properties": {}},
            ui: {"ui:order": []},
            formFieldCount: 0,
            formFieldAnswered: 0,
            formData: {},
            checklistModal: false,
            custom_validation: {},
            "availableContacts": null,
            "fillFromContactsModal": false,
            "fillFromContactsCallback": null
        };
        this.showDialog = this.showDialog.bind(this);
        this.buildForm = this.buildForm.bind(this);
        this.checkFieldVisibility = this.checkFieldVisibility.bind(this);
        this.reBuildForm = this.reBuildForm.bind(this);
        this.fillMCCOptions = this.fillMCCOptions.bind(this);
        this.isOptional = this.isOptional.bind(this);
        this.checkFieldRebuildVisibility = this.checkFieldRebuildVisibility.bind(this);
        this.showForm = this.showForm.bind(this);
        this.getPercentageComplete = this.getPercentageComplete.bind(this);
        this.validate = this.validate.bind(this);
        this.filterFile = this.filterFile.bind(this);
        this.buttonFieldClick = this.buttonFieldClick.bind(this);
        this.fillFromAsic = this.fillFromAsic.bind(this);
        this.fillFromBsb = this.fillFromBsb.bind(this);
        this.regionCodeToState = this.regionCodeToState.bind(this);
        this.fillFromContacts  = this.fillFromContacts.bind(this);
        this.doFillFromContacts = this.doFillFromContacts.bind(this);
        this.fillFromAccountDetails = this.fillFromAccountDetails.bind(this);
        this.fillFromAddress = this.fillFromAddress.bind(this);
        this.processFillFromAddress = this.processFillFromAddress.bind(this);
        this.findInFormData = this.findInFormData.bind(this);
        this.setFormDataKeyName = this.setFormDataKeyName.bind(this);
        this.setWatched = this.setWatched.bind(this);
    }

    componentWillReceiveProps (nextProps) {
        this.setState({registry_entries: nextProps.registry_entries});
        let formData = this.buildForm(nextProps, this.state.formData);
        setTimeout(() => {
            try {this.reBuildForm(nextProps, formData);} catch(e) {}
        }, 200);
    }

    componentDidMount() {
        this.buildForm(this.props, this.state.formData);
    }

    showDialog(show) {
        if (!show) {
            this.buildForm(this.props, this.state.formData);
        }
        this.setState({checklistModal: show});
    }

    filterFile(file, configuration) {
        let validTag = false;
        let validExt = false;
        if (configuration.hasOwnProperty('allowTags')) {
            let file_tags = file.tags.map((tag) => tag.toLowerCase());
            if (file_tags.length > 0) {
                configuration.allowTags.forEach(allowedTag => {
                    if (allowedTag === '*') {
                        validTag = true;
                    } else if (allowedTag.indexOf('*')) {
                        file_tags.forEach(tag => {
                            if (validations.matchRule(tag, allowedTag)) {
                                validTag = true;
                            }
                        })
                    } else if (file_tags.includes(allowedTag.toLowerCase())) {
                        validTag = true;
                    }
                });
            }
        } else {
            validTag = true;
        }
        if (configuration.hasOwnProperty('ui') && configuration.ui.hasOwnProperty('ui:options') && configuration.ui['ui:options'].hasOwnProperty('accept')) {
            let allowed_exts = configuration.ui['ui:options'].accept.split(',').map((ext) => ext.toLowerCase().replace(/\s/g, ''));
            if (allowed_exts.includes('.' + file.filename.split('.').pop().toLowerCase())) {
                validExt = true;
            }
        } else {
            validExt = true;
        }
        return validTag && validExt;
    }

    checkFieldVisibility(properties, registry_entries, checklist_template, transaction_methods) {
        if (properties.hasOwnProperty('visibility_config') && properties.visibility_config.hasOwnProperty('fields')) {
            let isAll = properties.visibility_config.hasOwnProperty('all') && properties.visibility_config.all;
            for(let key in properties.visibility_config.fields) {
                let fieldValue = Object.keys(registry_entries).length > 0
                    ? registry_entries.find((entry) => entry.registry_key === key) : undefined;
                let value = undefined;
                if (fieldValue !== undefined && fieldValue.hasOwnProperty('value')) {
                    value = fieldValue.value;
                } else if (checklist_template.hasOwnProperty('field_defaults') && Object.keys(checklist_template.field_defaults).length > 0) {
                    for(let default_key in checklist_template.field_defaults) {
                        if (checklist_template.field_defaults[default_key]['registry'] === key) {
                            value = checklist_template.field_defaults[default_key]['value'];
                            break;
                        }
                    }
                }
                if (value === undefined && properties.visibility_config.fields[key] === true) {
                    value = false;
                }
                if (value === "true") {value = true;} else if (value === "false") {value = false;}
                if (typeof properties.visibility_config.fields[key] === 'object') {
                    if (!isAll) {
                        if (!properties.visibility_config.fields[key]['all'] && properties.visibility_config.fields[key]['values'].includes(value)) {
                            return true;
                        }
                    } else {
                        if (!properties.visibility_config.fields[key]['all'] && !properties.visibility_config.fields[key]['values'].includes(value)) {
                            return false;
                        }
                    }
                } else {
                    if (!isAll && value === properties.visibility_config.fields[key]) {
                        return true;
                    } else if (isAll && value !== properties.visibility_config.fields[key]) {
                        return false;
                    }
                }
            }
            if (!isAll) return false;
        } else if (properties.hasOwnProperty('visibility_config') && properties.visibility_config.hasOwnProperty('client_details')) {
            let isAll = properties.visibility_config.hasOwnProperty('all') && properties.visibility_config.all;
            if (properties.visibility_config.client_details.hasOwnProperty('transaction_methods')) {
                let methods = properties.visibility_config.client_details.transaction_methods;
                for(let key in methods) {
                    if (!isAll && !transaction_methods.includes(methods[key])) {
                        continue;
                    } else if (!isAll && transaction_methods.includes(methods[key])) {
                        return true;
                    } else if (isAll && !transaction_methods.includes(methods[key])) {
                        return false;
                    }
                }
            }
            if (!isAll) return false;
        }
        return true;
    }

    isOptional(optional_condition, field_properties) {
        let isAll = optional_condition.hasOwnProperty('all') && optional_condition.all;
        for(let key in optional_condition.fields) {
            let fieldValue = Object.keys(this.props.registry_entries).length > 0
                ? this.props.registry_entries.find((entry) => entry.registry_key === optional_condition.fields[key]['registry']) : undefined;
            let value = undefined;
            let condition_value = optional_condition.fields[key]['value'];
            if (fieldValue !== undefined && fieldValue.hasOwnProperty('value')) {
                value = fieldValue.value;
            } else if (this.props.step.checklist_template.hasOwnProperty('field_defaults') && Object.keys(this.props.step.checklist_template.field_defaults).length > 0) {
                for(let default_key in this.props.step.checklist_template.field_defaults) {
                    if (this.props.step.checklist_template.field_defaults[default_key]['registry'] === optional_condition.fields[key]['registry']) {
                        value = this.props.step.checklist_template.field_defaults[default_key]['value'];
                        break;
                    }
                }
            }
            if (value === undefined && field_properties.configuration.properties.hasOwnProperty('default')) {
                value = field_properties.configuration.properties.default;
            }
            if (value === undefined && optional_condition.fields[key]['value'] === true) {
                value = false;
            }
            if (value === undefined && optional_condition.fields[key]['value'] === "false") {
                value = false;
            }
            if (value === "true") {value = true;} else if (value === "false") {value = false;}
            if (condition_value === "true") {condition_value = true;} else if (condition_value === "false") {condition_value = false;}
            if (!isAll && value === condition_value) {
                return true;
            } else if (isAll && value !== condition_value) {
                return false;
            }
        }
        return isAll;
    }

    setWatched(watched, key) {
        try {
            if (Object.keys(
                watched.filter((watch) => watch.event === 'entry' && watch.element === key && watch.status === 1)
            ).length === 1) return true;
        } catch(e) {}
        return false;
    }

    buildForm(props, data) {
        let form = {"type": "object", "isWatched" : props.ignored_watch || props.isWatched, "properties": {}};
        let ui = {"ui:order": []};
        let formData = data;
        let formFieldCount = 0;
        let formFieldAnswered = 0;
        let custom_validation = {};
        let transaction_methods = props.transaction_methods.map(method => method.method);
        if (Object.keys(props.step.checklist_template.required_fields).length > 0) {
            form.required = props.step.checklist_template.required_fields;
        }
        props.step.checklist_template.checklist_field_templates.forEach(field => {
            let property_name = field.configuration.properties.name;
            let properties = JSON.parse(JSON.stringify(field.configuration.properties));
            try {
                if (props.step.checklist_template.hasOwnProperty('custom_properties') && Object.keys(props.step.checklist_template.custom_properties).length > 0) {
                    if (props.step.checklist_template.custom_properties.hasOwnProperty(property_name)) {
                        for (var key in props.step.checklist_template.custom_properties[property_name]) {
                            properties[key] = props.step.checklist_template.custom_properties[property_name][key];
                        }
                    }
                }
            } catch(e) {}
            let is_optional = properties.hasOwnProperty('is_optional') && properties.is_optional === true;
            if (properties.hasOwnProperty('optional_condition') && properties.visibility_config.hasOwnProperty('fields')) {
                is_optional = this.isOptional(properties.optional_condition, field);
            }
            if (!is_optional && !(field.configuration.hasOwnProperty('for_client_area') && field.configuration.for_client_area === true)) {
                formFieldCount++;
            }
            properties.watched = props.ignored_watch || props.isWatched ? false : this.setWatched(props.watched, field.registry_key);

            properties = this.fillMCCOptions(properties, props);

            if (field.configuration.hasOwnProperty('for_client_area') && field.configuration.for_client_area === true) {
                // do not display
            } else {
                // if (this.checkFieldVisibility(field.configuration.properties, props.registry_entries, props.step.checklist_template)) {
                    let isFile = false;
                    let isFileButEmpty = false;
                    let files = [];
                    let isMultiple = properties.multiple ? properties.multiple : false;
                    if (properties.field_type !== undefined && (properties.field_type === 'file' || properties.field_type === 'file_sig')) {
                        isFile = true;
                        files.push("");
                        props.files.forEach((file) => {
                            if (this.filterFile(file, field.configuration)) {
                                files.push('(id: ' + file.id + ') ' + file.filename);
                            }
                        });
                        if (files.length > 1) {
                            form.properties[property_name + '_asfileselect'] = {"type": "string", "title": properties.title, "enum": files, "multiple": isMultiple};
                            if (props.readOnly) {
                                form.properties[property_name + '_asfileselect']['readOnly'] = true;
                            }
                        } else {
                            isFileButEmpty = true;
                        }
                    }
                    form.properties[property_name] = properties;
                    if (props.readOnly) {
                        form.properties[property_name]['readOnly'] = true;
                    }
                    if (field.configuration.hasOwnProperty('custom_validation')) {
                        for(let key in field.configuration.custom_validation) {
                            if (!custom_validation.hasOwnProperty(key)) {
                                custom_validation[key] = field.configuration.custom_validation[key];
                            }
                        }
                    }
                    if (Object.keys(field.configuration.ui).length > 0) {
                        ui[property_name] = field.configuration.ui;
                    }
                    if (isFile) {
                        try {
                            ui[property_name]['ui:options']['hide_alt_label'] = isFileButEmpty;
                        } catch(e) {}

                        if (properties.hasOwnProperty('preview_btn') && properties.preview_btn === true) {
                            if (files.length > 1) {
                                if (ui[property_name + '_asfileselect'] === undefined) {
                                    ui[property_name + '_asfileselect'] = {};
                                }
                                ui[property_name + '_asfileselect']['ui:widget'] = "previewSelectWidget";
                            }
                        }
                        ui["ui:order"].push(property_name + '_asfileselect');
                    }
                    ui["ui:order"].push(property_name);
                    if (properties.hasOwnProperty('ignore_count') && properties.ignore_count === true) {
                        formFieldCount--;
                    } else {
                        // if (Object.keys(props.registry_entries).length > 0) {
                            let fieldValue = Object.keys(props.registry_entries).length > 0
                                ? props.registry_entries.find((entry) => entry.registry_key === field.registry_key) : undefined;
                            if (fieldValue !== undefined) {
                                let value = fieldValue.value;
                                if (properties['type'] === 'number') {
                                    value = Number(value);
                                } else if (properties['type'] === 'integer') {
                                    value = parseInt(value);
                                } else if (properties['type'] === 'boolean') {
                                    value = typeof value === "boolean" ? value : value === 'true' || value === '1' || value === 1;
                                }
                                if (isFile) {
                                    let selectedFiles = [];
                                    for (let i=0; i<Object.keys(props.files).length; i++) {
                                        let file = props.files[i];
                                        value.split(',').forEach((val) => {
                                            if (file.id === Number(val) || file.id === val) {
                                                selectedFiles.push('(id: ' + file.id + ') ' + file.filename);
                                            }
                                        });
                                    }
                                    if (formData.hasOwnProperty(property_name) &&
                                        props.hasOwnProperty('saved_data') && props.saved_data !== null &&
                                        props.saved_data.hasOwnProperty(property_name) &&
                                        formData[property_name] && props.saved_data[property_name] &&
                                        (
                                            formData[property_name] === props.saved_data[property_name] ||
                                            helpers.arraysEqual(formData[property_name], props.saved_data[property_name])
                                        )
                                    ) {
                                        delete formData[property_name];
                                    }
                                    if (selectedFiles.length > 0) {
                                        formData[property_name + '_asfileselect'] = selectedFiles.join('||');
                                    }
                                } else {
                                    if (formData.hasOwnProperty(property_name) //&&
                                        // formData[property_name] !== undefined && formData[property_name] !== null &&
                                        // (formData[property_name] + "").length > 0
                                    ) {} else {
                                        formData[property_name] = value;
                                    }
                                }
                                if (!is_optional) {
                                    formFieldAnswered++;
                                }
                            } else {
                                let has_default = false;
                                if (props.step.checklist_template.hasOwnProperty('field_defaults') && Object.keys(props.step.checklist_template.field_defaults).length > 0) {
                                    if (props.step.checklist_template.field_defaults.hasOwnProperty(property_name)) {
                                        try {
                                            has_default = props.step.checklist_template.field_defaults[property_name]['value'].toString().length > 0;
                                        } catch(e) {}
                                        formData[property_name] = props.step.checklist_template.field_defaults[property_name]['value'];
                                    }
                                }
                                if (!is_optional) {
                                    if (field.configuration.properties.type === 'boolean' || has_default) {
                                        formFieldAnswered++;
                                    }
                                }
                            }
                        // }
                    }
                // }
                    if (!this.checkFieldVisibility(properties, props.registry_entries, props.step.checklist_template, transaction_methods)) {
                        if (isFile) {
                            delete form.properties[property_name + '_asfileselect'];
                        }
                        delete form.properties[property_name];
                    }
            }
        });
        
        this.setState({form, ui, formData, formFieldCount, formFieldAnswered, custom_validation});
        return formData;
    }

    checkFieldRebuildVisibility(properties, formData, registry_entries, transaction_methods) {
        if (properties.hasOwnProperty('visibility_config') && properties.visibility_config.hasOwnProperty('fields')) {
            let isAll = properties.visibility_config.hasOwnProperty('all') && properties.visibility_config.all;
            for(let key in properties.visibility_config.fields) {
                let formField = this.props.step.checklist_template.checklist_field_templates.find(field => field.registry_key === key);
                let registry = Object.keys(registry_entries).length > 0
                    ? registry_entries.find((entry) => entry.registry_key === key) : undefined;
                if (formField === undefined || formField === null) {
                    if ((registry === undefined || registry === null) && properties.hasOwnProperty('readOnly') && properties.readOnly !== true) {
                        continue;
                    }
                    let name = key.split('_').map((val) => val[0].toUpperCase() + val.substring(1)).join('');
                    name = name[0].toLowerCase() + name.substring(1);
                    formField = {
                        'configuration': {
                            'properties': {
                                'name': name
                            }
                        }
                    }
                }
                let formDataKey = formField.configuration.properties.name;
                let value = formData.hasOwnProperty(formDataKey)
                    ? formData[formDataKey]
                    : (registry !== undefined && registry.hasOwnProperty('value') ? registry.value : undefined);
                if (!isAll && (value === undefined || typeof value === 'object')) {
                    continue;
                }
                if (value === undefined && properties.visibility_config.fields[key] === true) {
                    value = false;
                }
                if (value === "true") {value = true;} else if (value === "false") {value = false;}
                if (typeof properties.visibility_config.fields[key] === 'object') {
                    if (!isAll) {
                        if (!properties.visibility_config.fields[key]['all'] && properties.visibility_config.fields[key]['values'].includes(value)) {
                            return true;
                        }
                    } else {
                        if (!properties.visibility_config.fields[key]['all'] && !properties.visibility_config.fields[key]['values'].includes(value)) {
                            return false;
                        }
                    }
                } else {
                    if (!isAll && value === properties.visibility_config.fields[key]) {
                        return true;
                    } else if (isAll && value !== properties.visibility_config.fields[key]) {
                        return false;
                    }
                }
            }
            if (!isAll) return false;
        } else if (properties.hasOwnProperty('visibility_config') && properties.visibility_config.hasOwnProperty('client_details')) {
            let isAll = properties.visibility_config.hasOwnProperty('all') && properties.visibility_config.all;
            if (properties.visibility_config.client_details.hasOwnProperty('transaction_methods')) {
                let methods = properties.visibility_config.client_details.transaction_methods;
                for(let key in methods) {
                    if (!isAll && !transaction_methods.includes(methods[key])) {
                        continue;
                    } else if (!isAll && transaction_methods.includes(methods[key])) {
                        return true;
                    } else if (isAll && !transaction_methods.includes(methods[key])) {
                        return false;
                    }
                }
            }
            if (!isAll) return false;
        }
        return true;
    }

    fillMCCOptions(properties, props) {
        if (properties.hasOwnProperty('fillMCC') && properties.fillMCC === true) {
            let enumVal = [];
            let enumNames = [];
            props.mccs.forEach((mcc) => {
                enumVal.push(mcc.code);
                enumNames.push('(' + mcc.code + ') ' + mcc.title.slice(0, 80));
            });
            properties['enum'] = enumVal;
            properties['enumNames'] = enumNames;
            properties['mccs'] = props.mccs;
        }

        return properties;
    }

    reBuildForm(props, formData) {
        let form = this.state.form;
        form.isWatched = props.ignored_watch || props.isWatched;
        let ui = this.state.ui;
        let custom_validation = this.state.custom_validation;
        let transaction_methods = props.transaction_methods.map(method => method.method);
        props.step.checklist_template.checklist_field_templates.forEach(field => {
            let property_name = field.configuration.properties.name;
            let properties = JSON.parse(JSON.stringify(field.configuration.properties));
            try {
                if (props.step.checklist_template.hasOwnProperty('custom_properties') && Object.keys(props.step.checklist_template.custom_properties).length > 0) {
                    if (props.step.checklist_template.custom_properties.hasOwnProperty(property_name)) {
                        for (var key in props.step.checklist_template.custom_properties[property_name]) {
                            properties[key] = props.step.checklist_template.custom_properties[property_name][key];
                        }
                    }
                }
            } catch(e) {}
            properties.watched = this.setWatched(props.watched, field.registry_key);
            
            properties = this.fillMCCOptions(properties, props);

            if (field.configuration.hasOwnProperty('for_client_area') && field.configuration.for_client_area === true) {
                // do not display
            } else {
                // if (this.checkFieldRebuildVisibility(field.configuration.properties, formData, props.registry_entries)) {
                    let isFile = false;
                    let isFileButEmpty = false;
                    let files = [];
                    if (properties.field_type !== undefined && (properties.field_type === 'file' || properties.field_type === 'file_sig')) {
                        isFile = true;
                        files.push("");
                        props.files.forEach((file) => {
                            if (this.filterFile(file, field.configuration)) {
                                files.push('(id: ' + file.id + ') ' + file.filename);
                            }
                        });
                        if (files.length > 1) {
                            let isMultiple = properties.multiple ? properties.multiple : false;
                            form.properties[property_name + '_asfileselect'] = {"type": "string", "title": properties.title, "enum": files, "multiple": isMultiple};
                            if (props.readOnly) {
                                form.properties[property_name + '_asfileselect']['readOnly'] = true;
                            }
                        } else {
                            isFileButEmpty = true;
                        }
                    }
                    form.properties[property_name] = properties;
                    if (props.readOnly) {
                        form.properties[property_name]['readOnly'] = true;
                    }
                    if (field.configuration.hasOwnProperty('custom_validation')) {
                        for(let key in field.configuration.custom_validation) {
                            if (!custom_validation.hasOwnProperty(key)) {
                                custom_validation[key] = field.configuration.custom_validation[key];
                            }
                        }
                    }
                    if (Object.keys(field.configuration.ui).length > 0 && !ui.hasOwnProperty(property_name)) {
                        ui[property_name] = field.configuration.ui;
                    }
                    if (isFile) {
                        try {
                            ui[property_name]['ui:options']['hide_alt_label'] = isFileButEmpty;
                        } catch(e) {}
                        
                        if (properties.hasOwnProperty('preview_btn') && properties.preview_btn === true) {
                            if (files.length > 1) {
                                if (ui[property_name + '_asfileselect'] === undefined) {
                                    ui[property_name + '_asfileselect'] = {};
                                }
                                ui[property_name + '_asfileselect']['ui:widget'] = "previewSelectWidget";
                            }
                        }
                        if (!ui["ui:order"].includes(property_name + '_asfileselect')) {
                            ui["ui:order"].push(property_name + '_asfileselect');
                        }
                    }
                    if (!ui["ui:order"].includes(property_name)) {
                        ui["ui:order"].push(property_name);
                    }
                // } else {
                //     delete form.properties[property_name];
                // }
                    if (!this.checkFieldRebuildVisibility(properties, formData, props.registry_entries, transaction_methods)) {
                        if (isFile) {
                            delete form.properties[property_name + '_asfileselect'];
                        }
                        delete form.properties[property_name];
                    }
            }
        });
        this.setState({form, ui, custom_validation});
    }

    showForm() {
        this.buildForm(this.props, {});
        this.showDialog(true);
    }

    getPercentageComplete() {
        if (this.state.formFieldCount === 0) {
            return '100%';
        }

        let progress = this.state.formFieldAnswered / this.state.formFieldCount;
        return ((isNaN(progress) ? 0 : progress) * 100).toFixed(2).replace('.00', '') + '%';
    };

    validate(formData, errors) {
        for(let key in this.state.custom_validation) {
            if (formData.hasOwnProperty(key)) {
                if (typeof this.state.custom_validation[key] === 'object') {
                    if (this.state.custom_validation[key].hasOwnProperty('func')) {
                        if (formData.hasOwnProperty(key) && formData[key]) {
                            if (this.state.custom_validation[key]['func'] === 'maxFileSizeValid') {
                                if (!validations.maxFileSizeValid(formData[key], this.state.custom_validation[key]['maxBytes'])) {
                                    errors[key].addError("Invalid file size");
                                } else if (!validations.fileTypeValid(formData[key], this.state.custom_validation[key]['accept'])) {
                                    errors[key].addError("Invalid file type");
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateNumberMax') {
                                if (!validations.validateNumberMax(formData[key], this.state.custom_validation[key]['max'])) {
                                    errors[key].addError("Value should not exceed " + this.state.custom_validation[key]['max']);
                                } else {
                                    if (this.state.custom_validation[key].hasOwnProperty('fill_estimated_annual_turnover') && this.state.custom_validation[key]['fill_estimated_annual_turnover'] === true) {
                                        let val = validations.computeEstimatedAnnualTurnover(formData, this.state.custom_validation[key]['fill_config']['params']);
                                        if (val !== null && val !== formData[this.state.custom_validation[key]['fill_config']['fill']]) {
                                            formData[this.state.custom_validation[key]['fill_config']['fill']] = val;
                                            this.setState({formData});
                                        }
                                    }
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateDateNotFuture') {
                                if (!validations.validateDateNotFuture(formData[key])) {
                                    errors[key].addError("Selected date should not be a future date");
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateGroupTotal') {
                                let fields = this.state.custom_validation[key]['fields'];
                                if (!validations.validateGroupTotal(formData, fields, this.state.custom_validation[key]['max'])) {
                                    errors[key].addError("Total should not exceed " + this.state.custom_validation[key]['max']);
                                } else {
                                    if (this.state.custom_validation[key].hasOwnProperty('fill_zeros') && this.state.custom_validation[key]['fill_zeros'] === true) {
                                        let total = validations.getGroupTotal(formData, fields);
                                        if (total === Number(this.state.custom_validation[key]['max'])) {
                                            let has_changed = false;
                                            for(let key in fields) {
                                                if (!formData.hasOwnProperty(fields[key]) || formData[fields[key]] === undefined || formData[fields[key]] === null || formData[fields[key]].length === 0) {
                                                    has_changed = true;
                                                    formData[fields[key]] = 0;
                                                }
                                            }
                                            if (has_changed) {
                                                this.setState({formData});
                                            }
                                        }
                                    }
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateMMYYYYDate') {
                                if (!validations.validateMMYYYYDate(formData[key])) {
                                    errors[key].addError("Entered date is invalid");
                                } else if (this.state.custom_validation[key].hasOwnProperty('checkExpired') && this.state.custom_validation[key]['checkExpired'] === true) {
                                    if (validations.isExpired(formData[key], 'MM/YYYY', true)) {
                                        errors[key].addError("Entered date is expired");
                                    }
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'validateDate') {
                                if (!validations.validateDate(formData[key])) {
                                    errors[key].addError("Entered date is invalid");
                                } else if (this.state.custom_validation[key].hasOwnProperty('checkExpired') && this.state.custom_validation[key]['checkExpired'] === true) {
                                    if (validations.isExpired(formData[key], null, false)) {
                                        errors[key].addError("Entered date is expired");
                                    }
                                }
                            } else if (this.state.custom_validation[key]['func'] === 'computeEstimatedAnnualTurnover') {
                                let val = validations.computeEstimatedAnnualTurnover(formData, this.state.custom_validation[key]['params']);
                                if (val !== null && val !== formData[this.state.custom_validation[key]['fill']]) {
                                    formData[this.state.custom_validation[key]['fill']] = val;
                                    this.setState({formData});
                                }
                            }
                        }
                    } else {
                        for(let sub_key in this.state.custom_validation[key]) {
                            let func = validations[this.state.custom_validation[key][sub_key]['func']];
                            let parent_value = this.state.custom_validation[key][sub_key]['parent_val'];
                            if (
                                typeof func === 'function' &&
                                parent_value.includes(formData[key][key]) &&
                                formData[key].hasOwnProperty(sub_key) &&
                                formData[key][sub_key] !== null &&
                                formData[key][sub_key] !== undefined
                            ) {
                                if (!func(formData[key][sub_key])) {
                                    if (!errors.hasOwnProperty(key)) {
                                        errors[key] = {};
                                    }
                                    if (!errors[key].hasOwnProperty(sub_key)) {
                                        errors[key][sub_key] = {};
                                    }
                                    errors[key][sub_key].addError("Invalid value");
                                }
                            }
                        }
                    }
                } else {
                    let func = validations[this.state.custom_validation[key]];
                    if (typeof func === 'function' && formData.hasOwnProperty(key) && formData[key] !== null && formData[key] !== undefined) {
                        if (!func(formData[key])) {
                            if (!errors.hasOwnProperty(key)) {
                                errors[key] = {};
                            }
                            errors[key].addError("Invalid value");
                        }
                    }
                }
            }
        }
        return errors;
    }

    buttonFieldClick(uiSchema, callback) {
        if (typeof this[uiSchema['ui:action']['func']] === 'function') {
            try {
                this[uiSchema['ui:action']['func']](uiSchema['ui:action'], callback);
            } catch(e) {}
        }
    }

    regionCodeToState(code) {
        code = code.toUpperCase();
        let states = {
            "NSW": "New South Wales",
            "QLD": "Queensland",
            "NT": "Northern Territory",
            "WA": "Western Australia",
            "SA": "South Australia",
            "VIC": "Victoria",
            "ACT": "the Australian Capital Territory",
            "TAS": "Tasmania"
        };
        if (Object.values(states).map(val => val.toLowerCase()).includes(code.toLowerCase())) return code;
        return states.hasOwnProperty(code) ? states[code] : '';
    }

    fillFromAsic(ui_action, callback) {
        let reference_fields = ui_action[ui_action['reference'] + '_fields'];
        let fillables = ui_action['fillables'];
        // let reference = [];
        // this.props.step.checklist_template.checklist_field_templates.forEach(field => {
        //     if (field.configuration.properties.name === ui_action['reference']) {
        //         reference = field.configuration;
        //     }
        // });
        let findStr = '';
        // if (ui_action.hasOwnProperty('reference')) {
        //     if (reference.hasOwnProperty('dependencies')) {
        //         if (reference.dependencies[ui_action['reference']].hasOwnProperty('oneOf')) {
        //             try {
        //                 let field_type = reference.dependencies[ui_action['reference']].oneOf
        //                     .find((entry) => entry.properties[ui_action['reference']].enum[0] === this.state.formData[ui_action['reference']][ui_action['reference']]);
        //                 for(let i=0; i<reference_fields.length; i++) {
        //                     if (field_type.properties.hasOwnProperty(reference_fields[i])) {
        //                         findStr = findVal(this.state.formData, reference_fields[i]);
        //                     }
        //                 }
        //             } catch(e) {}
        //         }
        //     } else {
        //         findStr = findVal(this.state.formData, ui_action.reference);
        //     }
        // } else {
            
            let reference = findVal(this.state.formData, ui_action['reference']);
            for(let i=0; i<reference_fields.length; i++) {
                if (reference.toLowerCase() === reference_fields[i].toLowerCase()) {
                    let val = findVal(this.state.formData, reference_fields[i]);
                    if (val !== undefined) {
                        findStr = val;
                    }
                    break;
                }
            }
        // }
        if (!findStr) {return '';}
        findStr = findStr.replace(/[^\d]/g, '');
        let searchBy = validations.validateABN(findStr) ? 'abn' : (validations.validateACN(findStr) ? 'acn' : false);
        try {
            if (findStr.length > 0 && searchBy !== false) {
                helpers.showLoading();
                axios(this.props)
                    .get(`/${searchBy}/${findStr}`)
                    .then((response) => {
                        let formData = this.state.formData;
                        for(let i=0; i<fillables.length; i++) {
                            if (fillables[i] === "registrationDate") {
                                if (response.data.hasOwnProperty('businessEntity') &&
                                    response.data.businessEntity.hasOwnProperty('mainName') &&
                                    response.data.businessEntity.mainName.hasOwnProperty('effectiveFrom')
                                ) {
                                    formData[fillables[i]] = response.data.businessEntity.mainName.effectiveFrom
                                }
                            } else if (fillables[i] === "businessType") {
                                if (response.data.hasOwnProperty('businessEntity') &&
                                    response.data.businessEntity.hasOwnProperty('entityType') &&
                                    response.data.businessEntity.entityType.hasOwnProperty('entityDescription')
                                ) {
                                    formData[fillables[i]] = response.data.businessEntity.entityType.entityDescription
                                }
                            } else {
                                let val = findVal(response, fillables[i]);
                                if (val !== undefined) {
                                    if (fillables[i] === "stateCode") {
                                        formData[fillables[i]] = this.regionCodeToState(val);
                                    } else {
                                        formData[fillables[i]] = val;
                                    }
                                }
                            }
                        }
                        this.setState({formData});
                        if (callback) {callback();}
                        helpers.hideLoading();
                    })
                    .catch((error) => helpers.hideLoading());
            }
            document.getElementById('root_' + fillables[0]).focus();
        } catch(e) {}
    }

    fillFromBsb(ui_action, callback) {
        let findStr = findVal(this.state.formData, ui_action['reference']);
        if (!findStr) {return '';}
        findStr = findStr.replace(/[^\d]/g, '');
        
        try {
            let fillables = ui_action['fillables'];
            if (findStr.length === 6) {
                helpers.showLoading();
                axios(this.props)
                    .get(`/bsb/lookup/${findStr}`)
                    .then((response) => {
                        if (response.hasOwnProperty('data') && response.data.hasOwnProperty('details') && response.data.details.hasOwnProperty('branch_details')) {
                            let formData = this.state.formData;
                            for (const key in fillables) {
                                let val = findVal(response, key);
                                if (val !== undefined) {
                                    if (key === 'state') {
                                        val = this.regionCodeToState(val);
                                    } else if (key === 'postcode') {
                                        val = val.toString();
                                    }
                                    formData[fillables[key]] = val;
                                }
                            }
                            this.setState({formData});
                            if (callback) {callback();}
                        }
                        helpers.hideLoading();
                    })
                    .catch((error) => helpers.hideLoading());
            }
            document.getElementById('root_' + Object.values(fillables)[0]).focus();
        } catch(e) {}
    }

    getFillItemToolTip(fillFromFields, key_start, key_end, formData) {
        let tips = [], fname = '', lname = '';
        for(let key in fillFromFields) {
            let registry_key = key_start + fillFromFields[key] + key_end;
            let value = formData[this.setFormDataKeyName(key_start, fillFromFields[key], key_end)];
            let formField = this.props.step.checklist_template.checklist_field_templates.find(field => field.registry_key === registry_key);
            if (value === undefined || value === null || value.length === 0 || formField === undefined || formField === null) {
                let fieldValue = this.props.registry_entries.find((entry) => entry.registry_key === registry_key);
                if (fieldValue !== undefined && fieldValue.hasOwnProperty('value')) {
                    tips.push(fieldValue.field_template.name + ': ' + fieldValue.value);
                    if (fieldValue.field_template.name.toLowerCase().indexOf('first name') >= 0) {
                        fname = fieldValue.value;
                    } else if (fieldValue.field_template.name.toLowerCase().indexOf('last name') >= 0) {
                        lname = fieldValue.value;
                    }
                }
            } else {
                tips.push(formField.name + ': ' + value);
                if (formField.name.toLowerCase().indexOf('first name') >= 0) {
                    fname = value;
                } else if (formField.name.toLowerCase().indexOf('last name') >= 0) {
                    lname = value;
                }
            }
        }
        return {'name': fname + " " + lname, 'tip': tips.join('\n')};
    }

    contact_fields = {
        "on_site": {'key_start': '', 'key_end': '', 'label': 'Business Info/Contact (On-site)'},
        "chargebacks": {'key_start': '', 'key_end': '_chargebacks', 'label': 'Business Info/Contact (Chargeback)'},
        "ecommerce": {'key_start': '', 'key_end': '_ecommerce', 'label': 'Business Info/Contact (eCommerce)'},
        "beneficial1": {'key_start': 'bo1_', 'key_end': '', 'label': 'Beneficial Owner 1'},
        "beneficial2": {'key_start': 'bo2_', 'key_end': '', 'label': 'Beneficial Owner 2'},
        "beneficial3": {'key_start': 'bo3_', 'key_end': '', 'label': 'Beneficial Owner 3'},
        "beneficial4": {'key_start': 'bo4_', 'key_end': '', 'label': 'Beneficial Owner 4'},
        "signatory1": {'key_start': 'as1_', 'key_end': '', 'label': 'Authorised Signatory 1'},
        "signatory2": {'key_start': 'as2_', 'key_end': '', 'label': 'Authorised Signatory 2'},
        "signatory3": {'key_start': 'as3_', 'key_end': '', 'label': 'Authorised Signatory 3'},
        "signatory4": {'key_start': 'as4_', 'key_end': '', 'label': 'Authorised Signatory 4'},
    };
    fillFromContacts(ui_action, callback) {
        let availableContacts = [];
        const formData = this.state.formData;
        // if (Object.keys(this.props.registry_entries).length > 0) {
            for(let contact_key in this.contact_fields) {
                if (ui_action.group_key === contact_key) {
                    continue;
                }
                let found = false;
                for(let key in ui_action.fill_from) {
                    let fieldValue = this.findInFormData(
                        this.contact_fields[contact_key]['key_start'], ui_action.fill_from[key], this.contact_fields[contact_key]['key_end'], formData
                    );
                    if (fieldValue !== undefined && fieldValue.hasOwnProperty('value')) {
                        let {name, tip} = this.getFillItemToolTip(ui_action.fill_from, this.contact_fields[contact_key]['key_start'], this.contact_fields[contact_key]['key_end'], formData)
                        availableContacts.push({
                            "key": contact_key,
                            "value": this.contact_fields[contact_key]['label'],
                            "name": name,
                            "tip": tip,
                            "key_start": this.contact_fields[contact_key]['key_start'],
                            "key_end": this.contact_fields[contact_key]['key_end'],
                        });
                        found = true;
                        break;
                    }
                }
                if (!found && (this.contact_fields[contact_key]['key_start'] + this.contact_fields[contact_key]['key_end']).length > 0) {
                    for(let key in ui_action.fill_from_partials) {
                        let registry_key = this.contact_fields[contact_key]['key_start'] + ui_action.fill_from_partials[key] + this.contact_fields[contact_key]['key_end'];
                        let fieldValue = this.props.registry_entries.find((entry) => entry.registry_key === registry_key);
                        if (fieldValue !== undefined && fieldValue.hasOwnProperty('value')) {
                            let {name, tip} = this.getFillItemToolTip(ui_action.fill_from_partials, this.contact_fields[contact_key]['key_start'], this.contact_fields[contact_key]['key_end'], formData);
                            availableContacts.push({
                                "key": contact_key,
                                "value": this.contact_fields[contact_key]['label'],
                                "name": name,
                                "tip": tip,
                                "key_start": this.contact_fields[contact_key]['key_start'],
                                "key_end": this.contact_fields[contact_key]['key_end'],
                            });
                            found = true;
                            break;
                        }
                    }
                }
            }
        // }

        this.setState({
            "fillFromContactsModal": true,
            "ui_action": ui_action,
            "availableContacts": Object.keys(availableContacts).length === 0 ? null : availableContacts,
            "fillFromContactsCallback": callback
        });

        if (Object.keys(availableContacts).length === 0) {
            this.props.setUsersNotification(
                "No contacts to duplicate",
                "warning"
            );
        }
    }

    setFormDataKeyName(key_start, key, key_end) {
        if (key_end === undefined || key_end === null) {
            key_end = '';
        } else {
            key_end = key_end.replace('_', '');
            try {
                key_end = key_end[0].toUpperCase() + key_end.substring(1);
            } catch(e) {}
        }
        key = key.split('_').map((val) => val[0].toUpperCase() + val.substring(1)).join('');
        if (key_start.length === 0) key = key[0].toLowerCase() + key.substring(1);
        return key_start.replace('_', '') + key + key_end;
    }

    findInFormData(key_start, key, key_end, formData) {
        let value = formData[this.setFormDataKeyName(key_start, key, key_end)];
        return value === undefined || value === null || value.length === 0
            ? this.props.registry_entries.find((entry) => {
                return entry.registry_key === key_start + key + key_end;
            }) : {'value': value};
    }

    doFillFromContacts(selected) {
        let formData = this.state.formData;
        let focusElement = this.state.ui_action.fill_key_start.replace('_', '') + this.state.ui_action.fillables[0].split('_').map(
            (val) => val[0].toUpperCase() + val.substring(1)
        ).join('');
        focusElement = focusElement[0].toLowerCase() + focusElement.substring(1);
        if (Object.keys(this.props.registry_entries).length > 0) {
            for(let key in this.state.ui_action.fillables) {
                let fill_registry_key = this.state.ui_action.fill_key_start.replace('_', '') + this.state.ui_action.fillables[key].split('_').map(
                    (val) => val[0].toUpperCase() + val.substring(1)
                ).join('');
                fill_registry_key = fill_registry_key[0].toLowerCase() + fill_registry_key.substring(1);
                let value = ""; let ignore = false;
                // Business Info
                if (this.state.ui_action.fillables[key].startsWith('contact_first_name')) {
                    let contact_first_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fill_from[key], this.contact_fields[selected]['key_end'], formData
                    );
                    let first_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'first_name', this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_first_name !== undefined) {
                        value = contact_first_name.value;
                    } else if (first_name !== undefined) {
                        value = first_name.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('contact_middle_name')) {
                    let contact_middle_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fill_from[key], this.contact_fields[selected]['key_end'], formData
                    );
                    let middle_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'middle_name', this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_middle_name !== undefined) {
                        value = contact_middle_name.value;
                    } else if (middle_name !== undefined) {
                        value = middle_name.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('contact_last_name')) {
                    let contact_last_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fill_from[key], this.contact_fields[selected]['key_end'], formData
                    );
                    let last_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'last_name', this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_last_name !== undefined) {
                        value = contact_last_name.value;
                    } else if (last_name !== undefined) {
                        value = last_name.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('contact_mobile')) {
                    let mobile_number = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fill_from[key], this.contact_fields[selected]['key_end'], formData
                    );
                    let contact_mobile_number = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'mobile_number', this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (mobile_number !== undefined) {
                        value = mobile_number.value;
                    } else if (contact_mobile_number !== undefined) {
                        value = contact_mobile_number.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('contact_work')) {
                    let work_number = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fill_from[key], this.contact_fields[selected]['key_end'], formData
                    );
                    let contact_work_number = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'work_number', this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (work_number !== undefined) {
                        value = work_number.value;
                    } else if (contact_work_number !== undefined) {
                        value = contact_work_number.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('contact_email')) {
                    let email = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fill_from[key], this.contact_fields[selected]['key_end'], formData
                    );
                    let contact_email = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'email', this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (email !== undefined) {
                        value = email.value;
                    } else if (contact_email !== undefined) {
                        value = contact_email.value;
                    }
                // Business Info
                // Beneficial and Signatory
                } else if (this.state.ui_action.fillables[key].startsWith('first_name')) {
                    let contact_first_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'contact_first_name', this.contact_fields[selected]['key_end'], formData
                    );
                    let first_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fillables[key], this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_first_name !== undefined) {
                        value = contact_first_name.value;
                    } else if (first_name !== undefined) {
                        value = first_name.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('middle_name')) {
                    let contact_middle_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'contact_middle_name', this.contact_fields[selected]['key_end'], formData
                    );
                    let middle_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fillables[key], this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_middle_name !== undefined) {
                        value = contact_middle_name.value;
                    } else if (middle_name !== undefined) {
                        value = middle_name.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('last_name')) {
                    let contact_last_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'contact_last_name', this.contact_fields[selected]['key_end'], formData
                    );
                    let last_name = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fillables[key], this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_last_name !== undefined) {
                        value = contact_last_name.value;
                    } else if (last_name !== undefined) {
                        value = last_name.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('mobile_number')) {
                    let contact_mobile_number = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'contact_mobile_number', this.contact_fields[selected]['key_end'], formData
                    );
                    let mobile_number = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fillables[key], this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_mobile_number !== undefined) {
                        value = contact_mobile_number.value;
                    } else if (mobile_number !== undefined) {
                        value = mobile_number.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('work_number')) {
                    let contact_work_number = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'contact_work_number', this.contact_fields[selected]['key_end'], formData
                    );
                    let work_number = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fillables[key], this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_work_number !== undefined) {
                        value = contact_work_number.value;
                    } else if (work_number !== undefined) {
                        value = work_number.value;
                    }
                } else if (this.state.ui_action.fillables[key].startsWith('email')) {
                    let contact_email = this.findInFormData(
                        this.contact_fields[selected]['key_start'], 'contact_email', this.contact_fields[selected]['key_end'], formData
                    );
                    let email = this.findInFormData(
                        this.contact_fields[selected]['key_start'], this.state.ui_action.fillables[key], this.contact_fields[selected]['key_end'], formData
                    );
                    
                    if (contact_email !== undefined) {
                        value = contact_email.value;
                    } else if (email !== undefined) {
                        value = email.value;
                    }
                // Beneficial and Signatory
                } else {
                    if (this.contact_fields[selected]['key_start'].length > 0) {
                        let formValue = this.findInFormData(
                            this.contact_fields[selected]['key_start'], this.state.ui_action.fillables[key], null, formData
                        );
                        if (formValue !== undefined && formValue.hasOwnProperty('value') && formValue.value.startsWith('data:')) {
                            ignore = true;
                        } else {
                            let registry_key = this.contact_fields[selected]['key_start'] + this.state.ui_action.fillables[key];
                            let fieldValue = this.props.registry_entries.find((entry) => entry.registry_key === registry_key);
                            let isFile = false;
                            if (fieldValue !== undefined && fieldValue.hasOwnProperty('value')) {
                                let properties = fieldValue.field_template.configuration.properties;
                                if (properties.field_type !== undefined && (properties.field_type === 'file' || properties.field_type === 'file_sig')) {
                                    isFile = true;
                                }
                            }
                            if (isFile) {
                                fill_registry_key = fill_registry_key + '_asfileselect';
                                for (let i=0; i<Object.keys(this.props.files).length; i++) {
                                    let file = this.props.files[i];
                                    if (file.id === Number(fieldValue.value) || file.id === fieldValue.value) {
                                        value = '(id: ' + file.id + ') ' + file.filename;
                                        break;
                                    }
                                }
                            } else {
                                if (formValue !== undefined) {
                                    value = formValue.value;
                                } else if (fieldValue !== undefined) {
                                    value = fieldValue.value;
                                }
                            }
                        }
                    }
                    try {
                        this.props.step.checklist_template.checklist_field_templates.forEach(field => {
                            if (field.configuration.properties.name === fill_registry_key) {
                                if (field.configuration.ui['ui:widget'] === 'CustomRadio' && (value === null || value === undefined || value.length === 0)) {
                                    ignore = true;
                                }
                            }
                        });
                    } catch(e) {}
                }
                if (ignore || (this.state.ui_action.fillables[key].startsWith('date_of_birth') && (value === undefined || value === null || value.length === 0 || !moment(value).isValid()))) {
                } else {
                    formData[fill_registry_key] = value;
                }
            }
        }
        let fillFromContactsCallback = this.state.fillFromContactsCallback;
        this.setState({
            formData,
            "fillFromContactsModal": false,
            "ui_action": null,
            "availableContacts": null,
            "fillFromContactsCallback": null
        });
        setTimeout(() => {
            try {this.reBuildForm(this.props, formData);} catch(e) {}
            try {document.getElementById('root_' + focusElement).focus();} catch(e) {}
            if (fillFromContactsCallback) {fillFromContactsCallback();}
        }, 200);
    }

    fillFromAccountDetails(ui_action, callback) {
        let formData = this.state.formData;
        let focusElement = ui_action.fill_key_start.replace('_', '') + ui_action.fillables[0].split('_').map(
            (val) => val[0].toUpperCase() + val.substring(1)
        ).join('');
        focusElement = focusElement[0].toLowerCase() + focusElement.substring(1);
        for(let key in ui_action.fillables) {
            let fill_registry_key = ui_action.fill_key_start.replace('_', '') + ui_action.fillables[key].split('_').map(
                (val) => val[0].toUpperCase() + val.substring(1)
            ).join('');
            fill_registry_key = fill_registry_key[0].toLowerCase() + fill_registry_key.substring(1);
            let value = "";
            let registry_key = 'dc' + ui_action.fillables[key].split('_').map(
                (val) => val[0].toUpperCase() + val.substring(1)
            ).join('');
            value = findVal(this.state.formData, registry_key);
            if ((value === undefined || value === "") && Object.keys(this.props.registry_entries).length > 0) {
                registry_key = 'dc_' + ui_action.fillables[key];
                let fieldValue  = this.props.registry_entries.find((entry) => entry.registry_key === registry_key);
                if (fieldValue !== undefined && fieldValue.hasOwnProperty('value')) {
                    value = fieldValue.value;
                }
            }
            formData[fill_registry_key] = value;
        }
        this.setState({
            formData,
            "fillFromContactsModal": false,
            "ui_action": null,
            "availableContacts": null,
            "fillFromContactsCallback": null
        });
        if (callback) {callback();}
        setTimeout(() => {
            try {document.getElementById('root_' + focusElement).focus();} catch(e) {}
        }, 200);
    }

    handleOnChange = ({formData}) => {
        this.setState({formData});
        this.reBuildForm(this.props, formData);
    }

    handleSignatureChange = (key, data) => {
        let formData = this.state.formData;
        
        formData[key] = "";
        this.setState({formData});
        this.reBuildForm(this.props, formData);
        
        setTimeout(() => {
            formData[key] = data;
            this.setState({formData});
            this.reBuildForm(this.props, formData);
        }, 200);
    }

    fillFromAddress(ui_action, address, callback) {
        try {
            helpers.showLoading();
            axios(this.props)
                .post('/address/details', {'id': address.place_id})
                .then((response) => {
                    this.processFillFromAddress(ui_action, address, response.data.result, callback);
                    helpers.hideLoading();
                })
                .catch((error) => helpers.hideLoading());
        } catch(e) {}
    }

    processFillFromAddress(ui_action, address, details, callback) {
        const state = details.hasOwnProperty('state') ? details.state : null;
        const suburb = details.hasOwnProperty('suburb') ? details.suburb : null;
        const post_code = details.hasOwnProperty('post_code') ? details.post_code : null;

        let fillables = ui_action['fillables'];
        let formData = this.state.formData;
        for(let i=0; i<fillables.length; i++) {
            let val = null;
            if (fillables[i]['fill'] === 'state' && state !== null) {
                val = this.regionCodeToState(state);
            } else if (fillables[i]['fill'] === 'suburb' && suburb !== null) {
                val = suburb;
            } else if (fillables[i]['fill'] === 'post_code' && post_code !== null) {
                val = post_code;
            }
            if (val !== null) {
                formData[fillables[i]['key']] = val;
            }
        }
        this.setState({formData});
        if (callback) {callback();}
    }

    render () {
        let progress = this.getPercentageComplete();
        return <div>
            <font style={{"fontWeight": progress === '100%' ? 'bold' : 'inherit'}}>
                {helpers.addLineBreaks((this.props.stepTitle ? this.props.stepTitle : this.props.step.checklist_template.name) + ' - ' + progress + ' completed')}
            </font>
            <Button
                // disabled={!(this.props.index > 0 ? this.props.checklist.steps[this.props.index - 1].status === 1 : true)}
                // className={classes.updateProfileButton}
                color="success"
                onClick={this.showForm}
            >
                Show
            </Button>
            {
                this.state.checklistModal
                    ? <ChecklistFormDialog
                        {...this.props}
                        checklistModal={this.state.checklistModal}
                        close={this.showDialog}
                        schema={this.state.form}
                        ui={this.state.ui}
                        data={this.state.formData}
                        formFieldCount={this.state.formFieldCount}
                        title={this.props.stepTitle ? this.props.stepTitle : this.props.step.checklist_template.name}
                        id={this.props.step.id}
                        client_script_id={this.props.step.client_script_id}
                        validate={this.validate}
                        buttonFieldClick={this.buttonFieldClick}
                        onChange={this.handleOnChange}
                        handleSignatureChange={this.handleSignatureChange}
                        fillFromAddress={this.fillFromAddress}
                        watched={
                            this.props.watched
                                .filter((prop) => prop.event === 'entry')
                                .map((prop) => {
                                    prop.name = prop.element.split('_').join('');
                                    return prop;
                                })
                        }
                        custom_validation={this.state.custom_validation}
                    />
                    : ""
            }
            {
                this.state.availableContacts
                    ? <FillFromContacts
                        handleFillFromContact={this.doFillFromContacts}
                        onClose={() => {
                            this.setState({
                                "fillFromContactsModal": false,
                                "ui_action": null,
                                "availableContacts": null,
                                "fillFromContactsCallback": null
                            });
                        }}
                        contacts={this.state.availableContacts}
                        {...this.state}
                    />
                    : ""
            }
        </div>;
    }
}

const Checklist = withStyles(extendedFormsStyle)(ChecklistComponent);

export default Checklist;
