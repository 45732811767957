import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

import helpers from "customs/helpers/helpers";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.jsx";

class DownloadGeneratedInvoiceDialog extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            
        }
    }

    setFiles (files) {
        let res = [];
        res = files.map((prop, key) => {
            return [
                prop.id,
                prop.filename ? prop.filename : "",
                prop.uploader ? "(" + prop.uploader.type + ") " + prop.uploader.username : "",
                [
                    <Button
                        title={'Download file'}
                        className="view"
                        color="facebook"
                        justIcon
                        key={`btnF${key}`}
                        onClick={(event) => this.props.readOnly ? "" : this.props.handleDownloadFile(event, prop)}
                        round
                        simple
                    >
                        <i
                            className="fa fa-download fa-sm"
                            key={`i_document_${key}`}
                        />
                    </Button>
                ]
            ];
        });
        return res;
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="small-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={this.props.onClose}
                open={this.props.downloadApplicationModal}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {helpers.addLineBreaks(this.props.downloadApplicationModalMsg)}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="small-modal-slide-description"
                >
                    <GridContainer>
                        <GridItem md={12}>
                            <h5 style={{"minWidth": "200px",
                                "marginBottom": "0px"}}
                            >
                                {"Generated Files"}
                            </h5>
                            <Table
                                customCellClasses={[`no-wrap ${classes.right}`]}
                                customClassesForCells={[4]}
                                customHeadCellClasses={[
                                    "w-20",
                                    "w-50",
                                    "w-20",
                                    classes.right
                                ]}
                                customHeadClassesForCells={[
                                    1,
                                    2,
                                    3,
                                    4
                                ]}
                                tableData={this.setFiles(this.props.generated_files)}
                                tableHead={[
                                    "ID",
                                    "File Name",
                                    "Uploader",
                                    "Download"
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("downloadApplicationModal")}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(extendedFormsStyle)(DownloadGeneratedInvoiceDialog);
