const contactDefaults = {
    "geo_id": () => "AU",

    "details": () => ({
        "id": null,
        "first_name": {"value": "",
            "valid": ""},
        "last_name": {"value": "",
            "valid": ""},
        "company": {"value": "",
            "valid": ""},
        "position": {"value": "",
            "valid": ""},
        "tags": {"value": [],
            "valid": ""}
    }),

    "email": () => [
        {"id": null,
            "default": true,
            "value": "",
            "valid": ""}
    ],

    "phone": () => [
        {
            "id": null,
            "default": true,
            "service": "CELLULAR",
            "geo_country_id": contactDefaults.geo_id(),
            "value": "",
            "valid": "",
            "service_valid": "",
            "geo_valid": ""
        }
    ],

    "address": () => [
        {
            "id": null,
            "default": true,
            "address": "",
            "city": "",
            "state": "",
            "states": [],
            "geo_country_id": contactDefaults.geo_id(),
            "postcode": "",
            "address_valid": "",
            "city_valid": "",
            "state_valid": "",
            "geo_valid": "",
            "postcode_valid": ""
        }
    ],

    "note": () => [
        {
            "id": null,
            "author": "",
            "subject": "",
            "note": "",
            "author_valid": "",
            "subject_valid": "",
            "note_valid": ""
        }
    ],

    "website": () => [
        {"id": null,
            "url": "",
            "url_valid": ""}
    ],

    "document": () => [
        {
            "id": null,
            "filename": "",
            "storage_filename": "",
            "filename_valid": "",
            "filename_tmp": "",
            "file": null
        }
    ]
};

export default contactDefaults;
