import React from "react";

import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Form from "@rjsf/material-ui";

import Button from "../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
// import {Container} from "@material-ui/core";
import applyNavs from "../forms";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import CustomFileWidget from "./CustomFileWidget";
import HiddenWidget from "@rjsf/core/lib/components/widgets/HiddenWidget";
import Typography from "@material-ui/core/Typography";
import {findVal} from "customs/forms/utils";
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";

const FormWithPagination = applyNavs(Form),

    useStyles = makeStyles({
        "root": {
            "marginTop": 10
        }
    });

function ObjectFieldTemplate ({
    DescriptionField,
    description,
    TitleField,
    title,
    properties,
    required,
    uiSchema,
    idSchema
}) {
    const classes = useStyles();
    function getXs (element) {
        if (element.content.props.uiSchema["ui:field"] === "hidden") {
            return false;
        }
        return 12;
    }

    return (
        <>
            {(uiSchema["ui:title"] || title) &&
            <TitleField
                id={`${idSchema.$id}-title`}
                required={required}
                title={title}
            />}
            {description &&
            <DescriptionField
                description={description}
                id={`${idSchema.$id}-description`}
            />}
            <Grid
                className={classes.root}
                container
                spacing={0}
            >
                {properties.map((element, index) => {
                    let e = {...element};
                    if (typeof element.content.props.uiSchema === 'object' && Object.keys(element.content.props.uiSchema).length === 0) {
                        let ui = findVal(uiSchema, e.name);
                        if (typeof ui === 'object' && Object.keys(ui).length > 1) {
                            e = {
                                ...element,
                                content: {
                                    ...element.content,
                                    props: {
                                        ...element.content.props,
                                        uiSchema: ui
                                    }
                                }
                            };
                        }
                    }
                    return <Grid item key={index} style={{marginBottom: "10px"}} xs={getXs(element)}>
                        {e.content}
                    </Grid>
                })}
            </Grid>
        </>
    );

}

const LabelledFileField = (props) => {
    let title = props.label ? props.label : (props.title ? props.title : null)
    return (
        <>
        {title !== null
            ? <Typography
                    color="textSecondary"
                    component="div"
                    variant="caption"
                >{title}</Typography>
            : ''}
        <CustomFileWidget {...props}/>
        </>
    );
  };

const CustomHiddenWidget = (props) => {
    let {value, ...p} = props;
    return (
        <HiddenWidget {...p} value={typeof value === 'object' ? "" : value}/>
    );
}

const CustomInputAsLabelWidget = (props) => {
    let {id, label} = props;
    return (
        <label id={id} style={{fontSize: "16px", fontWeight: "normal", paddingTop: "3px"}}>
            {typeof label === 'object' ? "" : label}
        </label>
    );
}
  
class FormDialog extends React.Component {
    constructor (props) {

        super(props);
        this.state = {
            fd: props.form_data_init
        };
        this.validate = this.validate.bind(this);

    }

    widgets = {
        labelledFileField: LabelledFileField,
        HiddenWidget: CustomHiddenWidget,
        LabelWidget: CustomInputAsLabelWidget
    };

    handleOnChange = ({formData}) => {
        this.setState({fd: formData});
    }

    handleClose = () => {
        this.setState({fd: this.props.form_data_init});
        this.props.onClose();
    }

    handleSubmitForApproval = () => {
        this.props.submitForApproval(this.state.fd);
    }

    validate(formData, errors) {
        let count = helpers.countKeys(formData, true);
        if (formData.abn !== undefined && formData.abn.length > 0 && !validations.validateABN(formData.abn)) {
            errors.abn.addError("ABN is invalid.");
        }
        if (formData.website !== undefined && formData.website.length > 0) {
            let urls = formData.website.split(',');
            let url_error = false, invalids = [];
            for(let i=0; i<urls.length; i++) {
                let uri = urls[i].trim();
                if (uri.length > 0) {
                    if (!validations.validateURL(uri) && !validations.validateDomain(uri)) {
                        url_error = true;
                        invalids.push(uri + ' is invalid.');
                    }
                }
            }
            if (url_error) {
                errors.website.addError(invalids.join('\n'));
            }
        }
        if (formData.statement_address !== undefined && formData.statement_address.statement_trading_address !== undefined && formData.statement_address.statement_trading_address) {
            if (formData.other_address === undefined) {
                errors.other_address.addError("This field is required");
            }
            if (formData.other_suburb === undefined) {
                errors.other_suburb.addError("This field is required");
            }
            if (formData.other_state === undefined) {
                errors.other_state.addError("This field is required");
            }
            if (formData.other_post_code === undefined) {
                errors.other_post_code.addError("This field is required");
            }
        } else {
            // Reduce fields count since fields above are not required
            if (formData.other_address === undefined) {
                count--;
            }
            if (formData.other_suburb === undefined) {
                count--;
            }
            if (formData.other_state === undefined) {
                count--;
            }
            if (formData.other_post_code === undefined) {
                count--;
            }
        }
        this.props.onCountChange(count);

        return errors;
    }

    render () {
        return (
            <Dialog
                aria-labelledby="simple-dialog-title"
                onClose={this.handleClose}
                open={this.props.open}
                maxWidth="md"
            >
                <div>
                    <style>
                        {
                            ".Mui-error {white-space: break-spaces}" +
                            ".MuiFormControl-root.MuiTextField-root {max-width: 600px; width: 100%; margin: auto;}" +
                            ".MuiFormControl-root.MuiFormControl-fullWidth > label {max-width: 600px; width: 100%; margin: auto;}" +
                            ".MuiFormControl-root.MuiFormControl-fullWidth > div > label {max-width: 600px; width: 100%; margin: auto;}" +
                            ".MuiFormControl-root.MuiFormControl-fullWidth > span {max-width: 600px; width: 100%; margin: auto;}" +
                            ".MuiFormControl-root.MuiFormControl-fullWidth > div > p {max-width: 600px; width: 100%; margin: auto;}" +
                            ".MuiFormControl-root.MuiFormControl-fullWidth > div > ul {max-width: 600px; width: 100%; margin: auto;}" +
                            ".MuiFormControl-root.MuiFormControl-fullWidth > div.MuiTypography-root.MuiTypography-caption {max-width: 600px; width: 100%; margin: auto;}" +
                            ".MuiFormControl-root.MuiFormControl-fullWidth > div.MuiFormControl-root.MuiFormControl-fullWidth {max-width: 600px; width: 100%; margin: auto;}" +
                            ""
                        }
                    </style>
                    <DialogContent>
                        <FormWithPagination
                            disabled={this.props.disabled}
                            formData={this.state.fd}
                            ObjectFieldTemplate={ObjectFieldTemplate}
                            onSubmit={this.props.onSubmit}
                            schema={this.props.schema}
                            uiSchema={this.props.uiSchema}
                            widgets={this.widgets}
                            onChange={this.handleOnChange}
                            validate={this.validate}
                            showErrorList={false}
                            // liveValidate
                        >
                            <div style={{float: "right"}}>
                                {this.props.disabled
                                ? ""
                                :<Button
                                    color="primary"
                                    type="submit"
                                    variant="outlined"
                                >
                                    Save
                                </Button>}
                                {this.props.disabled
                                ? ""
                                : <Button
                                    color="warning"
                                    onClick={this.handleSubmitForApproval}
                                >
                                    Submit For Approval
                                </Button>}
                            </div>
                        </FormWithPagination>
                    </DialogContent>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(FormDialog);