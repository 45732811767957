import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class DuplicateClientScripts extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            client: "",
            scripts: [],
            clientScripts: this.props.clientScripts
        };
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.clientScripts !== null || nextProps.clientScripts !== undefined) {
            this.setState({
                "clientScripts": nextProps.clientScripts
            });
        }
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="update-profile-confirm-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => {this.setState({client: "", scripts: []}); this.props.onClose("duplicateClientScriptsModal");}}
                open={this.props.duplicateClientScriptsModal}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    Duplicate Client Script
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="update-profile-confirm-modal-slide-description"
                >
                    <FormControl
                        disabled={this.state.is_view}
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            className={classes.selectLabel}
                        >
                            Clients
                        </InputLabel>
                        <Select
                            value={this.state.client}
                            onChange={(event) => {
                                var id = event.target.value;
                                this.props.onChange(id);
                                this.setState({'client': id});
                            }}
                            MenuProps={{"className": classes.selectMenu}}
                            classes={{"select": classes.select}}
                            inputProps={{
                                name: 'clients',
                                id: 'clients'
                            }}
                        >
                            {this.props.clientsList !== null && this.props.clientsList.length !== []
                                ? this.props.clientsList.filter((prop, key) => {
                                    return this.props.client_id_script !== prop.id;
                                })
                                .map((prop, key) => 
                                    <MenuItem
                                        key={"client_" + key}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelectedMultiple
                                        }}
                                        value={prop.id}
                                    >
                                        {prop.id + ": " + (prop.username !== null && prop.username.length > 0 ? prop.username : prop.company)}
                                    </MenuItem>
                                    )
                                : ""}
                        </Select>
                    </FormControl>
                    {this.state.clientScripts !== null && this.state.clientScripts.length > 0
                    ?<FormControl
                        disabled={this.state.is_view}
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            className={classes.selectLabel}
                        >
                            Select Client Scripts
                        </InputLabel>
                        <Select
                            value={this.state.scripts}
                            onChange={(event) => {
                                var ids = event.target.value;
                                if (event.target.value.includes('all')) {
                                    ids = this.state.clientScripts.map(function(prop) { return prop.script_template_id; });
                                }
                                this.props.onMultipleChange(ids);
                                this.setState({'scripts': ids});
                            }}
                            multiple
                            MenuProps={{"className": classes.selectMenu}}
                            classes={{"select": classes.select}}
                            inputProps={{
                                name: 'clientScripts',
                                id: 'clientScripts'
                            }}
                        >
                            <MenuItem
                                key={"template_all"}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelectedMultiple
                                }}
                                value={'all'}
                            >
                                {"Select All"}
                            </MenuItem>
                            {this.state.clientScripts !== null && this.state.clientScripts.length > 0
                                ? this.state.clientScripts.map((prop, key) => 
                                    <MenuItem
                                        key={"template_" + key}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelectedMultiple
                                        }}
                                        value={prop.script_template_id}
                                    >
                                        {prop.name}
                                    </MenuItem>
                                    )
                                : ""}
                        </Select>
                    </FormControl>
                    :(
                        this.state.client.toString().length > 0 && !(this.state.clientScripts !== null && this.state.clientScripts.length > 0)
                            ? <span>No scripts available.<br/><br/></span> : ''
                    )}
                    <span style={{"color": "red"}}>Note: Existing script values will be overwritten.</span>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => {this.setState({client: "", scripts: []}); this.props.onClose("duplicateClientScriptsModal");}}
                    >
                        Close
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        disabled={this.props.btnDisabled}
                        onClick={(event) => this.props.handleDuplicateScripts(this.state.client, this.state.scripts)}
                        simple
                        >
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(DuplicateClientScripts);
