import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";
import AuthService from "customs/auth/AuthService";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Person from "@material-ui/icons/PersonOutline";
// Import LockOutline from "@material-ui/icons/LockOutline";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import SendResetEmailStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import {connect} from "react-redux";
import {doSendResetEmail} from "../../store/actions/authActions";
import { withRouter } from "react-router-dom";

class SendResetEmail extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "cardAnimation": "cardHidden",
            "username_valid": ""
        };

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value,
            [`${event.target.id}_valid`]: ""
        });
    }

    componentDidMount () {
        this.timeOutFunction = setTimeout(
            () => {
                this.setState({"cardAnimation": ""});
            },
            700
        );
    }

    componentWillUnmount () {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

  handleSendResetEmailClick = (event) => {
      event.preventDefault();
      this.handleSendResetEmail();
  };

  handleSendResetEmail () {
      const is_valid_username = validations.validateUsername(this.state.username);
      if (is_valid_username) {
          helpers.showLoading();
          this.props.doSendResetEmail(
              this.state.username,
              {...this.props}
          );
          this.setState({"username_valid": ""});
      } else {
          this.setState({
              "username_valid": is_valid_username ? "success" : "error"
          });
      }
  }

  handleKeyDown = (event) => {
      if (event.key === "Enter") {
          this.handleSendResetEmail();
      }
  };

  handleFormSubmit (event) {
      event.preventDefault();
  }

  handleLoginClick = () => {
      this.props.history.push("/auth/login");
  };

  render () {
      if (new AuthService(
          this.state,
          this.props
      ).getToken()) {
          return (
              <div>
                  <Redirect to="/admin/dashboard" />
              </div>
          );
      }


      const {classes} = this.props;
      return (
          <div className={classes.container}>
              <GridContainer justify="center">
                  <GridItem
                      md={4}
                      sm={6}
                      xs={12}
                  >
                      <form onSubmit={this.handleFormSubmit}>
                          <Card
                              className={classes[this.state.cardAnimation]}
                              login
                          >
                              <CardHeader
                                  className={`${classes.cardHeader} ${classes.textCenter}`}
                                  color="success"
                              >
                                  <h4 className={classes.cardTitle}>
                                      Password Reset
                                  </h4>
                              </CardHeader>
                              {this.props.reset_msg
                                  ? <CardBody style={{"paddingBottom": "0px"}}>
                                      <h4
                                          className={classes.textCenter}
                                          id="reset_password_status"
                                          style={{
                                              "color": this.props.reset_status,
                                              "marginBottom": "0px",
                                              "paddingBottom": "0px"
                                          }}
                                      >
                                          {this.props.reset_msg}
                                      </h4>
                                    </CardBody>
                                  : ""}
                              <CardBody style={{"paddingTop": "0px",
                                  "paddingBottom": "0px"}}
                              >
                                  <h5
                                      className={classes.socialLine}
                                      style={{"marginBottom": "0px",
                                          "paddingBottom": "0px"}}
                                  >
                                      Please enter your username below and click the Send Reset
                                      Email to reset your password.
                                  </h5>
                              </CardBody>
                              <CardBody>
                                  <CustomInput
                                      error={this.state.username_valid === "error"}
                                      formControlProps={{
                                          "fullWidth": true
                                      }}
                                      helperText={
                                          this.state.username_valid === "error" &&
                        this.state.username
                                              ? "Invalid username format"
                                              : undefined
                                      }
                                      id="username"
                                      inputProps={{
                                          "endAdornment":
                                            <InputAdornment position="end">
                                                <Person className={classes.inputAdornmentIcon} />
                                            </InputAdornment>,
                                          "type": "username",
                                          "onChange": this.handleChange,
                                          "onKeyDown": this.handleKeyDown
                                      }}
                                      labelText="Username..."
                                      success={this.state.username_valid === "success"}
                                  />
                              </CardBody>
                              <CardFooter
                                  className={classes.justifyContentCenter}
                                  style={{"paddingTop": "0px"}}
                              >
                                  <Button
                                      block
                                      color="success"
                                      onClick={(event) => this.handleSendResetEmailClick(event)}
                                      simple
                                      size="lg"
                                  >
                                      Send Reset Email
                                  </Button>
                                  <Button
                                      block
                                      color="info"
                                      onClick={this.handleLoginClick}
                                      simple
                                      size="lg"
                                  >
                                      Log In
                                  </Button>
                              </CardFooter>
                          </Card>
                      </form>
                  </GridItem>
              </GridContainer>
          </div>
      );
  }
}

SendResetEmail.propTypes = {
    "classes": PropTypes.object.isRequired,
    "reset_msg": PropTypes.string,
    "reset_status": PropTypes.string
};

const mapStateToProps = (state, props) => {

        helpers.hideLoading();
        return {
            "auth": state.auth.data,
            "reset_msg":
                state.auth && state.auth.data && state.auth.data.resetLinkMessage
                    ? state.auth.data.resetLinkMessage
                    : "",
            "reset_status":
                state.auth && state.auth.data && state.auth.data.authStatus
                    ? state.auth.data.authStatus
                    : ""
        };
    },

    mapDispatchToProps = (dispatch) => ({
        "doSendResetEmail": (username, props) => dispatch(doSendResetEmail(
            username,
            props
        ))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(SendResetEmailStyle)(SendResetEmail)));
