const AccessControl = {
    "checkRoutePermissions" (path, auth, action = "view") {
        const userPermissions = auth.user && auth.user.permissions ? auth.user.permissions : [];
        if (userPermissions.length === 0) {
            return true;
        }
        try {
            if (userPermissions[path]) {
                return userPermissions[path][action]
                    ? userPermissions[path][action]
                    : false;
            }
        } catch (error) {}
        return true;
    }
};

export default AccessControl;
