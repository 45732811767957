import moment from "moment-timezone";

const timezone_helpers = {
    getUtcOffset (timezone) {
        let offset = 0;
        try {
            if (moment.tz.zone(timezone) === null) {
                return null;
            } else {
                offset = moment.tz(timezone).utcOffset() / 60;
            }
        } catch (e) {
            offset = moment.tz("UTC").utcOffset() / 60;
        }
        return `UTC${offset < 0 ? "" : "+"}${offset}`;
    }
};

export default timezone_helpers;
