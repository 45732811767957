import * as authActionTypes from "../actions/authActionTypes";
import helpers from "customs/helpers/helpers";

const initState = {
        "data": {
            "token": null,
            "user": null,
            "authMessage": "",
            "authStatus": "",
            "resetLinkMessage": "",
            "resetPasswordMessage": "",
            "selectedClient": null
        }
    },

    executeLoginSuccess = (state, action) => {
        try {
            localStorage.setItem('admin_' + state.data.user.id + '_notifications', JSON.stringify([]));
        } catch(e) {}
        return {...state}
    },

    executeTokenLogin = (state, action) => ({
        ...state, ...{'showPages': true}
    }),

    executeLoginError = (state, action) => {
        const error = state.data && state.data.message === "Too Many Attempts."
            ? "Too Many Attempts."
            : (state.data.error
                ? state.data.error
                : "Invalid Credentials");
        state.data.authMessage = error;
        return {
            ...state
        };
    },

    executeDefault = (state, action) => ({
        ...state
    }),

    executeLogout = (state, action) => ({
        ...state, ...{'showPages': true, 'logout': true}
    }),

    executeUpdateProfileSuccess = (state, action) => {
        state.data.authMessage = "Profile updated";
        return {
            ...state
        };
    },

    executeUpdateProfileError = (state, action) => {
        const err = helpers.getErrorMessage(action);
        if (err === false) {
            state = initState;
        } else {
            state.data = action.props.auth;
            state.data.authMessage = err;
        }
        return {
            ...state
        };
    },

    executeChangePasswordSuccess = (state, action) => {
        state.data.authMessage = "Password changed";
        return {
            ...state
        };
    },

    executeChangePasswordError = (state, action) => {
        const err = helpers.getErrorMessage(action);
        if (err === false) {
            state = initState;
        } else {
            state.data = action.props.auth;
            state.data.authMessage = err;
        }
        return {
            ...state
        };
    },

    executeSendResetEmailSuccess = (state, action) => {
        state.data.resetLinkMessage = state.data && state.data.status
            ? state.data.status
            : "We have sent your password reset link! Please check your e-mail.";
        state.data.authStatus = "#4caf50";
        return {
            ...state
        };
    },

    executeSendResetEmailError = (state, action) => {
        state.data.resetLinkMessage = state.data && state.data.error
            ? state.data.error
            : "Unable to send password reset link to your e-mail.";
        state.data.authStatus = "red";
        return {
            ...state
        };
    },

    executeResetPasswordSuccess = (state, action) => {
        state.data.resetPasswordMessage = state.data && state.data.status
            ? state.data.status
            : "Password successfully changed. Please login with your new password.";
        state.data.authStatus = "#4caf50";
        return {
            ...state
        };
    },

    executeResetPasswordError = (state, action) => {
        state.data.resetPasswordMessage = state.data && state.data.error
            ? state.data.error
            : "The password reset token is invalid or has expired.";
        state.data.authStatus = "red";
        return {
            ...state
        };
    },

    executeUpdateProfileData = (state, action) => ({
        ...state
    }),

    executeRefreshTokenSuccess = (state, action) => ({
        ...state
    }),

    authReducer = (state = initState, action) => {
        if (action.response && action.response.data) {
            state.data = action.response.data;
        }
        initState.data.authMessage = "";
        state.data.authMessage = "";
        if (action.hasOwnProperty('selectedClient')) {
            state.data.selectedClient = action.selectedClient;
        }
        switch (action.type) {
            case authActionTypes.LOGIN_SUCCESS:
                return executeLoginSuccess(
                    state,
                    action
                );
            case authActionTypes.TOKEN_LOGIN_SUCCESS:
                return executeTokenLogin(
                    state,
                    action
                );
            case authActionTypes.LOGIN_ERROR:
                return executeLoginError(
                    state,
                    action
                );
            case authActionTypes.TOKEN_LOGIN_ERROR:
                return executeTokenLogin(
                    state,
                    action
                );
            case authActionTypes.DO_LOGOUT:
                return executeLogout(
                    initState,
                    action
                );
            case authActionTypes.UPDATE_PROFILE_SUCCESS:
                return executeUpdateProfileSuccess(
                    state,
                    action
                );
            case authActionTypes.UPDATE_PROFILE_ERROR:
                return executeUpdateProfileError(
                    state,
                    action
                );
            case authActionTypes.CHANGE_PASSWORD_SUCCESS:
                return executeChangePasswordSuccess(
                    state,
                    action
                );
            case authActionTypes.CHANGE_PASSWORD_ERROR:
                return executeChangePasswordError(
                    state,
                    action
                );
            case authActionTypes.SEND_RESET_EMAIL_SUCCESS:
                return executeSendResetEmailSuccess(
                    state,
                    action
                );
            case authActionTypes.SEND_RESET_EMAIL_ERROR:
                return executeSendResetEmailError(
                    state,
                    action
                );
            case authActionTypes.RESET_PASSWORD_SUCCESS:
                return executeResetPasswordSuccess(
                    state,
                    action
                );
            case authActionTypes.RESET_PASSWORD_ERROR:
                return executeResetPasswordError(
                    state,
                    action
                );
            case authActionTypes.UPDATE_PROFILE_DATA:
                return executeUpdateProfileData(
                    state,
                    action
                );
            case authActionTypes.DO_REFRESH_TOKEN:
                return executeRefreshTokenSuccess(
                    state,
                    action
                );
            case "updateSelectedClient":
                return {
                    ...state
                };
            default:
                return executeDefault(
                    state,
                    action
                );
        }
    };

export default authReducer;
