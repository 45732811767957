import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class SelectFileToPreview extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            files: this.props.files
        };
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.files !== null || nextProps.files !== undefined) {
            this.setState({
                "files": nextProps.files
            });
        }
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="update-profile-confirm-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => this.props.onClose("selectFileToPreviewModal")}
                open={this.props.selectFileToPreviewModal}
                maxWidth="md"
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    Selected Files
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="update-profile-confirm-modal-slide-description"
                >
                    <table width="100%"><tbody>
                        {
                            this.state.files.map((file, id) => {
                                return <tr key={id}>
                                    <td style={{paddingRight: "10px"}}>
                                        {file}
                                    </td>
                                    <td style={{width: "10%"}}>
                                        <Button color="success" className={this.props.classes.marginRight} type="button" onClick={() => {
                                            this.props.extensions.includes(file.split('.').pop().toLowerCase())
                                            ? this.props.onPreview(file)
                                            : this.props.onDownload(file.split(') ')[0].replace('(id: ', ''), file.split(') ')[1])
                                        }} disabled={this.props.readonly} >
                                            {this.props.extensions.includes(file.split('.').pop().toLowerCase()) ? "Preview" : "Download"}
                                        </Button>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody></table>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("selectFileToPreviewModal")}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(SelectFileToPreview);
