import React from "react";
import PropTypes from "prop-types";
// import IconButton from "@material-ui/core/IconButton";
// import Input from "@material-ui/core/Input";
// import Paper from "@material-ui/core/Paper";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/core/styles/withStyles";
// import classNames from "classnames";
// import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
// import Results from "customs/components/Results";

const styles = (theme) => ({
    "root": {
        "height": theme.spacing(6),
        "display": "flex",
        "justifyContent": "space-between"
    },
    "iconButton": {
        "color": theme.palette.action.active,
        "transform": "scale(1, 1)",
        "transition": theme.transitions.create(
            [
                "transform",
                "color"
            ],
            {
                "duration": theme.transitions.duration.shorter,
                "easing": theme.transitions.easing.easeInOut
            }
        )
    },
    "searchIconButton": {
        "marginRight": theme.spacing(-6)
    },
    "icon": {
        "transition": theme.transitions.create(
            ["opacity"],
            {
                "duration": theme.transitions.duration.shorter,
                "easing": theme.transitions.easing.easeInOut
            }
        )
    },
    "input": {
        "width": "100%",
        "paddingLeft": "10px"
    },
    "searchContainer": {
        "margin": "auto 16px",
        "width": `calc(100% - ${theme.spacing(6 + 4)}px)` // 6 button + 4 margin
    }
});

/**
 * Primary UI component for user interaction
 */
export const SearchBar = React.forwardRef((
    {
        cancelOnEscape,
        className,
        classes,
        closeIcon,
        disabled,
        onCancelSearch,
        onRequestSearch,
        searchIcon,
        style,
        ...props
    },
    ref
) => {
    const inputRef = React.useRef(),
        [
            value,
            setValue
        ] = React.useState(props.value);

    React.useEffect(
        () => {
            setValue(props.value);
        },
        [props.value]
    );

    const handleFocus = React.useCallback(
            (e) => {
                if (props.onFocus) {
                    props.onFocus(e);
                }
            },
            [props]
        ),

        handleBlur = React.useCallback(
            (e) => {
                setValue((v) => v.trim());
                if (props.onBlur) {
                    props.onBlur(e);
                }
            },
            [props]
        ),

        handleInput = React.useCallback(
            (e) => {
                setValue(e.target.value);
                if (props.onChange) {
                    props.onChange(e.target.value);
                }
            },
            [props]
        ),

        handleCancel = React.useCallback(
            () => {
                setValue("");
                if (onCancelSearch) {
                    onCancelSearch();
                }
            },
            [onCancelSearch]
        ),

        handleRequestSearch = React.useCallback(
            () => {
                if (onRequestSearch) {
                    onRequestSearch(value);
                }
            },
            [
                onRequestSearch,
                value
            ]
        ),

        handleKeyUp = React.useCallback(
            (e) => {
                if (e.charCode === 13 || e.key === "Enter") {
                    handleRequestSearch();
                } else if (
                    cancelOnEscape && (e.charCode === 27 || e.key === "Escape")
                ) {
                    handleCancel();
                }
                if (props.onKeyUp) {
                    props.onKeyUp(e);
                }
            },
            [
                props,
                handleRequestSearch,
                cancelOnEscape,
                handleCancel
            ]
        );

    return (
        <div className={classes.searchContainer}>
            <TextField
                {...props}
                className={classes.input}
                disabled={disabled}
                // disableUnderline
                fullWidth
                // InputProps={{
                //     "endAdornment":
                //         <InputAdornment>
                //             {" "}
                //             {value === ""
                //                 ? <IconButton
                //                     className={classNames(
                //                         classes.iconButton,
                //                         classes.searchIconButton
                //                     )}
                //                     disabled={disabled}
                //                     onClick={handleRequestSearch}
                //                 >
                //                     {React.cloneElement(
                //                         searchIcon,
                //                         {
                //                             "classes": {"root": classes.icon}
                //                         }
                //                     )}
                //                 </IconButton>
                //                 : <IconButton
                //                     className={classNames(classes.iconButton)}
                //                     disabled={disabled}
                //                     onClick={handleCancel}
                //                 >
                //                     {React.cloneElement(
                //                         closeIcon,
                //                         {
                //                             "classes": {"root": classes.icon}
                //                         }
                //                     )}
                //                 </IconButton>}
                //         </InputAdornment>

                // }}
                inputRef={inputRef}
                onBlur={handleBlur}
                onChange={handleInput}
                onFocus={handleFocus}
                onKeyUp={handleKeyUp}
                value={value}
            />
        </div>
    );

});

SearchBar.propTypes = {

    /** Whether to clear search on escape */
    "cancelOnEscape": PropTypes.bool,

    /** Override or extend the styles applied to the component. */
    "classes": PropTypes.object.isRequired,

    /** Custom top-level class */
    "className": PropTypes.string,

    /** Override the close icon. */
    "closeIcon": PropTypes.node,

    /** Disables text field. */
    "disabled": PropTypes.bool,

    /** Fired when the search is cancelled. */
    "onCancelSearch": PropTypes.func,

    /** Fired when the text value changes. */
    "onChange": PropTypes.func,

    /** Fired when the search icon is clicked. */
    "onRequestSearch": PropTypes.func,

    /** Sets placeholder text for the embedded text field. */
    "placeholder": PropTypes.string,

    /** Override the search icon. */
    "searchIcon": PropTypes.node,

    /** Override the inline-styles of the root element. */
    "style": PropTypes.object,

    /** The value of the text field. */
    "value": PropTypes.string
};

SearchBar.defaultProps = {
    "className": "",
    "closeIcon": <ClearIcon />,
    "disabled": false,
    "placeholder": "Search",
    "searchIcon": <SearchIcon />,
    "style": null,
    "value": "",
    "classes": styles
};

export default withStyles(styles)(SearchBar);
