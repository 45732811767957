import React from "react";
// Nodejs library that concatenates classes
import classNames from "classnames";
// Nodejs library to set properties for components
import PropTypes from "prop-types";

// Material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const RegularButton = React.forwardRef((props, ref) => {

    const {
            classes,
            color,
            round,
            children,
            fullWidth,
            disabled,
            simple,
            size,
            block,
            link,
            justIcon,
            className,
            muiClasses,
            ...rest
        } = props,
        btnClasses = classNames({
            [classes.button]: true,
            [classes[size]]: size,
            [classes[color]]: color,
            [classes.round]: round,
            [classes.fullWidth]: fullWidth,
            [classes.disabled]: disabled,
            [classes.simple]: simple,
            [classes.block]: block,
            [classes.link]: link,
            [classes.justIcon]: justIcon,
            [className]: className
        });
    return (
        <Button
            {...rest}
            classes={muiClasses}
            className={btnClasses}
            ref={ref}
        >
            {children}
        </Button>
    );

});

RegularButton.propTypes = {
    "classes": PropTypes.object.isRequired,
    "color": PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "rose",
        "white",
        "twitter",
        "facebook",
        "google",
        "linkedin",
        "pinterest",
        "youtube",
        "tumblr",
        "github",
        "behance",
        "dribbble",
        "reddit",
        "transparent"
    ]),
    "size": PropTypes.oneOf([
        "sm",
        "lg"
    ]),
    "simple": PropTypes.bool,
    "round": PropTypes.bool,
    "fullWidth": PropTypes.bool,
    "disabled": PropTypes.bool,
    "block": PropTypes.bool,
    "link": PropTypes.bool,
    "justIcon": PropTypes.bool,
    "className": PropTypes.string,
    "muiClasses": PropTypes.object,
    "children": PropTypes.node
};

export default withStyles(buttonStyle)(RegularButton);
