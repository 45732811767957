import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class LeadDetails extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "lead_source_id": this.props.lead_source_id,
            "name_valid": "",
            "lead_source_id_valid": "",
            "new_lead_name": "",
            "new_lead_name_valid": ""
        };
        this.handleSourceChange = this.handleSourceChange.bind(this);
        this.handleNewLeadSourceChange = this.handleNewLeadSourceChange.bind(this);
        this.handleYesClick = this.handleYesClick.bind(this);

    }

    handleChange = (event) => {
        this.setState({
            "name_valid": ""
        });
        this.props.handleChange(event);
    };

    handleNewLeadSourceChange = (event) => {this.setState({
        "new_lead_name": event.target.value,
        "new_lead_name_valid": ""
    });
    }

    handleSourceChange = (event) => {
        this.setState({
            "lead_source_id": event.target.value,
            "lead_source_id_valid": "",
            "new_lead_name_valid": ""
        });
    };

    handleYesClick() {
        if (this.props.name === undefined || this.props.name.length === null || this.props.name.length === 0) {
            this.setState({
                "name_valid": "error"
            });
        } else if (this.state.lead_source_id === "add_new" && (this.state.new_lead_name === undefined || this.state.new_lead_name.length === 0)) {
            this.setState({
                "new_lead_name_valid": "error"
            });
        } else if (this.state.lead_source_id === undefined || this.state.lead_source_id.length === 0) {
            this.setState({
                "lead_source_id_valid": "error"
            });
        } else {
            this.props.onYesClick(
                // lead_operator_id, lead_source_id, new_lead_source
                this.props.name,
                this.state.lead_source_id,
                this.state.new_lead_name
            );
        }
    }

    render () {const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="avatar-update-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{"root": `${classes.center} ${classes.modalRoot}`}}
                fullWidth
                keepMounted
                maxWidth="sm"
                onClose={() => this.props.onClose("leadDetailsModal")}
                open={this.props.leadDetailsModal}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.dialogTitle}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="avatar-update-modal-slide-description"
                    style={{"width": "100%",
                        "overflowX": "hidden"}}
                >
                    <GridContainer
                        key="details_grid"
                        style={{"borderTop": "#9c27b0 solid 1px"}}
                    >
                        <GridItem md={12}>
                            <CustomInput
                                error={this.state.name_valid === "error"}
                                formControlProps={{"fullWidth": true}}
                                id="name"
                                inputProps={{
                                    "onChange": this.handleChange,
                                    "value": this.props.name,
                                    "name": "Name",
                                    "disabled": this.props.is_view
                                }}
                                labelText="Name"
                                success={this.state.name_valid === "success"}
                            />
                        </GridItem>
                        <GridItem
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <FormControl
                                className={classes.selectFormControl}
                                fullWidth
                                >
                                <InputLabel
                                    className={classes.selectLabel}
                                    error={this.state.lead_source_id_valid === "error"}
                                    htmlFor="lead_source_id"
                                    success={this.state.lead_source_id_valid === "success" ? "success" : ""}
                                >
                                    Choose a Source
                                </InputLabel>
                                <Select
                                    classes={{"select": classes.select}}
                                    inputProps={{"name": "lead_source_id", "id": "lead_source_id"}}
                                    MenuProps={{"className": classes.selectMenu}}
                                    onChange={(event) => this.handleSourceChange(event)}
                                    value={this.state.lead_source_id}
                                >
                                    <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        disabled
                                    >
                                        Choose Source
                                    </MenuItem>
                                    <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        key="add_new"
                                        value="add_new"
                                    >
                                        New Lead Source
                                    </MenuItem>
                                    {this.props.leadSources
                                        ? this.props.leadSources.map((prop, key) => (<MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelectedMultiple
                                                }}
                                                key={key}
                                                value={prop.id}
                                            >
                                                {prop.name}
                                            </MenuItem>))
                                        : ""}
                                </Select>
                            </FormControl>
                        </GridItem>
                        {this.state.lead_source_id === "add_new"
                            ? <GridItem md={12}>
                                <CustomInput
                                    error={this.state.new_lead_name_valid === "error"}
                                    formControlProps={{"fullWidth": true}}
                                    id="new_lead_name"
                                    inputProps={{
                                        "onChange": this.handleNewLeadSourceChange,
                                        "value": this.state.new_lead_name,
                                        "name": "new_lead_name"
                                    }}
                                    labelText="Lead Source"
                                    success={this.state.new_lead_name_valid === "success"}
                                />
                            </GridItem>
                            : ""
                        }
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("leadDetailsModal")}
                    >
                        Close
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        disabled={this.props.btnDisabled}
                        onClick={this.handleYesClick}
                        simple
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(extendedFormsStyle)(LeadDetails);
