import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import helpers from "customs/helpers/helpers";
import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";

class ClientNote extends React.Component {

    constructor (props) {

        super(props);
        const {first_name, last_name} =  this.props && this.props.auth && this.props.auth.user ? this.props.auth.user : {};
        this.state = {
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "note_id":
        this.props.selected_note && this.props.selected_note.id
            ? this.props.selected_note.id
            : null,
            "author":
        this.props.selected_note && this.props.selected_note.author
            ? this.props.selected_note.author
            : first_name + ' ' + last_name,
            "subject":
        this.props.selected_note && this.props.selected_note.subject
            ? this.props.selected_note.subject
            : "",
            "note":
        this.props.selected_note && this.props.selected_note.note
            ? this.props.selected_note.note
            : "",
            "note_valid": ""
        };
        this.handleSaveNoteDetails = this.handleSaveNoteDetails.bind(this);

    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "client_note") {
            this.doSaveNoteDetails();
        }
    }

    handleSaveNoteDetails () {
        if (this.state.note.length > 0) {
            this.setState({
                "note_valid": "",
                "confirmModal": true,
                "confirmModalMsg": "Are you sure you want to save note details?",
                "confirmModalTitle": `${this.state.note_id === null ? "Create" : "Update"} Note`,
                "action": "client_note"
            });
        } else {
            this.setState({"note_valid": "error"});
        }
    }

    doSaveNoteDetails () {
        helpers.showLoading();
        const fd = new FormData();
        fd.append(
            "author",
            this.state.author
        );
        fd.append(
            "subject",
            this.state.subject
        );
        fd.append(
            "note",
            this.state.note
        );
        if (this.state.note_id != null) {
            fd.append(
                "_method",
                "PATCH"
            );
        }

        axios(this.props)
            .post(
                `/client/${
                    this.props.client_id
                }/note${
                    this.state.note_id !== null ? `/${this.state.note_id}` : ""}`,
                fd,
                {"headers": {"Content-Type": "application/x-www-form-urlencoded"}}
            )
            .then((response) => {
                if (this.isUnmounted) {
                    return;
                }
                helpers.hideLoading();
                this.props.onClose("client_note_details");
                this.props.setUsersNotification(
                    "Note saved",
                    "success"
                );
                this.props.refreshClientContactsTable(
                    null,
                    this.props.client_id
                );
            })
            .catch((error) => this.processErrorAxios(error));
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            (msg, color) => {
                this.props.setUsersNotification(
                    msg,
                    color
                );
            }
        );
    }

    change (event, stateName, validate) {
        this.setState({[stateName]: event.target.value});
        if (validate) {
            this.setState({
                [`${stateName}_valid`]: event.target.value.length === 0 ? "error" : ""
            });
        }
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="avatar-update-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{"root": `${classes.center} ${classes.modalRoot}`}}
                fullWidth
                keepMounted
                maxWidth="sm"
                onClose={() => this.props.onClose("client_note_details")}
                open={this.props.client_note_details}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.note_dialog_title}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="avatar-update-modal-slide-description"
                    style={{"width": "100%",
                        "overflowX": "hidden"}}
                >
                    <GridContainer
                        key="details_grid"
                        style={{"borderTop": "#9c27b0 solid 1px"}}
                    >
                        <GridItem md={6}>
                            <CustomInput
                                formControlProps={{"fullWidth": true}}
                                inputProps={{
                                    "onChange": (event) => this.change(
                                        event,
                                        "author",
                                        false
                                    ),
                                    "value": this.state.author,
                                    "name": "Author",
                                    "disabled": true //this.props.is_view
                                }}
                                labelText="Author"
                            />
                        </GridItem>
                        <GridItem md={6}>
                            <CustomInput
                                formControlProps={{"fullWidth": true}}
                                inputProps={{
                                    "onChange": (event) => this.change(
                                        event,
                                        "subject",
                                        false
                                    ),
                                    "value": this.state.subject,
                                    "name": "Subject",
                                    "disabled": this.props.is_view
                                }}
                                labelText="Subject"
                            />
                        </GridItem>
                        <GridItem
                            md={12}
                            sm={12}
                            xs={12}
                        >
                            <CustomInput
                                error={this.state.note_valid === "error"}
                                formControlProps={{
                                    "fullWidth": true
                                }}
                                id="note"
                                inputProps={{
                                    "value": this.state.note,
                                    "onChange": (event) => this.change(
                                        event,
                                        "note",
                                        true
                                    ),
                                    "multiline": true,
                                    "rows": 5,
                                    "disabled": this.props.is_view
                                }}
                                labelText={<span>
                                    Note
                                </span>}
                                success={this.state.note_valid === "success"}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("client_note_details")}
                    >
                        Close
                    </Button>
                    {!this.props.is_view
                        ? <Button
                            className={
                                `${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`
                            }
                            color="success"
                            disabled={this.props.btnDisabled}
                            onClick={(event) => this.handleSaveNoteDetails()}
                            simple
                          >
                            Save
                        </Button>
                        : ""}
                </DialogActions>
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.setState({"confirmModal": false})}
                    onYesClick={() => this.handleConfirmClick()}
                />
                {this.state.doneModalMsg
                    ? <Snackbar
                        close
                        closeNotification={() => {

                            this.handleCloseNotification();

                        }}
                        color={this.state.tr_color}
                        icon={AddAlert}
                        message={this.state.doneModalMsg}
                        open={this.state.tr}
                        place="tr"
                      />
                    : ""}
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(ClientNote);
