import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "canvas-toBlob";

import React from "react";
import ReactDOM from "react-dom";

import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";

import {Provider} from "react-redux";
import store from "./store/store.js";

import "@fortawesome/fontawesome-free/css/all.css";
import CheckAuth from "layouts/CheckAuth.jsx";

import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';

ReactDOM.render(
    <ClearBrowserCacheBoundary auto fallback='' duration={3600000}>
        <Provider store={store}>
            <CheckAuth />
        </Provider>
    </ClearBrowserCacheBoundary>
    ,
    document.getElementById("root")
);
