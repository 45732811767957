import axios from "axios";
import Constants from "customs/config/Constants";
import {doForceLogout, doRefreshToken} from "../store/actions/authActions";
import store from "../store/store.js";

const axiosAuth = (props) => {
    const instance = axios.create({
        "baseURL": Constants.API_URL
    });
    instance.interceptors.request.use(
        (config) => {
            const token = props.auth.token ? props.auth.token : "";
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        (error) => Promise.reject(error)
    );
    instance.interceptors.response.use(
        (response) => response,
        (error) => {
            const originalRequest = error.config;
            if (error.response.status === 401 && error.response.data.status === "Token is Expired") {
                const token = store.getState().auth.data.token
                    ? store.getState().auth.data.token
                    : (props.auth.token
                        ? props.auth.token
                        : "");
                return axios
                    .post(
                        `${Constants.API_URL}/client/refresh`,
                        {},
                        {
                            "headers": {
                                "Content-Type": "application/x-www-form-urlencoded",
                                "Authorization": `Bearer ${token}`
                            },
                            withCredentials: true
                        }
                    )
                    .then((res) => {
                        store.dispatch(doRefreshToken(
                            res,
                            props
                        ));
                        if (res.status === 200) {
                            originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
                            return axios(originalRequest);
                        }
                        return Promise.reject("");
                    })
                    .catch((error) => {
                        store.dispatch(doForceLogout(props));
                        try {
                            setTimeout(
                                window.location.replace("/client/auth/login"),
                                100
                            );
                        } catch (e) {}
                        return Promise.reject("");
                    });
            }
            return Promise.reject(error);
        }
    );
    return instance;
};

export default axiosAuth;
