import React from "react";
import Todo from "./ScriptStep/Todo";
import Checklist from "./ScriptStep/Checklist";
import GenerateApplication from "./ScriptStep/GenerateApplication";
import GenerateCredentials from "./ScriptStep/GenerateCredentials";
import GenerateMatchReport from "./ScriptStep/GenerateMatchReport";
import ExportWebdoshImportFile from "./ScriptStep/ExportWebdoshImportFile";
import GenerateInvoice from "./ScriptStep/GenerateInvoice";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";

function ProcessComponent (props) {
    const {step} = props;

    if (step.action_type === 'checklist') {
        return <Checklist {...props}/>;
    } else if (step.action_type === 'todo') {
        return <Todo {...props}/>;
    } else if (step.action_type === 'generate_application') {
        return <GenerateApplication {...props}/>;
    } else if (step.action_type === 'generate_credentials') {
        return <GenerateCredentials {...props}/>;
    } else if (step.action_type === 'generate_match_report') {
        return <GenerateMatchReport {...props}/>;
    } else if (step.action_type === 'export_webdosh_import') {
        return <ExportWebdoshImportFile {...props}/>;
    } else if (step.action_type === 'generate_invoice') {
        return <GenerateInvoice {...props}/>;
    }
    return "";
}

const StepComponent = withStyles(extendedFormsStyle)(ProcessComponent);

export default StepComponent;
