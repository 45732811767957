import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

import helpers from "customs/helpers/helpers";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.jsx";
import moment from "moment";

class DownloadGeneratedApplicationsDialog extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            
        }
    }

    setFileProgress(file) {
        if (file.hasOwnProperty('application') && file.application.hasOwnProperty('envelope_details')
            && file.application.envelope_details !== null && file.application.envelope_details.hasOwnProperty('progress')
            && file.application.envelope_details.progress.hasOwnProperty('signed') && file.application.envelope_details.progress.hasOwnProperty('total')) {
            return 'Signatures: ' + file.application.envelope_details.progress.signed + ' of ' + file.application.envelope_details.progress.total + 
                (file.application.envelope_details.progress.hasOwnProperty('declined') && file.application.envelope_details.progress.declined !== 0
                    ? " %0ADeclined to sign: " + file.application.envelope_details.progress.declined : "") + 
                (file.application.envelope_details.progress.hasOwnProperty('error') && file.application.envelope_details.progress.error !== 0
                    ? " %0ASend email failed: " + file.application.envelope_details.progress.error : "");
        }
        return '';
    }

    setFiles (files, acquirers) {
        let res = [];
        res = files.map((prop, key) => {
            const acquirer = prop.acquirer_id ? acquirers.find((o) => o.id === prop.acquirer_id) : null;
            const signable = Object.keys(acquirer.template_details.signing_config).length > 0 && moment.utc(prop.updated_at).isAfter(moment.utc("2022-05-09T00:00:00.000000Z"));
            return [
                prop.id,
                prop.filename ? prop.filename : "",
                acquirer ? acquirer.name : '',
                prop.uploader ? "(" + prop.uploader.type + ") " + prop.uploader.username : "",
                prop.status ? <span style={{"color": prop.status === "APPROVED" ? "#4caf50" : (prop.status === "DENIED" ? "#ff9800" : "#3b5998")}}>{prop.status}</span> : '',
                signable && prop.signature_status
                    ? <div style={{whiteSpace: "nowrap"}}>
                        <span style={{"color": prop.signature_status === "SIGNED" ? "#4caf50" : (prop.signature_status === "PENDING" ? "#ff9800" : "#3b5998")}}
                            title={prop.signature_status === "PENDING" ? decodeURI(this.setFileProgress(prop)) : ""}
                        >
                            {prop.signature_status}
                        </span>
                        {
                            prop.signature_status === "SIGNED"
                            ? ""
                            : <Button
                                title={prop.signature_status === "PENDING" ? 'Check Status' : 'Submit'}
                                className="view"
                                color="facebook"
                                justIcon
                                key={`btnS${key}`}
                                onClick={(event) => this.props.readOnly ? "" : this.props.handleApplicationSignature(event, prop)}
                                round
                                simple
                            >
                                <i
                                    className={prop.signature_status === "PENDING" ? "fa fa-spinner fa-sm" : "fa fa-paper-plane fa-sm"}
                                    key={`i_document_sig${key}`}
                                />
                            </Button>
                        }
                    </div>
                    : 'N/A',
                [
                    <Button
                        title={'Download file'}
                        className="view"
                        color="facebook"
                        justIcon
                        key={`btnF${key}`}
                        onClick={(event) => this.props.readOnly ? "" : this.props.handleDownloadFile(event, prop)}
                        round
                        simple
                    >
                        <i
                            className="fa fa-download fa-sm"
                            key={`i_document_${key}`}
                        />
                    </Button>
                ]
            ];
        });
        return res;
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="small-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={this.props.onClose}
                open={this.props.downloadApplicationModal}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {helpers.addLineBreaks(this.props.downloadApplicationModalMsg)}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="small-modal-slide-description"
                >
                    <GridContainer>
                        <GridItem md={12}>
                            <h5 style={{"minWidth": "200px",
                                "marginBottom": "0px"}}
                            >
                                {"Generated Files"}
                            </h5>
                            <Table
                                customCellClasses={[`no-wrap ${classes.right}`]}
                                customClassesForCells={[4]}
                                customHeadCellClasses={[
                                    "w-20",
                                    "w-20",
                                    "w-20",
                                    "w-15",
                                    "w-15",
                                    classes.right
                                ]}
                                customHeadClassesForCells={[
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6
                                ]}
                                tableData={this.setFiles(this.props.generated_files, this.props.acquirers)}
                                tableHead={[
                                    "ID",
                                    "File Name",
                                    "Acquirer",
                                    "Uploader",
                                    "Approval Status",
                                    "Signature",
                                    "Download"
                                ]}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("downloadApplicationModal")}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(extendedFormsStyle)(DownloadGeneratedApplicationsDialog);
