import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";

import instructionStyle from "assets/jss/material-dashboard-pro-react/components/instructionStyle.jsx";

function Instruction ({...props}) {

    const {
            classes,
            title,
            text,
            image,
            className,
            imageClassName,
            imageAlt
        } = props,
        instructionClasses = cx({
            [classes.instruction]: true,
            [className]: className !== undefined
        }),
        pictureClasses = cx({
            [classes.picture]: true,
            [imageClassName]: imageClassName !== undefined
        });
    return (
        <div className={instructionClasses}>
            <GridContainer>
                <GridItem
                    md={8}
                    sm={12}
                    xs={12}
                >
                    <strong>
                        {title}
                    </strong>
                    <p>
                        {text}
                    </p>
                </GridItem>
                <GridItem
                    md={4}
                    sm={12}
                    xs={12}
                >
                    <div className={pictureClasses}>
                        <img
                            alt={imageAlt}
                            className={classes.image}
                            src={image}
                        />
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    );

}

Instruction.defaultProps = {
    "imageAlt": "..."
};

Instruction.propTypes = {
    "classes": PropTypes.object.isRequired,
    "title": PropTypes.node.isRequired,
    "text": PropTypes.node.isRequired,
    "image": PropTypes.string.isRequired,
    "imageAlt": PropTypes.string,
    "className": PropTypes.string,
    "imageClassName": PropTypes.string
};

export default withStyles(instructionStyle)(Instruction);
