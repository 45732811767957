import React from "react";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import DownloadGeneratedInvoiceDialog from "customs/components/ScriptStep/DownloadGeneratedInvoiceDialog";

class GenerateInvoiceComponent extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            downloadApplicationModal: false,
            downloadApplicationModalMsg: this.props.step.step,

            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": ""
        };
        this.handleGenerateInvoiceDialog = this.handleGenerateInvoiceDialog.bind(this);
        this.allPreviousStepsCompleted = this.allPreviousStepsCompleted.bind(this);
        this.hasApprovedGeneratedApplication = this.hasApprovedGeneratedApplication.bind(this);
        this.handleDownloadApplicationDialog = this.handleDownloadApplicationDialog.bind(this);
    }

    handleDownloadApplicationDialog(show) {
        this.setState({downloadApplicationModal: show});
    }

    handleGenerateInvoiceDialog(show) {
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to continue?`,
            "confirmModalTitle": this.props.step.step,
            "action": "generate_invoice"
        });
    }

    handleConfirmClick () {
        this.setState({"confirmModal": false});
        if (this.state.action === "generate_invoice") {
            this.props.handleUpdateStep(
                this.props.step.id, this.props.step.client_script_id,
                {'form_data': {'generate' : 'invoice'}},
                () => {}
            );
        }
    }

    allPreviousStepsCompleted() {
        let allCompleted = true;
        Object.keys(this.props.checklist.steps).forEach((key) => {
            try {
                if (this.props.checklist.steps[key].action_type !== "generate_invoice" && this.props.checklist.steps[key].status !== 1) {
                    allCompleted = false;
                }
            } catch(e) {}
        });
        return allCompleted;
    }

    hasApprovedGeneratedApplication() {
        let hasGeneratedApplication = false;
        Object.keys(this.props.checklist.steps).forEach((key) => {
            try {
                if (this.props.checklist.steps[key].action_type === 'generate_application') {
                    let step = this.props.checklist.steps[key];
                    if (step.status === 1 && Object.keys(step.data).length > 0 && step.data[0] !== 0) {
                        hasGeneratedApplication = true;
                    }
                }
            } catch(e) {}
        });
        return hasGeneratedApplication;
    }

    render () {
        let generated_files = [];
        if (Object.keys(this.props.step.data).length > 0 && this.props.step.data[0] !== 0) {
            Object.keys(this.props.step.data).forEach((key) => {
                let file = this.props.client_files.find((file) => {
                    return file.id === this.props.step.data[key]['client_file_id'];
                });
                if (file) {
                    generated_files.push(file);
                }
            });
        }
        let hasGeneratedApplication = this.hasApprovedGeneratedApplication();
        return <div>
            <Button
                disabled={!hasGeneratedApplication || this.props.readOnly || !this.allPreviousStepsCompleted()}
                color="success"
                onClick={() => this.handleGenerateInvoiceDialog(true)}
            >
                {hasGeneratedApplication ? 'Generate' : 'No generated application'}
            </Button>
            {
                hasGeneratedApplication && generated_files.length > 0
                ? <Button
                    disabled={!(this.props.index > 0 ? this.props.checklist.steps[this.props.index - 1].status === 1 : true)}
                    color="primary"
                    onClick={() => this.handleDownloadApplicationDialog(true)}
                >
                    Show
                </Button>
                : ""
            }
            <ConfirmDialog
                confirmModal={this.state.confirmModal}
                confirmModalMsg={this.state.confirmModalMsg}
                confirmModalTitle={this.state.confirmModalTitle}
                onClose={() => this.setState({"confirmModal": false})}
                onYesClick={() => this.handleConfirmClick()}
            />
            {
                this.state.downloadApplicationModal
                    ? <DownloadGeneratedInvoiceDialog
                        generated_files={generated_files}
                        handleDownloadFile={this.props.handleDownloadFile}
                        downloadApplicationModal={this.state.downloadApplicationModal}
                        downloadApplicationModalMsg={this.state.downloadApplicationModalMsg}
                        onClose={() => this.handleDownloadApplicationDialog(false)}
                        readOnly={this.props.readOnly}
                    />
                    : ""
            }
        </div>;
    }
}

const GenerateInvoice = withStyles(extendedFormsStyle)(GenerateInvoiceComponent);

export default GenerateInvoice;
